import React, { useState, useEffect } from 'react';
import Statistic from "./Statistic.js";
import { useGlobalState, logout, showInfoMessage, isEmpty } from '../GlobalState.js';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import ToDoList from "./ToDoList.js";
import AddressBook from "./AddressBook.js";
import DuyuruPanel from './DuyuruPanel.js';
import UserStatistics from '../User/UserStatistics.js';
import AppMonthStatistic from '../Statistic/AppMonthStatistic.js';
import { ajaxFail } from '../GlobalState.js';
import EImzaListem from '../Application/EImzaListem.js';
import MessagePanel from '../User/MessagePanel.js';



export default function Home(props) {

    const [role, setRole] = useGlobalState('role');
    const [activepannel, setActivepannel] = useState(null);

    var [globalData, setGlobalData] = useGlobalState('globalData');

    const fetchGeneralData = () => {
        fetch("getgeneraldata", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(async data => {
                    setGlobalData(data);
                });
            }
        });
    }

    const initModal = () => {
        var locModal = document.getElementById('myModal');
        if (locModal == null) return;
        var btncloseup = document.getElementById('upclosemodal');
        var btnclosedown = document.getElementById('downclosemodal');


        btncloseup.addEventListener('click', (e) => {
            locModal.style.display = "none";
            locModal.classList.add("hide");
            if (document.getElementById("gosterme").checked == true) {
                disablePopup();
            }
        });
        btnclosedown.addEventListener('click', (e) => {
            locModal.style.display = "none";
            locModal.classList.add("hide");
            if (document.getElementById("gosterme").checked == true) {
                disablePopup();
            }
        });
    }



    const disablePopup = () => {
        fetch("disablepopup", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
        });
    }


    const fetchUserData = () => {
        fetch("getuser", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(async data => {
                    var userdata = JSON.parse(data);
                    if (userdata != null && userdata.showPopup == true && globalData != null && isEmpty(globalData.popupMessage) == false && globalData.popupMessage.length>12) {
                        showInfoMessage(globalData.popupMessage,"",  "Bir Daha Gösterme", "Tamam", disablePopup);
                    }
                });
            }
        });
    }


    useEffect(() => {
       
        fetchUserData();
    }, []);



    var stat = <Statistic />;
    var addressBook = <AddressBook />;
    var cal = <Calendar className="border border-rounded rounded-3 h-100 w-100" />;
    var todo = <ToDoList />;
    var duyuru = <DuyuruPanel />;
    var homepage = null;
var userstat = <UserStatistics counter={0} />;
    var appmonthstat = "";// <AppMonthStatistic />


    if (globalData != null && globalData.belediyeName != null) {
        homepage = <div className="text-center border  rounded border m-1 pt-3 pb-3 shadow">
            <img src={require('./logobig.jpeg').default} width="100%" />
            <h2 className="font-weight-bold pt-3" style={{ "text-shadow": "0px 0px 1px grey", color: "#0098db" }}>{globalData.belediyeName}</h2>
        </div>
    }


       

    const showPanel = (name) => {
        setActivepannel(name);
    }


    return (

        <div className="rounded-3" >
            <div className="" >
                <div className="row mb-2">
                    <div className="col-12 col-md-5">
                             {duyuru}

                    </div>
                    <div className="col-12 col-md-5">
                         {stat} 
                    </div>
                    <div className="col-12 col-md-2 pt-2" style={{ fontSize: "14px" }}>
                        <div className="border rounded-2 p-1 pt-3 pb-3 mb-3">
                            <i class="fa fa-bookmark-o text-primary me-1" style={{ fontSize: "18px" }}></i>

                            <a id="bekleyeneimza" href="#" className="text-decoration-none" onClick={() => { props.changeComponent(<EImzaListem changeComponent={props.changeComponent} />) }}>
                                Bekleyen E-İmza:0
                            </a>
                        </div>
                        <div className="border rounded-2 p-1 pt-3 pb-3 mb-3">
                            <i class="	fa fa-envelope-o text-primary" style={{ fontSize: "18px" }}></i><span id="yenibildirim"> Yeni Bildirim:0</span> 
                        </div>
                        <div className="border rounded-2 p-1 pt-2 mb-3">
                            <div className="fw-bold text-secondary pb-3">
                                <i class="fa fa-folder-open-o text-primary" style={{ fontSize: "18px" }}></i>  Başvurular
                            </div>
                            <div className="text-secondary pb-2"> <i class="fa fa-tag text-primary ms-3" style={{ fontSize: "18px" }}></i><span id="aktifbasvuru">Aktif:0</span> </div>
                            <div className="text-secondary pb-2"> <i class="fa fa-check-square-o text-primary ms-3" style={{ fontSize: "18px" }}></i><span id="tamamlananbasvuru">Tamamlanan:0</span>  </div>
                            <div className="text-secondary pb-2"> <i class="	fa fa-star text-primary ms-3" style={{ fontSize: "18px" }}></i><span id="toplambasvuru">Toplam:0</span></div>
 
                        </div>
                    </div>
                </div>

                <div className="row mt-3 mb-3">
                    <div className="col-md-6 col-12 rounded-3 " >
                        <MessagePanel messages={[]} changeComponent={props.changeComponent} />
                    </div>
                    <div className="col-md-6 col-12 " >
                        {todo}
                    </div>
                </div>
                <div className="row  mb-3 p-2  p-3">
                    <div className="col-12 col-md-12" >
                        <div className="bg-white rounded-3 m-1"> {appmonthstat}</div>
                    </div>
                </div>
            </div>


            {/*<div className="modal " id="myModal" >*/}
            {/*    <div class="modal-dialog modal modal-dialog-scrollable modal-dialog-centered modal-lg ">*/}
            {/*        <div class="modal-content shadow border-2 border-dark" >*/}
            {/*            <div class="modal-header bg-light">*/}
            {/*                <h4 class="modal-title">Bilgi</h4>*/}

            {/*                <button type="button" class="btn-close" id="upclosemodal" data-bs-dismiss="modal"></button>*/}

            {/*            </div>*/}
            {/*            <div class="modal-body " id="mymodalbody" >*/}

            {/*            </div>*/}


            {/*            <div class="modal-footer  bg-light">*/}
            {/*                <div className="form-check">*/}
            {/*                    <input className="form-check-input" type="checkbox" name="gosterme" id="gosterme" />*/}
            {/*                    <label className="form-check-label" for="gosterme">*/}
            {/*                        Tekrar Gösterme*/}
            {/*                    </label>*/}
            {/*                </div>*/}
            {/*                <button type="button" class="btn btn-danger" id="downclosemodal" data-bs-dismiss="modal">Close</button>*/}
            {/*            </div>*/}

            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    );
}
