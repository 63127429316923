import { Link } from "react-router-dom";
import NavBarProfileComponent from './NavBarProfileComponent';
import Title from './Title';
import React, { useState, useEffect } from 'react';
import MessageMenu from './MessageMenu';
import { useGlobalState } from '../GlobalState.js';
import * as UserPermConstants from '../User/UserPermConstants';

export default function TopHeader(props) {


    return (
        <div className="pb-2 border-bottom mb-1 border-bottom d-flex flex-row-reverse">
            <NavBarProfileComponent changeComponent={props.showPanel}/>
        </div>
    );



}