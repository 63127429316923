import React, { useState, useEffect } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import { ajaxFail, showConfirmMessage, showSuccessMessage } from '../GlobalState.js';

export default function AppTypeGroupSetting(props) {
    var postdata = {};
    const [listData, setListData] = useState([]);
    const multiselectRef = React.createRef();

    const multiselectRefNew = React.createRef();




    const fetchList = () => {
        fetch("apptypegroupslist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                response.json().then(value => {
                    setListData(value);
                })
            }
        });
    }


    const updateRecord = (rec, event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        rec[name] = value;
    }

    const renderTable = (tabledata) => {
        var mytable =
            <div className="table-responsive table-sm mainpagescroll"  style={{ "height": "85vh" }}>
                <table className='table table-vdstriped  ' aria-labelledby="tabelLabel">
                <thead style={{ "position": "sticky", "top": 0, "z-index": "1" }} className="bg-light ">
                    <tr>
                        <th width="35px">ID</th>
                        <th>Grup Adı</th>
                        <th>Sıra</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody id="myTable">
                    {tabledata.map(apprec =>
                        <tr key={apprec.id}>
                            <td>{apprec.id}</td>
                            <td><input type="text" className="form-control form-control-sm  " style={{ "background-color": "transparent" }} placeholder="Dosya Grubu" name="name" onChange={(e) => updateRecord(apprec, e)} defaultValue={apprec.name} /></td>
                            <td><input type="number" className="form-control form-control-sm  " style={{ "background-color": "transparent" }} placeholder="Sıra" name="sira" onChange={(e) => updateRecord(apprec, e)} defaultValue={apprec.sira} /></td>

                            <td className="text-end">  <button onClick={() => { save(apprec); }} className="btn btn-link btn-sm text-decoration-none m-0 p-0">Guncelle</button>
                                &nbsp;&nbsp;
                                <button onClick={() => { sil(apprec.id); }} className="btn btn-link btn-sm text-decoration-none m-0 p-0">Sil</button>
                            </td>
                        </tr>
                    )}
                    <tr className="bg-white">
                        <td></td>
                        <td>
                            <input type="text" className="form-control form-control-sm" id="name" placeholder="Dosya Grubu" name="name" onChange={handleInputChange} />
                        </td>
                        <td>
                            <input type="text" className="form-control form-control-sm" id="sira" placeholder="Sıra" name="sira" onChange={handleInputChange} />
                        </td>

                        <td className="text-end">  <button onClick={() => save(null)} className="btn btn-sm btn-link text-decoration-none m-0 p-0">Ekle</button>
                        </td>
                    </tr>
                </tbody>
            </table>
            </div>
        return (mytable);
    }

    const sil = (rec) => {
        showConfirmMessage(rec.id + " Numaralı Kaydı Silmek İstedinizden Emin Misiniz?", "", "Evet", "Hayır", () =>
            fetch("removeapptypegroup", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(rec)
            }).then(async response => {
                if (!response.ok) {
                    ajaxFail(response)
                }
                else {
                    response.json().then(value => {
                        setListData(value);
                    })
                }
            }));

    }



    const save = (rec) => {
        var r = rec;
        if (r == null)
            r = postdata;

        fetch("saveapptypegroup", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(r)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                if (rec != null) {
                    showSuccessMessage("Güncelleme Başarılı. ")
                }
                else {
                    document.getElementById("name").value = "";
                    document.getElementById("sira").value = "";
                    response.json().then(value => {
                        setListData(value);
                    })
                }
            }
        });

    }

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        postdata[target.name] = value;

        if (target.name == "deadline" && value == '')
            postdata[target.name] = null;

    }


    useEffect(() => {
        fetchList();
    }, [])


    var listTable = renderTable(listData);

    return (
        <div className="border  rounded border-light">
            <h5>Başvuru Tipi Grupları</h5>
            <div className="row">
                <div className="col-12">
                    {listTable}
                </div>
            </div>
        </div>

    );


}