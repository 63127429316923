import React, { useState, useEffect } from 'react';
import { ajaxFail, showConfirmMessage, showSuccessMessage } from '../GlobalState.js';


export default function UserTypePanel(props) {
    var postdata = {};
    const [listData, setListData] = useState(null);

    const updateRecord = (rec, event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        rec[name] = value;
    }

    const removeRecord = (id) => {

        showConfirmMessage(id + " Numaralı Kaydı Silmek İstedinizden Emin Misiniz?", "","Evet","Hayır",()=>

        fetch("removeapptype", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(id)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                fetchList();
            }
        }) )
    }



    const submitUpdateRecord = (rec) => {

        fetch("updateapptype", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(rec)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                showSuccessMessage("Güncelleme Başarılı")
            }
        });
    }
    const fetchList = () => {

        fetch("getapptypelist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    setListData(value);
                })
            }
        });
    }

    const Add = () => {
        var apptype = { appType: document.getElementById("basvuruTuru").value, gizli: document.getElementById("gizli").checked }
        fetch("addapptype", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(apptype)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                document.getElementById("basvuruTuru").value = "";
                document.getElementById("gizli").checked = false;
                fetchList();
            }
        });

    }


    const renderTable = (tabledata) => {
        var mytable =
            <div className="table-responsive  mainpagescroll" style={{ "height": "85vh" }}>
            <table className='table table-vdstriped  table-sm' aria-labelledby="tabelLabel">
                    <thead style={{ "position": "sticky", "top": 0, "z-index": "1" }} className="bg-light">
                        <tr>
                            <th className="text-start">No</th>
                            <th className="text-start">Kullanıcı Tipi</th>
                            <th className="text-start">Gizli</th>
                            <th className="text-start">Kayıt Tarihi</th>
                            <th className="text-start">Ekleyen</th>
                            <th className="text-start"></th>
                    </tr>
                </thead>
                <tbody id="myTable">
                    {tabledata.map(apprec =>
                        <tr key={apprec.id}>
                            <td>{apprec.id}</td>
                            <td>
                                <input type="text" className="form-control form-control-sm" placeholder="Kullanıcı Tipi" name="appType"  defaultValue={apprec.appType} onChange={(e) => updateRecord(apprec, e)} />
                            </td>
                            <td className="text-center">
                                <input type="checkbox" className="form-check-input"  name="gizli" defaultChecked={apprec.gizli} onChange={(e) => updateRecord(apprec, e)} />
                            </td>
                            <td>{apprec.regDate}</td>
                            <td>{apprec.userName}</td>

                            <td>
                                <button onClick={() => submitUpdateRecord(apprec)} className="btn btn-link btn-sm text-decoration-none m-0 p-0">Güncelle</button>&nbsp;
                                <button onClick={() => removeRecord(apprec.id)} className="btn btn-link btn-sm text-decoration-none m-0 p-0">Sil</button>
                            </td>
                        </tr>
                    )}
                    <tr className="bg-white">
                        <td></td>
                        <td>
                            <input type="text" className="form-control form-control-sm" id="name" placeholder="Yeni Kullanıcı Tipi" id="basvuruTuru" name="name" defaultValue="" />
                        </td>
                        <td className="text-center">
                            <input type="checkbox" className="form-check-input" name="gizli" id="gizli" defaultChecked={false}  />
                        </td>
                        <td></td>
                        <td></td>
                        <td>  <button onClick={Add} className="btn btn-sm btn-link text-decoration-none m-0 p-0">Ekle</button></td>
                    </tr>
                </tbody>
            </table>
            </div>
        return (mytable);
    }





    useEffect(() => {
        fetchList();
    }, [])


    var listTable = "";
    if (listData != null) {
        listTable = renderTable(listData);
    }
    else
        listTable = renderTable([]);

    return (
        <div>

            <h5 className="ps-1">Kullanıcı Tipleri</h5>
            <div className="row">
                <div className="col-12">
                    {listTable}
                </div>
            </div>


           
        </div>

    );


}