import React, { useState, useEffect } from 'react';
import { ajaxFail, sendpost, showConfirmMessage } from '../GlobalState.js';
import $ from 'jquery';
import Application from "./Application.js"

export default function RemovedFiles(props) {

    const [filelist, setFilelist] = useState([]);
    const [sortDirection, setSortDirection] = useState({ "transactionUUID": 1 });

    const changeComponent = props.changeComponent;
    var sortField = null;

    useEffect(() => {
        getFileList();
    }, [])


    const myCompare = (a, b) => {
        if (sortField == null)
            sortField = "transactionUUID"
        var av = a[sortField];
        var bv = b[sortField];

        if (av == null && a.imza != null) {
            av = a.imza[sortField];
        }
        if (bv == null && b.imza != null) {
            bv = b.imza[sortField];
        }
        if (av == null && bv == null)
            return 0;
        if (av == null && bv != null)
            return -1 * sortDirection[sortField];
        if (av != null && bv == null)
            return 1 * sortDirection[sortField];

        if (typeof av === 'string' || av instanceof String)
            if (typeof bv === 'string' || bv instanceof String)
                return av.localeCompare(bv) * sortDirection[sortField];

        if (av < bv) {
            return -1 * sortDirection[sortField];
        }
        if (av > bv) {
            return 1 * sortDirection[sortField];
        }
        return 0;
    }


    const sortList = (fildname) => {
        sortField = fildname;

        if (sortDirection[sortField] == null || sortDirection[sortField] == -1) {
            sortDirection[sortField] = 1;
        }
        else {
            sortDirection[sortField] = -1;
        }

        var temp = [...filelist]; //clone the array to update the component on setApplist
        temp.sort(myCompare);
        setFilelist(temp);
    }

    

    const getFileList = () => {

        fetch("getremovedfiles", {
            method: 'GET',
            headers: {
                'Accept': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }

            else {
                response.json().then(async data => {
                    setFilelist(data);
                });
            }
        });
    }

    const removefile = (rec) => {

        showConfirmMessage(rec.caption + " dosyasını silmek istediğinizden emin misiniz?",
            '', 'Evet', 'Hayır', () => {
                sendpost("removefile", rec,"", () => {
                    setFilelist(filelist.filter(x => x.id != rec.id));
                })
            });
    }

    const removeRecs = () => {
        var r = [];
        $("#myTable tr").each(function () {
            if ($(this).is(":visible"))
                r.push(filelist.find(x =>x.id == this.id));

        });
        if (r.length > 0) {
            showConfirmMessage( r.length + " dosyayı silmek istediğinizden emin misiniz?",
                '', 'Evet', 'Hayır', () => {
                    sendpost("removefiles", r,"removeallspin", () => {
                        document.getElementById("arama").value = "";
                        $("#myTable tr").each(function () {
                            $(this).toggle(true)
                        });
                        getFileList();
                    })
                });
        }
    }
    

    const filterTable = event => {
        const target = event.target;
        const value = target.value.toLowerCase();
        $("#myTable tr").filter(function () {
            var keywords = value.split(" ");
            for (let index = 0; index < keywords.length; ++index)
                if ($(this).text().toLowerCase().indexOf(keywords[index]) == -1)
                    return $(this).toggle(false);
            return $(this).toggle(true);
        });
        document.getElementById("totalrec").innerHTML = $("#myTable tr:visible").length + " kayıt";
    }


    return <div className="align-items-center justify-content-center" >
        <div className="row mb-2">
            <div className="col-6 text-center rounded-3">
                <input className="form-control form-control-sm" id="arama" type="text" placeholder="Ara..." onKeyUp={filterTable} />
            </div>
            <div className="col-6 text-end rounded-3">
                <span className="spinner-border spinner-border-sm" hidden id="removeallspin"></span>
                < button className="btn btn-sm btn-danger  text-decoration-none p-1" type="button" onClick={removeRecs}>
                   Tümünü Sil
                </button>
              
            </div>
        </div>

        <div className="mb-1 w-100  text-center align-items-center justify-content-center ">
           
        </div>
        <div className="overflow-auto" style={{ maxHeight: 600 }}>

            <table className='table table-vdstriped table-sm' aria-labelledby="tabelLabel">
                <thead>
                    <tr >

                        <th className="text-start">
                            <div>
                                <button className="btn btn-link btn-sm text-decoration-none" onClick={() => sortList("appId")} >Başvuru No </button>
                            </div>
                        </th>

                        <th className="text-start">
                            <div>
                                <button className="btn btn-link btn-sm text-decoration-none" onClick={() => sortList("id")} >Dosya No </button>
                            </div>
                        </th>
                        <th className="text-start">
                            <div>
                                <button className="btn btn-link btn-sm text-decoration-none" onClick={() => sortList("caption")} >Dosya İsmi </button>
                            </div>
                        </th>
                        {/*<th className="text-start">*/}
                        {/*    <div>*/}
                        {/*        <button className="btn btn-link btn-sm text-decoration-none" onClick={() => sortList("typeId")} >Dosya Turu </button>*/}
                        {/*    </div>*/}
                        {/*</th>*/}
                        <th className="text-start">
                            <div>
                                <button className="btn btn-link btn-sm text-decoration-none" onClick={() => sortList("userName")} >Ekleyen </button>
                            </div>
                        </th>
                        <th className="text-start">
                            <div>
                                <button className="btn btn-link btn-sm text-decoration-none" onClick={() => sortList("removedBy")} >Silen </button>
                            </div>
                        </th>

                        <th className="text-start">
                            <div>
                                <button className="btn btn-link btn-sm text-decoration-none" onClick={() => sortList("tarih")} >Ekleme Tarihi </button>
                            </div>
                        </th>

                        <th className="text-start">
                            <div>
                                <button className="btn btn-link btn-sm text-decoration-none" onClick={() => sortList("removedDate")} >Silme Tarihi </button>
                            </div>
                        </th>

                        <th className="text-start">
                            <div id="totalrec" key={filelist.length}>
                                {filelist.length}  kayıt
                            </div>
                        </th>

                    </tr>
                </thead>


                <tbody id="myTable">
                    {filelist.map(apprec => <tr id={apprec.id}>
                        <td >
                            {apprec.appId}
                        </td>
                        <td >
                            {apprec.id}
                        </td>
                        <td >
                            {apprec.caption}
                        </td>
                        {/*<td >*/}
                        {/*    {apprec.typeId}*/}
                        {/*</td>*/}
                        <td >
                            {apprec.userName}
                        </td>
                        <td >
                            {apprec.removedBy}
                        </td>
                        <td >
                            {apprec.tarih}
                        </td>
                        <td >
                            {apprec.removedDate}
                        </td>
                       
                        <td style={{ "overflow": "hidden", "white-space": "nowrap" }}>
                            <a className="text-decoration-none align-middle" href={'getfile?id=' + apprec.id + "&appid=" + apprec.appId + "&sec=" + apprec.readers} title="Aç" target="_blank"><i className="	fa fa-television" style={{ "font-size": "18px" }} ></i>  </a>
                            <a href="#" onClick={() => { removefile(apprec) }} className=" text-decoration-none "><i className="fa fa-trash align-middle" style={{ "font-size": "20px" }}></i></a>
 
                        </td>
                    </tr>
                    )}
                </tbody>
            </table>
        </div>
    </div>







}


