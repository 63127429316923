import React, { useState, useEffect } from 'react';
import Application from "./Application.js"
import $ from 'jquery';
import { ajaxFail, removeTurkcLow, showErrorMessage } from '../GlobalState.js';

export default function TakipEdilenler(props) {
    const [takiplist, setTakiplist] = useState(null);

    useEffect(() => {
        fetchTakipList();

    }, [])

    const fetchTakipList = (e) => {

        var refreshButton = document.getElementById("refreshButton");
        if (refreshButton != null) {
            refreshButton.classList.remove("fa", "fa-refresh")
            refreshButton.classList.add("spinner-grow", "spinner-grow-sm")
        }

        fetch("takipedilenbasvurular", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {

            var refreshButton = document.getElementById("refreshButton");
            if (refreshButton != null && refreshButton.classList.contains("fa-refresh") == false) {
                refreshButton.classList.remove("spinner-grow", "spinner-grow-sm")
                refreshButton.classList.add("fa", "fa-refresh")
            }

            if (!response.ok) {
                response.text().then(async data => {
                    showErrorMessage(data);
                    setTakiplist([])
                });
            }
            else {
                response.json().then(async data => {
                    setTakiplist(data)
                });
            }
        });
    }

    const  filterTable = (event) => {
        const target = event.target;
        const value = target.value.toLowerCase();
        $("#myTable tr").filter(function () {
            var keywords = value.split(" ");
            for (let index = 0; index < keywords.length; ++index)
                if (removeTurkcLow($(this).text()).indexOf(removeTurkcLow(keywords[index])) == -1)
                    return $(this).toggle(false);
            return $(this).toggle(true);
        });
    }



    const renderToList = () => {

        if (takiplist == null )
            return <div className="w-100 mt-4 mb-4 text-center align-items-center justify-content-center" ><div className="spinner-border" ></div></div>


        if (takiplist.length == 0)
            return <div className="w-100 mt-4 mb-4 text-center align-items-center justify-content-center text-danger" >Kayıt Yok</div>

        var mytable =
            <div className="mt-1">
                <div className="mt-1 p-2 row" style={{ "background-color": "#fff8dd" }}>
                    <div className="col-12 col-md-4">
                    </div>
                    <div className="col-12 col-md-4">
                        Arama
                        <input className="form-control form-control-sm" id="myInput" type="text" placeholder="" onKeyUp={filterTable} />
                    </div>
                    <div className="col-12 col-md-4 text-end pt-2">
                        <a onClick={fetchTakipList} href="#" className="text-primary"> <i className="fa fa-refresh" id="refreshButton"></i></a>
                    </div>
                </div>


                <div className="table-responsive">
                    <table className='table mt-3 table-vdstriped' aria-labelledby="tabelLabel">
                        <tbody id="myTable">
                            {takiplist.map(apprec =>
                                <tr key={apprec.id} className="p-1">
                                    <td>
                                        {apprec.id}
                                    </td>
                                    <td>
                                        {apprec.basvuruTipi}
                                    </td>
                                    <td>
                                        {apprec.mevki}
                                    </td>
                                    <td>
                                        {apprec.mahalle}
                                    </td>
                                    <td>
                                        {apprec.ada}
                                    </td>
                                    <td>
                                        {apprec.basvuruYapan}
                                    </td>
                                    <td>
                                        {apprec.arsaSahibi}
                                    </td>
                                    <td>
                                        {apprec.basvuruTarihi}
                                    </td>
                                    <td>
                                        <div style={{ "overflow": "hidden", "white-space": "nowrap" }}>
                                            {apprec.removed != true && <a href="#" onClick={() => { props.changeComponent(<Application key={apprec.id} appData={apprec} changeComponent={props.changeComponent} backButton={<TakipEdilenler changeComponent={props.changeComponent} />} />) }} className=" text-decoration-none "><i className="fa fa-edit align-middle" style={{ "font-size": "24px" }}></i></a>}
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        return (mytable);
    }

    return (
        <div>
                {renderToList()}
    </div>
    );


}



