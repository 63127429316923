import React, { useState, useEffect } from 'react';
import { ajaxFail, isEmpty, showConfirmMessage, showErrorMessage, showSuccessMessage } from '../GlobalState.js';
import { useGlobalState } from '../GlobalState.js';

import $ from 'jquery';

export default function MaksTasiyici(props) {

    const [rec, setRec] = useState({});
    const [maksrec, setMaksRec] = useState(props.rec);
    const [takiplist, setTakiplist] = useState(null);

    useEffect(() => {
        fetchList();

    }, [])

    const remove = (id) => {

        showConfirmMessage("Silmel istediğinizden emin misniz?", "", "Evet", "Hayır", () => {
            fetch("removemakstasiyici", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(id)
            }).then(async response => {
                if (!response.ok) {
                    ajaxFail(response)
                }
                else {
                    response.json().then(value => {
                        setTakiplist(value)
                    })
                }
            });
        });
    }

    const fetchList = (e) => {
        fetch("makstasiyicilist", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            ,
            body: JSON.stringify(maksrec.id)
        }).then(async response => {


            if (!response.ok) {
                response.text().then(async data => {
                    showErrorMessage(data);
                    setTakiplist([])
                });
            }
            else {
                response.json().then(async data => {
                    setTakiplist(data)
                });
            }
        });
    }



    const getDefult = (name) => {
        if (rec == null)
            return "";
        return rec[name];
    }



    const save = () => {
        rec.maksBasvuruId = maksrec.id;

        fetch("savemakstasiyici", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(rec)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                response.json().then(value => {
                    $('.root').find('input').val('');
                    setTakiplist(value)

                })
            }
        });
    }





    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        rec[target.name] = value;
    }



    var recid = getDefult("id");

    useEffect(() => {

    }, [])




    const getName = (value) => {
        if (value == 1) return value + "- Yok";
        if (value == 2) return value + "- İskelet(Karkas)"
        if (value == 3) return value + "- Yığma(Kagir)"
        if (value == 4) return value + "- Prefabrik";
        if (value == 5) return value + "- Yarı Prefabrike"
        if (value == 6) return value + "- Karma"
        if (value == 7) return value + "- Diğer";
        if (value == 8) return value + "- İskelet(Karkas) --> Betonarme";
        if (value == 9) return value + "- İskelet(Karkas) --> Ahşap";
        if (value == 10) return value + "- İskelet(Karkas) --> Çelik";
        if (value == 11) return value + "- İskelet(Karkas) --> Betonarme --> Çerçeveli Sistem";
        if (value == 12) return value + "- İskelet(Karkas) --> Betonarme --> Perdeli Sistem";
        if (value == 13) return value + "- İskelet(Karkas) --> Betonarme --> Çerçeveli + Perdeli Sistem";

        return "";

    }




    const renderToList = () => {

        if (takiplist == null)
            return <div className="w-100 mt-4 mb-4 text-center align-items-center justify-content-center" ><div className="spinner-border" ></div></div>


        if (takiplist.length == 0)
            return <div className="w-100 mt-4 mb-4 text-center align-items-center justify-content-center text-danger" >Kayıt Yok</div>

        var mytable =
            <div className="mt-1 ">
                <div className="table-responsive ">
                    <table className='table table-vdstriped border' aria-labelledby="tabelLabel">
                        <tbody id="myTable">
                            {takiplist.map(apprec =>
                                <tr key={apprec.id} style={{ "height": "25px" }}>
                                    <td>
                                        {getName(apprec.tasiyiciKod)}
                                    </td>
                                    <td>
                                        {apprec.regDate}
                                    </td>

                                    <td>
                                        {apprec.kayitNo}
                                    </td>
                                    <td>
                                        {apprec.diger}
                                    </td>
                                    <td>
                                        <div style={{ "overflow": "hidden", "white-space": "nowrap" }} className="text-end">
                                            {<a href="#" className=" text-decoration-none " onClick={() => { remove(apprec.id) }}> <i className="fa fa-trash align-middle" style={{ "font-size": "20px" }}></i></a>}
                                        </div>

                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        return (mytable);
    }


    if (maksrec == null || maksrec.id == null) {
        return <div className="text-start  text-muted ">
            İçme Su eklemek için önce başvuruyu kayediniz
        </div>
    }



    return (
        <div className="mb-2 root">


            <div className="row">
                <div className="text-start col-12 fw-bold text-primary">
                    Tasiyici Sistem
                </div>

            </div>
            <div className="row mt-1">
                <div className="text-start col-3">
                    <label className="form-label">Tasiyici Sistem </label>
                    <select class="form-select form-select-sm" defaultValue={getDefult("tasiyiciKod")} id="tasiyiciKod" name="tasiyiciKod" onChange={handleInputChange} >
                        <option selected></option>

                            <option value="1">Yok</option>
                            <option value="2" >İskelet(Karkas)</option>
                            <option value="3" >Yığma(Kagir)</option>
                            <option value="4" >Prefabrik</option>
                            <option value="5">Yarı Prefabrik</option>
                            <option value="6" >Karma</option>
                            <option value="7">Diğer</option>
                            <option value="8">İskelet(Karkas) --> Betonarme</option>
                            <option value="9">İskelet(Karkas) --> Ahşap</option>
                            <option value="10">İskelet(Karkas) --> Çelik</option>
                            <option value="11">İskelet(Karkas) --> Betonarme --> Çerçeveli Sistem</option>
                            <option value="12">İskelet(Karkas) --> Betonarme --> Perdeli Sistem</option>
                            <option value="13">İskelet(Karkas) --> Betonarme --> Çerçeveli + Perdeli Sistem</option>
                       
                    </select>
                </div>
                <div className="text-start col-3">
                    <label className="form-label">Diğer </label>
                    <input type="text" className="form-control form-control-sm" id="diger" name="diger" defaultValue={getDefult("diger")} onChange={handleInputChange} />
                </div>
                <div className="text-end col-6">
                    <button className="btn btn-sm btn-link" onClick={() => { save() }} ><i className="fa fa-plus align-middle" style={{ "font-size": "20px" }}></i></button>
                </div>
            </div>

            {renderToList()}
        </div>

    );


}