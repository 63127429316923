import React, { useState, useEffect } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import { isEmpty, useGlobalState } from '../GlobalState.js';
import axios from "axios";
import { ajaxFail } from '../GlobalState.js';
import HelpVideoPanel from './HelpVideoPanel.js';
import $ from 'jquery';
import { logout, passFilter, removeTurkcLow } from '../GlobalState.js';


export default function HelpSettingPanel(props) {
    var postdata = {};
    postdata["FileType"] = "pdf";
    var selectedRecord = null;
    const [listData, setListData] = useState(null);
    const [makaleList, setMakaleList] = useState(null);
    const [formList, setFormList] = useState(null);
    const [userData, setUserData] = useState([]);
    var multiselectRef = React.createRef();
    const [role, setRole] = useGlobalState('role');
    const [myuserName, setuserName] = useGlobalState('userName');


    useEffect(() => {
        fetchUserData();


    }, [])


    const fetchUserData = () => {
        var param = "?user=" + myuserName;
        fetch("getuser" + param, {
            method: 'GET'
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                response.json().then(async data => {
                    setUserData(data);
                    getHelpList();
                    fetchMakaleList()
                    fetchSampleFormList();
                });
            }
        });
    }


    const filterTable = (event) => {
        var textbox = document.getElementById("ara")
        if (textbox == null)
            return;
        var value = textbox.value.toLowerCase();

        $("#myTable div.filterable").filter(function () {
            var keywords = value.split(" ");
            var ret = true;
            var t1 = removeTurkcLow($(this).text());
            for (let index = 0; index < keywords.length; ++index) {
                var t2 = removeTurkcLow(keywords[index]);
                if (t1.indexOf(t2) == -1)
                    return $(this).toggle(false);
            }
            return $(this).toggle(true);
        });

    }


    const fetchMakaleList = () => {

        fetch("getmakalelist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    setMakaleList(value);
                })
            }
        });
    }


    const fetchSampleFormList = () => {

        fetch("getsampleformlist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    setFormList(value)
                })
            }
        });
    }



    const getHelpList = () => {
        fetch("gethelplist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    setListData(value)

                })
            }
        });
    }

    const getFileIcon = (filname) => {
        if (filname.endsWith("pdf")) return <i class="fa fa-file-pdf-o text-danger" style={{ "font-size": "32px" }}></i>
        if (filname.endsWith("doc") || filname.endsWith("docx")) return <i class="fa fa-file-word-o text-primary" style={{ "font-size": "32px" }}></i>
        if (filname.endsWith("xls") || filname.endsWith("xlsx")) return <i class="fa fa-file-excel-o text-success" style={{ "font-size": "32px" }}></i>
        if (filname.endsWith("zip") || filname.endsWith("rar")) return <i class="fa fa-file-zip-o text-info" style={{ "font-size": "32px" }}></i>
        return <i class="fa fa-file-text-o" style={{ "font-size": "32px" }}></i>
    }

    const renderSampleFormTable = (tabledata) => {
        var mytable = <div className="row p-2" >

            {tabledata.map(apprec => <div className="col-12 col-md-4 mb-4 mt-1  filterable " key={apprec.id} style={{ "font-family": "Inter" }}>

                <div className="rounded-3 p-3 bg-light  rounded-4  overflow-auto">
                    <div>
                        <div className="row align-bottom d-flex align-items-end text-secondary mt-2">
                            <div className="col-10  align-bottom">
                                {isEmpty(apprec.fileName) == false &&
                                    <a className="text-decoration-none" href={'getsampleform?id=' + apprec.id} download={apprec.fileName}>
                                        {getFileIcon(apprec.fileName)} {apprec.fileName}
                                    </a>
                                }

                            </div>
                        </div>
                        <div className="fw-bold text-justify mt-2" style={{ "font-size": "16px", "color": "#78829d", "text-align": "justify" }}>
                            <div className="col-12" > <div className="mt-1" dangerouslySetInnerHTML={{ __html: apprec.caption }} /></div>
                        </div>

                        <div className=" text-start mt-2" style={{ "font-size": "15px" }}>
                            <a href={apprec.url} target="_blank" className="text-decoration-none"> {apprec.url} </a>
                        </div>

                        <div className="row  text-secondary mt-3">
                            <div className="col-10  ">
                                <img className="rounded-circle" style={{ width: "32px", height: "32px" }} src={"/getuserpic?user=" + apprec.userName} />
                                <span style={{ "font-size": "14px", "font-weight": "600", "color": "#4b5675" }}>  {apprec.userName}</span> <span style={{ "font-size": "14px", "font-weight": "600", "color": "#99a1b7" }}>{apprec.postDate}</span>
                            </div>
                            <div className="col-2 text-end  ">
                                {apprec.id}
                            </div>
                        </div>

                    </div>
                </div>
            </div>)}
        </div>

        return (mytable);
    }



    const renderMakaleTable = (tabledata) => {

        var mylist = tabledata.filter(x => isEmpty(x.roles) || x.roles.split(',').some(x => role.includes(x)));

        var mytable = <div className="row" >

            {mylist.map(apprec => <div className="col-12 col-md-6 mb-4 mt-1  filterable" key={apprec.id} style={{ "font-family": "Inter" }}>

                <div className="rounded-3 p-3">
                    <div>
                        <div className=" text-start mt-1 fw-bold pb-2" style={{ "font-size": "16px", "font-weight": "600", "color": "#071437" }}>{apprec.title}</div>
                        <div className=" text-justify" style={{ "font-size": "15px", "color": "#78829d", "text-align": "justify" }}>
                            <div className="col-12" > <div className="mt-1" dangerouslySetInnerHTML={{ __html: apprec.text }} /></div>
                        </div>
                        <div className="row align-bottom d-flex align-items-end text-secondary mt-3">
                            <div className="col-10  align-bottom">
                                <img className="rounded-circle" style={{ width: "32px", height: "32px" }} src={"/getuserpic?user=" + apprec.userName} />
                                <span style={{ "font-size": "14px", "font-weight": "600", "color": "#4b5675" }}>  {apprec.userName}</span> <span style={{ "font-size": "14px", "font-weight": "600", "color": "#99a1b7" }}>{apprec.sendDate}</span> {apprec.id}
                            </div>
                            <div className="col-2 text-end  align-bottom">
                                {apprec.id}
                            </div>
                        </div>

                    </div>
                </div>
            </div>)}
        </div>

        return (mytable);
    }





    const renderTable = (tabledata) => {
        var userTypes = userData.userType.split(",");
        var mylist = tabledata.filter(x => isEmpty(x.roles) || x.roles.split(',').some(y => userTypes.includes(y)));
        var mytable = <div className="row" >
            {mylist.map(apprec =>
                <div className="col-12 col-md-4 mb-4 mt-1 filterable" key={apprec.id} style={{ "font-family": "Inter" }}>
                    <div className="rounded-3 p-3">
                        {isEmpty(apprec.url) == false &&
                            <a className="text-decoration-none text-secondary" href="#" style={{ position: "relative", display: "grid" }}
                                onClick={() => props.changeComponent(<HelpVideoPanel changeComponent={props.changeComponent}
                                    helpRec={apprec} />)}>
                                <img className="w-100 rounded-3 " src={"https://img.youtube.com/vi/" + apprec.url.substring(apprec.url.lastIndexOf("/") + 1) + "/mqdefault.jpg"} />

                                <i class="fa fa-youtube-play" style={{ position: "absolute", "z-index": 2, top: "40%", left: "40%", "opacity": 0.8, "font-size": "48px" }}></i>
                            </a>
                        }
                        <div>
                            <div className=" text-start mt-1 fw-bold" style={{ "font-size": "16px", "font-weight": "600", "color": "#071437" }}>{apprec.title}</div>
                            <div className=" fw-bold text-start " style={{ "font-size": "15px", "font-weight": "500", "color": "#78829d" }}>
                                {apprec.caption}
                            </div>
                            <div className="row mt-2 align-bottom d-flex align-items-end text-secondary">
                                <div className="col-8  align-bottom">
                                    <img className="rounded-circle" style={{ width: "32px", height: "32px" }} src={"/getuserpic?user=" + apprec.userName} />
                                    <span style={{ "font-size": "14px", "font-weight": "600", "color": "#4b5675" }}> {apprec.userName} </span> <span style={{ "font-size": "14px", "font-weight": "600", "color": "#99a1b7" }}> {apprec.postDate}</span> {apprec.id}
                                </div>
                                <div className="col-4 text-end">
                                    {isEmpty(apprec.fileName) == false &&
                                        <a className="text-decoration-none align-middle btn-sm btn btn-primary" href={'gethelpfile?id=' + apprec.id} download={apprec.fileName}> Ek Dosya</a>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>



        return (mytable);
    }

    var helplistTable = <div class="spinner-border  " ></div>;
    var sampleformTable = <div class="spinner-border  " ></div>;
    var makalelistTable = <div class="spinner-border  " ></div>;

    if (listData != null) {
        helplistTable = renderTable(listData);
    }
    if (makaleList != null) {
        makalelistTable = renderMakaleTable(makaleList);
    }

    if (formList != null) {
        sampleformTable = renderSampleFormTable(formList);
    }


    return (
        <div className="mt-2 rounded bg-light p-2 rounded-3 " style={{ "font-family": "Inter" }}>
            <div className="mb-3" style={{ "font-size": 18, "font-weight": "600", "color": "#252f4a" }}>Yardım Paneli</div>
            <div className="m-1 rounded-3 border   bg-white shadow-sm" >

                <div className="row  align-middle d-flex align-items-center " >
                    <div className="col-12 col-md-1 "></div>
                    <div className="col-12 col-md-7 pt-5 pb-4">
                        <div className="align-middle ms-1 me-5" style={{ "font-size": 23, "font-weight": "600", "color": "#252f4a" }}>
                            Size Nasıl Yardımcı Olabiliriz ?

                            <div className="row border rounded-3 ms-1 mt-4 p-1 d-flex align-items-center" >
                                <div className="col-1 d-flex align-items-center"><span className='fa fa-circle-o  text-primary' style={{ "font-size": 16 }}> </span></div>
                                <div className="col-10 ">
                                    <input type="text" className="form-control form-control-sm border-0 shadow-none" id="ara" defaultValue="" placeholder="" name="ara" onKeyUp={filterTable} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-4 text-center">
                        <img src={require('../Header/textlogo.png').default} style={{ "max-width": "auto", "max-height": "128px", "object-fit": "cover" }} />
                    </div>
                </div>

                <div className="row  ms-5 me-5 mb-3 rounded-3  bg-light " >

                    <div className="col-12 p-2">
                        <a className="btn text-start  btn-outline-primary text-decoration-none me-1  border-0 rounded-2 p-2 ps-3 pe-3" style={{ "font-size": 14, "font-weight": "600" }} href="#makalelerdiv">
                            MAKALELER
                        </a>
                        <a className="btn  text-start btn-outline-primary text-decoration-none me-1  border-0 rounded-2 p-2 ps-3 pe-3" style={{ "font-size": 14, "font-weight": "600" }} href="#videolardiv">
                            VİDEOLAR
                        </a>
                        <a className="btn  text-start  btn-outline-primary text-decoration-none me-1  border-0 rounded-2 p-2 ps-3 pe-3" style={{ "font-size": 14, "font-weight": "600" }} href="#formlardiv">
                            ÖRNEK FORMLAR
                        </a>
                        <a className="btn text-start  btn-outline-primary text-decoration-none me-1  border-0 rounded-2 p-2 ps-3 pe-3" style={{ "font-size": 14, "font-weight": "600" }} href="https://universe.web.tr/iletisim/" target="_blank">
                            DESTEK TALEBİ
                        </a>
                    </div>


                </div>
            </div>
            <div id="myTable">
                <div className="mt-5 bg-white border rounded-3 p-4 shadow-sm border-bottom pb-3" id="makalelerdiv">
                    <div className="mb-2 border-bottom  pb-3" style={{ "font-size": 18, "font-weight": "600", "color": "#252f4a" }}>Makaleler</div>
                    {makalelistTable}
                </div>

                <div className="mt-5 bg-white border rounded-3 p-4 shadow-sm" id="videolardiv">
                    <div className="mb-2 border-bottom pb-3" style={{ "font-size": 18, "font-weight": "600", "color": "#252f4a" }}>Videolar</div>
                    {helplistTable}
                </div>

                <div className="mt-5 bg-white border rounded-3 p-4 shadow-sm mb-4" id="formlardiv">
                    <div className="mb-2 border-bottom pb-3" style={{ "font-size": 18, "font-weight": "600", "color": "#252f4a" }}>Örnek Formlar</div>
                    {sampleformTable}
                </div>
            </div>

        </div>

    );


}