import React, { useState, useEffect } from 'react';
import { useGlobalState,ajaxFail } from '../GlobalState.js';


export default function UserEventsPanel(props) {

    
    const [events, setEvents] = useState(null);
    const colors = ["#fff8dd", "#e8fff3", "#fff5f8", "#f8f5ff"]
    const scolors = ["text-primary", "text-success", "text-danger", "text-warning", "text-info"]
    const [myuserName, setuserName] = useGlobalState('userName');

    const fetchEvents = (e) => {
        fetch("getuserevents", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(props.userdata != null ? props.userdata.userName :myuserName)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(async data => {

                    setEvents(data);
                    rendertolist(data);
                });
            }
        });
    }

    useEffect(() => {
        fetchEvents();
    }, [])



    const rendertolist = (events) => {
        var groupedEvents = {};
        for (var i = 0; i < events.length; i++) {
            var d = events[i].eventDate.split(" ")[0];
            var t = events[i].eventDate.split(" ")[1];
            if (groupedEvents[d] == null) groupedEvents[d] = [];
            groupedEvents[d].push(t);
        }
        var tab = "";
        var c = 0;
        for (var d in groupedEvents) {
            tab += '<div class="row ms-2 me-2 p-2 mb-3  rounded-2 border border-rounded" style="background-color:' + colors[c % 4] + ';"}><div class="col-12"><h5  class="text-secondary"><span class="fa fa-calendar text-muted"></span> <b>' + d + '</b></h5></div><div class="pt-2">'
            for (var i = 0; i < events.length; i++) {
                var m = events[i].eventDate.split(" ")[0];
                var t = events[i].eventDate.split(" ")[1];
                if (d == m) {
                    tab += "<div class='row'>"
                    tab += "<div class=' col-3  col-md-1 mb-1'  style='position: relative;'> <span class='ps-1 pe-3 text-muted fw-bold' style='position: absolute; top:-5px;'>" + t + "</span></div>"
                    tab += "<div class=' col-9  col-md-9 border-start border-4 ps-0 mt-2' style='position: relative;'> <span style='margin-left:-8px; position: absolute; top:-10px;'  class='fa fa-circle-o me-2 fw-bold " + scolors[i % 5] + "'> </span> "
                    tab += "<div class='ms-2 mb-3' style='margin-top:-18px;'>" + events[i].comment+ "&nbsp;&nbsp; <img class=\"rounded-circle\" style='width:30px; height:30px;'src='/getuserpic?user=" + events[i].userName + "'/><span class='ps-2 text-muted'>" + events[i].userName + "</span>";
                    tab += "</div></div>";

                    tab += "</div>"
                }

            }
            tab += "</div></div>"
            c++;
        }

        const para = document.getElementById("appevents");

        para.innerHTML = tab;
        return para;
    }





    var panel = "";


    if (events == null)
        panel = <div className="w-100 mt-4 mb-4 text-center align-items-center justify-content-center" ><div className="spinner-border" ></div></div>
    else if (events.length == 0)
        panel = <div className="w-100 mt-4 mb-4 text-center align-items-center justify-content-center text-danger" >Kayıt Yok</div>




    return (
        <div >
            <div className="row">
                <div className="col-10">
                    <h5 className="text-primary pb-3">Geçmiş Aktiviteler</h5 >
                </div>
                <div className="col-2 justify-content-end align-items-end text-end ">
                    {events != null && events.length > 0 && <span className="small badge bg-primary rounded-pill me-2">{events.length}</span>}
                </div>

            </div>
            <div id="appevents">
                {panel}
            </div>
        </div>

    );
}



