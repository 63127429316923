import React, { useState, useEffect } from 'react';
import { ajaxFail, cutString, removeTurkcLow, showConfirmMessage, toUpperCaseTurkce } from '../GlobalState.js';
import MahalleSetting from './MahalleSetting.js';
import $ from 'jquery';


export default function CBSMahalleMatching(props) {
    const [listData, setListData] = useState(null);
    const [cbsListData, setCBSListData] = useState(null);

    const [listtype, setListType] = useState("MATCH");
    var mergedset = [];

    useEffect(() => {
        fetchList();
        fetchCBSList();
    }, [])



    const matchRecords = (mlistData) => {
        var mergedList = [];

        fetch("matchmahalle", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                
            }
        });


        cbsListData.forEach(cbs => {
            var mahal = listData.find(x => x.cbs == null && toUpperCaseTurkce(x.name) == toUpperCaseTurkce(cbs.tapumahallead));
            if (mahal != null) {
                cbs.mahalle = mahal;
                mahal.cbs = cbs;
                mergedList.push({ "mahalle": mahal, "cbs": cbs });
            }
            else
                mergedList.push({ "mahalle": null, "cbs": cbs });


        });
        var temp = listData.filter(x => x.cbs == null);
        temp.forEach(x => {
            mergedList.push({ "mahalle": x, "cbs": null });
        });
        cbsListData.forEach(cbs => cbs.mahalle = null);
        listData.forEach(x => x.cbs = null);
        return mergedList;
    }


    const removeall = () => {
        var ids = mergedset.filter(x => x.cbs == null && x.mahalle != null).map(x => x.mahalle.id);
        showConfirmMessage(ids.length + " Kaydı Silmek İstedinizden Emin Misiniz?", "", "Evet", "Hayır", () =>
            fetch("removemahalle", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(ids)
            }).then(async response => {
                if (!response.ok) {
                    ajaxFail(response);
                }
                else {
                    response.json().then(value => {
                        setListData(value);
                    })
                }
            }));
    }

    const remove = (rec) => {
        showConfirmMessage(" Kaydı Silmek İstedinizden Emin Misiniz?", "", "Evet", "Hayır", () =>
            fetch("removemahalle", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify([rec.id])
            }).then(async response => {
                if (!response.ok) {
                    ajaxFail(response);
                }
                else {
                    response.json().then(value => {
                        setListData(value);
                    })
                }
            }));
    }

    const add = (cbs) => {
        var recs = [{ name: cbs.tapumahallead, koordinat: cbs.coordinates }];
        fetch("addmahalle", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(recs)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                fetchList();
            }
        });
    }


    const addall = () => {
        var recs = mergedset.filter(x => x.cbs != null && x.mahalle == null).map(x => ({ name: x.cbs.tapumahallead, koordinat: x.cbs.coordinates }));
        fetch("addmahalle", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(recs)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                fetchList();
            }
        });
    }



    const fetchCBSList = () => {

        fetch("getcbsmahallelist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    setCBSListData(value);
                })
            }
        });
    }

    const fetchList = () => {

        fetch("getmahallelist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {

                    setListData(value);
                })
            }
        });
    }


    const filterCBS = (event) => {
        var textbox = event.target;
        if (textbox == null)
            return;
        var value = textbox.value;

        $("#myTable tr").filter(function () {
            var rowtext = $(this).children(":first").text()
            var t1 = removeTurkcLow(rowtext);
            var t2 = removeTurkcLow(value);
            if (t1.indexOf(t2) == -1)
                return $(this).toggle(false);
            return $(this).toggle(true);
        });
    }
    const filterMahalle = (event) => {
        var textbox = event.target;
        if (textbox == null)
            return;
        var value = textbox.value;

        $("#myTable tr").filter(function () {
            var rowtext = $(this).children(":last").text()
            var t1 = removeTurkcLow(rowtext);
            var t2 = removeTurkcLow (value);
            if (t1.indexOf(t2) == -1)
                return $(this).toggle(false);
            return $(this).toggle(true);
        });

    }


    const renderTable = (mergedset) => {
        var mytable =
            <div className="table-responsive  mainpagescroll table-sm " style={{ "height": "85vh" }}>

                <div className="row">
                    <div className="col-6">

                    </div>
                </div>


                <table className='table table-vdstriped  table-sm' aria-labelledby="tabelLabel">
                    <thead style={{ "position": "sticky", "top": 0, "z-index": "1" }} className="bg-white">
                        <tr>

                            <th className="text-start fw-bold text-center ps-3 pe-3">
                                <input className="form-control form-control-sm " id="mySearchInput" type="text" placeholder="" onKeyUp={filterCBS} />
                                TKGM Verisi ({cbsListData.length} kayıt)

                            </th>
                            <th className="text-start fw-bold text-center ps-3 pe-3">
                                <input className="form-control form-control-sm " id="mySearchInput" type="text" placeholder="" onKeyUp={filterMahalle} />
                                Sistem Verisi ({listData.length} kayıt)
                            </th>
                        </tr>
                    </thead>
                    <tbody id="myTable">
                        {mergedset.map(rec =>
                            <tr className={(rec.mahalle != null && rec.cbs != null) ? "text-success" : "text-danger"} style={{ "height": "15px" }}>
                                <td >
                                    
                                    {rec.cbs != null ? rec.cbs.tapumahallead : "YOK"}
                                    <span className="ms-4 small">{rec.cbs != null ? cutString(rec.cbs.coordinates, 10) : ""}</span>

                                </td>
                                <td >
                                    {rec.mahalle == null && <button className=" btn btn-sm btn-success me-2 p-0 ps-1 pe-1 text-decoration-none" onClick={() => add(rec.cbs)}>Ekle</button>}
                                    {rec.cbs == null && <button className=" btn btn-sm btn-danger me-2 p-0 ps-1 pe-1 text-decoration-none" onClick={() => remove(rec.mahalle)}>Sil</button>}
                                    {rec.mahalle != null ? rec.mahalle.name : "YOK"}
                                    <span className="ms-4 small">{rec.mahalle != null ? cutString(rec.mahalle.koordinat, 10) : ""}</span>
                                </td>
                                {/*<td ><i className={(rec.mahalle != null || rec.cbs != null) ?"fa fa-check":"fa fa-exclamation"}></i></td>*/}

                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        return (mytable);
    }








    var listTable = <div class="d-flex justify-content-center"><div class="spinner-border" role="status"></div></div>;
    var recCount = "";

    if (listData != null && cbsListData != null) {
        mergedset = matchRecords()
        listTable = renderTable(mergedset);
    }

    return (
        <div className=" m-2 p-3 rounded-3">
            <div className="row mb-1">
                <div className="col-9 fw-bold" >
                    <h5 className="">Mahalle Eşleştirme</h5>
                </div>
                <div className="col-3 text-end" >
                    <button onClick={() => props.changeComponent(<MahalleSetting changeComponent={props.changeComponent} />)} className="btn btn-primary btn-sm text-decoration-none ">Geri Dön</button>
                </div>
            </div>

            <div className="row mb-3">
                <div className="col-12 textvmuted " style={{ "text-align": "justify" }} >
                    <div className="bg-light p-3 rounded-3 textvmuted fw-bold align-middle" >
                        <span className="fa fa-info pe-3" style={{ color: "lightblue", fontSize: "26px" }} ></span>
                        Bu ekranda TKGM den gelen parsel verileri ile Universe'de kullanılan parsel verisi karşılaştırılmaktadır
                    </div>
                </div>
            </div>

            {listData != null && cbsListData != null &&
                <div className="row mb-3 mt-3 text-center">
                    <div className="col-6">
                    </div>
                    <div className="col-6">
                        <button onClick={addall} className="btn btn-success btn-sm mb-1">TKGM'de olan Universe'de olmayan tüm kayıtları ekle ({mergedset.filter(x => x.mahalle == null).length} kayıt)</button>
                        <button onClick={removeall} className="btn btn-danger btn-sm me-1">Universe'de olan TKGM'de olmayan tüm kayıtları sil ({mergedset.filter(x => x.cbs == null).length} kayıt)</button>

                    </div>

                </div>}


            <div className="row" key={listTable}>
                <div className="col-12" id="tablediv" >
                    {listTable}
                </div>
            </div>



        </div>

    );


}