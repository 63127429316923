import React, { useState, useEffect } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import { ajaxFail, isEmpty, removeTurkcLow, showConfirmMessage, showErrorMessage, showSuccessMessage } from '../GlobalState.js';
import $ from 'jquery';

export default function SablonMappingPanel(props) {
    var postdata = {};
    const [listData, setListData] = useState([]);
    const [rolList, setRolList] = useState([]);
    var sablons = props.sablons;

    const multiselectRef = React.createRef();

    useEffect(() => {
        fetchSablonMapping();
        fetchRoleList();
    }, [props.sablons])


    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        postdata[target.name] = value;
    }

    var sablonSelect = <select className="form-select form-select-sm" id="sablonSelect" name="sablonSelect" onChange={handleInputChange}>
        <option value="" selected="selected"> </option>
        {sablons.map(s => <option value={s.name} > {s.name}</option>)}
    </select>




    const fetchSablonMapping = () => {
        fetch("sablonmappinglist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                response.json().then(value => {
                    setListData(value);

                })

            }
        });
    }

    const fetchRoleList = () => {
        fetch("getrolelist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                response.json().then(value => {
                    setRolList(value);
                })
            }
        });
    }


    const filterTable = (event) => {
        var value = document.getElementById("rolfiltercombo").value;
       
        $("#myTable2 .row").filter(function () {
            var text = $(this).children().eq(0).text();
            var values = text.split(",");
            if (isEmpty(value) || values.length == 0)
                return $(this).toggle(true);

            if (values.some(x => removeTurkcLow(x).toLowerCase() == removeTurkcLow(value).toLowerCase()))
                    return $(this).toggle(true);
            return $(this).toggle(false);
        });

    }

    const updateRecord = (rec, event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        rec[name] = value;
    }

    const renderTable = (tabledata) => {
        var mytable =
            <div id="myTable2" >
                {tabledata.map(apprec =>
                    <div key={apprec.id} className="rounded-3 p-3 row border-top ">
                        <div hidden>
                            {apprec.userId}
                        </div>
                        <div className="col-md-3 col-12">
                            <select className="form-select form-select-sm" name="sablonSelect" onChange={handleInputChange}>
                                {sablons.map(s => <option value={s.name} selected={s.name == apprec.sablonName}> {s.name}</option>)}
                            </select>
                        </div>
                        <div className="col-md-5 col-12">
                            <Multiselect className="" id="multiselect-select"
                                options={rolList.map(r => r.name)}
                                emptyRecordMsg="Başka Kullanıcı Tipi Yok"
                                isObject={false}
                                //ref={multiselectRef}
                                selectedValues={apprec.userId != null && apprec.userId.length > 0 ? apprec.userId.split(',') : []}
                                onSelect={(selectedList, selectedItem) => { apprec.userId = selectedList.length == 0 ? null : selectedList.join() }}
                                onRemove={(selectedList, selectedItem) => { apprec.userId = selectedList.length == 0 ? null : selectedList.join() }}
                                placeholder="Roller"
                            />
                        </div>
                        <div className="col-md-2 col-12">
                            <input type="Number" placeholder="Sıra" defaultValue={apprec.sira} className="form-control form-control-sm" name="sira" onChange={(e) => updateRecord(apprec, e)} />
                        </div>
                        <div className="text-end col-md-2 col-12">
                            <button onClick={() => updatemapping(apprec)} className="btn btn-primary btn-sm text-decoration-none">Güncelle</button>&nbsp;
                            <button onClick={() => { Sil(apprec.id) }} className="btn btn-secondary btn-sm text-decoration-none">Sil</button></div>
                    </div>
                )}
            </div>

        return (mytable);
    }

    const Sil = (id) => {
        showConfirmMessage(id + " Numaralı Kaydı Silmek İstedinizden Emin Misiniz?", "", "Evet", "Hayır", () =>
            fetch("removesablonmapping", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: id
            }).then(async response => {
                if (!response.ok) {
                    ajaxFail(response)
                }
                else {
                    fetchSablonMapping();
                }
            }))
    }




    const updatemapping = (rec) => {
        fetch("updatesablonmapping", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(rec)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                response.json().then(value => {
                    showSuccessMessage("Güncelleme Başarılı")
                    // setListData(value);

                })
            }
        });

    }


    const addMapping = () => {
        var mapping = [];
        var newmapping = {
            UserId: multiselectRef.current.getSelectedItems().join(),
            SablonName: document.getElementById("sablonSelect").value
        }
        if (document.getElementById("sira").value != null && document.getElementById("sira").value.length > 0) {
            newmapping.sira = parseInt(document.getElementById("sira").value);
        }

        if (listData.filter(x => x.sablonName == newmapping.SablonName).length > 0) {
            showErrorMessage(newmapping.SablonName + " ekranının daha önce yetkilendirilmesi yapılmış.", "Lütfen o kayıt üzerinden güncelleme yapınız.");
            return;
        }



        mapping.push(newmapping);
        fetch("saveuserdefaultsablon", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(mapping)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                document.getElementById("sablonSelect").selectedIndex = -1;
                multiselectRef.current.resetSelectedValues();
                document.getElementById("sira").value = "";
                fetchSablonMapping();
            }
        });

    }


    var listTable = renderTable(listData);

    return (
        <div className="p-3 mb-4">
            <div className="bg-light mb-4   p-3 rounded-3 textvmuted fw-bold align-middle"  >
                <span className="fa fa-info pe-3" style={{ color: "lightblue", fontSize: "26px" }} ></span>
                Oluşturduğunuz ekranlar ile kullanıcı rollerini eşleştirerek, hangi ekranın hangi role sahip kullanıcılarda görüntüleneceğini belirleyebilirsiniz.
            </div>

            <div className="row border-bottom  mb-4 pb-4">
                <div className="col-md-3 col-12">
                    Ekran Seç <span className="text-danger">*</span>
                    {sablonSelect}
                </div>
                <div className="col-md-6 col-12">
                    Rolleri Seç <span className="text-danger">*</span>
                    <Multiselect className="p-0 form-control-sm bg-white"
                        options={rolList.map(r => r.name)}
                        emptyRecordMsg="Başka Kullanıcı Tipi Yok"
                        isObject={false}
                        ref={multiselectRef}
                        //onSelect={(selectedList, selectedItem) => { postdata.userId = selectedList.join() }}
                        // onRemove={(selectedList, selectedItem) => { postdata.userId = selectedList.join() }}
                        placeholder="Yetkiler"
                        selectedValues={[]}
                    />
                </div>
                <div className="col-md-2 col-12">
                    Sıra <span className="text-danger">*</span>
                    <input type="Number" placeholder="" className="form-control form-control-sm" id="sira" name="sira" onChange={handleInputChange} />
                    <span className="textvmuted small">Ekranların soldan sağa yer alacağı sırayı belirler</span>
                </div>
                <div className="col-md-1 col-12 text-end ">
                    <br />
                    <button onClick={addMapping} className="btn btn-sm btn-primary text-decoration-none ">Ekle
                    </button>
                </div>

            </div>



            <div className="row pb-1">
                <div className="col-12 col-md-5 fw-bold">
                    Yetkilendirilmiş Ekranlar

                </div>
            </div>
            <div className="row mb-3 ">
                <div className="col-12 col-md-3">
                </div>
                <div className="col-12 col-md-5 ps-4 pe-4">
                    Role Göre Filtrele

                    <select className="form-select form-select-sm" id="rolfiltercombo" onChange={filterTable}>
                        <option value=""> </option>
                        {rolList.map(x=>x.name).sort().map(x => <option >{x} </option>)}
                        </select>

                          {/*  <input className="form-control form-control-sm" id="myInput" type="text" placeholder="Arama" onKeyUp={filterTable} />*/}

                        </div>
            </div>

                {listTable}
            </div>

            );


}