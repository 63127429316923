import React, { useState, useEffect } from 'react';
import { ajaxFail, isEmpty } from '../GlobalState.js';
import Application from './Application.js';
import ApplicationList from './ApplicationList.js';


export default function GMapLatLang(props) {
    var appData = props.appData;
    var polygon = props.polygon;
    var [gdata, setGData] = useState(null);
    const [googlemapkey, setGoogleMapKey] = useState(null);
    var script = null;

    useEffect(() => {
        fetchGeneralData();
        getgooglemapkey();
    }, [])

    useEffect(() => {
        if (window.google!=null)
        initMap();
    }, [props.polygon])

    const getgooglemapkey = () => {
        fetch("getgooglemapkey", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.text().then(async data => {
                    setGoogleMapKey(data.replaceAll('"', ""));
                });
            }
        });
    }



    const fetchGeneralData = () => {
        fetch("getgeneraldata", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(async data => {
                    setGData(data);


                });
            }
        });
    }



    var mymapscript = document.getElementById("mymapscript");

    if (gdata != null && script==null&& document.getElementById("googlemapscript") == null && isEmpty(googlemapkey)==false) {

        script = document.createElement("script");
        script.id = "googlemapscript"
        script.src = "https://maps.googleapis.com/maps/api/js?key=" + googlemapkey + "&callback=initAutocomplete&libraries=places&v=weekly";
        script.async = true;
        script.defer = true;
        mymapscript.appendChild(script);
        script.onload = () => {
            initMap();
        }
    }

    const initMap = () => {
        var myLatlng = { lat: 38.389, lng: 27.146 };
        
        if (gdata != null && gdata.genlem != null && isNaN(gdata.genlem)==false) {
            myLatlng.lat = parseFloat(gdata.genlem);
        }
        if (gdata != null && gdata.gboylem != null && isNaN(gdata.genlem) == false) {
            myLatlng.lng = parseFloat(gdata.gboylem);
        }
        var z = 7;
        if (gdata != null && gdata.gzoom != null) {
            z = parseInt(gdata.gzoom)
        }
        if (window.google == null || window.google.maps == null)
            return;
        var map = new window.google.maps.Map(document.getElementById("map"), {
            zoom: z,
            center: myLatlng,

        });
        var Coords = [];
        
        if (isEmpty(polygon)==false) {
            var bounds = new window.google.maps.LatLngBounds();
            var ct = polygon.replaceAll("'", "").replaceAll('"', '').split(" ");
            for (var i = 0; i < ct.length; i++) {
                var latlang = ct[i].split(",");
                if (isNaN(Number(latlang[0])) == false && isNaN(Number(latlang[1])) == false) {
                    var lg = { lng: Number(latlang[0]), lat: Number(latlang[1]) };
                    Coords.push(lg)
                    bounds.extend(lg);
                }
            }

            myLatlng = bounds.getCenter();
            if (props.setLatLang != null)
                props.setLatLang(myLatlng)
            map.zoom = 12;
            const bermudaTriangle = new window.google.maps.Polygon({
                paths: Coords,
                strokeColor: "#FF0000",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: "#FF0000",
                fillOpacity: 0.35,
            });
            bermudaTriangle.setMap(map);
            map.fitBounds(bounds);
        }

        // Create the search box and link it to the UI element.
        // const input = document.getElementById("mapsearch") ;
        var input = document.createElement("INPUT");
        input.setAttribute("type", "text");
        input.setAttribute("placeholder", "Ara");
        input.setAttribute("class", "form-control form-control-sm w-50 mt-2");
        const searchBox = new window.google.maps.places.SearchBox(input);

        map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(input);

        // Bias the SearchBox results towards current map's viewport.
        map.addListener("bounds_changed", () => {
            searchBox.setBounds(map.getBounds());
        });

        let markers: window.google.maps.Marker[] = [];

        // Listen for the event fired when the user selects a prediction and retrieve
        // more details for that place.
        searchBox.addListener("places_changed", () => {
            const places = searchBox.getPlaces();

            if (places.length == 0) {
                return;
            }

            // Clear out the old markers.
            markers.forEach((marker) => {
                marker.setMap(null);
            });
            markers = [];

            // For each place, get the icon, name and location.
            const bounds = new window.google.maps.LatLngBounds();

            places.forEach((place) => {
                if (!place.geometry || !place.geometry.location) {
                    console.log("Returned place contains no geometry");
                    return;
                }

                const icon = {
                    url: place.icon,
                    size: new window.google.maps.Size(71, 71),
                    origin: new window.google.maps.Point(0, 0),
                    anchor: new window.google.maps.Point(17, 34),
                    scaledSize: new window.google.maps.Size(25, 25),
                };

                // Create a marker for each place.
                markers.push(
                    new window.google.maps.Marker({
                        map,
                        icon,
                        title: place.name,
                        position: place.geometry.location,
                    })
                );

                if (place.geometry.viewport) {
                    // Only geocodes have viewport.
                    bounds.union(place.geometry.viewport);
                } else {
                    bounds.extend(place.geometry.location);
                }
            });
            map.fitBounds(bounds);
        });





        let infoWindow = null;

        // Create the initial InfoWindow.
        if (props.polygon == null) {
            infoWindow = new window.google.maps.InfoWindow({
                content: "Enlem,Boylam için teıklayınız",
                position: myLatlng,
            });
            infoWindow.open(map);
        }

        //

        // Configure the click listener.
        map.addListener("click", (mapsMouseEvent) => {
            // Close the current InfoWindow.
            if (infoWindow != null)
                infoWindow.close();

            // Create a new InfoWindow.
            infoWindow = new window.google.maps.InfoWindow({
                position: mapsMouseEvent.latLng,
            });
            infoWindow.setContent(
                JSON.stringify(mapsMouseEvent.latLng.toJSON(), null, 2)
            );
            infoWindow.open(map);

            if (props.setLatLang != null)
                props.setLatLang(mapsMouseEvent.latLng)
            else if (appData) {
                appData["enlem"]  = mapsMouseEvent.latLng.lat();
               appData["boylam"]  = mapsMouseEvent.latLng.lng();
            }
                
        });
    }




    return <div className="p-0 rounded-3 h-100 w-100 ">
        <div id="mymapscript"></div>
        {props.changeComponent && <div className="text-end m-2">
            <button className="btn btn-sm btn-primary rounded m-1" onClick={() => { props.changeComponent(< Application key={props.appData.id} appData={props.appData} changeComponent={props.changeComponent} backButton={< ApplicationList changeComponent={props.changeComponent} />}/>) }}> <i className="fa fa-arrow-circle-left pe-1"></i> Geri Don</button>

        </div>}


        <div id="map" className="rounded-3 w-100 h-100" style={{ minHeight:props.changeComponent!=null?600:100 }}>

        </div>

    </div>
}
