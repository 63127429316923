import React, { useState, useEffect } from 'react';
import { ajaxFail, showConfirmMessage, showSuccessMessage } from '../GlobalState.js';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default function DuyuruSetting(props) {
    var postdata = "";
    const [listData, setListData] = useState(null);

    const updateRecord = (rec, event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        rec[name] = value;
    }

    const removeRecord = (id) => {
        showConfirmMessage(id + " Numaralı Kaydı Silmek İstedinizden Emin Misiniz?", "", "Evet", "Hayır", () =>
            fetch("removeduyuru", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(id)
            }).then(async response => {
                if (!response.ok) {
                    ajaxFail(response);
                }
                else {
                    fetchList();
                }
            }));
    }



    const submitUpdateRecord = (rec) => {

        fetch("updateduyuru", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(rec)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                showSuccessMessage("Güncelleme Başarılı")
            }
        });
    }
    const fetchList = () => {

        fetch("getduyurulist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    setListData(value);
                })
            }
        });
    }

    const Add = () => {
        fetch("addduyuru", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postdata)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                
                fetchList();
            }
        });

    }


    const renderTable = (tabledata) => {
        var mytable =

            <table className='table table-striped  table-sm' aria-labelledby="tabelLabel">
                <thead>
                    <tr>
                        <th width="50px">No</th>
                        <th >Duyuru</th>
                        <th width="190px">Kayıt Tarihi</th>
                        <th width="130px">Ekleyen</th>
                        <th width="100px">Ayarlar</th>
                    </tr>
                </thead>
                <tbody id="myTable">
                    {tabledata.map(apprec =>
                        <tr key={apprec.id}>
                            <td className="text-center">{apprec.id}</td>
                            <td style={{ "resize": "vertical", "overflow-y": "scroll", "height": "120px" }} className="p-2">

                              
                                        <ReactQuill className="h-100 bg-white" theme="snow" onChange={(e) => apprec.message = e} defaultValue={apprec.message}  />
                               
                              {/*  <input type="text" className="form-control form-control-sm" placeholder="Başvuru Turu" name="message" defaultValue={apprec.message} onChange={(e) => updateRecord(apprec, e)} />*/}
                           
                            </td>
                            <td>{apprec.regDate}</td>
                            <td>{apprec.userName}</td>

                            <td>
                                <button onClick={() => submitUpdateRecord(apprec)} className="btn btn-link btn-sm text-decoration-none m-0 p-0">Güncelle</button>&nbsp;
                                <button onClick={() => removeRecord(apprec.id)} className="btn btn-link btn-sm text-decoration-none m-0 p-0">Sil</button>
                            </td>
                        </tr>
                    )}
                    <tr className="bg-white">
                        <td></td>
                        <td>
                            <ReactQuill className="" theme="snow" value="" onChange={(e) => postdata = e} />
                        </td>

                        <td></td>
                        <td></td>
                        <td>  <button onClick={Add} className="btn btn-sm btn-link text-decoration-none m-0 p-0">Ekle</button></td>
                    </tr>
                </tbody>
            </table>

        return (mytable);
    }





    useEffect(() => {
        fetchList();
    }, [])


    var listTable = "";
    if (listData != null) {
        listTable = renderTable(listData);
    }
    else
        listTable = renderTable([]);

    return (
        <div className="  rounded ">

            <h5 className="ps-1">Duyurular</h5>
            <div className="row">
                <div className="col-12">
                    {listTable}
                </div>
            </div>
        </div>

    );


}