import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/js/bootstrap.min.js'
import Register2 from "./Register2.js";
import { myGlobalState, showErrorMessage, showSuccessMessage, useGlobalState } from '../GlobalState.js';
import UserFilePanel from "./UserFilePanel.js";
import { ajaxFail } from '../GlobalState.js';

export default function Register3(props) {
    const [filds, setDynamicFields] = useState(null);
    var dynamicdata = {};
    var dynamicFields = null;
    const [role, setRole] = useGlobalState('role');

    useEffect(() => {
        getFieldList();
        
    }, [])
   
    const multiselectRef = React.createRef();
    var userdata = props.userdata != null ? props.userdata : {};
    if (userdata.dynamicdata != null && userdata.dynamicdata.length > 0) {
        dynamicdata = JSON.parse(userdata.dynamicdata)
    }
    const getFieldList = () => {
        fetch("getuserfields", {
            method: 'GET',
            headers: {
                'Accept': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                response.json().then(async data => {
                    setDynamicFields(data);

                });
            }
        });
    }

    const handleDynamicChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked.toString() : target.value;
        dynamicdata[target.name] = { "value": value, "type": target.type };
    }

    if (filds != null) { //!!!!!dont set the dynamicFields in fetch repoonce. it is in another scope and not see the handlechange
        dynamicFields = <div className="mb-3 mt-3 row">
            {filds.map(rec => <div className="col-12 col-md-6">
                <label htmlFor={rec.name} className="form-label m-0">{rec.name}</label><br />
                {rec.type != "select" ?
                    <input type={rec.type} className={rec.type != "checkbox" ? "form-control" : "form-check-input"} id={rec.name} placeholder={rec.name} name={rec.name} defaultValue={dynamicdata[rec.name] != null ? dynamicdata[rec.name]: rec.initValue} onChange={handleDynamicChange} />
                    :
                    <select className="form-select form-select-sm" id={rec.name} name={rec.name} placeholder="Soru Türü" onChange={handleDynamicChange} >
                        {rec.initValue != null ? rec.initValue.split(",").map(rec =>
                            <option value={rec}>{rec}</option>) : ""}
                    </select>

                }
            </div>
            )}

        </div>
    }



    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked.toString() : target.value;
        userdata[target.name] = value;
    }


    const onSubmit = (e) => {
        e.preventDefault();
        userdata.dynamicData = JSON.stringify(dynamicdata);
        fetch("updateuser", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(userdata)
        }).then(async response => {
            if (!response.ok) {
                showErrorMessage("UserDetails: " + response.statusText)
            }
            else {

                showSuccessMessage("Başvuru Başarıyla Tamamlanmıştır","",()=>window.location.href = "/")

               // if (role == null || role.length == 0)
            }
        });





    }






    return <div className="mt-3">
        <div id="myscript"></div>
        <div className="row" >
            <div className="ms-2 me-2 p-0 col-md-3 col-12  border-bottom border-3 " > 1. Kişisel Bilgiler
            </div>
            <div className="ms-3 me-2 p-0 col-md-3 col-12   border-bottom border-3 " >2. Mesleki Bilgiler
            </div>
            <div className="ms-3 me-2 p-0 col-md-3 col-12   border-bottom border-3 text-primary border-primary fw-bold" > 3. Diğer
            </div>
        </div>
        <div className="row m-3" >
            <div className="col-12" >
                <div className="  align-items-center justify-content-center p-1" >
                    <form action="submitapplication" className="mt-3" method="POST" onSubmit={onSubmit}>

                        <div>

                            <div className="row pt-4">
                                {dynamicFields}
                            </div>

                            <div className="row pt-4">
                                <div className="col-12 col-md-6" >
                                    <UserFilePanel userdata={userdata} />
                                </div>
                            </div>

                            <div className="row mt-3 border-top pt-2" >
                                <div className="col-6 col-md-6 text-start">
                                    <button type="button" name="actionSave" value="Save" className="btn btn-sm btn-primary mb-2 " onClick={() => { props.changeComponent(<Register2 userdata={userdata} changeComponent={props.changeComponent} />) }}>Geri Dön</button>
                                </div>
                                <div className="col-6 col-md-6 text-end">
                                    <button type="submit" name="actionSave" value="Save" className="btn btn-sm btn-primary mb-2 ">Tamamla</button>
                                </div>

                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
}



