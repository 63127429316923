import React, { useState, useEffect } from 'react';
import { ajaxFail, isEmpty, removeTurkcLow, showConfirmMessage, showErrorMessage, showSuccessMessage } from '../GlobalState.js';
import Multiselect from 'multiselect-react-dropdown';
import $ from 'jquery';

export default function HavaleSablonSetting(props) {
    var basvuruTipi = props.basvuruTipi;


    const [listData, setListData] = useState([]);
    const [denetimList, setDenetimList] = useState([]);
    const [basvuruTipleri, setBasvuruTipleri] = useState([]);
    const [kontrolorList, setKontrolorList] = useState([]);
    const [kontrolorListForSelectTask, setKontrolorListForSelectTask] = useState([]);
    const [postdata, setPostData] = useState({});
    const [mapping, setMapping] = useState([]);
    const multiselectRef = React.createRef();
    const multiselectRefNew = React.createRef();
    const colorset = ["#f0f7ff", "#FFFFFF", "#FFFFFF", "#f0f7ff"];

    const updateRecord = (rec, event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        rec[name] = value;
    }

    useEffect(() => {
        fetchDenetimList();
        fetchList();
        fetchKontrollers();

    }, [])


    const fetchDenetimList = () => {
        fetch("denetimlist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                response.json().then(value => {
                    setBasvuruTipleri(value.filter(x => x.isadimi != true))
                    if (props.isAdimlari)
                        setDenetimList(value.filter(x => x.isadimi == true && props.isAdimlari.some(y=>y.isAdimi==x.id)));
                    else
                        setDenetimList(value.filter(x=>x.isadimi==true));
                })
            }
        });
    }
    const getDenetimName = (id) => {
        try { return denetimList.find(x => x.id == id).name }
        catch (ex) { return "Error" }
    }

    const showTable = () => {
        var maxSira = 0;
        mapping.forEach(x => maxSira = Math.max(maxSira, x.sira))
        if (mapping.filter(x => x.sira == maxSira).length > 1)
            maxSira++;
        var map2= mapping.sort((a, b) => a.sira - b.sira)

       return <table className='table  table-sm' aria-labelledby="tabelLabel">
            <thead>
                    <td> </td>
                    <th className='text-start'>Sıra</th>
                    <th className='text-start'>Kontrolör</th>
                    <th className='text-start'>İş Adımı</th>
                                        <th className='text-start'></th>
            </thead>
           <tbody >
               {map2.map((rec, idx) => <tr style={rec.sira % 2 == 0 ? { "background-color": "#f3f9ff", height: "auto" } : { height: "auto" }}>
                    <td>
                        <div hidden={isEmpty(rec.Completed) == false}>

                           <button disabled={rec.sira <= 0} type="button" className="btn btn-link p-0 m-0 shadow-none" onClick={(e) => { rec.sira--; setMapping([...map2]); }} >
                                        <span class="material-icons">
                                            remove_circle
                                        </span>
                           </button>
                           <button type="button" className="btn btn-link p-0 m-0 shadow-none" disabled={rec.sira >= maxSira} onClick={(e) => { rec.sira++; setMapping([...map2]); }}>
                                        <span class="material-icons">
                                            add_circle
                                        </span>
                                    </button>
                                
                        </div>
                    </td>

                    <td> {rec.sira+1}</td>

                    <td>
                       {rec.userName}</td>
                   <td> {getDenetimName(rec.isadimi) }
                    </td>

                   
                    <td width="10%">
                       {rec.Completed == null && <button type="button" className="btn btn-link shadow-none text-decoration-none text-danger btn-sm" onClick={() => { setMapping(map2.filter((x,i)=>idx!=i)); }} >
                            <i className="fa fa-trash-o"></i>
                        </button>}
                    </td></tr>
                )}

            </tbody>
        </table>

    }


    const fetchKontrollers = () => {
       // var rec = e.target.value;
       /* if (rec == null || rec == -1) {
            if (kontrolorList.length > 0)
                setKontrolorList([])
            return;
        }*/
        fetch("userswithperm", {
            method: 'POST',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            body: JSON.stringify("")
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(async data => {
                    setKontrolorList(data)
                });
            }
        });

    }

    const removeRecord = (rec) => {
        showConfirmMessage(rec.sablonName + " İsimli Kaydı Silmek İstedinizden Emin Misiniz?", "", "Evet", "Hayır", () =>
            fetch("removehavalesablon", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(rec)
            }).then(async response => {
                if (!response.ok) {
                    ajaxFail(response);
                }
                else {
                    response.json().then(value => {
                        if (basvuruTipi != null)
                            value = value.filter(x => x.basvuruTipi.includes(basvuruTipi.name))
                        setListData(value);
                    })
                }
            }));
    }




    const fetchList = () => {

        fetch("havalesablonlist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    if (basvuruTipi != null)
                        value = value.filter(x => x.basvuruTipi.includes(basvuruTipi.name))

                    setListData(value);
                })
            }
        });
    }

    const Reset = () => {

        document.getElementById("denetim").value = null;
        document.getElementById("kontolor").value = null
        /*document.getElementById("mapping").value = null;*/
        setMapping([])
       /* document.getElementById("parallel").disabled = true;*/

    }

    const Add = () => {
        var maxSira = -1;
        mapping.forEach(x => maxSira = Math.max(maxSira, x.sira))

        var d = document.getElementById("denetim").value
        var k = document.getElementById("kontolor").value
        if (d == -1 || d == null) {
            showErrorMessage("İş Adımını Seçiniz");
            return;
        }
        if (k == -1 || k == null) {
            showErrorMessage("Kontrolörü Seçiniz");
            return;
        }

        /*var parallel = document.getElementById("parallel").checked*/

        if (d == null || k == null) return;
        //if (document.getElementById("mapping").value != null && document.getElementById("mapping").value != "")
        //    document.getElementById("mapping").value += "," + (parallel == true ? "&" : "");
       /* document.getElementById("parallel").disabled = false;*/
        setMapping([...mapping, { sira: maxSira+1, isadimi: d, userName: k }]);
        setPostData({ ...postdata })
    }

    const SaveSablon = () => {
        var update = true;

        if (postdata.id == null) {
            update=false
        }
        var m = mappingToString();
        var s = document.getElementById("sablonName").value
        
        if (m == null || m == "" || s == null || s == "" ) {
            showErrorMessage("Tüm alanları doldurnuz");
            return;
        }
      //  postdata.basvuruTipi=b
        postdata.mapping = m;
        postdata.sablonName = s;

        if (basvuruTipi != null && isEmpty(postdata.basvuruTipi))
            postdata.basvuruTipi = basvuruTipi.name;
       

        fetch("savehavalesablon", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postdata)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    if (update)
                        showSuccessMessage("Guncelleme Başarılı")
                    else
                        showSuccessMessage("Şablon Oluşturuldu")

                    if (basvuruTipi != null)
                        value = value.filter(x => x.basvuruTipi.includes(basvuruTipi.name))
                    setListData(value);
                })

            }
        });
    }



    const filterTable = (event) => {
        const target = event.target;
        const value = target.value.toLowerCase();
        $("#myTable tr").filter(function (idx) {
            var keywords = removeTurkcLow(value);
            var childs = $(this).children();
            var text = removeTurkcLow(childs.eq(1).children().eq(0).val()) + " " + removeTurkcLow(childs.eq(3).children().eq(0).val());
            if (listData[idx].basvuruTipi != null)
                text = text + " " + removeTurkcLow(listData[idx].basvuruTipi);
            if (text.toLowerCase().indexOf(keywords.toLowerCase()) == -1)
                return $(this).toggle(false);
            return $(this).toggle(true);
        });

    }

    const renderTable = (tabledata) => {
        var mytable =
            <div className="row ms-2 me-2 pb-3">
                {tabledata.map((apprec, idx) =>
                    <div className={"col-md-6 col-12  "}>
                        <div className="border rounded-3  mt-3 p-0 text-center border-1" style={{ "background-color": colorset[idx % colorset.length] }}>
                            <div className="d-inline float-start">
                                <button onClick={() => removeRecord(apprec)} className="btn btn-secondary btn-sm  text-decoration-none ">Sil</button>
                            </div>
                            <div className="d-inline ">
                                <button onClick={() => { setPostData(apprec); setMapping(stringToMapping(apprec.mapping)) }} className="btn btn-link btn-sm text-decoration-none ">{apprec.sablonName}</button>
                            </div>

                        </div>
                    </div>
                )}
            </div>
        return (mytable);
    }

    





    var listTable = "";
    if (listData != null) {
        listTable = renderTable(listData);
    }
    else
        listTable = renderTable([]);

   
    const getMappingName = (rec) => {
        var username = rec.split("=")[0].replace("&", "");
        var k = kontrolorList.find(x => x.userName == username);
        if (k == null) {
            return username + " kullanıcısı bulunamadı !";
        }
        var d = denetimList.find(x => x.id == rec.split("=")[1]);
        return k.name + " " + k.family + "(" + (d == null ? "" : d.name) + ")  "

    }

    const gnerateHavaleMappingNames = (rec) => {
        if (denetimList.length > 0 && kontrolorList.length > 0 && isEmpty(rec.mapping)==false) {
            var mapping = rec.mapping.split(",");
            var ret = mapping.map(x => getMappingName(x));
            var sire = -1;
            for (var i = 0; i < ret.length; i++) {
                if (mapping[i].includes("&") == false)
                    sire++;
                ret[i] = sire + ": " + ret[i]

            }
            return ret.join(" , ")

        }
        return "";
    }


    const mappingToString = () => {
        var s = mapping.map((x, idx) => {
            var amp = "";
            if (idx > 0 && mapping[idx-1].sira==x.sira) amp="&"
            return amp+x.userName + "=" + x.isadimi
        })
        return s.join(",")
    }
    const stringToMapping = (str) => {
        var arr = str.split(",");
        var s = arr.map(x => ({ "userName": x.split("=")[0], "isadimi": x.split("=")[1], sira: 0 }))
        var sira = -1;
        s.forEach(x => {
            if (x.userName.startsWith("&")) {
                x.userName = x.userName.substr(1);
                x.sira = sira;
            }
            else {
                sira++;
                x.sira = sira;
               
            }
        })
        return s;
    }


    return (
        <div className="p-2 rounded-3" style={{ "background-color": "#EDEDF7", "min-height": "100vh" }}>
            <div className="row mt-1 p-3 d-flex flex-wrap pe-4" key={postdata.sablonName}>
                <div className="col-12 col-md-6 d-flex flex-column">
                    <div className=" bg-white rounded-3" >
                        <div className="p-3 fw-bold border-bottom mb-3" >
                            Yeni Havale Şablonu Oluştur
                        </div>
                        <div className="bg-light m-4  p-3 rounded-3 textvmuted fw-bold align-middle"  >
                            <span className="fa fa-info pe-3" style={{ color: "lightblue", fontSize: "26px" }} ></span>
                            Bu alanda, Yeni Havale Şablonları oluşturabilirsiniz.
                        </div>


                        {isEmpty(postdata.sablonName) != true && < div className="row ms-2 me-2   mb-4 pb-4 border-bottom">
                            <div className="col-12 align-middle   text-center " >
                                <span className="text-success fw-bold"> {postdata.sablonName} </span> şablonunu düzenliyorsunuz (Id: {postdata.id})
                                <button onClick={() => {
                                    setPostData({})
                                }} className="ms-2 btn btn-sm btn-secondary text-decoration-none">Vazgeç</button>
                            </div>
                        </div>}


                        <div className="m-4   pt-1">
                            Şablon Adı <span className="text-danger">*</span>
                            <input type="text" className="form-control form-control-sm " id="sablonName" placeholder="" name="sablonName" defaultValue={postdata.sablonName} />
                            <span className="textvmuted small">Oluşturacağınız şablona bir isim veriniz</span>
                        </div>

                        <div className="m-4  ">
                            Başvuru Tipi <span className="text-danger">*</span>
                            {basvuruTipi == null ?
                                <> <Multiselect className="p-0 ps-1 form-control-sm bg-white"
                                    options={basvuruTipleri.map(x => x.name)}
                                    emptyRecordMsg="Başka Kayıt Yok"
                                    displayValue="name"
                                    isObject={false}
                                    ref={multiselectRefNew}
                                    selectedValues={isEmpty(postdata.basvuruTipi) == false ? postdata.basvuruTipi.split(',') : []}
                                    onSelect={(selectedList, removedItem) => { postdata.basvuruTipi = selectedList.length == 0 ? null : selectedList.join() }} // Function will trigger on select event
                                    onRemove={(selectedList, removedItem) => { postdata.basvuruTipi = selectedList.length == 0 ? null : selectedList.join() }} // Function will trigger on remove event
                                /> <div className="textvmuted small">Şablonun hangi başvuru tiplerinde görüntüleneceğini seçin</div></>
                                : <div>{postdata.basvuruTipi}</div>
                            }
                           
                        </div>

                         <div className="row ms-3 mt-1">
                            <div className="col-12 col-md-5 pe-0">
                                İş Adımları <span className="text-danger">*</span>
                                <select className="form-select form-select-sm " id="denetim" name="denetim" placeholder="Denetim" onChange={(e) => {
                                    setKontrolorListForSelectTask(kontrolorList.filter(x=>x.role==e.target.value))
                                }
                                }>
                                    <option value={-1}></option>
                                    {denetimList.map(x => <option value={x.id}>{x.name} </option>)}
                                </select>
                            </div>

                            <div className="col-12 col-md-5 pe-0">
                                Kontrolörler <span className="text-danger">*</span>
                                <select className="form-select form-select-sm" id="kontolor" name="kontolor" placeholder="Denetim">
                                    <option value={-1}></option>
                                    {kontrolorListForSelectTask.map(x => <option value={x.userName}>{x.name + " " + x.family} </option>)}
                                </select>
                            </div>
                            {/*<div className="col-6 col-md-2">*/}
                            {/*    <br />*/}
                            {/*    <input type="checkbox" className="form-check-input" id="parallel" name="parallel" disabled={document.getElementById("mapping") != null && document.getElementById("mapping").value.length == 0} /> Paralel*/}

                            {/*</div>*/}
                            <div className="col-6 col-md-2">
                            <br/>
                                <button onClick={Add} className="btn btn-sm btn-primary text-decoration-none ">Ekle</button>
                            </div>
                        </div>

                        <div className="m-4 mt-3" key={postdata}>
                            {showTable(postdata)}
                         </div>

                        <div className="m-4 mt-1 mb-1">
                            <span className="textvmuted small">{mappingToString()}</span>
                            
                        </div>

                        <div className="row mt-2 mb-2">
                            <div className="col-12 text-center">
                                <button onClick={SaveSablon} className="btn btn-sm btn-primary text-decoration-none ">Şablonu Kaydet</button>
                                <button onClick={Reset} className="btn btn-sm btn-primary text-decoration-none ms-1">Sıfırla</button>
                            </div>
                        </div>

                      
                    </div>
                </div>
                {/*----------------------------------------------------------------------------------------------------*/}
                <div className="col-12 col-md-6 d-flex flex-column bg-white rounded-3">
                    <div className=" " >
                        <div className="p-3 fw-bold border-bottom" >
                            Oluşturulan Şablonlar
                        </div>
                        <div className="bg-light m-3  p-3 mb-2 rounded-3 textvmuted fw-bold align-middle"  >
                            <span className="fa fa-info pe-3" style={{ color: "lightblue", fontSize: "26px" }} ></span>
                            Oluşturduğunuz havale şablonları silebilir veya özelliklerini değiştirebilirsiniz. Şablon isminin üzerine tıkladıktan sonra “Yeni Şablon Oluşturma” alanından özelliklerini düzenleyebilirsiniz.                       </div>
                        <div className="">
                            {listData != null ? renderTable(listData) : ""}
                        </div>

                    </div>
                </div>
                {/*----------------------------------------------------------------------------------------------------*/}
            </div >
        </div >



    );

}