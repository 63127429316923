import axios from "axios";


import React, { useState, useEffect } from 'react';
import { useGlobalState, isEmpty, showErrorMessage, showSuccessMessage, showConfirmMessage, setVisible } from '../GlobalState.js';
import background from "../Dashboard/loading.gif";
import Application from "./Application.js";
import ApplicationList from "./ApplicationList.js";
import EimzaOnaylarimMobil from "./EimzaOnaylarimMobil.js";


export default function EimzaOnaylarim(props) {

    var eimza = props.eimza;
    var imzalayan = props.imzalayan;
    var errorPic = "";
    var page = 0;
    const colors = { grey: "#f3f2f7", green: "#106485" }

    const [selectedSertificate, setSelectedSertificate] = useState(0);
    // var appDownloadURL = '/getfile?sec=onaylarim.msi';

    var appDownloadURL = '';
    var appDownloadURLMAC = '';
    var workingUrl = "";
    const [localURL, setloaclURL] = useState("");

    const [certificates, setCertificates] = useState([]);


    //  const API_URL= "https://primeapicustomerapi.onaylarim.com"

    const errormsg1 = "Bir hata oluştu. Lütfen “Uygulamayı Aç” butonuna basın ve imzayı atana kadar açılan UYGULAMAYI KAPATMAYIN."
    const errormsg2 = "İmza atabilmek için Onaylarım İmza Uygulamasının bilgisayarınızda kurulu olması gerekmektedir. <br/>" +
        "Eğer uygulama kurulu ise; Aşağıdaki “Uygulamayı Aç” butonuna basın ve imzayı atana kadar açılan UYGULAMAYI KAPATMAYIN. <br/>"


    // onaylarim API'den alınan, e-imza aracının güncel versiyon bilgisi. bu bilgi ile kullanıcı bilgisayarında kurulu olan e-imza aracı sürümü karşılaştırılır, kullanıcıdaki sürüm eski ise uyarı verilir
    var getSignerAppVersionsResult = {}
    // e-imza aracına yapılan RESET adlı GET request'i sonrasında dönen değer
    var signerAppResetResult = {}
    // primeAPI'de kullanılacak tekil operasyon numarası
    var operationId = "";
    var refreshTimer = null;
    var refreshCount = 0;
    // imza türleri
    const signatureTypes = [
        { id: "pades", title: "Pades" },
        { id: "cades", title: "Cades" },
    ];
    // kullanıcının seçtiği imza türü
    const selectedSignatureType = signatureTypes[0];//we dont use this. we use the imzaType fild from database.

    var [guncelnecekEimza, setGuncelnecekEimza] = useState(null);

    useEffect(() => {
        // sayfa ilk yüklendiğinde onaylarim API'den e-imza aracının güncel versiyon bilgisi alınır
        document.getElementById("activepaneldiv").scrollTo(0, 0)
        GetSignerAppVersions();
    }, [])


    if (certificates.length > 0 && certificates[selectedSertificate].isFinancialSeal == true) {
        var errorbox = document.getElementById("errormessage");
        if (errorbox) {
            var s = '<div class="alert alert-danger fw-bold m-0 p-1 small" > MALİ MÜHÜR ile İMZA ATILAMAZ.  </div>'
            s += '<div class="text-dark p-2 rounded-2 small" style="background-color:#fef2f2"> Bilgisayarda takılı sertifika, bir mali mühüre aittir.  </div >'
            errorbox.innerHTML = s
        }
    }
    else {
        var errorbox = document.getElementById("errormessage");
        if (errorbox != null && errorbox.innerHTML.includes("MALİ MÜHÜR")) {
            errorbox.innerHTML = "";
        }
    }

    const showErrorpic = (pic) => {
        document.getElementById("eimzaerror1").hidden = true;
        document.getElementById("eimzaerror2").hidden = true;
        document.getElementById("eimzaerror3").hidden = true;
        document.getElementById("eimzaerror4").hidden = true;


        var errorpic = document.getElementById(pic);
        if (errorpic) {
            errorpic.hidden = false;
        }
    }



    const programYok = (title, msg) => {
        var errorbox = document.getElementById("errormessage");

       /* showErrorpic("eimzaerror1");*/

        if (errorbox) {
            var s = '<div class="alert alert-danger fw-bold m-0 p-1 small" > E - imza Programı Bulunamadı </div>'
            s += '<div class="text-dark p-2 rounded-2 small" style="background-color:#fef2f2">İmza atabilmek için e-imza programının bilgisayarınızda kurulu ve çalışır vaziyette olması gerekmektedir.</div> <div><div class="border shadow-sm rounded-2 m-1 p-2  small" > <div class="fw-bold">E-imza Programını Aç </div><div> E-imza programını bilgisayarınıza daha önce kurduysanız \"Aç\" butonu kullanarak programı açın ve alttaki \"Sertifikaları Listele\" butonuna basın. </div><div class="text-start"> <a href="onaylarimsignerapp:\"start\"" type="button" class="btn btn-sm btn-outline text-primary fw-bold " style="background-color:#dbeaff"> <i class="fa fa-tv"></i>  Aç </a></div> </div >'
            s += '<div class="border shadow-sm rounded-2 m-1 p-2 mt-2  small" > <div class="fw-bold"> E-imza Programını İndir </div>  <div > E-imza programı bilgisayarınızda kurulu değilse, aşağıdaki butonlardan uygun olanı kullanarak indirip kurabilirsiniz.  Lütfen kurulumu tamamladıktan sonra aşağıdaki yenile butonuna basınız. </div>'
            s += '<a href="' + appDownloadURL + '" class="mt-1 btn btn-sm btn-outline text-primary fw-bold " style="background-color: #dbeaff"> <i class="fa fa-windows"></i> İndir Windows</a>'
            s += ' <a href="' + appDownloadURLMAC + '" class="mt-1 btn btn-sm  btn-outline text-primary fw-bold " style="background-color: #dbeaff"> <i class="fa fa-apple"></i> İndir macOS </a>'
            errorbox.innerHTML = s + "</div> </div > </div >"
        }
    }


    const takiliEimzaYok = () => {
        var errorbox = document.getElementById("errormessage");
        //showErrorpic("eimzaerror2");
        if (errorbox) {
            var s = '<div class="alert alert-warning fw-bold small m-0 p-1" > Bilgisayara Takılı E - imza Bulunamadı. </div>'
            s += '<span class="text-dark"> Lütfen aşağıdakileri sırasıyla deneyiniz </span>';
            s += '<ol class="list-group list-group-flush list-group-numbered small">';
            s += '<li class="list-group-item p-0"> E-imzanızı bilgisayarınıza takınız. </li>'
            s += '<li class="list-group-item p-0"> www.turkiye.gov.tr adresine girerek aşağıdaki resimde gösterildiği gibi e-devlet’e "Elektronik İmza" ile giriş yapmayı deneyiniz. E-devlet’e de elektronik imza ile giriş yapamıyorsanız lütfen e-imzanızı tedarik ettiğiniz firmadan destek alın.</li>'
            s += '<li class="list-group-item p-0"> E-Devlete elektronik imzanız ile girebiliyorsanız, imzanızı bilgisayarınızdan çıkartıp tekrar takmayı deneyebilirsiniz.</li>'
            s += '<li class="list-group-item p-0"> Sim kartınızı, kart okuyucudan çıkarıp tekrar taktıktan sonra deneyiniz.</li>'
            s += '<li class="list-group-item p-0"> Yukarıdaki işlemleri yaptıktan sonra aşağıdaki yenile düğmesine basabilirsiniz.</li>'
            s += '</ol>'
            errorbox.innerHTML = s;
            setVisible("edeveletimage", true);
        }
    }
    const programGunceldegil = () => {
        var errorbox = document.getElementById("errormessage");
        if (errorbox) {
            var s = '<div class="alert alert-warning fw-bold small m-0 p-1" > "E-imza Programı Güncel Değil. </div>'
            s += '<div class="text-dark">Güncel versiyonu indirmek için aşağıdaki butonu kullanabilirsiniz. <br/>Not: E-imza programınızın güncel olması tavsiye edilir.Ancak güncel olmadan da imza atabilirsiniz. </div>';
            s += '<a href="' + appDownloadURL + '" class="btn btn-sm btn-outline text-primary fw-bold " style="backgroundColor: \'#dbeaff\'"> <i class="fa fa-windows"></i> İndir Windows</a>';
            errorbox.innerHTML = s + '<a href="' + appDownloadURLMAC + '" class="float-end ms-1 btn btn-sm  btn-outline text-primary fw-bold " style="backgroundColor: \'#dbeaff\'"  > <i class="fa fa-apple"></i> İndir macOS </a>'
        }
    }



    const showImzayaHzair = (title, msg) => {
        var errorbox = document.getElementById("errormessage");
        if (errorbox) {
            errorbox.innerHTML = '<div class="alert alert-success fw-bold small m-0 p-1" > İmza Atmaya Hazırsınız. </div>'
            errorbox.innerHTML += '<span class="text-success">PIN girip imzala butonuna basarak aşağıdaki sertifika ile belgeyi imzalayabilirsiniz.<br/>Atacağınız e-imza, elle atılan ıslak imzalar ile aynı hukuki geçerliliğe sahiptir.</span>'
        }
    }



    const showWarningMessage = (title, msg) => {
        var errorbox = document.getElementById("errormessage");
        if (errorbox) {
            errorbox.innerHTML = '<div class="alert alert-warning fw-bold small m-0 p-1" >' + title + '</div>'
            errorbox.innerHTML += '<span class="text-dark">' + msg + '</span>'
        }
    }


    const showSuccessMessage = (title, msg) => {
        var errorbox = document.getElementById("errormessage");
        if (errorbox) {
            errorbox.innerHTML = '<div class="alert alert-success fw-bold small m-0 p-1" >' + title + '</div>'
            errorbox.innerHTML += '<span class="text-success">' + msg + '</span>'
        }
    }
    const showErrorMessage = (title, msg) => {
        var errorbox = document.getElementById("errormessage");
        if (errorbox) {
            errorbox.innerHTML = '<div class="alert alert-danger fw-bold m-0 p-1 small" >' + title + '</div>'
            if (isEmpty(msg) == false)
                errorbox.innerHTML += '<div class="text-dark p-2 rounded-2 small" style="background-color:#fef2f2">' + msg + '</div>'
        }
    }
    const showMessage = (msg) => {
        var errorbox = document.getElementById("errormessage");
        if (errorbox) {
            errorbox.innerHTML = msg
        }
    }



    const disableImzabutton = () => {
        if (certificates.length == 0 || certificates.find(x => x.citizenshipNo == imzalayan.tc) == null)
            return true;
        if (certificates.length > 0 && certificates[selectedSertificate].isFinancialSeal == true)
            return true;
        return false;
    }
    const checkTC = (sertlist) => {
        if (sertlist.length > 0 && sertlist.find(x => x.citizenshipNo == imzalayan.tc) != null)
            return true;

        var list = "<ol>";
        sertlist.forEach(x => {
            list += "<li>"
            if (x.citizenshipNo.length > 10)
                list += "Elektronik İmza :" + x.citizenshipNo
            else
                list += "Mali Mühür :" + x.citizenshipNo
            list += "</li>"
        })

        showWarningMessage("Farklı Bir Sertifika Algılandı", imzalayan.tc + " TC kimlik numaralı kişinin imza atması beklenmekteyken bilgisayarda aşağıdaki elektronik imza veya mali mühürler tespit edildi:" + list)
        return false;
    }


    const HandleError = (error) => {
        if (axios.isAxiosError(error)) {
            if (error.code && error.code === "ERR_NETWORK") {
                return "Sunuculara erişilemedi.";
            } else if (error.response !== undefined && error.response !== null) {
                if (error.response.status === 401) {
                    return "Yetkisiz erişim. Giriş sayfasına yönlendirileceksiniz.";
                } else if (error.response.status === 500) {
                    return "Sunucuda hata oluştu.";
                } else if (error.response.status === 501) {
                    return "Bilinmeyen fonksiyon.";
                } else if (error.response.status === 404) {
                    return "Bilinmeyen fonksiyon.";
                } else if (error.response.status === 0) {
                    return "Sunuculara erişilemedi.";
                } else {
                    return error.response.status.toString();
                }
            } else if (error.request !== undefined && error.request !== null) {
                return "Sunuculara erişilemedi.";
            } else {
                if (error.message !== undefined && error.message !== null) {
                    return error.message;
                } else {
                    return error.name;
                }
            }
        } else {
            return error.message;
        }
    }


    // kullanıcı AÇ butonuna bastığında e-imza aracı açılır
    const OpenSignerApp = () => {
        try {
            window.location.href = 'onaylarimsignerapp:"start"';

        } catch (err) {
            showErrorMessage("open signer app error.", err);
        }
    }

    async function LocalSignerPing(useHttps: boolean, useLocalhost: boolean): Promise<boolean> {
        const url = (useHttps ? 'https' : 'http') + (useLocalhost ? "://localhost:8099/ping" : "://localsigner.onaylarim.com:8099/ping");

        console.log("e-İmza aracına " + (useHttps ? 'SSL' : 'HTTP') + " PING isteği gönderiliyor. Url: " + url);
        try {

            const axiosResponse = await axios.get(url, { timeout: 500 });
            console.log("e-İmza aracına " + (useHttps ? 'SSL' : 'HTTP') + " PING isteğ sonucu.", axiosResponse);
            const signerAppPingResult = axiosResponse.data;
            if (signerAppPingResult.error === undefined || signerAppPingResult.error === null || signerAppPingResult.error.length === 0) {
                return true;
            }
            return false;
        } catch (error) {
            console.log("e-İmza aracına " + (useHttps ? 'SSL' : 'HTTP') + " PING isteği gönderilemedi.", error);
            showErrorMessage("e-İmza aracına " + (useHttps ? 'SSL' : 'HTTP') + " PING isteği gönderilemedi.", error);
            return false;
        }
    }

    async function TryToConnect() {
        try {
            const httpsTryIsOk = await LocalSignerPing(true, false);
            if (httpsTryIsOk) {

                workingUrl = "https://localsigner.onaylarim.com:8099"

                // bilgisayarda e-imza aracı var, çalışıyor durumda ve https bağlantı yapılabildi
                LocalSignerReset();
            }
            else {
                // bilgisayarda e-imza aracı var, çalışmıyor
                // bilgisayarda e-imza aracı var, çalışıyor ancak https bağlantı yapılamadı
                const httpTryIsOk = await LocalSignerPing(false, false);
                if (httpTryIsOk) {
                    workingUrl = "http://localsigner.onaylarim.com:8099"
                    // bilgisayarda e-imza aracı var, çalışıyor durumda ve http bağlantı yapılabildi
                    LocalSignerReset();
                }
                else {
                    // bilgisayarda e-imza aracı var, çalışmıyor
                    // bilgisayarda e-imza aracı var, çalışıyor ancak http bağlantı yapılamadı

                    const httpTryWithLocalhostIsOk = await LocalSignerPing(false, true);
                    if (httpTryWithLocalhostIsOk) {
                        workingUrl = "http://localhost:8099"

                        LocalSignerReset();
                    }
                    else {
                        programYok();
                    }

                }
            }
        }
        catch (error) {
            console.log(error);
            showErrorMessage("Try To Connect", error)
        }
    }



    // onaylarim API'den e-imza aracının güncel versiyon bilgisini alır
    const GetSignerAppVersions = () => {
        // CORS hatası alacaktır. Ancak demo uygulamanın çalışmasına engel bir durum değildir.
        // ONAYLARIM on-Prem kurulduğunda, client uygulamadan gelen requestlere izin verilecek şekilde CORS ayarı yapılacaktır.
        // ONAYLARIM SaaS kullanıldığında, client uygulamadan gelen requestlere izin verilecek şekilde CORS ayarı yapılacaktır.

        console.log("e-İmza aracı son sürümü alınıyor.");

        axios
            .get("https://api.onaylarim.com/sign/GetSignerAppVersions")
            .then((result) => {
                if (result.data.error) {
                    console.log("Uygulama güncel sürümü alınırken hata oluştu.", result);
                } else {
                    getSignerAppVersionsResult = result.data.result;
                    console.log("Uygulama güncel sürümü.", getSignerAppVersionsResult);
                    appDownloadURL = getSignerAppVersionsResult.signerAppWindowsUrl;
                    appDownloadURLMAC = getSignerAppVersionsResult.signerAppMacUrl;
                    //   LocalSignerReset();
                    TryToConnect();

                }
            })
            .catch(async (error) => {
                showErrorMessage("Uygulama güncel sürümü alınırken hata oluştu. ", error);
            });
    }

    // e-İmza aracına bağlanıp varsa takılı sertifikalar alınır
    const LocalSignerReset = () => {
        signerAppResetResult = null;
        operationId = "";
        setSelectedSertificate(0);
        setCertificates([]);

        setVisible("edeveletimage", false);
        if (refreshCount > 20 && refreshTimer != null) {
            refreshCount = 0;
            clearInterval(refreshTimer);
            refreshTimer = null;
            return;
        }
        // reset fonkisyonu ile e-İmza aracına bağlanıp varsa takılı sertifikalar alınır. e-imza aracı reset fonksiyonu ile takılı sertifiları baştan aramaya başlar.
        console.log("e-İmza aracına RESET isteği gönderiliyor.");

        axios
            //.get("https://localsigner.onaylarim.com:8099/reset")
            .get(workingUrl + "/reset?mm")
            .then((result) => {

                console.log("e-İmza aracına RESET isteğ sonucu.", result);
                signerAppResetResult = result.data;
                // signerAppStatus açıklamaları definition'da bulunabilir
                if (signerAppResetResult.signerAppStatus === 1) {
                    // e-imza aracı sertifikaları bulmaya çalışıyorsa arka arkaya en fazla 10 kere olacak şekilde tekrardan e-imza aracı durumu sorgulanır. reset fonksiyonundan farklı olarak takılı sertifikalar baştan aranmaz, sadece e-imza aracı durumunu döner
                    let counter = 0;
                    while (counter < 10) {
                        axios.get(workingUrl + "/ping").then((result) => {
                            const signerAppPingResult = result.data;
                            // GettingSmartCards dışında bir değer dönerse while loop sonlandırılır
                            if (signerAppPingResult.signerAppStatus !== 1) {
                                counter = 10;
                            }
                        });
                        counter++;
                    }
                }
                let desiredVersion = 0;
                if (signerAppResetResult.signerAppPlatform === "windows") {
                    // e-imza aracı Windows sürümü ise, yani son kullanıcı bilgisayarı Windows ise, olması gereken sürüm olarak OnaylarimAPI'den alınan Windows için son sürüm numarası set edilir
                    desiredVersion = getSignerAppVersionsResult.signerAppWindowsVersion;
                } else if (signerAppResetResult.signerAppPlatform === "macos") {
                    // e-imza aracı MacOS sürümü ise, yani son kullanıcı bilgisayarı MacOS ise, olması gereken sürüm olarak OnaylarimAPI'den alınan MacOS için son sürüm numarası set edilir
                    desiredVersion = getSignerAppVersionsResult.signerAppMacVersion;
                } else if (signerAppResetResult.signerAppPlatform === "linux") {
                    // e-imza aracı Linux sürümü ise, yani son kullanıcı bilgisayarı Linux ise, olması gereken sürüm olarak OnaylarimAPI'den alınan Linux için son sürüm numarası set edilir
                    desiredVersion = getSignerAppVersionsResult.signerAppMacVersion;
                } else if (signerAppResetResult.signerAppPlatform === "freebsd") {
                    // e-imza aracı freebsd sürümü ise, yani son kullanıcı bilgisayarı freebsd ise, olması gereken sürüm olarak OnaylarimAPI'den alınan freebsd için son sürüm numarası set edilir
                    desiredVersion = getSignerAppVersionsResult.signerAppMacVersion;
                }
                // kullanıcı bilgisayarında en az bir tane sertifika bulundu ise seçili sertifika olarak atanır
                if (signerAppResetResult.certificates !== undefined && signerAppResetResult.certificates !== null && signerAppResetResult.certificates.length > 0) {

                    if (checkTC(signerAppResetResult.certificates)) {
                        if (signerAppResetResult.signerAppDllVersion == desiredVersion)
                            showImzayaHzair();
                        else
                            programGunceldegil();

                    }
                    setloaclURL(workingUrl)
                    setCertificates(signerAppResetResult.certificates);
                }
                else {
                    takiliEimzaYok();
                }
                return;
            })
            .catch((error) => {
                //  showErrorMessage("e-İmza aracına RESET isteği gönderilemedi.", error);
                //programYok();
                programGunceldegil();
                setCertificates([]);

            })
            .finally(() => {
                console.log("e-İmza aracı durumu: ");
                var s = document.getElementById("sertspin");
                if (s)
                    s.classList.remove("fa-spin");
            });
    }

    // imza işlemini gerçekleştiren fonksiyondur
    const Sign = () => {
        if (certificates.length == 0) {
            showErrorMessage("Sertifika Bulunamadı");
            return;
        }
        var pin = document.getElementById("pin").value;
        if (isEmpty(pin)) {
            showErrorMessage("E-İmza Şifrenizi Giriniz");
            return;

        }
        var certificate = certificates[selectedSertificate];

        if (certificate.citizenshipNo != imzalayan.tc) {
            showErrorMessage(imzalayan.tc + " TC kimlik numaralı kişinin imza atması beklenmekteyken " + certificate.citizenshipNo + " numaralı kimlik ile işlem yapılmak istendiği için imza atılamadı.");
            return;
        }

        operationId = "";
        const createStateOnOnaylarimApiRequest = {
            certificate: certificate.data,
            signatureType: selectedSignatureType.id,
            appid: props.eimza.appId,
            eimzaId: props.eimza.id,
            tc: imzalayan.tc
        };

        showMessage("İmzalama işlemi başlatıldı");
        document.getElementById("imzaspin").hidden = false;

        axios
            .post("CreateStateOnOnaylarimApi", createStateOnOnaylarimApiRequest)
            .then((createStateOnOnaylarimApiResponse) => {

                showMessage("İmza sunucusu isteği kabul etti");
                console.log("sunucu katmanına CreateState isteği gönderildi.", createStateOnOnaylarimApiResponse);
                const config = {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                };
                const createStateOnOnaylarimApiResult = createStateOnOnaylarimApiResponse.data;
                console.log("createStateOnOnaylarimApiResult", createStateOnOnaylarimApiResult);
                if (isEmpty(createStateOnOnaylarimApiResult.error) == false) {
                    if (createStateOnOnaylarimApiResult.error.includes("bozuk")) {
                        showErrorMessage(createStateOnOnaylarimApiResult.error, " Bu sorunun çözümü için aşağıdaki yöntemlerden birini deneyebilirsiniz<br/>* PDF dosyasını tarayıcıda(chrome, vb) açarak “yazdır” menusunde hedef / yazıcı seçim bölümünde “PDF olarak kaydet” yapabilir<br/> * PDF dosyasını Adobe programı ile açarak farklı kaydet yapabilir<br/> * Dosyanızın orjinal halini farklı bir program vasıtasıyla PDF'e çevirmeyi deneyebilirsiniz");
                    }
                    else showErrorMessage(createStateOnOnaylarimApiResult.error)
                    document.getElementById("imzaspin").hidden = true;
                    return;
                }

                const signStepTwoRequest = {
                    keyId: createStateOnOnaylarimApiResult.keyID,
                    keySecret: createStateOnOnaylarimApiResult.keySecret,
                    state: createStateOnOnaylarimApiResult.state,
                    pkcsLibrary: certificate.pkcsLibrary,
                    slot: certificate.slot,
                    pin: pin,
                };
                // e-imza aracına e-imza atması için istekte bulunulur. Kartta bulunan sertifika ile imzalama işlemi bu adımda yapılır.
                console.log("e-İmza aracına SIGNSTEPTWO isteği gönderiliyor.");
                showMessage("İmzalanacak dosya gönderiliyor");
                axios
                    .post(localURL + "/signStepTwo", JSON.stringify(signStepTwoRequest), config)
                    .then((signStepTwoResponse) => {
                        const signStepTwoResult = signStepTwoResponse.data;
                        if (signStepTwoResult.error !== undefined && signStepTwoResult.error !== null) {
                            console.log("e-İmza aracına SIGNSTEPTWO isteği sonucu.", signStepTwoResult);
                            showErrorMessage(signStepTwoResult.error, "");
                            document.getElementById("imzaspin").hidden = true;

                        } else {
                            console.log("e-İmza aracına SIGNSTEPTWO isteği başarıyla tamamlandı.");
                            showMessage("Sunucuda imzalama işlemi yapılıyor");
                            // e-imza son adım çalıştırılır. 2. adımda imzalanan veri API'ye gönderilir
                            const finishSignRequest = {
                                keyId: createStateOnOnaylarimApiResult.keyID,
                                keySecret: createStateOnOnaylarimApiResult.keySecret,
                                signedData: signStepTwoResult.signedData,
                                operationId: createStateOnOnaylarimApiResult.operationId,
                                signatureType: selectedSignatureType.id,
                            };
                            console.log("Sizin sunucu katmanına FinishSign isteği gönderiliyor.");
                            showMessage("Zaman damgası basılıyor");
                            axios
                                .post("FinishSign", finishSignRequest)
                                .then((finishSignResponse) => {
                                    document.getElementById("imzaspin").hidden = true;
                                    console.log("Sizin sunucu katmanına FinishSign isteği gönderildi.", createStateOnOnaylarimApiResponse);
                                    showMessage("Sunucudan imzalı dosya alınıyor");
                                    const finishSignResult = finishSignResponse.data;
                                    if (finishSignResult.isSuccess) {
                                        console.log("Sizin sunucu katmanına FinishSign istiği sonucu: İşlem başarılı.");
                                        operationId = createStateOnOnaylarimApiResult.operationId;
                                        showSuccessMessage("İmzalama işlemi başarıyla tamamlandı", "Atmış olduğunuz e-imza 5070 sayılı e-imza kanunu kapsamında ıslak imza ile eşdeğerdir");
                                        document.getElementById("imzaKapat").hidden = false;
                                        document.getElementById("imazpanel").hidden = true;


                                    } else {
                                        console.log("Sizin sunucu katmanına FinishSign istiği sonucu: İşlem başarısız.");


                                    }
                                })
                                .catch((error) => {
                                    console.log("Sizin sunucu katmanına FinishSign isteği gönderilemedi.", error);
                                    showErrorMessage("FinishSign isteği gönderilemedi", error)
                                    // showErrorMessage(errormsg1 + "  FinishSign isteği gönderilemedi.");
                                    document.getElementById("imzaspin").hidden = true;
                                });
                        }
                    })
                    .catch((error) => {
                        console.log("e-İmza aracına SIGNSTEPTWO isteği gönderilemedi.", error);
                        showErrorMessage("SIGNSTEPTWO isteği gönderilemedi", error)
                        // showErrorMessage(errormsg1 + "  e-İmza aracına SIGNSTEPTWO isteği gönderilemedi.");
                        document.getElementById("imzaspin").hidden = true;

                    });
            })
            .catch((error) => {
                console.log("Sizin sunucu katmanına CreateStateOnOnaylarimApi isteği gönderilemedi.", error);
                showErrorMessage("CreateStateOnOnaylarimApi isteği gönderilemedi", error)
                // showErrorMessage(errormsg1 + "  CreateStateOnOnaylarimApi isteği gönderilemedi");
                document.getElementById("imzaspin").hidden = true;
            });

    }

    const DownloadFile = () => {
        axios
            .get("DownloadSignedFileFromOnaylarimApi?operationId=" + operationId, { responseType: "blob" })
            .then((e) => {
                if (e.data.error) {
                    console.log("Hata oluştu. " + e.data.error);
                } else {
                    let filename = "dosya.pdf";
                    const contentDisposition = e.headers["Content-Disposition"];
                    if (contentDisposition) {
                        const match = contentDisposition.match(/filename[^;\n]*=(UTF-\d['"]*)?((['"]).*?[.]$\2|[^;\n]*)?/gi);
                        if (match && match[1]) {
                            const a1 = match[1].split("''")[1];
                            if (a1) {
                                filename = decodeURI(a1);
                            }
                        }
                    }
                    const fileURL = window.URL.createObjectURL(new Blob([e.data]));
                    const fileLink = document.createElement("a");
                    fileLink.href = fileURL;
                    fileLink.setAttribute("download", filename);
                    document.body.appendChild(fileLink);
                    fileLink.click();
                }
            })
            .catch((error) => {
                console.log("Sizin sunucu katmanına DownloadSignedFileFromOnaylarimApi isteği gönderilemedi.", error);
                showErrorMessage("DownloadSignedFileFromOnaylarimApi isteği gönderilemedi", error)
            });
    }

    const imageLoaded = () => {
        var imgset = document.getElementById("pdfimagediv");
        if (imgset == null)
            return;
        imgset.style.background = null;
        const node = document.createElement("img");
        node.src = "/getdosyaasimage?eimzaid=" + eimza.id + "&page=" + (++page);
        node.onload = imageLoaded;
        node.addEventListener('error', () => node.remove(), false);
        node.style.maxWidth = "100%";
        node.style.maxHeight = "100%";
        node.style.objectFit = "contain";
        imgset.appendChild(node)

    }



    return <div className="">
        <div className="mb-2 p-2 fw-bold text-white" style={{ backgroundColor: colors.green, fontSize: "18px" }}>
            {/*<div className="col-10">*/}
            {/*    {props.eimza != null*/}
            {/*        && <>*/}
            {/*            <a href={'geteimzaoriginaldosya?eimzaid=' + props.eimza.id} className="text-decoration-none btn  btn-sm btn-link me-1 p-0 " target="_blank" download="imzalanacak_evrak.pdf"><h6 className="fw-bold"> {props.eimza.id} - {props.eimza.surecname}</h6></a>*/}
            {/*            <span className="text-danger">*/}
            {/*                (İmzalanacak Dosya)*/}
            {/*            </span>*/}
            {/*        </>*/}
            {/*    }*/}
            {/*</div >*/}
            {/*<div className="col-2 text-end">*/}
            {/*    <button className="btn btn-sm btn-link text-decoration-none p-0 me-1" type="button" onClick={() => props.setActivePanel(props.source)} title="kapat"> <i className="fa fa-close" style={{ "font-size": "18px" }}></i></button>*/}
            {/*</div>*/}


            <a href={'geteimzaoriginaldosya?eimzaid=' + props.eimza.id} className="text-decoration-none btn  btn-sm btn-link text-white" target="_blank" download="imzalanacak_evrak.pdf">
                ({props.eimza.id} - {props.eimza.surecname})  İmzalanacak dosyayi bilgisayarınıza indirmek için tıklayınız.
            </a>
        </div>
        <div className="row">
            <div className="col-12 col-md-3 border rounded-2 border" style={{ textAlign: "justify" }}>
                <div className="mb-1 " >
                    <ul class="nav nav-tabs">
                        <li class="nav-item">
                            <a class="nav-link active" aria-current="page" id="eimzatab" href="#" onClick={() => {
                                document.getElementById("errormessage").innerHTML = '<div class="spinner-border spinner-border-sm" ></div>';
                                document.getElementById("kartileimzala").hidden = false
                                document.getElementById("mobilileimzala").hidden = true
                                document.getElementById("eimzatab").classList.add("active");
                                document.getElementById("mobiltab").classList.remove("active");
                                GetSignerAppVersions()
                            }}> E-imza</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#" id="mobiltab" onClick={() => {
                                document.getElementById("errormessage").innerHTML = "";
                                document.getElementById("mobilileimzala").hidden = false
                                document.getElementById("kartileimzala").hidden = true
                                document.getElementById("edeveletimage").hidden = true
                                document.getElementById("mobiltab").classList.add("active");
                                document.getElementById("eimzatab").classList.remove("active");
                            }}>Mobil İmza</a>
                        </li>

                    </ul>
                </div >
                <span className="ps-0 spinner-border spinner-border-sm " hidden id="imzaspin" ></span>
                <div id="errormessage" style={{ textAlign: "justify" }}>
                    <div className="w-100 mt-4 mb-4 text-center align-items-center justify-content-center text-black" >
                        <div className="spinner-border spinner-border-sm" ></div>
                    </div>
                </div >


                <img src={require('../pics/edevlet_eimza.png').default} style={{ width: "100%" }} hidden id="edeveletimage" />
                <div id="imzaKapat" className="text-center" hidden>
                    <button onClick={() => {
                        props.changeComponent(<Application key={props.appData.id} appData={props.appData} changeComponent={props.changeComponent} backButton={<ApplicationList changeComponent={props.changeComponent} />} />)
                    }}
                        type="button" className="btn btn-sm btn-success border" > Kapat </button>
                </div>

                <div id="kartileimzala">
                    <div id="imazpanel" >
                        {certificates.length > 0 && < div className="m-2 p-2 rounded-2" style={{ backgroundColor: colors.grey }} key={selectedSertificate}>
                            < div className="row" >
                                <div className="col-2  text-center m-0">
                                    <span className="material-icons text-success p-0 m-0" style={{ "rotate": "90deg" }}>
                                        sim_card
                                    </span>
                                </div>
                                <div className="col-10 fw-bold text-dark" >
                                    {certificates[selectedSertificate].personFullname}  <i className="ps-1 fa fa-info-circle "></i>
                                </div>


                            </div>
                            < div className="row" >
                                <div className="col-2  ">
                                    <a href="#" onClick={() => { setSelectedSertificate(selectedSertificate > 0 ? selectedSertificate - 1 : certificates.length - 1) }}> <i class="fa fa-chevron-left "  ></i></a>
                                </div>
                                <div className="col-8 text-secondary">
                                    {certificates[selectedSertificate].citizenshipNo}
                                </div>
                                <div className="col-2 ">
                                    <a href="#" onClick={() => { setSelectedSertificate((selectedSertificate + 1) % certificates.length) }}> <i class="fa fa-chevron-right"  ></i></a>
                                </div>

                            </div>
                            < div className="row mt-1 " >
                                <div className="col-2  text-center">

                                </div>
                                <div className="col-8 text-dark">
                                    {certificates[selectedSertificate].validTo}
                                </div>
                                <div className="col-2   mt-2">

                                </div>

                            </div>
                        </div>
                        }
                        <div>
                            <div className="mt-1">
                                {/*<select className="form-select form-select-sm" id="certificateSelect">*/}
                                {/*    {certificates.length > 1 && <option value={-1} selected>Sertifika Seçiniz</option>}*/}
                                {/*    {*/}
                                {/*        certificates.map((c, i) => <option value={i}>{c.citizenshipNo}-{c.personFullname}  ({c.validTo})</option>)*/}
                                {/*    }*/}
                                {/*</select>*/}
                            </div>
                            <div className=" mt-1">
                                <a onClick={(e) => { document.getElementById("sertspin").classList.add("fa-spin"); GetSignerAppVersions(); }} href="#" type="button " className="btn btn-sm btn-outline border border-secondary w-100 text-decoration-none" style={{ backgroundColor: colors.grey }}>Sertifikaları Listele <i className="fa fa-refresh " id="sertspin"></i> </a>
                            </div >

                        </div>
                        <div hidden={disableImzabutton()}>
                            <div className="mt-2 d-flex justify-content-center  w-100" >
                                <input type="password" className="form-control form-control-sm  shadow-none" id="pin" style={{ width: "135px" }} placeholder="E-İmza Şifre" name="pin" />
                            </div >
                            <div className="mt-2 w-100 text-center">
                                <button type="button" className="btn btn-dark shadow-none" style={{ width: "40px", backgroundColor: "#112e50" }} onClick={() => document.getElementById("pin").value += "1"}>1</button>
                                <button type="button" className="ms-2 btn btn-dark shadow-none" style={{ width: "40px", backgroundColor: "#112e50" }} onClick={() => document.getElementById("pin").value += "2"}>2</button>
                                <button type="button" className="ms-2 btn btn-dark shadow-none" style={{ width: "40px", backgroundColor: "#112e50" }} onClick={() => document.getElementById("pin").value += "3"}>3</button>
                            </div>
                            <div className="mt-1 w-100 text-center">
                                <button type="button" className="btn btn-dark  shadow-none" style={{ width: "40px", backgroundColor: "#112e50" }} onClick={() => document.getElementById("pin").value += "4"}>4</button>
                                <button type="button" className="ms-2 btn btn-dark  shadow-none" style={{ width: "40px", backgroundColor: "#112e50" }} onClick={() => document.getElementById("pin").value += "5"}>5</button>
                                <button type="button" className="ms-2 btn btn-dark  shadow-none" style={{ width: "40px", backgroundColor: "#112e50" }} onClick={() => document.getElementById("pin").value += "6"}>6</button>
                            </div>
                            <div className="mt-1 w-100 text-center">
                                <button type="button" className="btn btn-dark shadow-none" style={{ width: "40px", backgroundColor: "#112e50" }} onClick={() => document.getElementById("pin").value += "7"}>7</button>
                                <button type="button" className="ms-2 btn btn-dark shadow-none " style={{ width: "40px", backgroundColor: "#112e50" }} onClick={() => document.getElementById("pin").value += "8"}>8</button>
                                <button type="button" className="ms-2 btn btn-dark  shadow-none" style={{ width: "40px", backgroundColor: "#112e50" }} onClick={() => document.getElementById("pin").value += "9"}>9</button>
                            </div>
                            <div className="mt-1 w-100 text-center">
                                <button type="button" className=" btn btn-dark shadow-none text-center" style={{ width: "40px", backgroundColor: "#112e50" }} onClick={() => document.getElementById("pin").value = ""}>
                                    <span class="material-icons text-center" style={{ fontSize: "16px" }}>
                                        refresh
                                    </span>
                                </button>

                                <button type="button" className="ms-2 btn btn-dark shadow-none " style={{ width: "40px", backgroundColor: "#112e50" }} onClick={() => document.getElementById("pin").value += "0"}>0</button>
                            </div>
                        </div>


                        <div className="row mt-2">
                            <div className="col-4 ">
                                <button onClick={() => {
                                    props.changeComponent(<Application key={props.appData.id} targetEimza={eimza} appData={props.appData} changeComponent={props.changeComponent} backButton={<ApplicationList changeComponent={props.changeComponent} />} />)
                                }}
                                    type="button" className="btn btn-sm btn-warning border  w-100" > İptal </button>
                            </div >

                            <div className="col-4 ">
                                <button onClick={() => { Sign() }} type="button" className="btn btn-sm btn-success w-100 border" hidden={disableImzabutton()}>
                                    İmzala
                                </button>
                            </div >

                            <div className="col-3">
                                <button type="button" class="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                    Yardım
                                </button>
                            </div >


                        </div >
                    </div >
                </div >

                <div id="mobilileimzala" hidden>
                    <EimzaOnaylarimMobil showSuccessMessage={showSuccessMessage} showWarningMessage={showWarningMessage} showErrorMessage={showErrorMessage} showMessage={showMessage} eimza={props.eimza} imzalayan={imzalayan} appData={props.appData} changeComponent={props.changeComponent} />
                </div >

            </div >
            <div className="col-12 col-md-9">

                <div id="pdfimagediv" className="w-100 border rounded-2"
                    style={{
                        backgroundImage: `url(${background})`, backgroundRepeat: "no-repeat", backgroundSize: "50px 50px", backgroundPosition: "center", width: "100% !important"
                    }}
                >
                    <img src={"/getdosyaasimage?eimzaid=" + eimza.id + "&page=" + page} onLoad={imageLoaded} onError='this. remove()' style={{ "max-width": "100%", "max-height": "100%", objectFit: "contain" }} />
                </div>

            </div >

        </div>








        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl modal-dialog-scrollable modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        {/*<h5 class="modal-title" id="exampleModalLabel">E-İmza </h5>*/}
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-6">
                                <img src={require('../Header/eimzaerror1.png').default} className="w-100" id="eimzaerror1" />
                            </div>
                            <div class="col-6">
                                <img src={require('../Header/eimzaerror2.jpg').default} className="w-100" id="eimzaerror2" />
                            </div>
                            <div class="col-6">
                                <img src={require('../Header/eimzaerror3.jpg').default} className="w-100" id="eimzaerror3" />
                            </div>
                            <div class="col-6">
                                <img src={require('../Header/eimzaerror4.jpg').default} className="w-100" id="eimzaerror4" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div >


}