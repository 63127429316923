import React, { useState, useEffect } from 'react';
import { isEmpty, showErrorMessage } from '../GlobalState';


export default function SharedUsersPanel(props) {
    var postdata = {};
    const [shareduserList, setSharedUserList] = useState([]);

    var appData = props.appData;
    var showCompact = props.showCompact;


    useEffect(() => {
        fetchSharedUsers();

    }, [])



    var compactList = shareduserList.map((r, idx) => <img className="rounded-circle" style={{ width: "32px", height: "32px" }} title={r.userName} src={"/getuserpic?user=" + r.userName} />)

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        postdata[target.name] = value;
    }

    const fetchSharedUsers = () => {
        fetch("getsharedusers", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: appData.id
        }).then(async response => {
            if (!response.ok) {
                response.text().then(value => {
                    showErrorMessage(value)
                })
            }
            else {
                response.json().then(value => {
                    setSharedUserList(value);
                })

            }
        });
    }




    const renderTable = (tabledata) => {
        var mytable =
            <div className="table-responsive  mt-2" >
                <table className='table ' aria-labelledby="tabelLabel">
                <thead >
                    <tr>

                        <th className="text-start"> Kullanıcı Adı</th>
                        <th className="text-start"> Yetki</th>
                        <th className="text-start"> Süre</th>
                        <th className="text-start"> Paylaşan</th>
                        <th className="text-start"> Paylaşma Tarihi</th>
                        <th> </th>

                    </tr>
                </thead>
                <tbody id="myTable">


                    {tabledata.map(r =>
                        <tr key={r.id} >

                            <td ><img className="rounded-circle" style={{ width: "32px", height: "32px" }} src={"/getuserpic?user=" + r.userName} /> {r.userName}</td>
                            <td >{r.sharingType}
                            </td>
                            <td >{r.deadline}
                            </td>
                            <td>{r.sharedBy}</td>
                            <td>{r.sharingDate}</td>
                            <td>  <button onClick={() => { Sil(r.id) }} className="btn btn-link btn-sm text-decoration-none m-0 p-0">Sil</button></td>
                        </tr>
                    )}
                    <tr className="bg-white">
                        <td >
                            <input type="text" className="form-control form-control-sm d-inline-block" placeholder="Kullanıcı Kodu" name="muellifusername" id="usercode" defaultValue="" />
                        </td>
                        <td >

                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="sharingType" id="goruntule" value="Görüntüleme" defaultChecked={false}/>
                                <label class="form-check-label" for="inlineRadio1" >Görüntüleme</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="sharingType" id="duzenle" value="Düzenleme" defaultChecked={false}/>
                                <label class="form-check-label" for="inlineRadio2">Düzenleme</label>
                            </div>
                        </td>
                        <td >
                            <input className="form-control form-control-sm" type="date" name="deadline" id="deadline" placeholder="YYYY.MM.DD"  />

                        </td>
                        <td > </td>
                        <td > </td>
                        <td>  <button onClick={addSharing} className="btn btn-sm btn-link text-decoration-none m-0 p-0">Ekle</button></td>
                    </tr>
                </tbody>
            </table>
            </div>
        return (mytable);
    }

    const Sil = (id) => {
        fetch("removesharing", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: id
        }).then(async response => {
            if (!response.ok) {
                response.text().then(value => {
                    showErrorMessage(response.statusText + "  " + value)
                })
            }
            else {
                fetchSharedUsers();

            }
        });
    }

    const addSharing = () => {
        var sharingType = ""
        if (document.getElementById("goruntule").checked == true) {
            sharingType = "Görüntüleme";
        }
        else if (document.getElementById("duzenle").checked == true) {
            sharingType = "Düzenleme";
        }
        else {
            showErrorMessage("Paylaştığınız kullanıcının başvuruyu düzenleme yetkisine(dosya yükleme, silme, imzalama, paylaşma, vb) sahip olmasını istiyorsanız 'Düzenleme' yi seciniz. Hiç bir işlem yapmadan sadece dosyaları gorebilmesini istiyorsanız 'Görüntüleme' yi seçebilirsiniz.")
            return;
        }


        var deadline = document.getElementById("deadline").value;
        if (isEmpty(deadline) == false) {
            deadline = deadline.replaceAll("-", "/").replaceAll(".", "/");
        }
        fetch("addsharing", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ userName: document.getElementById("usercode").value, appId: appData.id, sharingType: sharingType, deadline: deadline })
        }).then(async response => {
            if (!response.ok) {
                response.text().then(value => {
                    showErrorMessage(response.statusText + "  " + value)
                })
            }
            else {
                document.getElementById("usercode").value = null;
                document.getElementById("deadline").value = null;
                document.getElementById("goruntule").checked = false;
                document.getElementById("duzenle").checked = false;
                fetchSharedUsers();
            }
        });

    }


    var listTable = "";
    if (shareduserList != null) {
        listTable = renderTable(shareduserList);
    }
    else
        listTable = renderTable([]);


    if (showCompact == null) {
        compactList = <div className="p-1">
            <h5 style={{ color: "#0098db" }}>Paylaşılan Kullanıcılar</h5>
            {listTable}
        </div>
    }
    else {
        compactList = <div > {compactList} </div>
    }
    return (compactList);


}