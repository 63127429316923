import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/js/bootstrap.min.js'
import * as UserPermConstants from '../User/UserPermConstants';
import { isEmpty, useGlobalState, showConfirmMessage, showErrorMessage, setVisible } from '../GlobalState.js';
import Apply1 from "./Apply1.js";
import Apply3 from "./Apply3.js";
import GMapLatLang from "./GMapLatLang.js";
import Application from "./Application.js";
import { ajaxFail, fvisible, fdisabled, fzorunlu } from '../GlobalState.js';

export default function Apply2(props) {

    const [sabitsorulistData, setsabitsorulistData] = useState(null);

    useEffect(() => {
        fetchSabitSoruList();



    }, [])

    const [MevkiList, setMevkiList] = useState([]);
    const [idariMahalle, setIdariMahalle] = useState([]);
    const [MahalleList, setMahalleList] = useState([]);
    const [AdaList, setAdaList] = useState([]);
    const [mahalleCoordinates, setMahalleCoordinates] = useState(null);
    const [userName, setuserName] = useGlobalState('userName');
    const [role, setRole] = useGlobalState('role');
    const zorunloBosBirakma = role.indexOf(UserPermConstants.Yeni_Basvuruda_Zorunlu_Alanlari_Bos_Birakabilme) == -1;
    const [app, setApp] = useState(null);

    var applicationdata = props.applicationdata;



    const fetchSabitSoruList = () => {
        fetch("getsabitsorulist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                response.json().then(value => {
                    setsabitsorulistData(value);
                    fetchMevkiList();
                    fetchMahalleList();
                    fetchIdariMahalleList();
                    if (applicationdata != null && applicationdata.mahaleId != null)
                        fetchAdaList(applicationdata.mevkiId, applicationdata.mahaleId)
                })
            }
        });
    }



    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked.toString() : target.value;
        applicationdata[target.name] = value;


        if (document.getElementById("Mahalle") == null
            || document.getElementById("Adaparsel") == null)
            return;


        if (target.name == "mevki") {
            var idx = document.getElementById("Mevki").selectedIndex;
            applicationdata[target.name] = document.getElementById("Mevki").options[idx].innerHTML;
            applicationdata.mevkiId = document.getElementById("Mevki").options[idx].value;
            var c = document.getElementById("Mahalle");
            c.innerHTML = "";
            for (var i = 0; i < MahalleList.length; i++) {
                if (value != MahalleList[i].mevkiId) continue;
                var opt = document.createElement('option');
                opt.value = MahalleList[i].id;
                opt.innerHTML = MahalleList[i].name;;
                c.appendChild(opt);
            }
            c.selectedIndex = -1;
            document.getElementById("Adaparsel").value = "";
        }
        if (target.name == "mahalle") {
            var idx = document.getElementById("Mahalle").selectedIndex;
            applicationdata[target.name] = document.getElementById("Mahalle").options[idx].innerHTML;
            applicationdata.mahaleId = document.getElementById("Mahalle").options[idx].value;
            document.getElementById("Adaparsel").value = "";
            var mahalid = document.getElementById("Mahalle").value;
            var mevki = document.getElementById("Mevki");
            var mevkiid = MevkiList[0].id;
            if (mevki != null)
                mevkiid = mevki.value;
            fetchAdaList(mevkiid, mahalid)
            setMahalleCoordinates(MahalleList.find(a => a.id == applicationdata.mahaleId).koordinat)
            //fetchCoordinates("MAHALLE:" + mahalid);
        }
    }


    const fetchMevkiList = () => {

        fetch("getmevkilist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            setVisible("mevki_snipper", false)

            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    var i = 0;
                    var c = document.getElementById("Mevki");
                    if (c != null) {
                        c.innerHTML = "";
                        if (value.length == 1) {  //for milas select milas by defult
                            var opt = document.createElement('option');
                            opt.value = value[i].id;
                            opt.innerHTML = value[i].name;
                            opt.selected = true;
                            c.appendChild(opt);
                        }
                        else {
                            for (i = 0; i < value.length; i++) {
                                var opt = document.createElement('option');
                                opt.value = value[i].id;
                                opt.innerHTML = value[i].name;
                                if (applicationdata.mevki == value[i].name) {
                                    opt.selected = true;
                                }
                                c.appendChild(opt);
                            }
                        }
                        if (applicationdata.mevki == null && value.length > 1) c.selectedIndex = -1;

                    }
                    if (applicationdata.mevki == null && value.length == 1) applicationdata.mevki = value[0].name;
                    setMevkiList(value);
                })
            }
        });
    }


    const fetchAdaList = (mevkiId, mahalleid) => {
        setVisible("adaparsel_snipper", true)
        fetch("getadalistbyid?mevki=" + mevkiId + "&mahalle=" + mahalleid, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            setVisible("adaparsel_snipper", false)
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                const contentType = response.headers.get("content-type");
                if (contentType == null || contentType.indexOf("application/json") == -1) {
                    showErrorMessage("Ada/Parsel Bilgileri Alınamadı !");
                    return;
                }
                response.json().then(value => {
                    var i = 0;
                    var c = document.getElementById("Adaparselistesi");
                    c.innerHTML = "";
                    for (i = 0; i < value.length; i++) {
                        var opt = document.createElement('option');

                        opt.value = value[i].name;
                        opt.innerHTML = value[i].name;
                        if (applicationdata.ada == value[i].name) {
                            opt.selected = true;
                        }
                        c.appendChild(opt);
                    }
                    if (applicationdata.ada == null) c.selectedIndex = -1;


                    document.getElementById("Adaparsel").disabled = false;
                    setAdaList(value)
                })
            }
        });
    }




    const validateNumberAndForwardSlash = (event) => {
        var key = event.which;
        if (key >= 48 && key <= 57 || key == 47) {
            return true;
        } else {

            event.preventDefault()
        }
    }

    const validateSelection = (event) => {
        var val = event.target.value;
        if (isEmpty(val))
            return;
        if (AdaList.some(x => x.name == val) == false) {
            showErrorMessage(val + " geçerli ada/parsel listesinde bulunmamaktadır.");
            document.getElementById("Adaparsel").value = "";
        }

    }

    const fetchCoordinates = (param) => {
        fetch("getcbscoordinates?param=" + param, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            document.getElementById("mahalle_snipper").hidden = true;
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.text().then(value => {
                    setMahalleCoordinates(value)
                })
            }
        });
    }

    const fetchMahalleList = () => {
        fetch("getmahallelist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            var mspin = document.getElementById("mahalle_snipper");
            if (mspin != null)
                mspin.hidden = true;
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    var i = 0;
                    var c = document.getElementById("Mahalle");
                    if (c != null) {
                        c.innerHTML = "";
                        for (i = 0; i < value.length; i++) {
                            var opt = document.createElement('option');
                            opt.value = value[i].id;
                            opt.innerHTML = value[i].name;
                            if (applicationdata.mahalle == value[i].name) {
                                opt.selected = true;
                            }
                            c.appendChild(opt);
                        }
                        if (applicationdata.mahalle == null) c.selectedIndex = -1;
                    }

                    setMahalleList(value);
                })
            }
        });
    }



    const fetchIdariMahalleList = () => {
        fetch("getidarimahallelist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    setIdariMahalle(value)
                })
            }
        });
    }

    const onSubmit = (e) => {
        e.preventDefault();
        document.getElementById("buttonSpinner").hidden = false;




        fetch("saveapplication", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(applicationdata)
        }).then(async response => {
            document.getElementById("buttonSpinner").hidden = true;
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(async newapp => {
                    if (newapp.updateCount < 0) {
                        showErrorMessage(newapp.temp);
                        newapp.updateCount = 0;
                        newapp.temp = "";
                        return;
                    }
                    if (newapp.id < 0) {
                        showConfirmMessage("Bu ada/parsele ilişkin aynı konuda zaten devam eden bir başvurunuz bulunmaktadır.", "O başvuruya yuklensin mı?", "Evet", "Hayır", () => {
                            newapp.id = -newapp.id;
                            props.changeComponent(<Application appData={newapp} changeComponent={props.changeComponent} />)

                        });
                        return;
                    }

                    if (newapp.id == 0) { //keep this before next if
                        showErrorMessage("Bu konuma ilişkin " + applicationdata.basvuruTipi +
                            " başvurusunu yapmadan önce " + newapp.appData +
                            " başvuru sürecinizin onaylanarak tamamlanmasını beklemelisiniz.");
                        return;
                    }

                    if (newapp.ada == null && newapp.mevki == null) {
                        showErrorMessage("Bu konuma ilişkin " + newapp.basvuruYapan + " kullanıcısının " + newapp.id + " numaralı başvurusu bulunmaktadır. Başvuruya devam edebilmeniz için " + newapp.id + " numaralı başvuru ilgili kullanıcı tarafından sizinle paylaşılmalıdır.");
                        return;
                    }





                    applicationdata.id = newapp.id;
                    applicationdata.basvuruDurumu = newapp.basvuruDurumu; //this list must match the controller save list
                    applicationdata.basvuruTarihi = newapp.basvuruTarihi;
                    applicationdata.securityToken = newapp.securityToken;
                    applicationdata.basvuruYapan = newapp.basvuruYapan;
                    applicationdata.updateCount = newapp.updateCount;
                    //setApp(applicationdata);
                    props.changeComponent(<Apply3 applicationdata={applicationdata} changeComponent={props.changeComponent} />)
                });
            }
        });
    }

    const setLatLang = (myLatlng) => {
        document.getElementById("Enlem").value = myLatlng.lat();
        document.getElementById("Boylam").value = myLatlng.lng();

        applicationdata["enlem"] = document.getElementById("Enlem").value;
        applicationdata["boylam"] = document.getElementById("Boylam").value;
    }

    const handleAdaChane = (e) => {
        var ada = AdaList.find(x => x.name == e.target.value);
        if (!isEmpty(ada)) {
            handleInputChange(e);
            setMahalleCoordinates(ada.koordinat)
            // fetchCoordinates("ADA:" + ada.id)
        }

    }


    if (sabitsorulistData == null) {
        return <div></div>
    }

    return <div  >

        <div className="row" >
            <div className="  col-md-2 col-12 " >
                <div className="  w-75 fw-bold border-bottom border-3 " >
                    1. Başvuru Tipi

                </div>
            </div>
            <div className=" col-md-2 col-12  text-primary" >
                <div className="  w-75 fw-bold border-bottom border-3 " >
                    2. Konum
                </div>
            </div>
            <div className=" col-md-2 col-12    " >
                <div className="  w-75 fw-bold border-bottom border-3 " >
                    3. Müellifler
                </div>
            </div>
            <div className=" col-md-2 col-12    " >
                <div className="  w-75 fw-bold border-bottom border-3 " >
                    4. Diğer Sorular
                </div>
            </div>
            <div className=" col-md-2 col-12    " >
                <div className="  w-75 fw-bold border-bottom border-3 " >
                    5. Dosyalar
                </div>
            </div>
            <div className=" col-md-2 col-12     " >
                <div className="  w-75 fw-bold border-bottom border-3 " >
                    6. Onay
                </div>
            </div>
        </div>

        <div className="row m-3" >
            <div className="col-12" >
                <div className="  align-items-center justify-content-center p-1" >
                    <form action="submitapplication" className="mt-3" method="POST" onSubmit={onSubmit}>

                        <div className="mt-2 row p-2 rounded-5" >
                            {window.selectedAppType.mevkiAktif == true && <div className="col-12 col-md-4">
                                <label htmlFor="Mahalle" className="form-label">Mevki {window.selectedAppType.mevkiZorunlu==true?'*':''}
                                    <div class="spinner-border spinner-border-sm" id="mevki_snipper"></div>
                                </label>
                                <select className="form-select form-select-sm" id="Mevki" name="mevki" required={window.selectedAppType.mevkiZorunlu == true && zorunloBosBirakma} onChange={handleInputChange} disabled={fdisabled("mevki", sabitsorulistData, applicationdata)}>

                                </select>
                            </div>}

                            {window.selectedAppType.mahalleAktif == true && <div className="col-12 col-md-4">
                                <label htmlFor="Mahalle" className="form-label">{idariMahalle.length > 0 ? "Tapu " : ""}  Mahalle {window.selectedAppType.mahalleZorunlu == true ? '*' : ''}
                                    <div class="spinner-border spinner-border-sm" id="mahalle_snipper"></div>
                                </label>
                                <select className="form-select form-select-sm" id="Mahalle" name="mahalle" required={window.selectedAppType.mahalleZorunlu == true && zorunloBosBirakma} onChange={handleInputChange} disabled={fdisabled("mahalle", sabitsorulistData, applicationdata)}>

                                </select>
                            </div>}


                            {window.selectedAppType.adaAktif == true  && <div className="col-12 col-md-4">
                                <label htmlFor="Ada" className="form-label">Ada/Parsel {window.selectedAppType.adaZorunlu == true ? '*' : ''}
                                    <div class="spinner-border spinner-border-sm" id="adaparsel_snipper" hidden></div>
                                </label>
                                <input disabled autocomplete={true} list="Adaparselistesi" className="form-control form-control-sm" required={window.selectedAppType.adaZorunlu == true && zorunloBosBirakma} id="Adaparsel" placeholder="Ada Parsel" name="ada" defaultValue={applicationdata.ada} onChange={handleAdaChane} onKeyPress={validateNumberAndForwardSlash} onBlur={validateSelection} disabled={fdisabled("ada", sabitsorulistData, applicationdata)} />
                                <datalist id="Adaparselistesi">fill by fetchsureclist</datalist>
                            </div>}

                        </div>


                        <div className="row" >
                            <div className="col-12 col-md-4">
                                {window.selectedAppType.idariMahalleAktif == true && idariMahalle.length > 0 && <div className="mt-1 row p-2 rounded-5" >
                                    <div className="col-12 ">
                                        <label htmlFor="IdariMahalle" className="form-label">Idari Mahalle </label>{window.selectedAppType.idariMahalleZorunlu == true ? '*' : ''}
                                        < select className="form-select form-select-sm" id="IdariMahalle" name="idariMahalle" required={window.selectedAppType.idariMahalleZorunlu == true && zorunloBosBirakma} onChange={handleInputChange}>
                                            <option value=""></option>
                                            {idariMahalle.map(x => <option value={x.name}>{x.name}</option>)}
                                        </select>
                                    </div>
                                </div>}

                                {window.selectedAppType.sokakAktif == true && <div className="mt-1 row p-2 rounded-5" >

                                    <div className="col-12 ">
                                        <label htmlFor="Sokak" className="form-label">Sokak {window.selectedAppType.sokakZorunlu == true ? '*' : ''}</label>
                                        <input type="text" className="form-control form-control-sm" id="Sokak" required={window.selectedAppType.sokakZorunlu == true && zorunloBosBirakma} placeholder="Sokak" name="sokak" onChange={handleInputChange} defaultValue={applicationdata.sokak} disabled={fdisabled("sokak", sabitsorulistData, applicationdata)} />
                                    </div>

                                </div>}


                                {window.selectedAppType.noAktif == true  && <div className="mt-1 row p-2 rounded-5" >
                                    <div className="col-12 ">
                                        <label htmlFor="No" className="form-label">No {window.selectedAppType.noZorunlu == true ? '*' : ''}</label>
                                        <input type="text" className="form-control form-control-sm" id="No" required={window.selectedAppType.noZorunlu == true && zorunloBosBirakma} placeholder="No" name="no" onChange={handleInputChange} defaultValue={applicationdata.no} disabled={fdisabled("no", sabitsorulistData, applicationdata)} />
                                    </div>

                                </div>}

                                {window.selectedAppType.adaAktif == true && <div className="mt-1 row p-2 rounded-5" >
                                    <div className="col-12 ">
                                        <label htmlFor="Enlem" className="form-label">Enlem  {window.selectedAppType.adaZorunlu == true ? '*' : ''}</label>
                                        <input type="text" className="form-control form-control-sm" id="Enlem" placeholder="Enlem (Harita'dan seçebilrsiniz)" required={window.selectedAppType.adaZorunlu == true && zorunloBosBirakma} name="enlem" onChange={handleInputChange} defaultValue={applicationdata.enlem} disabled={fdisabled("enlem", sabitsorulistData, applicationdata)} />
                                    </div>

                                </div>}
                                {window.selectedAppType.adaAktif == true && <div className="mt-1 row p-2 rounded-5" >
                                    <div className="col-12 ">
                                        <label htmlFor="Boylam" className="form-label">Boylam {window.selectedAppType.adaZorunlu == true ? '*' : ''}</label>
                                        <input type="text" className="form-control form-control-sm" id="Boylam" placeholder="Boylam (Harita'dan seçebilrsiniz)" required={window.selectedAppType.adaZorunlu == true && zorunloBosBirakma} name="boylam" onChange={handleInputChange} defaultValue={applicationdata.boylam} disabled={fdisabled("boylam", sabitsorulistData, applicationdata)} />
                                    </div>

                                </div>}
                            </div>
                            {fvisible("enlem", sabitsorulistData, applicationdata) && fvisible("boylam", sabitsorulistData, applicationdata) && <div className="col-12 col-md-8 pt-4 ps-3 pe-3 mt-3" style={{ "min-height": "250px" }} >
                                <GMapLatLang appData={applicationdata} setLatLang={setLatLang} polygon={mahalleCoordinates} />
                            </div>}
                        </div>


                        <div className="row mt-3 border-top pt-2" >
                            <div className="col-6 text-start">
                                <button type="button" className="btn btn-sm btn-primary mb-2 " onClick={() => { props.changeComponent(<Apply1 applicationdata={applicationdata} changeComponent={props.changeComponent} />) }}>Geri Dön</button>
                            </div>

                            <div className="col-6 text-end">
                                <button type="submit" name="actionSave" value="Save" className="btn btn-sm btn-primary mb-2 ">
                                    Kaydet ve Devam Et
                                    <span class="ms-1 spinner-border spinner-border-sm" id="buttonSpinner" hidden></span>
                                </button>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </div >
    </div >

}



