import React, { useState, useEffect } from 'react';
import UserLabel from "../User/UserLabel.js";
import { isEmpty, showConfirmMessage, showErrorMessage, useGlobalState } from '../GlobalState.js';
import { ajaxFail } from '../GlobalState.js';
import Vekalet from './Vekalet.js';
import $ from 'jquery';

export default function ApplicationAuthorPanel(props) {
    const appData = props.appData
    const [userName, setuserName] = useGlobalState('userName');
    const waiting = <div className="w-100 mt-4 mb-4 text-center align-items-center justify-content-center" ><div className="spinner-border" ></div></div>
    var muelliflist = []
    const [tabledata, setTabledata] = useState([]);
    const [previousAppAuthors, setPreviousAppAuthors] = useState(null);
    const [userTypeList, setUserTypeList] = useState([]);
    const [user, setUser] = useState(null);
    const colors = ["text-primary", "text-warning", "text-success"]

    const [vekils, setVekils] = useState([]);


    useEffect(() => {
        getvakaletdurums();
        fetchPreviousAppsAuthers();
    }, [])



    const addAuthor = (u, t) => {
        if (t == null || t == "") {
            showErrorMessage("Muellif Tipi Boş olamaz");
            return;
        }
        if (u == null || u == "") {
            showErrorMessage("Kullanıcı Adı Boş olamaz");
            return;
        }

        fetch("addauther", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                appID: appData.id,
                userName: u,
                muellifType: t
            })
        }).then(async response => {
            if (!response.ok) {
                response.text().then(value => {
                    if (isEmpty(value) == false) {
                        value = value.replaceAll('"', '');
                        showErrorMessage(value)
                    }
                })

            }
            else {
                document.getElementById("muellifusername").value = "";

                fetchAuthers();
            }
        });
    }



    const getvakaletdurums = () => {
        fetch("getvakaletdurums", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(appData.id),
        }).then(async response => {

            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(async data => {
                    setVekils(data);
                    fetchAuthers(data);
                });
            }
        });
    }



    const fetchAuthers = (newlist) => {
        fetch("appauthorlist", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: appData.id
        }).then(async response => {

            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(async data => {
                    if (data == null) {
                        setTabledata([])
                        return;
                    }
                    data = JSON.parse(data)
                    //the fatch result may receive before page update
                    var vekiller = vekils;
                    if (vekils.length == 0 && newlist != null && newlist.length > 0) {
                        vekiller = newlist;
                    }

                    for (var i = 0; i < data.length; i++) {
                        data[i].vekil = 0;
                        var v = vekiller.find(x => x.id == data[i].id);
                        if (v != null)
                            data[i].vekil = v.appID + 1;
                    }

                    setTabledata(data)



                });
            }
        });
    }

    const fetchPreviousAppsAuthers = () => {
        fetch("appauthorlist", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: -1
        }).then(async response => {

            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(async data => {
                    if (data == null) {
                        setPreviousAppAuthors([])
                        return;
                    }
                    data = JSON.parse(data)
                    var ulist = data.map(rec => <tr>
                        <td width="30%">
                            {rec.name}  {rec.family} ({rec.userName})
                        </td>
                        <td width="30%"> {rec.muellifType}</td>
                        <td width="30%">
                            {rec.gecicimuellif == true ? "Belge Miadı" : ""} {isEmpty(rec.gecicisure) == false ? "(" + rec.gecicisure + ")" : "Belirtilmemiş"}
                        </td>
                        <td className="text-end">
                            <button className="btn btn-sm btn-link" onClick={() => { addAuthor(rec.userName, rec.muellifType) }}> <i className="fa fa-plus-circle align-middle" style={{ "font-size": "18px" }}></i> </button>
                        </td></tr>
                    );
                    setPreviousAppAuthors(ulist)
                });
            }
        });
    }

    const getuser = (myself) => {

        var u = document.getElementById("muellifusername").value;
        if (myself != null) {
            u = myself;
        }
        if (u == null || u == "") {
            showErrorMessage("Müellif Kodunu Giriniz");
            return;
        }
        fetch("getuserpublcinfo", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(u)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(user => {
                    if (user == null || user.length == 0) {
                        showErrorMessage("Müellif Bulunamadı");
                        return;
                    }
                    var u = JSON.parse(user);
                    if (u.name == null || u.family == null) {
                        showErrorMessage("Bir hata oluştur" + user);
                        return;
                    }
                    if (isEmpty(u.userType)) {
                        showErrorMessage("Müellif türü boş olamaz");
                        return;

                    }

                    var temp = u.userType.split(",")


                    setUser(u);
                    setUserTypeList(temp);
                    $('#ModalButton').click();


                })


            }
        });
    }



    const removeAuthor = (id) => {
        showConfirmMessage("Müellifi silmek istediğinizden emin misniz?", "", "Evet", "Hayır", () => {
            fetch("removeauther", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(id)
            }).then(async response => {
                if (!response.ok) {
                    ajaxFail(response);
                }
                else {
                    document.getElementById("muellifusername").value = "";
                    fetchAuthers();
                }
            });
        });
    }

    return (
        <div>
            <div className="row">
                <div className="col-12 ">
                    <h5 className="text-primary">Müellif Listesi </h5>
                </div>
                <div className="col-2 justify-content-end align-items-end text-end ">
                    {muelliflist.length > 0 && <span className="small badge bg-primary rounded-pill me-2">{muelliflist.length}</span>}

                </div>
            </div>
            <div className="row">

                <div className="col-12 col-md-4 mt-2">
                    <input type="text" className="form-control form-control-sm d-inline-block" placeholder="Müellif Kodu veya TCKN" name="muellifusername" id="muellifusername" defaultValue="" />
                </div>
                <div className="col-12 col-md-6 mt-2">
                    {appData.archived != true ? <>
                        <button onClick={() => getuser()} className="btn btn-primary btn-sm ms-1 d-inline-block">Müellif Ekle</button>
                        <button onClick={() => getuser(userName)} className="btn btn-primary btn-sm ms-2 d-inline-block">Kendimi Ekle</button></>

                        : <span className="text-secondary fw-bold">Arşivlenen Başvuruya Müellif Eklenemez</span>}


                </div>
            </div>
            <div className="row mt-5">
                <div className="col-12 text-primary ">
                    <h6>Başvuruya Eklenen Müellifler</h6>
                </div>
                <div className="col-12">
                    {tabledata != null ? <table className='table  table-sm' >
                        <tbody >
                            {tabledata.map(rec => <tr title={"Kayıt No: " + rec.id} key={rec.id} className="p-0 m-0">
                                <td width="30%">
                                    {rec.name}  {rec.family} ({rec.userName})
                                </td>

                                <td width="20%">
                                    {rec.muellifType}
                                </td>
                                <td width="30%">
                                    {rec.gecicimuellif == true ? "Belge Miadı" : ""} {isEmpty(rec.gecicisure) == false ? "(" + rec.gecicisure +")" : " Belirtilmemiş"}
                                </td>
                                <td className="text-end">
                                    <button className={"btn  btn-link text-decoration-none pe-1 " + colors[rec.vekil]} title="Vekalet Bilgisi" onClick={(e) => { props.setActivePanel(<Vekalet appData={appData} setActivePanel={props.setActivePanel} mapping={rec} />) }}  > <i class="fa fa-vimeo-square" aria-hidden="true"></i>   </button>
                                    <button className="btn text-danger btn-link " onClick={() => { removeAuthor(rec.id) }} disabled={appData.archived == true}> <i class="fa fa-trash" aria-hidden="true"></i> </button>
                                </td>
                            </tr>
                            )}
                        </tbody>
                    </table> : waiting}
                </div>
            </div>

            {appData.archived != true && <div className="row mt-5 border-top pt-2">
                <div className="col-12 text-success">
                    <h6>Önceki Başvurularınıza Eklediğiniz Müellifler</h6>
                </div>
                <div className="col-12">
                    {previousAppAuthors != null ? <table className=' table   table-sm' aria-labelledby="tabelLabel">
                        <tbody >
                            {previousAppAuthors}
                        </tbody>
                    </table> : waiting}
                </div>
            </div>}

            <button type="button" id="ModalButton" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#confirmModal" hidden>

            </button>


            <div class="modal fade" tabindex="-1" role="dialog" id="confirmModal">
                <div class="modal-dialog" >
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Müellif Türünü Seçiniz</h5>
                        </div>
                        <div class="modal-body">
                            <div class="mb-3">
                                {user != null ? user.name.substr(0, 2) + "***  " + user.family.substr(0, 2) + "***  için bir müellif türü seçiniz" : ""}

                            </div>

                            <select className="form-select form-select-sm d-inline-block me-2" id="kullanicitipleri" name="userType">
                                {userTypeList.map(u => <option value={u}>{u}</option>)}

                            </select>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Vazgeç</button>
                            <button type="button" class="btn btn-primary" data-bs-dismiss="modal" onClick={() => { addAuthor(user.userName, document.getElementById("kullanicitipleri").value) }}>Ekle</button>
                        </div>
                    </div>
                </div>
            </div>

        </div >
    );
}







