import React, { useState, useEffect } from 'react';
import { ajaxFail, sendpost } from '../GlobalState.js';
import Multiselect from 'multiselect-react-dropdown';
import bootstrap from 'bootstrap/dist/js/bootstrap.min.js'

export default function ListAppTypePermission(props) {
    var postdata = {};
    const [listData, setListData] = useState([]);
    const [denetimList, setDenetimList] = useState(props.denetimList);
    var username = props.username;
    const multiselectRefNew = React.createRef();


    const fetchList = () => {
        fetch("applistperms", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(username)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    setListData(value);
                    
                })

            }
        });
    }

   

  

    const Add = (item) => {
        var postdata = {};
        postdata.UserName = username;
        postdata.appType = item.id;
        sendpost("addapplistperm", postdata, "spin2");
    }

    const Sil = (postdata) => {
        sendpost("removeapplistperm", listData.find(x => x.appType == postdata.id), "spin2");
        
    }

 
    useEffect(() => {
        fetchList();
        var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
        var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
            return new bootstrap.Popover(popoverTriggerEl)
        })
    }, [])



    var selectedids = listData.map(x => x.appType);

 
    return (
        <div >
            <div className="row">
                <div className="col-8">
                    <h6>Başvuru Listesi Yetkileri
                        <button type="button" className="btn btn-secondary btn-sm fw-bold pt-0 pb-0 mt-0 ms-2" id="mypopover2" data-bs-toggle="popover" data-bs-trigger="focus" title="AÇIKLAMA" data-bs-content="Başvuru Listesinde kullanıcının görebileceği başvuruları, başvuru tipine göre buradan kısıtlayabilirsiniz.">?</button>
</h6>
                </div>
                <div className="col-4 text-end">
                    <span className="spinner-border spinner-border-sm ms-3" id="spin2" hidden>   </span>
                </div>
            </div>
            <Multiselect className="p-0 ps-1 form-control-sm bg-white mb-2"
                options={denetimList}
                emptyRecordMsg="Başka Kayıt Yok"
                displayValue="name"
                isObject={true}
                closeOnSelect={true}
                ref={multiselectRefNew}
                selectedValues={denetimList.filter(x => selectedids.includes(x.id))}
                avoidHighlightFirstOption={true}
                onSelect={(selectedList, item) => { Add(item) }}
                onRemove={(selectedList, item) => { Sil(item) }}
            />


        </div>

    );


}