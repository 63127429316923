import React, { useState, useEffect } from 'react';
import { useGlobalState, logout, showErrorMessage, removeTurkcLow } from '../GlobalState.js';



export default function NotePanel(props) {

    const [appData, setData] = useState(props.appData);
    // const setLastNote = props.appData[1];
    const [notes, setNotes] = useState([]);
    const [userName, setuserName] = useGlobalState('userName');
    var panelid = "notetable";
    if (props.panelid != null) {
        panelid = props.panelid;
    }

    var reccount = 0;

    const removeRecord = (id) => {
        fetch("removeapplicationnotes", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(id)
        }).then(async response => {
            if (!response.ok) {
                response.text().then(value => {
                    showErrorMessage(response.statusText)
                })
            }
            else {
                response.json().then(value => {
                    setNotes(value);
                })
            }
        });
    }

    if (notes.length > 0) {
        if (document.getElementById("headlastnote") != null)
            document.getElementById("headlastnote").innerHTML = notes[notes.length - 1].note;
    }

    const fetchNotes = (e) => {
        fetch("getapplicationnotes", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: appData.id
        }).then(async response => {
            if (!response.ok) {
                showErrorMessage(response.statusText)
            }
            else {
                response.json().then(async data => {
                    setNotes(data)
                });
            }
        });
    }

    useEffect(() => {
        fetchNotes();
    }, [props.appData])  //this is to force chid refresh when parent chnge appdata



    const addNot = () => {
        fetch("addnote", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ appId: appData.id, note: document.getElementById(panelid + "not").value })
        }).then(async response => {
            if (!response.ok) {
                showErrorMessage(response.statusText)
            }
            else {
                document.getElementById(panelid + "not").value = "";
                fetchNotes();
            }
        });
    }

    return (
        <div className=" rounded ">
            <div className="row">
                <div className="col-md-10 col-12">
                    <h5 className="text-primary">Notlar </h5>

                </div>
                <div className="col-md-2 col-12 justify-content-end align-items-end text-end ">
                    {notes.length > 0 && <span className="small badge bg-primary rounded-pill me-2">{notes.length}</span>}

                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="mb-4">

                        <input type="text" className="form-control form-control-sm w-75 d-inline-block" id={panelid + "not"} placeholder="Yeni Not" name="not" defaultValue="" />
                        <button type="button" onClick={() => addNot()} className="btn btn-primary btn-sm ms-2 d-inline-block">Ekle</button>&nbsp;

                    </div>
                    <table className=' table table-striped  border table-sm' aria-labelledby="tabelLabel">
                        <tbody >
                            {notes.map(rec => <tr><td width="32px">
                                <img className="rounded-circle" style={{ 'width': '32px', 'height': '32px' }} src={'/getuserpic?user=' + rec.userName} />
                            </td>
                                <td width="20%"> {rec.userName} </td><td> {rec.note}</td><td width="25%"> {rec.sendDate}</td>

                                <td width="70px">
                                    <button disabled={removeTurkcLow(rec.userName) != removeTurkcLow(userName)} onClick={() => removeRecord(rec.id)}  className="btn btn-link btn-sm text-decoration-none m-0 p-0 text-muted"><span className="text-decoration-none material-icons align-middle text-danger" >delete</span></button>
                                </td>
                            </tr>
                            )}
                            {(appData.iadeEden != null && appData.iadeNedeni != null) && <tr><td width="32px">
                                <img className="rounded-circle" style={{ 'width': '32px', 'height': '32px' }} src={'/getuserpic?user=' + appData.iadeEden} />
                            </td>
                                <td width="20%"> {appData.iadeEden} </td><td> {appData.iadeNedeni}</td><td width="25%"> {appData.iadeTarihi}</td>

                                <td width="70px" className="text-danger fw-bold">
                                    İade Notu
                                 </td>
                            </tr>  }


                        </tbody>
                    </table>
                </div>
            </div>
        </div >
    );
}



