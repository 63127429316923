import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/js/bootstrap.min.js'
import Multiselect from 'multiselect-react-dropdown';
import { ajaxFail, isEmpty, showErrorMessage } from '../GlobalState.js';
import * as UserPermConstants from '../User/UserPermConstants';
import { useGlobalState } from '../GlobalState.js';


import Register3 from "./Register3.js";

import Register1 from "./Register1.js";


export default function Register2(props) {

    useEffect(() => {
        fetchUserTypeList();

    }, [])
    const [options, setOptions] = useState([]);
    const multiselectRef = React.createRef();
    var userdata = props.userdata != null ? props.userdata : {};

    const [role, setRole] = useGlobalState('role');
    const zorunlo = role.indexOf(UserPermConstants.Yeni_Kullanici_Kaydinda_Zorunlu_Alanlari_Bos_Birakabilme) == -1;




    const fetchUserTypeList = () => {
        fetch("getapptypelist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                response.json().then(value => {
                    var i = 0;
                    var opts = [];
                    for (i = 0; i < value.length; i++) {
                        opts.push(value[i].appType)

                    }
                    setOptions(opts)

                })
            }
        });
    }

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked.toString() : target.value;
        userdata[target.name] = value;
    }
    const onSelect = (selectedList, selectedItem) => {
        var clist = selectedList.join();
        userdata["userType"] = clist;

    }

    const onRemove = (selectedList, removedItem) => {
        var clist = selectedList.join();
        userdata["userType"] = clist;

    }

    const onSubmit = (e) => {
        e.preventDefault();
        if (isEmpty(userdata["userType"])) {
            showErrorMessage("Kullanıcı Tipi boş olamaz.");
            return;
        }


        fetch("updateuser", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(userdata)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                props.changeComponent(<Register3 userdata={userdata} changeComponent={props.changeComponent} />)
               
            }
        });
    }






    return <div className="mt-3">
        <div id="myscript"></div>
        <div className="row" >
            <div className="ms-2 me-2 p-0 col-md-3 col-12  border-bottom border-3 " > 1. Kişisel Bilgiler
            </div>
            <div className="ms-3 me-2 p-0 col-md-3 col-12   border-bottom border-3 text-primary border-primary fw-bold" >2. Mesleki Bilgiler
            </div>
            <div className="ms-3 me-2 p-0 col-md-3 col-12   border-bottom border-3 " > 3. Diğer
            </div>
        </div>
        <div className="row m-3" >
            <div className="col-12" >
                <div className="  align-items-center justify-content-center p-1" >
                    <form action="submitapplication" className="mt-3" method="POST" onSubmit={onSubmit}>

                        <div>
                            <div className="row mt-3  pt-2" >

                            <div className="col-12 col-md-5">
                                <label className="form-label m-0">Kullanici Tipi</label>
                                <Multiselect className="p-0 form-control-sm bg-white" id="kullanicitiplerim"
                                    options={options}
                                    emptyRecordMsg="Başka Kullanıcı Tipi Yok"
                                    ref={multiselectRef}
                                    isObject={false}
                                    onSelect={onSelect}
                                        onRemove={onRemove}
                                        selectedValues={userdata.userType != null ? userdata.userType.split(',') : []}
                                    placeholder="Kullanıcı Tipi"
                                />
                            </div>


                            </div>




                            <div className="row pt-4">
                                <div className="col-5">
                                    <label htmlFor="UserName" className="form-label m-0">Meslek</label>
                                    <input type="text" className="form-control col-9" id="meslek" placeholder="Meslek" name="meslek" onChange={handleInputChange} defaultValue={userdata.meslek} />
                                </div>

                            </div>


                            <div className="row pt-4">
                                <div className="col-5">
                                    <label htmlFor="UserName" className="form-label m-0">Firma</label>
                                    <input type="text" className="form-control col-9" id="firma" placeholder="Firma" name="firma" onChange={handleInputChange} defaultValue={userdata.firma} />
                                </div>
                                <div className="col-5">
                                    <label htmlFor="Name" className="form-label m-0">Pozisyonu</label>
                                    <input type="text" className="form-control" id="pozisyonu" placeholder="Pozisyonu" name="pozisyonu" onChange={handleInputChange} defaultValue={userdata.pozisyonu} />

                                </div>
                            </div>


                            <div className="row pt-4">
                                <div className="col-5">
                                    <label htmlFor="Family" className="form-label m-0">Firma Konumu</label>
                                    <input type="text" className="form-control" id="firmaKonumu" placeholder="Firma Konumu" name="firmaKonumu" onChange={handleInputChange} defaultValue={userdata.firmaKonumu} />
                                </div>
 
                                <div className="col-5">
                                    <label htmlFor="Family" className="form-label m-0">Firma Web Sitesi</label>
                                    <input type="text" className="form-control" id="firmaWebSitesi" placeholder="Firma Web Sitesi" name="firmaWebSitesi" onChange={handleInputChange} defaultValue={userdata.firmaWebSitesi} />
                                </div>
                            </div>

                            <div className="row mt-3 border-top pt-2" >
                                <div className="col-6 col-md-6 text-start">
                                    <button type="button" name="actionSave" value="Save" className="btn btn-sm btn-primary mb-2 " onClick={() => { props.changeComponent(<Register1 userdata={userdata} changeComponent={props.changeComponent} />) }}>Geri Dön</button>
                                </div>
                                <div className="col-12 col-md-6 text-end">
                                    <button type="submit" name="actionSave" value="Save" className="btn btn-sm btn-primary mb-2 ">Devam</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
}



