import React, { useState, useEffect } from 'react';
import MessagePanel from '../User/MessagePanel';
import { ajaxFail } from '../GlobalState.js';

export default function MessageMenu(props) {



    const [msglist, setMessages] = React.useState(null);

    useEffect(() => {
        getMessageList();
    }, []);



    const getMessageList = () => {
        fetch("getmymessages", {
            method: 'GET',
            headers: {
                'Accept': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                response.json().then(async data => {
                    setMessages(data);
                });
            }
        });
    }

    var cc = 0;
    var menutxt = <span id="unreadmessagecount" class="badge rounded-pill " > {cc}</span>;
    if (msglist != null) {
        for (let i = 0; i < msglist.length; i++)
            if (msglist[i].opened != true)
                cc++;
        if (cc > 0)
            menutxt = <span id="unreadmessagecount" class="badge rounded-pill bg-primary" > {cc}</span>;
    }


    return <a href="#" onClick={() => { props.changeComponent(<MessagePanel messages={msglist} changeComponent={props.changeComponent}/>) }}> <i className="fa fa-comment-o"></i> Mesajlarım {menutxt}</a>
}
