import React, { useState, useEffect, createRef } from 'react';
import Multiselect from 'multiselect-react-dropdown';

import { isEmpty, showConfirmMessage, showErrorMessage, showSuccessMessage, useGlobalState, levenshtein } from '../GlobalState.js';
import { ajaxFail, removeTurkcLow } from '../GlobalState.js';
import $ from 'jquery';
import FileSettingList from './FileSettingList.js';


export default function FileSettingDetaile(props) {
    var postdata = {};
    var [rec, setRec] = useState({ ...props.rec });
    const [fileNames, setfileNames] = useState([...props.fileNames.filter(x=>x.name!=null)]);

   
    postdata["FileType"] = "";
    const [listData, setListData] = useState([]);
    const [fileGurups, setFileGrupList] = useState([]);
    const [typeOptions, setTypeOptions] = useState([]);
    const [zoptions, setZOptions] = useState(rec.basvuruTipi != null && rec.basvuruTipi.length > 0 ? rec.basvuruTipi.split(',') : []);

    var multiselectRef = React.createRef();

    var sortField = null;
    const [role, setrol] = useGlobalState('role');
    const [roles, setRoles] = useState([]);

    var ref = createRef();

    useEffect(() => {
        fetchGrupList();
        fetchFileList();
        getAppTypes();
        fetchRoles();
        $("#filenamepage").on("click", function (e) {
            const windowElement = document.getElementById('floatingWindow');
            windowElement.style.display = 'none';
        });
    }, [])


    const fetchRoles = () => {
        fetch("getrolelist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    setRoles(value)
                })
            }
        });

    }



    const savefilename = () => {


        if (isEmpty(rec.name)) {
            showErrorMessage("Dosya İsmi Boş Olamaz");
            return;
        }

        if (isEmpty(rec.gurupid)) {
            showErrorMessage("Dosya Grubu Boş Olamaz");
            return;
        }
        if (isEmpty(rec.fileType)) {
            showErrorMessage("Dosya Tipi  Boş Olamaz");
            return;
        }
        fetch("savefilename", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(rec)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    window.changeComponent(<FileSettingList />)
                })
            }
        });
    }


    const updatefilename = (rec) => {

        fetch("updatefilename", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(rec)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    showSuccessMessage("Bilgiler Güncellenmiştir.")
                    window.changeComponent(<FileSettingList />)
                })
            }
        });
    }






    $('#dosyaadi').on('keypress', function (event) {
        var regex = new RegExp("^[a-zA-Z0-9_()#&%!üğşöçı ÇŞÜĞİÖ+-]+$");
        var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
        if (!regex.test(key)) {
            event.preventDefault();
            return false;
        }
    });





    const getAppTypes = () => {
        fetch("denetimlist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    var opt = [];
                    for (var x of value) {
                        if(x.isadimi!=true)
                        opt.push(x.name)
                    }
                    setTypeOptions(opt);
                })
            }
        });
    }


    const fetchGrupList = () => {

        fetch("filegroupslist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    var i = 0;
                    setFileGrupList(value);
                    fetchFileList();
                })
            }
        });
    }


    const fetchFileList = () => {
        fetch("filenamelist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    setListData(value);
                })
            }
        });
    }
    const updateRecord = (rec, event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        rec[name] = value;
    }


   


    const showWindow = () => {
        const input = document.getElementById('dosyaadi');
        const windowElement = document.getElementById('floatingWindow');
        const inputText = input.value.toLowerCase();
        const matchedItems = fileNames
            .map(item => ({
                item,
                similarity: levenshtein(inputText.toLowerCase(), item.name.toLowerCase())
            }))
            .filter(({ similarity }) => similarity > 0.1) // Only keep items with some similarity
            .sort((a, b) => b.similarity - a.similarity);

        if (matchedItems.length > 0 && matchedItems[0].similarity == 1)
            document.getElementById('kaudetbut').disabled = true;
        else
            document.getElementById('kaudetbut').disabled = false;

        if (matchedItems.length > 0 && inputText !== '') {
            windowElement.innerHTML = "<div class='text-danger border-bottom border-primary fw-bold'>Daha önce eklediğiniz benzer isimdeki dosyalar</div>" +
                matchedItems.map(item =>
                    `<div class='border-bottom fw-bold text-dark' style='opacity:${0.2 + item.similarity}'>
                    <button class='btn btn-link btn-sm text-decoration-none shadow-none' 
                            onclick="window.similarItemSelect(${item.item.id},'${item.item.name}')">
                        ${item.item.name}
                    </button>
                </div>`).join('');
            const rect = input.getBoundingClientRect();
            windowElement.style.left = rect.left + 3 + 'px';
            windowElement.style.top = rect.bottom + 5 + 'px';
            windowElement.style.display = 'block';
        } else {
            windowElement.style.display = 'none';
        }
    }


    window.similarItemSelect = (id,name) => {
        showConfirmMessage(name + " kaydının bilgileri yüklensin mı?", "", "Evet", "Hayır", () => {
            document.getElementById('kaudetbut').disabled = false;
            window.changeComponent(<FileSettingDetaile rec={fileNames.find(x => x.id == id)} fileNames={fileNames} />);
        })
    }


    var mus = document.getElementById("KonMultiSelect");
    if (mus != null) {
        if (mus.disabled == true) {
            if (!mus.classList.contains('bg-pasif')) {
                mus.classList.add("bg-pasif");
            }
        }
        else {
            mus.classList.remove("bg-pasif");
            mus.classList.add("bg-white");
        }
    }



    return (
        <div className="p-2 rounded-3" style={{ "background-color": "#EDEDF7", "min-height": "100vh" }} id="filenamepage">
            <div className="row " >

                <div className="col-12 col-md-6 " >
                    <img className="rounded-circle " style={{ width: "32px", height: "32px" }} src={"/getuserpic?user=" + rec.creatorUserName} />
                    <span className="me-3" style={{ "font-size": "14px", "font-weight": "600", "color": "#4b5675" }}>  {rec.creatorUserName}</span>
                    <span className="me-3" style={{ "font-size": "14px", "font-weight": "600", "color": "#99a1b7" }}>{rec.crerateTime}</span>
                    <span style={{ "font-size": "14px", "font-weight": "600", "color": "#99a1b7" }}>Id: {rec.id}</span>

                </div>
                <div className="col-12 col-md-6 text-end  pt-1" >
                    <button onClick={() => savefilename(rec)} className="btn btn-primary btn-sm text-decoration-none me-2" id="kaudetbut">Kaydet</button>

                    <button onClick={() => window.changeComponent(<FileSettingList />)} className="btn btn-secondary btn-sm text-decoration-none">Vazgeç</button>
                </div>
            </div>

            <div className="row mt-3 p-3" >
                <div className="col-12 col-md-6">
                    <div className=" bg-white rounded-3" >
                        <div className="p-3 fw-bold border-bottom" >
                            Genel Özellikler

                        </div>
                        <div className="p-4" >
                            Dosya Adı <span className="text-danger">*</span>
                            <input type="text" autocomplete="off" className="form-control form-control-sm" name="name" id="dosyaadi" defaultValue={rec.name} onChange={(e) => { updateRecord(rec, e); showWindow() }} key={ rec.id} />
                            <div id="floatingWindow" class="floating-window"></div>
                        </div>

                        <div className="p-4"  >
                            Dosya Grubu <span className="text-danger">*</span>
                            <select className="form-select form-select-sm" name="gurupid" required={true} onChange={(e) => updateRecord(rec, e)} key={rec.id} >
                                <option ></option>
                                {fileGurups.map(x => <option value={x.id} selected={x.id == rec.gurupid}>{x.name}</option>)}
                            </select>
                            <div class="textvmuted">
                                Dosyanın hangi başlık altında yer alacağını seçebilirsiniz.
                            </div>
                        </div>

                        <div className="p-4" >

                            Dosya Tipi<span className="text-danger">*</span>

                            <Multiselect className="text-dark p-0"
                                options={[".pdf", ".zip", ".doc", ".docx", ".xls", "xlsx", ".dwg", ".ncz", ".txt", "image/*"]}
                                emptyRecordMsg="Başka kayıt yok"
                                isObject={false}
                                selectedValues={rec.fileType != null && rec.fileType.length > 0 ? rec.fileType.split(',') : []}
                                onSelect={(selectedList, removedItem) => { rec.fileType = selectedList.length == 0 ? null : selectedList.join() }} // Function will trigger on select event
                                onRemove={(selectedList, removedItem) => { rec.fileType = selectedList.length == 0 ? null : selectedList.join() }} // Function will trigger on remove event
                                hidePlaceholder={true}
                            />
                        </div>

                        <div className="p-4" >

                            Dosya Boyutu (MB)

                            <input type="number" min="1" max="50" className="form-control form-control-sm " name="fileSize" defaultValue={rec.id == null ? 5 : rec.fileSize} onChange={(e) => updateRecord(rec, e)} key={rec.id} />

                            <div className="textvmuted" >
                                Boş bırakılırsa  dosya boyutunda bir sınırlama uygulanmaz.
                            </div>
                        </div>


                        <div className="p-4" >
                            Açıklama
                            <input type="text" className="form-control form-control-sm" name="aciklama" defaultValue={rec.aciklama} onChange={(e) => updateRecord(rec, e)} key={rec.id} />
                            <div className="textvmuted" >
                                Dosya hakkında açıklama yazabilirsiniz.
                            </div>
                        </div>



                    </div>
                </div>
                <div className="col-12 col-md-6">
                    <div className="bg-white rounded-3" >
                        <div className="p-3 fw-bold border-bottom" >
                            Görüntüleme ve Yetkilendirme

                        </div>
                        <div className="p-4" >

                            Başvuru Tipi
                            <Multiselect className="text-dark p-0"
                                options={typeOptions}
                                avoidHighlightFirstOption={true}
                                emptyRecordMsg="Başka kayıt yok"
                                hidePlaceholder={true}
                                isObject={false}
                                selectedValues={rec.basvuruTipi != null && rec.basvuruTipi.length > 0 && typeOptions.length > 0 ? rec.basvuruTipi.split(',') : []}
                                onSelect={(selectedList, removedItem) => {
                                    rec.basvuruTipi = selectedList.length == 0 ? null :    selectedList.join();
                                    setRec({ ...rec })
                                    setZOptions(selectedList);
                                }} // Function will trigger on select event
                                onRemove={(selectedList, removedItem) => {
                                    if (isEmpty(rec.zorunlu) == false && rec.zorunlu.includes(removedItem)) {
                                        var temp = rec.basvuruTipi.split(',')
                                        temp = temp.filter(x => x != removedItem)
                                        rec.zorunlu = temp.join(",");
                                    }
                                    rec.basvuruTipi = selectedList.length == 0 ? null : selectedList.join();
                                   setRec({ ...rec })
                                    setZOptions(selectedList);
                                }} // Function will trigger on remove event

                            />
                            <div class="textvmuted">
                                Hangi başvurularda yüklenmesini istiyorsunuz?
                            </div>

                        </div>



                        <div className="p-4" >

                            Zorunlu Olan Başvuru Tipleri

                            <Multiselect className="text-dark p-0"
                                options={zoptions}
                                emptyRecordMsg="Başka kayıt yok"
                                avoidHighlightFirstOption={true }
                                hidePlaceholder={true}
                                isObject={false}
                                selectedValues={rec.zorunlu != null && rec.zorunlu.length > 0 && zoptions.length > 0 ? rec.zorunlu.split(',') : []}
                                onSelect={(selectedList, removedItem) => { rec.zorunlu = selectedList.length == 0 ? null : selectedList.join() }} // Function will trigger on select event
                                onRemove={(selectedList, removedItem) => { rec.zorunlu = selectedList.length == 0 ? null : selectedList.join() }} // Function will trigger on remove event

                            />
                            <div class="textvmuted">
                                Hangi başvurularda ZORUNLU OLARAK yüklenmesini istiyorsunuz?
                            </div>

                        </div>

                        <div className="p-4 row" >
                            <div class="col-3">
                                <label class="form-check-label" for="inlineCheckbox1"> Kontrolör Dosyası</label>
                            </div>
                            <div class="col-1">

                                <input type="checkbox" className="form-check-input " name="kontrolorDosyasi" defaultChecked={rec.kontrolorDosyasi} key={rec.id} onChange={(e) => {
                                    var ms = document.getElementById("KonMultiSelect");
                                    ms.disabled = e.target.checked == false;



                                    if (ms.disabled == true) {
                                        ref.current.resetSelectedValues();
                                        if (!ms.classList.contains('bg-pasif')) {
                                            ms.classList.add("bg-pasif");
                                        }
                                    }
                                    else {
                                        ms.classList.remove("bg-pasif");
                                        ms.classList.add("bg-white");
                                    }
                                    updateRecord(rec, e)
                                }} />
                            </div>
                            <div class="col-7 p-0 textvmuted">
                                Dosyayı sadeceözel yetkiye sahip kullanıcıların yükleyebilmesi için işaretleyiniz.
                            </div>
                        </div>

                        <div className="p-4 row" >
                            <div class="col-3">
                                <label class="form-check-label" for="inlineCheckbox1"> E-İmza Zorunlu Dosya</label>
                            </div>
                            <div class="col-1">

                                <input type="checkbox" className="form-check-input " name="zorunluEimza" defaultChecked={rec.zorunluEimza} onChange={(e) => {
                                      updateRecord(rec, e)
                                }} />
                            </div>
                            <div class="col-7 p-0 textvmuted">
                                Başvuruyu göndermek için dosya E-İmzalı olmalıdır. 
                            </div>
                        </div>

                        <div className="p-4 "  >
                            Kontrolör Dosyası Görme Yetkisi

                            <fieldset id={"KonMultiSelect"} disabled={rec.kontrolorDosyasi != true} >
                                <Multiselect className="text-dark p-0 rounded-3 " 
                                    options={roles}
                                    ref={ref}
                                    placeholder="Rol"
                                    emptyRecordMsg="Başka Kayıt Yok"
                                    displayValue="name"
                                    isObject={true}
                                    selectedValues={rec.kontrolorDosyaYetki != null ? rec.kontrolorDosyaYetki.split(',').filter(r => roles.find(x => x.id == r) != null).map(r => roles.find(x => x.id == r)) : []}
                                    onSelect={(selectedList, removedItem) => { rec.kontrolorDosyaYetki = selectedList.length == 0 ? null : selectedList.map(rec => rec.id).join() }} // Function will trigger on select event
                                    onRemove={(selectedList, removedItem) => { rec.kontrolorDosyaYetki = selectedList.length == 0 ? null : selectedList.map(rec => rec.id).join() }} // Function will trigger on remove event
                                />
                            </fieldset>
                            <div class="textvmuted">
                                Bu dosyayı görme yetkisine sahip olacak rolleri seçiniz.
                            </div>
                        </div>
                        <div className="p-5" >

                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}