import React, { useState, useEffect } from 'react';
import UploadFile from "../User/UploadFile.js";
import {  ajaxFail, showConfirmMessage, showErrorMessage, showSuccessMessage } from '../GlobalState.js';
import KadastralVeri from '../Application/KadastralVeri.js';
export default function MevkiSetting(props) {
    var postdata = {};
    const [listData, setListData] = useState(null);

    const updateRecord = (rec, event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        rec[name] = value;
    }

    const removeRecord = (id) => {
        showConfirmMessage(id + " Numaralı Kaydı Silmek İstedinizden Emin Misiniz?", "", "Evet", "Hayır", () =>
        fetch("removemevki", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(id)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                response.json().then(value => {
                    setListData(value);
                })
            }
        }))
    }









    const submitUpdateRecord = (rec) => {

        fetch("updatemevki", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(rec)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                showSuccessMessage("Güncelleme Başarılı")
            }
        });
    }

    const fetchList = () => {

        fetch("getmevkilist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                response.json().then(value => {
                    setListData(value);
                })
            }
        });
    }

    const Add = () => {
        fetch("addmevki", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postdata)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                document.getElementById("name").value = "";
                fetchList();


            }
        });

    }

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        postdata[target.name] = value;
    }

    const handleFile = (resp) => {
        fetchList();
        if (resp.data != null && resp.data.length > 0) {
            showErrorMessage("Eklenmeyen (Hatalı) Satırlar: \n" + resp.data)
        }


    }

    const renderTable = (tabledata) => {

        var mytable = <div>
            <div className="row rounded-3 p-1 mb-3">
                <div className="col-5">
                    <input type="text" className="form-control form-control-sm" id="name" placeholder="Yeni" name="name" onChange={handleInputChange} defaultValue="" />
                </div>
                <div className="col-2">  <button onClick={Add} className="btn btn-sm btn-link text-decoration-none m-0 p-0">Ekle</button></div>

                <div className="col-5 text-end"> <UploadFile setSumbitResult={handleFile} username={""} filelabel={"Dosyadan Yukle"} target={"sumbitmevkifile"} /></div>
            </div>


            {tabledata.map(apprec =>
                < div className="row rounded-3" key={apprec.id}>
                    <div className="col-1" >
                        {apprec.id}
                    </div>
                    <div className="col-8 m-1" >
                        <input type="text" className="form-control form-control-sm border-0" name="name" defaultValue={apprec.name} onChange={(e) => updateRecord(apprec, e)} />
                    </div>

                    <div className="col-2" >
                        <button onClick={() => submitUpdateRecord(apprec)} className="btn btn-link btn-sm text-decoration-none m-0 p-0">Güncelle</button>&nbsp;
                        <button onClick={() => removeRecord(apprec.id)} className="btn btn-link btn-sm text-decoration-none m-0 p-0">Sil</button>
                    </div>
                </div>
            )}
        </div>

        return (mytable);
    }





    useEffect(() => {
        fetchList();
    }, [])


    var listTable = "";
    if (listData != null) {
        listTable = renderTable(listData);
    }
    else
        listTable = renderTable([]);

    return (
        <div className="bg-light m-2 p-3 ">
            <div className="row mb-3">
                <div className="col-6 " >
                    <h5>Mevki</h5>
                </div>
                <div className="col-6 text-end" >
                    <button onClick={() => props.changeComponent(<KadastralVeri changeComponent={props.changeComponent} />)} className="btn btn-primary btn-sm text-decoration-none ">Geri Dön</button>
                </div>
            </div>
            {listTable}



        </div>
    );


}