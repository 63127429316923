import React, { useState, useEffect } from 'react';
import { ajaxFail, showConfirmMessage, showSuccessMessage } from '../GlobalState.js';
import AdaSetting from './AdaSetting.js';


export default function CBSADAMatching(props) {
    const [listData, setListData] = useState(null);
    const [matchresult, setMAtchResult] = useState(null);
    const [cbsListData, setCBSListData] = useState(props.cbsdata);

    const [listtype, setListType] = useState("MATCH");

    useEffect(() => {
        match();


    }, [])



    const fetchAdaList = () => {
        fetch("getadalist?mevki=NOTMATCH&mahalle=NOTMATCH", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {

            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    setListData(value)
                   
                    document.getElementById("spinnermessage").innerText = "";
                 })
            }
        });
    }


    const fetchCbsList = () => {
        fetch("getcbsadalist?param=NOTMATCHED", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {

            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    setCBSListData(value)
                   
                    document.getElementById("spinnermessage").innerText = "";
                })
            }
        });
    }

    const match = () => {
        document.getElementById("spinner").hidden = false;
        fetch("matchada", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
           
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
               
                response.text().then(value => {
                    document.getElementById("spinnermessage").innerText = " Veriler Alınıyor...";
                    var nums = value.replaceAll('"', '').split(' ');
                    setMAtchResult(nums)
                    fetchAdaList();
                    fetchCbsList();
                })
            }
        });
    }

    const removeAda = (id) => {
        showConfirmMessage(id + " Numaralı Kaydı Silmek İstedinizden Emin Misiniz?", "", "Evet", "Hayır", () =>
            fetch("removeada", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(id)
            }).then(async response => {
                if (!response.ok) {
                    ajaxFail(response);

                }
                else {
                    setListData([...listData.filter(x => x.id != id)]);
                }
            }));
    }


    const removenotmatched = (list) => {
        fetch("removenotmatchedadas", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                fetchAdaList();
            }
        });
    }

    const addnotmatched = (rec) => {

        fetch("addnotmatchedcbsadas", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(rec)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
               
                fetchCbsList();
                fetchAdaList();
                document.getElementById("spinner").hidden = false;
                
            }
        });
    }


    const renderCBSTable = (tabledata) => {
        var count = tabledata.length;
        tabledata = tabledata.slice(0, Math.min(30000, tabledata.length ));
        var mytable = <div><div className="fw-bold mb-1 text-center">TKGM'de olan Universe'de olmayan   {count} kayıt
           
        </div>
        
            <div className="table-responsive  mainpagescroll table-sm " style={{ "height": "85vh" }}>
                <table className='table table-vdstriped  table-sm' aria-labelledby="tabelLabel">
                    <tbody id="myTable">
                        {tabledata.map(rec =>
                            <tr style={{ "height": "15px" }}>
                                <td >{rec.tapumahalleref + " " + rec.adano + "/" + rec.parselno}</td>
                                <td className="text-end" ><button className="btn btn-sm btn-primary me-2 p-0 ps-1 pe-1 text-decoration-none" onClick={() => addnotmatched(rec)}>Ekle</button></td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
        document.getElementById("spinner").hidden = true;
        return (mytable);
    }

    const renderAdaTable = (tabledata) => {
        var count = tabledata.length;
        tabledata = tabledata.slice(0, Math.min(30000, tabledata.length));
        var mytable = <div > <div className="fw-bold mb-1 text-center ">Universe'de olan TKGM'de olmayan {count } kayıt
          
        </div>
        
            <div className="table-responsive  mainpagescroll table-sm " style={{ "height": "85vh" }}>
                <table className='table table-vdstriped  table-sm' aria-labelledby="tabelLabel">
                    <tbody id="myTable">
                        {tabledata.map(rec =>
                            <tr  style={{ "height": "15px" }}>
                                <td >{rec.userName + " " + rec.name}</td>
                                <td className="text-end" ><button className=" btn btn-sm btn-secondary me-2 p-0 ps-1 pe-1 text-decoration-none" onClick={() => removeAda(rec.id)}>Sil</button></td>

                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
       </div >
        return (mytable);
    }








    var listTable = "";

    var taskpart = document.getElementById("taskpart")
    if (taskpart != null) {
        if (listtype == "MATCH") {
            taskpart.hidden = true;
        }
        else {
            taskpart.hidden = false;
        }
    }

    return (
        <div className="p-3">
            

            <div className="row mb-1">
                <div className="col-10">
                    <h5 className="">TKGM'deki Veri ile Eşleştir</h5>
                </div>
                <div className="col-2 text-end" >
                    <button onClick={() => props.changeComponent(<AdaSetting changeComponent={props.changeComponent} />)} className="btn btn-primary btn-sm text-decoration-none ">Geri Dön</button>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-12 textvmuted " style={{ "text-align": "justify" }} >
                    <div className="bg-light p-3 rounded-3 textvmuted fw-bold align-middle" >
                        <span className="fa fa-info pe-3" style={{ color: "lightblue", fontSize: "26px" }} ></span>
                        Bu ekranda TKGM den gelen parsel verileri ile Universe'de kullanılan parsel verisi karşılaştırılmaktadır.
                    </div>
                </div>
            </div>



            <div class="d-flex justify-content-center"  >
                <span id="spinnermessage">Eşleştirme yapılıyor. Lütfen işlem tamamlanmadan sayfayı kapatmayınız...</span>
                <div hidden class="spinner-border spinner-border-sm ms-1" role="status" id="spinner"></div>
            </div>

            {matchresult != null && <div class="mt-3 text-primary" id="reportdata">
                <div className="row mb-1" >
                    <div className="col-4 ">
                        Universe Parsel Sayısı: {matchresult[0]}
                    </div>
                    <div className="col-4">
                        TKGM Parsel Sayısı: {matchresult[1]}
                    </div>
                    <div className="col-4 ">
                        Eşleşen Kayıt Sayısı: {matchresult[2]}
                    </div>
                    <div className="col-4 ">
                       Universe'de olan TKGM'de olmayan: {matchresult[3]}
                    </div>
                    <div className="col-4">
                       TKGM'de olan Universe'de olmayan: {matchresult[4]}
                    </div>
                </div>
            </div>}




            <div className="row mb-1" hidden id="taskpart">
                <div className="col-12 text-end">
                    <button onClick={removenotmatched} className="btn btn-link btn-sm me-1 text-decoration-none">Eşleşmeyen Tüm Lokal Kayıtları Sil</button>
                    <button onClick={() => { addnotmatched(cbsListData.filter(x => x.ada == null)) }} className="btn btn-link btn-sm text-decoration-none">Eşleşmeyen Tüm CBS Kayıtları Ekle</button>

                </div>
            </div>
            <div className="row mt-4" id="tablepart">
                <div className="col-6"  >
                    <button className="ms-1 btn btn-sm btn-danger me-2 mb-1 text-decoration-none" onClick={() => removenotmatched()}>Universe'de olan TKGM'de olmayan tüm kayıtları sil ({listData != null ? listData.length + " kayıt" : "0 kayıt"})</button>
               
                </div>
                <div className="col-6"  >
                    <button className="ms-1 btn btn-sm btn-success  me-2 mb-1 text-decoration-none" onClick={() => addnotmatched()}>TKGM'de olan Universe'de olmayan tüm kayıtları ekle  ({cbsListData != null ? cbsListData.length + " kayıt" : "0 kayıt"})</button>

                </div>
            </div>



 
            <div className="row mt-4"  id="tablepart">
                <div className="col-6"  >
                    {listData != null ? renderAdaTable(listData,"userName","mahal"):""}
                </div>
                <div className="col-6"  >
                    {cbsListData != null ? renderCBSTable(cbsListData, "tapumahalleref", "mahal"):""}
                </div>
            </div>


            <div className="row" key={listTable} id="progress-row" hidden>
                <div className="col-12"  >

                    <div className="mt-4" >
                        <div className="progress" >
                            <div className="progress-bar p-0" style={{ "width": "0%" }} id="progressbar">0%</div>
                        </div>
                    </div>

                    <div className="" id="recordstatus">

                    </div>


                </div>
            </div>





        </div >

    );


}