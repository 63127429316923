import React, { useState, useEffect } from 'react';
import { ajaxFail, isEmpty, showConfirmMessage, showErrorMessage, showSuccessMessage } from '../GlobalState.js';
import { useGlobalState } from '../GlobalState.js';

import $ from 'jquery';

export default function MaksYapiDenetim(props) {

    const [role, setRole] = useGlobalState('role');
    const [rec, setRec] = useState({});
    const [maksrec, setMaksRec] = useState(props.rec);
    const [takiplist, setTakiplist] = useState(null);



    useEffect(() => {
        fetchList();

    }, [])

    const remove = (id) => {

        showConfirmMessage("Silmek istediğinizden emin misniz?", "", "Evet", "Hayır", () => {
            fetch("removemaksyapidenetim", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(id)
            }).then(async response => {
                if (!response.ok) {
                    ajaxFail(response)
                }
                else {
                    response.json().then(value => {
                        setTakiplist(value)
                    })
                }
            });
        });
    }

    const fetchList = (e) => {
        fetch("maksyapidenetimlist", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            ,
            body: JSON.stringify(maksrec.id)
        }).then(async response => {


            if (!response.ok) {
                response.text().then(async data => {
                    showErrorMessage(data);
                    setTakiplist([])
                });
            }
            else {
                response.json().then(async data => {
                    setTakiplist(data)
                });
            }
        });
    }



    const getDefult = (name) => {
        if (rec == null)
            return "";
        return rec[name];
    }



    const save = () => {
        rec.maksBasvuruId = maksrec.id;
        fetch("savemaksyapidenetim", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(rec)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                response.json().then(value => {
                    $('.root').find('input').val('');
                    setTakiplist(value)

                })
            }
        });
    }





    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        rec[target.name] = value;
    }



    var recid = getDefult("id");

    useEffect(() => {

    }, [])




    const getName = (value) => {
      
        if (value == 1) return value + "- Mimari";
        if (value == 2) return value + "- Statik";
        if (value == 3) return value + "- Elektrik"
        if (value == 4) return value + "- Mekanik Tesisat"
        if (value == 5) return value + "- Diğer"
        return "";

    }




    const renderToList = () => {

        if (takiplist == null)
            return <div className="w-100 mt-4 mb-4 text-center align-items-center justify-content-center" ><div className="spinner-border" ></div></div>


        if (takiplist.length == 0)
            return <div className="w-100 mt-4 mb-4 text-center align-items-center justify-content-center text-danger" >Kayıt Yok</div>

        var mytable =
            <div className="mt-1 ">
                <div className="table-responsive ">
                    <table className='table table-vdstriped border' aria-labelledby="tabelLabel">

                        <tbody id="myTable">
                            {takiplist.map(apprec =>
                                <tr key={apprec.id} style={{ "height": "25px" }}>
                                    <td>
                                        {getName(apprec.yapiDenetimTurKod)}
                                    </td>
                                    <td>
                                        {apprec.regDate}
                                    </td>
                                    <td>
                                        {apprec.kayitNo}
                                    </td>
                                    <td>
                                        {apprec.tcKimlikNo}
                                    </td>
                                    <td>
                                        {apprec.ad}
                                    </td>
                                    <td>
                                        {apprec.soyad}
                                    </td>
                                    <td>
                                        {apprec.acikAdres}
                                    </td>
                                    <td>
                                        {apprec.yapiDenetimTurDiger}
                                    </td>
                                    <td>
                                        {apprec.bagimsizBolumKimlikNo}
                                    </td>
                                    <td>
                                        {apprec.odaSicilNo}
                                    </td>
                                    <td>
                                        <div style={{ "overflow": "hidden", "white-space": "nowrap" }} className="text-end">
                                            {<a href="#" className=" text-decoration-none " onClick={() => { remove(apprec.id) }}> <i className="fa fa-trash align-middle" style={{ "font-size": "20px" }}></i></a>}
                                        </div>

                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        return (mytable);
    }


    if (maksrec == null || maksrec.id == null) {
        return <div className="text-start  text-muted ">
            Atık Su eklemek için önce başvuruyu kayediniz
        </div>
    }



    return (
        <div className="mb-2 root">


            <div className="row">
                <div className="text-start col-12 fw-bold text-primary">
                    Yapı Denetim Bilgileri
                </div>

            </div>
            <div className="row mt-1">
                <div className="text-start col-3">
                    <label className="form-label">Onay Konu </label>
                    <select class="form-select form-select-sm" defaultValue={getDefult("yapiDenetimTurKod")} id="yapiDenetimTurKod" name="yapiDenetimTurKod" onChange={handleInputChange} >
                        <option selected></option>
                        <option value="1">Mimari</option>
                        <option value="2">Statik</option>
                        <option value="3">Elektrik</option>
                        <option value="4">Mekanik Tesisat</option>
                        <option value="5">Diğer</option>
                    </select>
                </div>
               

                <div className="text-start col-3">
                    <label className="form-label">TC Kimlik No </label>
                    <input type="text" className="form-control form-control-sm" id="tcKimlikNo" name="tcKimlikNo" defaultValue={getDefult("tcKimlikNo")} onChange={handleInputChange} />
                </div>


                <div className="text-start col-3">
                    <label className="form-label">Ad </label>
                    <input type="text" className="form-control form-control-sm" id="ad" name="ad" defaultValue={getDefult("ad")} onChange={handleInputChange} />
                </div>

                <div className="text-start col-3">
                    <label className="form-label">Soyad </label>
                    <input type="text" className="form-control form-control-sm" id="soyad" name="soyad" defaultValue={getDefult("soyad")} onChange={handleInputChange} />
                </div>
                <div className="text-start col-3">
                    <label className="form-label">Oda Sicil No </label>
                    <input type="text" className="form-control form-control-sm" id="odaSicilNo" name="odaSicilNo" defaultValue={getDefult("odaSicilNo")} onChange={handleInputChange} />
                </div>
                <div className="text-start col-3">
                    <label className="form-label">Kayit No </label>
                    <input type="text" className="form-control form-control-sm" id="kayitNo" name="kayitNo" defaultValue={getDefult("kayitNo")} onChange={handleInputChange} />
                </div>
                <div className="text-start col-3">
                    <label className="form-label">Açık Adres </label>
                    <input type="text" className="form-control form-control-sm" id="acikAdres" name="acikAdres" defaultValue={getDefult("acikAdres")} onChange={handleInputChange} />
                </div>
                <div className="text-start col-3">
                    <label className="form-label">Tur Diger</label>
                    <input type="text" className="form-control form-control-sm" id="yapiDenetimTurDiger" name="yapiDenetimTurDiger" defaultValue={getDefult("yapiDenetimTurDiger")} onChange={handleInputChange} />
                </div>
                <div className="text-start col-3">
                    <label className="form-label">Bagimsiz Bolum Kimlik No </label>
                    <input type="text" className="form-control form-control-sm" id="bagimsizBolumKimlikNo" name="bagimsizBolumKimlikNo" defaultValue={getDefult("bagimsizBolumKimlikNo")} onChange={handleInputChange} />
                </div>


                <div className="text-end col-9 pt-3">
                    <button className="btn btn-sm btn-link" onClick={() => { save() }} ><i className="fa fa-plus align-middle" style={{ "font-size": "20px" }}></i></button>
                </div>
            </div>


            {renderToList()}
        </div>

    );


}