import React, { useState, useEffect } from 'react';
import KadastralVeri from '../Application/KadastralVeri.js';
import $ from 'jquery';
import { ajaxFail, cutString, isEmpty, showErrorMessage, toUpperCaseTurkce } from '../GlobalState.js';
import CBSADAMatching from './CBSADAMatching.js';
import CBSAdaSorgula from './CBSAdaSorgula.js';
import bootstrap from 'bootstrap/dist/js/bootstrap.min.js'
import { Tarife_Cetveli } from '../User/UserPermConstants.js';

export default function CBSAdaSetting(props) {
    const [listData, setListData] = useState(props.cbsdata);
    const [searchKeyword, setSearchKeyWord] = useState(null);
    var recivedRecCount = 0;
    useEffect(() => {
        if (listData == null)
            fetchList();
        var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
        var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
            return new bootstrap.Popover(popoverTriggerEl)
        })
    }, [])



    const getDurumName = (d) => {
        if (d == 0) return "Bilinmiyor";
        if (d == 1) return "Taslak";
        if (d == 2) return "Pasif";
        if (d == 3) return "Aktif";
    }


    const refreshList = () => {
        var durum = "";
        document.getElementById("errorbox").innerHTML = "";
        var raktif = document.getElementById("Aktif").checked;
        var rpasif = document.getElementById("Pasif").checked;

        if (raktif == true)
            durum = "durum=3";
        else if (rpasif == true)
            durum = "durum=2";

        var strtType = "start";
        if (document.getElementById("start0").checked)
            strtType = "continue";
        if (document.getElementById("fromdate").checked) {
            strtType = "fromdate";
            var tarih = document.getElementById("tarih").value;
            if (isEmpty(tarih)) {
                showErrorMessage("Tarih Boş Olamaz");
                return;
            }
            if (durum.length > 0)
                durum = durum + " and ";
            durum = durum + "sistemkayittarihi>%27" + tarih + "%27"; //%27 is '

        }

        setListData([]);
        recivedRecCount = 0;


        var source = new EventSource('fetchcbsadalist?durum=' + durum + "&startType=" + strtType);

        source.onmessage = function (event) {

            var rec = null;
            try {
                rec=JSON.parse(event.data)
            }
            catch (ex) {

            }
            if (rec==null || rec.tapukimlikno == "-1") {
                document.getElementById("errorbox").innerHTML = event.data;
                return;
            }


            recivedRecCount++;
            var table = document.getElementById("myTable")
            if (table == null)
                source.close();

            var row = table.insertRow(0); //-1 to end
            var trowcount = table.rows.length;
            if (trowcount > 5000)
                table.deleteRow(trowcount - 1);

            row.style.height = "15px";
            var cell0 = row.insertCell(0);
            var cell1 = row.insertCell(1);
            var cell2 = row.insertCell(2);
            var cell3 = row.insertCell(3);
            var cell4 = row.insertCell(4);
            cell0.innerHTML = rec.tapukimlikno;
            cell1.innerHTML = rec.tapumahalleref;
            cell2.innerHTML = rec.adano + "/" + rec.parselno;
            cell3.innerHTML = rec.durum + "( " + getDurumName(rec.durum) + ")";
            cell4.innerHTML = cutString(rec.coordinates, 70);
            var reccount = document.getElementById("reccount")
            if (reccount == null) {
                source.close();
            }
            else {
                reccount.innerHTML = recivedRecCount + " kayıt.";
                reccount.innerHTML += " index=" + rec.index;
            }
        };

        source.onopen = function (event) {
            console.log('connected');
            var sp = document.getElementById("loadspinner");
            if (sp != null)
                sp.hidden = false;
        };

        source.onerror = function (event) {
            console.log(event);
            var sp = document.getElementById("loadspinner");
            if (sp != null)
                sp.hidden = true;
            source.close();
        }


        //fetch("fetchcbsadalist", {
        //    method: 'GET',
        //    headers: {
        //        'Accept': 'application/json',
        //        'Content-Type': 'application/json'
        //    }
        //}).then(async response => {
        //    if (!response.ok) {
        //        ajaxFail(response);
        //    }
        //    else {
        //        response.json().then(value => {
        //            setListData(value);
        //        })
        //    }
        //});
    }


    const fetchList = () => {




        fetch("getcbsadalist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    setListData(value);
                })
            }
        });
    }

    const renderTable = (tabledata) => {
        var array10 = tabledata;
        var reccount = document.getElementById("reccount")
        if (isEmpty(searchKeyword)) {
            if (tabledata.length > 100) {
                var len = tabledata.length;
                array10 = []
                for (var i = 0; i < 100; i++) {
                    var rect = tabledata[Math.floor(Math.random() * len)]
                    array10.push(rect);
                }
            }
            if (reccount != null) {
                reccount.innerHTML = tabledata.length + " kayıt.";
            }
        }
        else {
            var keys = searchKeyword.split(" ");
            array10 = tabledata.filter(x => toUpperCaseTurkce(x.tapumahalleref).includes(toUpperCaseTurkce(keys[0]))
                || x.adano.includes(keys[0])
                || x.parselno.includes(keys[0])
                || (x.adano + "/" + x.parselno).includes(keys[0])
            )
            for (var i = 1; i < keys.length; i++) {
                array10 = array10.filter(x => toUpperCaseTurkce(x.tapumahalleref).includes(toUpperCaseTurkce(keys[i]))
                    || x.adano.includes(keys[i])
                    || x.parselno.includes(keys[i])
                    || (x.adano + "/" + x.parselno).includes(keys[i])
                )
            }



            if (reccount != null) {
                reccount.innerHTML = array10.length + " kayıt.";
            }

        }
        var mytable =
            <div className="table-responsive  mainpagescroll table-sm " style={{ "height": "80vh" }}>
                <table className='table table-vdstriped  table-sm' aria-labelledby="tabelLabel">
                    <thead style={{ "position": "sticky", "top": 0, "z-index": "1" }} className="bg-light">
                        <tr>
                            <th className="text-start" style={{ "width": "10%", overflow: "hidden" }}>ID</th>
                            <th className="text-start" >Mahalle</th>
                            <th className="text-start" >Ada/Parsel No</th>
                            <th className="text-start" >Durum</th>
                            <th className="text-start" >Koordinat</th>
                        </tr>
                    </thead>
                    <tbody id="myTable">
                        {array10.map(apprec =>
                            <tr style={{ "height": "15px" }}>
                                <td >{apprec.tapukimlikno}</td>
                                {/* <td title={apprec.tapucinsaciklama}  >{apprec.tapucinsaciklama}</td>*/}
                                {/*<td >{apprec.tapuzeminref}</td>*/}
                                <td >{apprec.tapumahalleref}</td>
                                {/* <td >{apprec.tapualan}</td>*/}
                                <td >{apprec.adano}/{apprec.parselno}</td>
                                {/* <td >{apprec.durum + "( " + getDurumName(apprec.durum) + ")"}</td>*/}
                                <td >{getDurumName(apprec.durum)}</td>
                                <td >{cutString(apprec.coordinates, 100)}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        return (mytable);
    }








    var listTable = <div class="d-flex justify-content-center"><div class="spinner-border" role="status"></div></div>;
    if (listData != null) {
        listTable = renderTable(listData);
    }

    return (
        <div className="p-2">
            <div className="row mb-1">
                <div className="col-11">
                    <h5 className="ps-1">KADASTRO VERİSİ - (ADA/PARSEL)</h5>
                </div>
                <div className="col-1 text-end" >
                    <button onClick={() => props.changeComponent(<KadastralVeri changeComponent={props.changeComponent} />)} className="btn btn-primary btn-sm text-decoration-none ">Geri Dön</button>
                </div>

            </div>
            <div className="row mb-3">
                <div className="col-12 textvmuted " style={{ "text-align": "justify" }} >
                    <div className="bg-light p-3 rounded-3 textvmuted fw-bold align-middle" >
                        <span className="fa fa-info pe-3" style={{ color: "lightblue", fontSize: "26px" }} ></span>
                        Bu ekranda, tapu kadastrodan gelen veriler indirilerek Universe içerisinde ayrı bir alana kaydedilmektedir. Burada kaydedeceğiniz veriler başvuru esnasında kullandığınız veriler DEĞİLDİR. Burada yaptıklarınız başvuru sırasında kullanılan parsel verilerinizi ETKİLEMEZ/DEĞİŞTİRMEZ. Başvuru esnasında kullandığınız veriler UNIVERSE'de kullanılan Kadastral Veriler başlığı altında yer almaktadır.

                    </div>
                </div>
            </div>

            <div className="row mb-1 border p-1 pt-3">

                <div className="col-12 col-md-3  ">
                    <div class="form-check form-check">
                        <input class="form-check-input" type="radio" name="startType" id="start0" value="start0" defaultChecked={true} onClick={() => document.getElementById("tarih").disabled = true}/>
                        <label class="form-check-label" for="inlineRadio1">Kaldığım Yerden Devam et</label>
                    </div>
                    <div class="form-check form-check">
                        <input class="form-check-input" type="radio" name="startType" id="continue" value="continue" onClick={() => document.getElementById("tarih").disabled = true}/>
                        <label class="form-check-label" for="inlineRadio2">Yeniden Başlat</label>
                    </div>

                    <div class="form-check form-check">
                        <input class="form-check-input" type="radio" name="startType" id="fromdate" value="fromdate" onClick={() => document.getElementById("tarih").disabled=false} />

                        <label class="form-check-label" for="inlineRadio2">Aşağıdaki Tarihten Sonra  Eklenenler
                            <input type="text" className="form-control form-control-sm" id="tarih" defaultValue="" placeholder="Örnek : 2023-10-22" name="tarih" disabled />
</label>
                    </div>

                </div>


                <div className="col-12 col-md-3  ">
                    <button onClick={refreshList} className="btn btn-primary btn-sm ">TKGM'deki VERİYİ İNDİR</button>
                    <button className="btn btn-secondary ms-1 btn-sm text-decoration-none fw-bold" id="mypopover2" data-bs-toggle="popover" data-bs-trigger="focus" title="AÇIKLAMA" data-bs-content="Aşağıdaki parsel verilerinin tamamı silinerek TKGM'deki mahalle verileri indirilir. İşlemi yarım bırakırsanız veriler eksik indirilebilir ve yeniden indirmeniz gerekir. Bu veriler UNIVERSE'de kullanılan BAŞVURUYA ESAS parsel verileriniz DEĞİLDİR. Bu işlem, başvuruda kullanılan parsel listesinizde herhangi bir değişiklik yapmaz. Bu işlem oldukça uzun sürmektedir. Lütfen işlem tamamlanmadan sayfayı kapatmayınız...">?</button>

                    <br />
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="Durum" id="Pasif" value="2" />
                        <label class="form-check-label" for="inlineRadio1">Pasif</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="Durum" id="Aktif" value="3" defaultChecked={true}/>
                        <label class="form-check-label" for="inlineRadio2">Aktif</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="Durum" id="Hepsi" value=""  />
                        <label class="form-check-label" for="inlineRadio3">Hepsi</label>
                    </div>
                </div>
                <div className="col-12 col-md-3 fw-bold" >
                    Son İndirilen Veri: {listData != null && listData[0] != null ? listData[0].updateTime : ""}
                    <br />
                    Parsel: {listData != null ? listData.length : ""}
                </div>
                <div className="col-12 col-md-2 " id="reccount">

                </div>
                <div className="col-12 col-md-1 text-end " id="loadspinner" hidden>
                    <div class="spinner-border spinner-border-sm ps-1"></div>
                </div>
            </div>
            <div className="row ">
                <div className="col-12 text-danger" style={{ "text-align": "justify" }} id="errorbox" >
                </div>
            </div>

            <div className="row mt-4 mb-1" >
                <div className="col-12 col-md-4" >

                        <button onClick={() => props.changeComponent(<CBSAdaSorgula cbsdata={listData} changeComponent={props.changeComponent} />)} className="btn btn-primary btn-sm ">TKGM'de ARAMA YAP</button>
                        <button className="btn btn-secondary ms-1 btn-sm text-decoration-none fw-bold " id="mypopover1" data-bs-toggle="popover" data-bs-trigger="focus" title="AÇIKLAMA" data-bs-content="Veriyi indirmeden TKGM içerisinde arama yapabilir, istediklerinizi UNIVERSE'de kullanılan BAŞVURUYA ESAS ada/parsel listenize ekleyebilirsiniz.">?</button>
                   
                </div>
                <div className="col-12 col-md-3" >
                    <input type="text" className="form-control form-control-sm" id="ara" defaultValue="" placeholder="Arama için en az 3 karakter giriniz" name="ara" />
                </div>
                <div className="col-12 col-md-5" >
                    <button type="button" className="btn  btn-sm btn-primary me-1 ps-3 pe-3" onClick={(e) => { var sk = document.getElementById("ara").value; if (sk.length == 0 || sk.length > 2) setSearchKeyWord(sk); else showErrorMessage("En az 3 karakter giriniz") }}>
                        Ara
                    </button>
                    <button type="button" className="btn  btn-sm btn-primary " onClick={(e) => { document.getElementById("ara").value = ""; setSearchKeyWord("") }}>
                        Temizle
                    </button>
                </div>
            </div>
            <div className="row mb-1" >
                <div className="col-12 textvmuted text-center" >
                    Aşağıda sadece rasgele 100 kayıt gösterilmektedir. Kayıtların tamamından ulaşmak istediğiniz veri için, yukarıdaki arama kutusunu kullanabilirsiniz
                </div>
            </div>
            <div className="row" key={listTable}>
                <div className="col-12" id="tablediv" >
                    {listTable}
                </div>
            </div>



        </div>

    );


}