import React, { useState, useEffect } from 'react';
import { ajaxFail, chartColors } from '../GlobalState.js';
import ReactDOM from 'react-dom';

export default function PersonelPerfSta(props) {

    const [appType, setAppType] = useState(null);
    const [refcounter, setRefcount] = useState(props.counter);
    const [userapps, setUserApps] = useState(null);

    var sortField = null;
    var sortDirection = {}
    const myCompare = (a, b) => {
        if (sortField == null)
            sortField = "transactionUUID"
        var av = a[sortField];
        var bv = b[sortField];
        if (av == null && bv == null)
            return 0;
        if (av == null && bv != null)
            return -1 * sortDirection[sortField];
        if (av != null && bv == null)
            return 1 * sortDirection[sortField];

        if (typeof av === 'string' || av instanceof String)
            if (typeof bv === 'string' || bv instanceof String)
                return av.localeCompare(bv) * sortDirection[sortField];

        if (av < bv) {
            return -1 * sortDirection[sortField];
        }
        if (av > bv) {
            return 1 * sortDirection[sortField];
        }
        return 0;
    }



    const sortList = (fildname, data) => {
        sortField = fildname;
        if (sortDirection[sortField] == null || sortDirection[sortField] == -1) {
            sortDirection[sortField] = 1;
        }
        else {
            sortDirection[sortField] = -1;
        }
        let sortablelist = [];

        data.forEach(function (item) {
            var obj = {};
            obj.Kullanıcı = item[0];
            obj.Toplam = item[1];
            obj.Biten = item[5];
            obj.Devam = item[9];
            obj.Sırada = item[13];
            sortablelist.push(obj);
        })
        sortablelist.sort(myCompare);

        let temp = [["this will be removed by generatetable"]]
        sortablelist.forEach(function (item) {
            temp.push([item.Kullanıcı, item.Toplam, 0, 0, 0, item.Biten, 0, 0, 0, item.Devam, 0, 0, 0, item.Sırada]);
        })
        ReactDOM.render(generateTable(temp), document.getElementById("tablePart"))
    }

    const handleInputChange = (event) => {
        const target = event.target;
        setAppType(target.value);
    }


    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://www.gstatic.com/charts/loader.js";
        script.async = true;
        document.getElementById("myscript").appendChild(script);
        script.onload = () => {
            window.google.charts.load('current', { packages: ['corechart'] });
            window.google.charts.setOnLoadCallback(getWorkLoadList);

        }

    }, [props.counter]);


    const generateTable = (data) => {
        data.shift();
        return <div className="table-responsive">
            <table className='table  mt-3 table-vdstriped p-0' aria-labelledby="tabelLabel">
                <thead >
                    <th>

                    </th>
                    <th className="text-start">
                        <button className="btn btn-link btn-sm text-decoration-none" onClick={() => sortList("Kullanıcı", data)} > Kullanıcı </button>
                    </th>
                    <th className="text-start">
                        <button className="btn btn-link btn-sm text-decoration-none" onClick={() => sortList("Toplam", data)} > Toplam </button>
                    </th>
                    <th className="text-start">
                        <button className="btn btn-link btn-sm text-decoration-none" onClick={() => sortList("Biten", data)} > Biten </button>
                    </th>
                    <th className="text-start">
                        <button className="btn btn-link btn-sm text-decoration-none" onClick={() => sortList("Devam", data)} >  Devam Eden </button>
                    </th>
                    <th className="text-start">
                        <button className="btn btn-link btn-sm text-decoration-none" onClick={() => sortList("Sırada", data)} > Sırada Bekleyen </button>
                    </th>
                </thead>
                <tbody id="myTable">
                    {data.map((rec, idx) => (rec[1] > 0 || rec[5] > 0 || rec[9] > 0 || rec[13] > 0) &&
                        <tr>
                            <td className="text-start">
                                {idx + 1}
                            </td>
                            <td className="text-start">
                                {rec[0]}
                            </td >
                            <td className="text-start">
                                {rec[1]}
                            </td>
                            <td className="text-start">
                                {rec[5]}
                            </td>
                            <td className="text-start">
                                {rec[9]}
                            </td>
                            <td className="text-start">
                                {rec[13]}
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    }


    const getWorkLoadList = () => {
        fetch("getuserworkloads", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
                this.setState({ status: response.statusText })
            }
            else {
                response.json().then(async data => {


                    var basvurutipleri = new Set()
                    var c = document.getElementById("basvurutipleri");
                    for (var i = 0; i < data.length; i++) {
                        //mapperusername is basvurutipi
                        data[i].basvuruTipi = data[i].mapperUserName;
                        if (basvurutipleri.has(data[i].basvuruTipi) == false) {
                            basvurutipleri.add(data[i].basvuruTipi);
                            var opt = document.createElement('option');
                            opt.value = data[i].basvuruTipi;
                            opt.innerHTML = data[i].basvuruTipi;
                            c.appendChild(opt);
                        }
                    }
                    c.selectedIndex = -1;

                    setUserApps(data)

                });
            }
        });
    }

    const generateReport = (data) => {
        var dataarray = [];
        var userstat = {}
        dataarray.push(['', 'Toplam', { role: 'annotation' }, { role: 'annotation' }, { role: 'style' },
            'Biten', { role: 'annotation' }, { role: 'annotation' }, { role: 'style' },
            'Devam Eden', { role: 'annotation' }, { role: 'annotation' }, { role: 'style' },
            'Sırada Bekleyen', { role: 'annotation' }, { role: 'annotation' }, { role: 'style' }]);
        // 'Günllük Ortalama', { role: 'annotation' }, { role: 'annotation' }, { role: 'style' }

        for (var i = 0; i < data.length; i++) {
            userstat[data[i].userName] = { toplam: 0, biten: 0, devam: 0, bekleyen: 0, ortalama: 0, namefamily: data[i].message }
        }


        for (var i = 0; i < data.length; i++) {
            if (appType != null && appType != "" && data[i].basvuruTipi != appType)
                continue;
            userstat[data[i].userName].toplam++;

            if (data[i].started == null && data[i].completed == null) {
                if (data[i].duzeltmeDurum == true)
                    userstat[data[i].userName].devam++;
                else
                    userstat[data[i].userName].bekleyen++;
            }
            else if (data[i].started != null && data[i].completed == null) {
                if (data[i].denetimID != 10)
                    userstat[data[i].userName].devam++;
                else
                    userstat[data[i].userName].biten++;
            }
            else if (data[i].started != null && data[i].completed != null)
                userstat[data[i].userName].biten++;
        }
        for (var i = 0; i < data.length; i++) {
            userstat[data[i].userName].ortalama = (userstat[data[i].userName].biten / 30.0).toFixed(1);
        }

        var i = 0;
        for (var rec in userstat)
            if (userstat[rec].toplam > 0)
                dataarray.push([userstat[rec].namefamily, userstat[rec].toplam, userstat[rec].toplam, "", 'stroke-color: #000000; stroke-width: 1;',
                userstat[rec].biten, userstat[rec].biten, "", 'stroke-color: #000000; stroke-width: 1;',
                userstat[rec].devam, userstat[rec].devam, "", 'stroke-color: #000000; stroke-width: 1;',
                userstat[rec].bekleyen, userstat[rec].bekleyen, "", 'stroke-color: #000000; stroke-width: 1;']);
        //userstat[rec].ortalama, userstat[rec].ortalama.toFixed(2), "Ort", 'stroke-color: #E7E6E1; stroke-width: 1;'


        drawChart(dataarray, 'userstaticpanel2')

    }


    const drawChart = (dataarray, target) => {
        var data = window.google.visualization.arrayToDataTable(dataarray);
        var options = {
            colors: chartColors,
            chartArea: {
                // leave room for y-axis labels
                top: 20,
                width: '90%',
                height: '80%'
            },
            hAxis: {
                slantedTextAngle: 45,
                textStyle: {
                    fontSize: 14

                },
                baselineColor: "EEF8FB"

            },
            vAxis: {
                gridlines: {
                    color: "EEF8FB"
                },

                minorGridlines: {
                    color: 'transparent'

                },
                textStyle: {
                    fontSize: 16 // or the number you want
                }
                ,
                baselineColor: "DDF7FD"

            },
            annotations: {
                stem: {
                    length: 0
                },
                alwaysOutside: true,
                textStyle: {
                    auraColor: 'none'
                }

            },
            animation: {
                duration: 1200,
                easing: 'out',
                "startup": true
            },

            width: '100%',
            legend: { position: "top" },

        };


        var chart = new window.google.visualization.ColumnChart(document.getElementById(target));

        chart.draw(data, options);
        ReactDOM.render(generateTable(dataarray), document.getElementById("tablePart"));
    }

    if (userapps != null)
        generateReport(userapps)


    return (
        <div className="border  rounded-2 mt-1" style={{ "height": "100%" }}>
            <div id="myscript"></div>
            <h4>Personel Performansı</h4>
            <div style={{ "max-width": "300px" }} className="p-1 mb-3">
                <select className="form-select form-select-sm" id="basvurutipleri" name="basvuruTipi" required={true} onChange={handleInputChange}>
                    <option value=""></option>
                </select>
            </div>

            <div id="userstaticpanel2" style={{ "height": "600px" }} className="w-100  text-center"><div class="spinner-border"></div></div>
            <div id="tablePart" className="w-100  text-center"><div class="spinner-border"></div></div>
        </div>

    );
}
