import React, { useState, useEffect } from 'react';
import { ajaxFail, isEmpty, showConfirmMessage, showErrorMessage, showSuccessMessage } from '../GlobalState.js';
import { useGlobalState } from '../GlobalState.js';

import $ from 'jquery';

export default function MaksBagimsizBolum(props) {

    const [role, setRole] = useGlobalState('role');
    const [rec, setRec] = useState({});
    const [maksrec, setMaksRec] = useState(props.rec);
    const [takiplist, setTakiplist] = useState(null);



    useEffect(() => {
        fetchList();

    }, [])

    const remove = (id) => {

        showConfirmMessage("Silmel istediğinizden emin misniz?", "", "Evet", "Hayır", () => {
            fetch("removemaksbagimsizbolum", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(id)
            }).then(async response => {
                if (!response.ok) {
                    ajaxFail(response)
                }
                else {
                    response.json().then(value => {
                        setTakiplist(value)
                    })
                }
            });
        });
    }

    const fetchList = (e) => {
        fetch("maksbagimsizbolumlist", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            ,
            body: JSON.stringify(maksrec.id)
        }).then(async response => {
            if (!response.ok) {
                response.text().then(async data => {
                    showErrorMessage(data);
                    setTakiplist([])
                });
            }
            else {
                response.json().then(async data => {
                    setTakiplist(data)
                });
            }
        });
    }



    const getDefult = (name) => {
        if (rec == null)
            return "";
        return rec[name];
    }



    const save = () => {
        rec.maksBasvuruId = maksrec.id;
        fetch("savemaksbagimsizbolum", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(rec)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                response.json().then(value => {
                    $('.root').find('input').val('');
                    setTakiplist(value)

                })
            }
        });
    }

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        rec[target.name] = value;
    }

    const getIslemName = (value) => {
        if (value == "1") return "Yapıya Eklenecek"
        if (value == "2") return "Belgede Bulunacak"
        if (value == "3") return "Yapıdan Silinecek"
        if (value == "4") return "Belgede Bulunmayacak"
    }

    const getDurumName = (value) => {
        if (value == "1") return "Bilinmeyen"
        if (value == "2") return "Proje"
        if (value == "3") return "İnşaat"
        if (value == "4") return "İskan"
        if (value == "5") return "Ruhsatsız"
        if (value == "6") return "Yanan Yıkılan"
        if (value == "7") return "Yapı Kayıt Belgeli"
        if (value == "8") return "MAKS Sürecinde"
        if (value == "9") return "Geçici 11 Madde"

    }


    const getKullanımName = (value) => {
        if (value == "0") return "Yok"
        if (value == "1110") return "Mesken"
        if (value == "12111") return "Küçük misafir"
        if (value == "12111") return "Otel"
        if (value == "12111") return "Pansiyon"
        if (value == "12111") return "Restorantlı veya restorantsız otel"
        if (value == "12112") return "Bar"
        if (value == "12112") return "Bar(ayrı bina olarak)"

    }



    const renderToList = () => {

        if (takiplist == null)
            return <div className="w-100 mt-4 mb-4 text-center align-items-center justify-content-center" ><div className="spinner-border" ></div></div>


        if (takiplist.length == 0)
            return <div className="w-100 mt-4 mb-4 text-center align-items-center justify-content-center text-danger" >Kayıt Yok</div>

        var mytable =
            <div className="mt-1 ">
                <div className="table-responsive ">
                    <table className='table table-vdstriped border' aria-labelledby="tabelLabel">

                        <tbody id="myTable">
                            {takiplist.map(apprec =>
                                <tr key={apprec.id} style={{ "height": "25px" }}>
                                    <td>
                                        {apprec.regDate}
                                    </td>
                                    <td>
                                        {getIslemName(apprec.yapilanIslem)}
                                    </td>


                                    <td>
                                        {getDurumName(apprec.bagimsizDurum)}
                                    </td>
                                    <td>
                                        {apprec.icKapiNo}
                                    </td>
                                    <td>
                                        {apprec.yuzolcum}
                                    </td>
                                    <td>
                                        {apprec.arsaPay}
                                    </td>
                                    <td>
                                        {apprec.arsaPayda}
                                    </td>
                                    <td>
                                        {apprec.bagimsizBolumKimlikNo}
                                    </td>
                                    <td>
                                        {apprec.bagimsizBolumTip == 1 ? "Kamu" : apprec.bagimsizBolumTip == 2?"Özel":""}
                                    </td>
                                    <td>
                                        {apprec.binaKimlikNo}
                                    </td>
                                    <td>
                                        {apprec.brutYuzolcum}
                                    </td>
                                    <td>
                                        {apprec.katNo}
                                    </td>
                                    <td>
                                        {apprec.kayitNo}
                                    </td>
                                    <td>
                                        {getKullanımName(apprec.kullanimAmac)}
                                    </td>
                                    <td>
                                        {apprec.numaratajKimlikNo}
                                    </td>
                                    <td>
                                        {apprec.tadilataKonuOlsunMu}
                                    </td>
                                    <td>
                                        {apprec.tapuBagimsizBolumNo}
                                    </td>


                                    <td>
                                        <div style={{ "overflow": "hidden", "white-space": "nowrap" }} className="text-end">
                                            {<a href="#" className=" text-decoration-none " onClick={() => { remove(apprec.id) }}> <i className="fa fa-trash align-middle" style={{ "font-size": "20px" }}></i></a>}
                                        </div>

                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        return (mytable);
    }


    if (maksrec == null || maksrec.id == null) {
        return <div className="text-start  text-muted ">
            Atık Su eklemek için önce başvuruyu kayediniz
        </div>
    }



    return (
        <div className="mb-2 root">


            <div className="row">
                <div className="text-start col-12 fw-bold text-primary">
                    Yapı Belge Bağımsız Bolum
                </div>

            </div>
            <div className="row mt-1">
                <div className="text-start col-3">
                    <label className="form-label">Yapılan İşlem </label>
                    <select class="form-select form-select-sm" defaultValue={getDefult("yapilanIslem")} id="yapilanIslem" name="yapilanIslem" onChange={handleInputChange} >
                        <option selected></option>
                        <option value="1">Yapıya Eklenecek</option>
                        <option value="2">Belgede Bulunacak</option>
                        <option value="3">Yapıdan Silinecek</option>
                        <option value="4">Belgede Bulunmayacak</option>
                    </select>
                </div>

                <div className="text-start col-3">
                    <label className="form-label">Durum</label>
                    <select class="form-select form-select-sm" defaultValue={getDefult("bagimsizDurum")} id="bagimsizDurum" name="bagimsizDurum" onChange={handleInputChange} >
                        <option selected></option>
                        <option value="1">Bilinmeyen</option>
                        <option value="2">Proje</option>
                        <option value="3">İnşaat</option>
                        <option value="4">İskan</option>
                        <option value="5">Ruhsatsız</option>
                        <option value="6">Yanan Yıkılan</option>
                        <option value="7">Yapı Kayıt Belgeli</option>
                        <option value="8">MAKS Sürecinde</option>
                        <option value="9">Geçici 11 Madde</option>
                    </select>
                </div>


                <div className="text-start col-3">
                    <label className="form-label">İç Kapı No </label>
                    <input type="text" className="form-control form-control-sm" id="icKapiNo" name="icKapiNo" defaultValue={getDefult("icKapiNo")} onChange={handleInputChange} />
                </div>
                <div className="text-start col-3">
                    <label className="form-label">Yüz Ölçum </label>
                    <input type="number" className="form-control form-control-sm" id="yuzolcum" name="yuzolcum" defaultValue={getDefult("yuzolcum")} onChange={handleInputChange} />
                </div>

                <div className="row mt-1">
                    <div className="text-start col-3">
                        <label className="form-label">Arsa Pay</label>
                        <input type="text" className="form-control form-control-sm" id="arsaPay" name="arsaPay" defaultValue={getDefult("arsaPay")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Arsa Payda </label>
                        <input type="text" className="form-control form-control-sm" id="arsaPayda" name="arsaPayda" defaultValue={getDefult("arsaPayda")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Kimlik No </label>
                        <input type="number" className="form-control form-control-sm" id="bagimsizBolumKimlikNo" name="bagimsizBolumKimlikNo" defaultValue={getDefult("bagimsizBolumKimlikNo")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Tip </label>
                        <select class="form-select form-select-sm" defaultValue={getDefult("bagimsizBolumTip")} id="bagimsizBolumTip" name="bagimsizBolumTip" onChange={handleInputChange} >
                            <option selected></option>
                            <option value="1">Kamu</option>
                            <option value="2">Özel</option>
                        </select>
                    </div>

                </div>


                <div className="row mt-1">
                    <div className="text-start col-3">
                        <label className="form-label">Bina Kimlik No</label>
                        <input type="number" className="form-control form-control-sm" id="binaKimlikNo" name="binaKimlikNo" defaultValue={getDefult("binaKimlikNo")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Brut Yuz Ölçum</label>
                        <input type="text" className="form-control form-control-sm" id="brutYuzolcum" name="brutYuzolcum" defaultValue={getDefult("brutYuzolcum")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Kat No</label>
                        <input type="number" className="form-control form-control-sm" id="katNo" name="katNo" defaultValue={getDefult("katNo")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Kayit No</label>
                        <input type="number" className="form-control form-control-sm" id="kayitNo" name="kayitNo" defaultValue={getDefult("kayitNo")} onChange={handleInputChange} />
                    </div>

                </div>

                <div className="row mt-1">
                    <div className="text-start col-3">
                        <label className="form-label">Kullanım Amaç </label>
                        <select class="form-select form-select-sm" defaultValue={getDefult("kullanimAmac")} id="kullanimAmac" name="kullanimAmac" onChange={handleInputChange} >
                            <option selected></option>
                            <option value="0">Yok</option>
                            <option value="1110">Mesken</option>
                            <option value="12111">Küçük misafir</option>
                            <option value="12111">Motel</option>
                            <option value="12111">Otel ve bunlara benzer misafir evi</option>
                            <option value="12111">Pansiyon</option>
                            <option value="12111">Restorantlı veya restorantsız otel</option>
                            <option value="12112">Bar</option>
                            <option value="12112">Bar(ayrı bina olarak)</option>
                        </select>
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Numarataj Kimlik No</label>
                        <input type="number" className="form-control form-control-sm" id="numaratajKimlikNo" name="numaratajKimlikNo" defaultValue={getDefult("numaratajKimlikNo")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Tadilata Konu Olsun Mu</label>
                        <input type="number" className="form-control form-control-sm" id="tadilataKonuOlsunMu" name="tadilataKonuOlsunMu" defaultValue={getDefult("tadilataKonuOlsunMu")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Tapu Bagimsiz Bolum No</label>
                        <input type="text" className="form-control form-control-sm" id="tapuBagimsizBolumNo" name="tapuBagimsizBolumNo" defaultValue={getDefult("tapuBagimsizBolumNo")} onChange={handleInputChange} />
                    </div>

                </div>



                <div className="row mt-1">

                    <div className="text-end col-12 text-end">
                        <button className="btn btn-sm btn-link" onClick={() => { save() }} ><i className="fa fa-plus align-middle" style={{ "font-size": "20px" }}></i></button>
                    </div>
                </div>
            </div>


            {renderToList()}
        </div>

    );


}