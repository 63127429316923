import React, { useState, useEffect } from 'react';
import Select from "react-select";
import axios from "axios";
import * as UserPermConstants from '../User/UserPermConstants';
import { isEmpty, removeTurkce, removeTurkcLow, sendpost, showConfirmMessage, showErrorMessage, useGlobalState } from '../GlobalState.js';
import { ajaxFail } from '../GlobalState.js';
import InlineFilePanel from './InlineFilePanel';
import $ from 'jquery';
import FilesPanel from './FilesPanel';
import VTable from '../Header/VTable';

export default function DWGCompare(props) {

    const files = props.files;
    const [dwgs, setDwgs] = useState([]);
    useEffect(() => {
        getlist();

    }, [])

    const compareFiles = (event) => {
        var f1 = document.getElementById("file1").value;
        var f2 = document.getElementById("file2").value;
        if (f1 == -1 || f2 == -1) {
            showErrorMessage("DWG dosya boş olamaz");
            return;
        }
        sendpost("comparedwg", f1 + "," + f2, "statusbar", (resp) => {
            /* resp.json().then(async data => {
                
             });*/
            getlist();

        }, (resp) => {
            getlist();
            resp.text().then(value => {
                showErrorMessage(value);
            })
        })

    }

    const getlist = (event) => {
        sendpost("getdwgcompares", props.appData.id, "listbox", (resp) => {
            resp.json().then(async data => {
                setDwgs(data);
            });

        }, (resp) => {
            resp.text().then(value => {
                showErrorMessage(value);
            })
        })

    }


    const rowClick = (idx, rec, col) => {


    }

    const rowDelete = (idx, rec) => {
        showConfirmMessage(rec.id + " numaralı kaydı silmek istediğinizden emin misiniz?", "", "Evet", "Hayır", () => {
            sendpost("removedwgcompare", rec.id, "", (resp) => {
                resp.json().then(async data => {
                    setDwgs(data);
                });

            }, (resp) => {
                resp.text().then(value => {
                    showErrorMessage(value);
                })
            })

        })


    }
   
    const renderTable = (tabledata) => {

        var mytable = <div className="mt-4">
            {tabledata.map((apprec,idx) =>
                < div className="row rounded-3 border-top pt-2" key={apprec.id}>
                    <div className="col-1" >
                        {apprec.id}
                    </div>
                    <div className="col-3" >
                        {apprec.file1id} - {apprec.file1typeName} ({apprec.file1caption})
                    </div>
                    <div className="col-3 " >
                        {apprec.file2id} - { apprec.file2typeName }({ apprec.file2caption })
                    </div>
                    <div className="col-3" >
                        {apprec.compareDate}
                    </div>
                    <div className="col-2 text-end" >
                        <a download={apprec.id + ".pdf"} href={"getdwgpic?id=" + apprec.id} className="text-decoration-none m-0 p-0 material-icons">download</a>&nbsp;
                        <a href="#" onClick={() => rowDelete(apprec,idx)} className="material-icons text-decoration-none m-0 p-0 text-danger">delete</a>
                    </div>
                </div>
            )}
        </div>

        return (mytable);
    }



    return (
        <div>
            <div className="row mb-1">
                <div className="col-8  fw-bold">
                    <h5 className="text-primary"> DWG Dosya Karşılaştırma</h5>
                </div>

                <div className="col-4  text-end">
                    <button type="button" className="btn btn-sm btn-primary " onClick={(e) => { props.setActivePanel(<FilesPanel appData={props.appData} showAll={true} setActivePanel={props.setActivePanel} changeComponent={props.changeComponent} />) }}>
                        <i className="fa fa-arrow-circle-left pe-1"></i>  Dosyalar
                    </button>
                </div>
            </div>


            <div className="row">
                <div className="col-12  col-md-5">
                    Yeni Dosya
                    <select className="form-select form-select-sm" id="file1">
                        <option selected value={-1}></option>
                        {files.map(x => <option value={x.id}>{x.id } - {x.typeName} ({x.caption})</option>)}
                    </select>
                </div>
                <div className="col-12  col-md-5">
                    Eski Dosya
                    <select className="form-select form-select-sm" id="file2">
                        <option selected value={-1}></option>
                        {files.map(x => <option value={x.id}>{x.id} -{x.typeName} ({x.caption})</option>)}
                    </select>
                </div>

                <div className="col-12  col-md-2 text-end">
                    <br />
                    <button type="button" className="btn btn-primary  btn-sm " onClick={compareFiles}>
                        Karşılaştır <i className="fa fa-copy"></i>
                    </button>
                </div>
            </div>

            <div className="row  mt-1" hidden id="statusbar">
                <div className="col-12 fw-bold" >
                    Lütfen bekleyin karşılaştırma bir kaç dakika sürebilir
                    <span class="ms-2 spinner-border spinner-border-sm" role="status">
                    </span>

                </div>
            </div>


            <div className="row" id="listbox" hidden>
                <div className="col-12 fw-bold" >
                    <span class="ms-2 spinner-border spinner-border-sm" role="status">
                    </span>
                </div>
            </div>

            {renderTable(dwgs)}

            {/*<VTable data={dwgs} cols={["id", "file1caption", "file1typeName", "file2caption", "file2typeName", "compareDate"]} headers={["ID", "Yeni Dosya", "Yeni Dosya Tip", "Eski Dosya", "Eski Dosya Tip", "Tarih"]} href={"getdwgpic?id="} hrefid={"id"} rowDelete={rowDelete} />*/}


        </div >



    );


}
