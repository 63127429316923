import React, { useState, useEffect } from 'react';
import { useGlobalState, ajaxFail } from '../GlobalState.js';

import UserUploadFilePanel from "./UserUploadFilePanel.js";
import * as UserPermConstants from '../User/UserPermConstants';


export default function UserFilePanel(props) {

    const [userdata, setData] = useState(props.userdata);
    const [fileNameList, setfileNameList] = useState(null);
    const [files, setFiles] = useState([]);
    const [userName, setUserNAme] = useGlobalState('userName');
    var eksikdosyauyari = "";

    const handleFile = (files) => {
        // selectedFiles = files;
    }

    const userfilenamelist = () => {
        fetch("userfilenamelist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                response.json().then(value => {
                    setfileNameList(value);
                    fetchFiles();
                })
            }
        });
    }

    useEffect(() => {
        userfilenamelist();
    }, [props.userdata])  //this is to force chid refresh when parent chnge appdata



    const eksikdosyatespit = (fileNameList) => {
        var ekseik = "";
        var i, j;
        for (i = 0; i < fileNameList.length; i++) {
            if (fileNameList[i].userType != null && props.userdata.userType != null && fileNameList[i].userType.split(',').some(t => props.userdata.userType.includes(t)) == false)
                continue;

            if (fileNameList[i].zorunlu != true)
                continue;


            for (j = 0; j < fileNameList.length; j++) {
                if (fileNameList[i].id == fileNameList[j].typeId)
                    break;
            }
            if (j == fileNameList.length) {
                ekseik += " " + fileNameList[i].name;
            }
        }
        document.getElementById("eksikdosyauyari").innerText = "";
        if (ekseik.length > 0) {
            document.getElementById("eksikdosyauyari").innerText = "Eksik zorunlu dosyalar bulunmaktadır (" + ekseik + " )";
        }
    }

    const getFileType = (id) => {
        if (fileNameList == null) return id;
        for (var i = 0; i < fileNameList.length; i++)
            if (fileNameList[i].id == id)
                return fileNameList[i].name;
        return id;
    }


    const fetchFiles = () => {
        fetch("getuserfiles", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(props.userdata.userName)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                response.json().then(async data => {
                    setFiles(data);
                    eksikdosyatespit(data);

                });
            }
        });
    }

    const removefile = (rec) => {
        fetch('removeuserfile?id=' + rec.id, {
            method: 'GET'
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                setFiles(files.filter(x => x.id != rec.id));
            }
        });
    }


    return (
        <div>
            <div className="row">
                <div className="col-3">
                    <h5 className="text-primary">Dosyalar</h5 >
                </div>
                <div className="col-9 text-danger justify-content-end align-items-end text-end  pe-4" id="eksikdosyauyari">

                </div>

                <div className="col-12" id="filetable">
                    (*) ile işaretlenen dosyaların yuklenmesi zorunludur.
                    <UserUploadFilePanel setFiles={handleFile} userdata={props.userdata} refreshFiles={fetchFiles} showAll={props.showAll} />

                    <table className='table table-striped  table-sm' aria-labelledby="tabelLabel">
                        <tbody id="userfiles">
                            {files.map(rec => <tr><td ></td><td> {rec.userName} </td><td >
                                {rec.tarih} </td><td> 
                                    {getFileType(rec.typeId)} </td><td className="text-end"><a href={'/getuserfile?id=' + rec.id} download={
                                    rec.caption}> <i className="fa fa-cloud-download" style={{ "font-size": "20px" }}></i></a>&nbsp;
                                    {rec.userName == userName && <a onClick={() => removefile(rec)} href="#"><i className="fa fa-trash-o" style={{ "font-size": "20px" }}></i></a>}

                                </td></tr>)}

                        </tbody>
                    </table>
                </div>

                
            </div>
        </div >
    );
}



