import React, { useState, useEffect } from 'react';
import Application from "./Application.js"
import { ajaxFail, removeTurkcLow, calcBusinessDays, isEmpty } from '../GlobalState.js';
import $ from 'jquery';

export default function DelayedApplications(props) {
    const surebg = ["#c9f7f5", "#e1f0ff", "#fff4de", "#ffe2e5"]
    const suretxt = ["text-info", "text-primary", "text-warning", "text-danger"]
    const [applist, setApplist] = useState(null);
    const [denetimlist, setDenetimlist] = useState([]);
    var sortField = null;
    const me = <DelayedApplications changeComponent={props.changeComponent}/>
    useEffect(() => {
        fetchDenetimList();

    }, [])

    const myCompare = (a, b) => {
        if (a[sortField] < b[sortField]) {
            return -1;
        }
        if (a[sortField] > b[sortField]) {
            return 1;
        }
        return 0;
    }

    


    const fetchDenetimList = () => {
        var refreshButton = document.getElementById("refreshButton");
        if (refreshButton != null) {
            refreshButton.classList.remove("fa", "fa-refresh")
            refreshButton.classList.add("spinner-grow", "spinner-grow-sm")
        }

        fetch("denetimlist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    setDenetimlist(value)
                    fetchlist();
                })
            }
        });
    }


    const getColorIdx = (diffDays, rec) => {
        var d = denetimlist.find(x => x.id == rec.denetimID);
        var i = diffDays > d * 2 / 3 ? 0 : diffDays > d / 3 ? 1 : diffDays > 0 ? 2 : 3;
        return i;
    }

    const getVerilenSure = (rec) => {

        var d = denetimlist.find(x => x.id == rec.denetimID);
        if (d == null) return "";
        d = d.deadline;
        if (d == null) return "";
        return d;

    }









    const getDenetimSure = (rec) => {

        var d = denetimlist.find(x => x.id == rec.denetimID);
        if (d == null || rec.deliverDate==null) return 0;


        d = d.deadline;
        if (d == null) return 0;

        //completed is the deadline refrence

         var havaleDate = rec.mappingDate;
        var deliverdate = rec.deliverDate.split(" ")[0].replaceAll(".", "-") + " 12:00:00";
        var h = new Date(deliverdate);
        havaleDate = new Date(havaleDate.split(" ")[0].replaceAll(".", "-") + " 12:00:00");
        if (h < havaleDate)
            h = havaleDate;
        //duzeltmeIste is sonDuzeltmeTarihi
        if (isEmpty(rec.duzeltmeIste) == false && rec.duzeltmeIste.includes(" ")) {
            var sonduzeltme = new Date(rec.duzeltmeIste.split(" ")[0].replaceAll(".", "-") + " 12:00:00");
            if (h < sonduzeltme)
                h = sonduzeltme;
        }



        var diffDays = d - calcBusinessDays(new Date(Date.now()), h)
        return diffDays
    }






    

    const fetchlist = (e) => {
        fetch("delayedcontrollist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {

            var refreshButton = document.getElementById("refreshButton");
            if (refreshButton != null && refreshButton.classList.contains("fa-refresh") == false) {
                refreshButton.classList.remove("spinner-grow", "spinner-grow-sm")
                refreshButton.classList.add("fa", "fa-refresh")
            }

            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(async data => {
                    setApplist(data)
                });
            }
        });
    }


    const getapp = (id) => {
        fetch("getapp", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(id)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(async data => {
                    props.changeComponent(<Application appData={data} changeComponent={props.changeComponent} backButton={me} />)
                });
            }
        });
    }



    const filterTable = (event) => {
        const target = event.target;
        const value = target.value.toLowerCase();
        $("#myTable tr").filter(function () {
            var keywords = value.split(" ");

            for (let index = 0; index < keywords.length; ++index)
                if (removeTurkcLow($(this).text()).indexOf(removeTurkcLow(keywords[index])) == -1)
                    return $(this).toggle(false);
            return $(this).toggle(true);
        });
    }

    const sortList = (fildname) => {
        sortField = fildname;
        var temp = [...applist]; //clone the array to update the component on setApplist
        temp.sort(myCompare);
        setApplist(temp);
    }


    if (applist == null)
        return <div className="w-100 mt-4 mb-4 text-center align-items-center justify-content-center" ><div className="spinner-border" ></div></div>


    if (applist.length == 0)
        return <div className="w-100 mt-4 mb-4 text-center align-items-center justify-content-center text-danger" >Kayıt Yok</div>

    applist.forEach(x => x.gecikme = getDenetimSure(x));
    applist.forEach(x => x.verilesure = getVerilenSure(x));

    return (<div>
        <div>
            <div className="row">
                <div className="col-12 pb-2">
                    <h5 className="text-primary">Geciken Başvurular</h5 >
                </div>
            </div>
            <div className="row " >
                <div className="col-12 col-md-3"></div>
                <div className="col-12 col-md-6">
                    <input className="form-control form-control-sm" id="myInput" type="text" placeholder="Arama" onKeyUp={filterTable} />

                </div>

                <div className="col-12 col-md-3 text-end pt-2">
                    <a onClick={fetchDenetimList} href="#" className="text-primary"> <i className="fa fa-refresh" id="refreshButton"></i></a>
                </div>
            </div>
            <div className="row">


                <div className="col-12">
                    <table className='table  table-sm' aria-labelledby="tabelLabel">
                        <thead>
                            <tr >

                                <th className='text-start'>
                                    Sıra
                                </th>
                                <th className='text-start'>
                                    <button className="btn btn-link btn-sm text-decoration-none" onClick={() => sortList("appID")}>  Başvuru No </button>
                                </th>
                                <th className='text-start'>
                                    <button className="btn btn-link btn-sm text-decoration-none" onClick={() => sortList("userName")}>  Kontrolör </button>

                                </th>
                                <th className='text-start'>
                                    <button className="btn btn-link btn-sm text-decoration-none" onClick={() => sortList("denetimID")}>  Başvuru Tipi </button>

                                </th>
                                <th className='text-start'>
                                    <button className="btn btn-link btn-sm text-decoration-none" onClick={() => sortList("verilesure")}>  Belirlenen Süre </button>

                                </th>
                                <th className='text-start'>
                                    <button className="btn btn-link btn-sm text-decoration-none" onClick={() => sortList("mappingDate")}>  Havale </button>

                                </th>
                                <th className='text-start'>
                                    <button className="btn btn-link btn-sm text-decoration-none" onClick={() => sortList("message")}>   Başvuru Durumu </button>

                                </th>
                                <th className='text-start'>
                                    <button className="btn btn-link btn-sm text-decoration-none" onClick={() => sortList("completed")}>  Sıraya Girme Tarihi </button>

                                </th>
                                <th className='text-start'>
                                    <button className="btn btn-link btn-sm text-decoration-none" onClick={() => sortList("gecikme")}>  Geciken Süre </button>

                                </th>
                                <th className='text-start'></th>

                            </tr>
                        </thead>
                        <tbody id="myTable">
                            {applist.filter(x => x.gecikme<0).map((rec, idx) => <tr>
                                <td> {idx + 1}</td>
                                <td> {rec.appID}  </td><td>
                               {/* <img class="rounded-circle" style={{ width: "32px", height: "32px" }} src={"/getuserpic?user=" + rec.userName} /> */}{rec.userName}</td>
                                <td> {denetimlist.find(x => x.id == rec.denetimID).name}</td> 
                                <td> {rec.verilesure}
                                </td> 
                                <td> {rec.mappingDate}  </td>
                                <td>  {rec.message} </td>
                                <td> {rec.deliverDate}  </td>
                                {/*<td> {rec.completed == null ? "" : rec.completed}</td>*/}
                                <td>
                                    <div className={"ps-3 pe-3 p-2 text-center fw-bolder " + suretxt[getColorIdx(rec.gecikme, rec)]} style={{ "background-color": surebg[getColorIdx(rec.gecikme, rec)], "border-radius": 5 }}>{rec.gecikme}</div>

                                    

                                </td>
                                <td>
                                    <a href="#" onClick={() => getapp(rec.appID)} className=" text-decoration-none "><i className="fa fa-edit align-middle" style={{ "font-size": "24px" }}></i></a>

                                </td></tr>
                            )}

                        </tbody>
                    </table>
                </div>
            </div >
        </div >
    </div>
    );


}



