import React, { useState, useEffect } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import SablonMappingPanel from './SablonMappingPanel';
import { ajaxFail, isEmpty, showConfirmMessage, showErrorMessage, showSuccessMessage,sendpost,sendget } from '../GlobalState.js';


export default function ReportSetting(props) {

    const [listData, setListData] = useState(null);
    const [useroptions, setOptions] = useState([]);
    const [postdata, setPostData] = useState({});
    
    var options = ["kontrolor", "basvuruSahibi", "basvuruYapan","basvuruTipi", "basvuruYapanIsimSoyisim", "role", "basvuruDurumu",  "mevki",
        "mahalle", "ada", "basvuruTarihi", "id",    "arsaSahibiTel", "arsaSahibiTC", "sokak",
        "no", "arsaSahibi", "idariMahalle", "ucret", "iadeEden", "kontrolBitmeTarihi", "onayVerilmeTarihi", "iadeTarihi",
        "taslakOlusturmaTarihi", "kabulEdilmeTarihi", "surecTamamlanmaTarihi", "eimzaAcilmaTrihi", "vekilName", 
        "vekilTC", "vekilTel", "duzeltmeSayi", "harcOdemeTarihi"];

    options = options.concat(useroptions);
    const colorset = ["#f0f7ff", "#FFFFFF", "#FFFFFF", "#f0f7ff"];

    options = options.sort(function (a, b) {
        if (a == null || b == null)
            return 0;
        return a.toLowerCase().localeCompare(b.toLowerCase());
    });


    var sablonlist = [];

    const multiselectRef = React.createRef();

    useEffect(() => {
        fetchList();

    }, [])

    const removeReport = (recid) => {

        showConfirmMessage(recid + " Numaralı Kaydı Silmek İstedinizden Emin Misiniz?", "", "Evet", "Hayır", () =>
            sendpost("removereport", recid, "myspinner", fetchList, (resp) => {
                showErrorMessage("Rapor Silinemedi");
            }));
    }

    const updatereport = (recid) => {
        sendpost("savesablon", postdata, "myspinner", (resp) => {
            showSuccessMessage(postdata.name + " İsimli Rapor Kaydedilmiştir")
            multiselectRef.current.resetSelectedValues();
            document.getElementById("SablonAdi").value = "";
            document.getElementById("filter").value = "";
            fetchList();
            setPostData({})

        }, (resp) => {
            showErrorMessage("Rapor Guncellenemedi");
        })
    }







    const fetchList = () => {
        sendget("reportlist", "myspinner", (resp) => {
            resp.json().then(value => {
                setListData(value)
            })
            
        }, (resp) => {
            showErrorMessage("Rapor Listesi Alınamadı");
        })
    }



    const updateRecord = (rec, event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        rec[name] = value;
    }


    const renderTable = (tabledata) => {
        var mytable =
            <div className="row ms-2 me-2 pb-3">
                {tabledata.map((apprec, idx) =>
                    <div className={"col-md-6 col-12  "}>
                        <div className="border rounded-3  mt-3 p-0 text-center border-1" style={{ "background-color": colorset[idx % colorset.length] }}>
                            <div className="d-inline float-start">
                                <button onClick={() => removeReport(apprec.name)} className="btn btn-secondary btn-sm  text-decoration-none ">Sil</button>
                            </div>
                            <div className="d-inline ">
                                <button onClick={() => setPostData(apprec)} className="btn btn-link btn-sm text-decoration-none ">{apprec.name}</button>
                            </div>

                        </div>
                    </div>
                )}
            </div>
        return (mytable);
    }



    const Add = () => {
        postdata.name = document.getElementById("SablonAdi").value;
        var ft = document.getElementById("filter").value;
        var baseRow = document.getElementById("baseRow").value;
        var sort = ""//document.getElementById("sort").value;
        
        postdata.sort = sort;
        postdata.baseRow = baseRow;

        if (sort.length > 0 && sort.endsWith(","))
            postdata.sort = sort.substring(0, sort.length - 1);

        postdata.filter = ft;
        if (ft.length > 0 && ft.endsWith(","))
            postdata.filter = ft.substring(0, ft.length - 1);

        if (isEmpty(postdata.name)) {
            showErrorMessage("Ekran ismi boş olamaz");
            return;
        }
        postdata.colNames = document.getElementById("colNames").value 
        if (isEmpty(postdata.colNames)) {
            showErrorMessage("Kolon İsmi Boş Olamaz");
            return;
        }


        sendpost("savereport", postdata, "myspinner", (resp) => {
            showSuccessMessage(postdata.name + " İsimli Rapor Kaydedilmiştir")
           //multiselectRef.current.resetSelectedValues();
            document.getElementById("SablonAdi").value = "";
            document.getElementById("filter").value = "";
            document.getElementById("colNames").selectedIndex = -1;
            document.getElementById("baseRow").selectedIndex = -1;
            fetchList();
            setPostData({})

        }, (resp) => {
            showErrorMessage("Bir Hata Oluştu");
        })
    }


    const AddFilter = () => {
        var andor = document.getElementById("andor").value;
        if (document.getElementById("andor").disabled == true)
            andor = "";
        var oper = document.getElementById("oper").value;
        var filtervalue = document.getElementById("filtervalue").value;
        var filterColSelect = document.getElementById("filterColSelect").value;
        var myfilter = andor + " " + filterColSelect + " " + oper + " " + filtervalue + " ";
        document.getElementById("filter").value += myfilter;
        document.getElementById("andor").disabled = false;


    }
    const ClearFilter = () => {
        document.getElementById("filter").value = "";
        document.getElementById("andor").disabled = true;
    }
    const clearSort = () => {
        document.getElementById("sort").value = "";
    }

    const AddArtan = () => {
        var filterColSelect = document.getElementById("siraColSelect").value;
        document.getElementById("sort").value = filterColSelect;

    }
    const AddAzalan = () => {
        var filterColSelect = document.getElementById("siraColSelect").value;
        document.getElementById("sort").value =  "-" + filterColSelect;
    }





    const onSelect = (selectedList, selectedItem) => {
        var clist = selectedList.map(rec => "<option value='" + rec + "'>" + rec + "</option>");
        // document.getElementById("filterColSelect").innerHTML = clist;
        document.getElementById("filterColSelect").selectedIndex = -1;
        document.getElementById("siraColSelect").innerHTML = clist;

    }

    const onRemove = (selectedList, removedItem) => {
        var clist = selectedList.map(rec => "<option value='" + rec + "'>" + rec + "</option>");
        // document.getElementById("filterColSelect").innerHTML = clist;
        document.getElementById("filterColSelect").selectedIndex = -1;
        document.getElementById("siraColSelect").innerHTML = clist;
    }

    return (
        <div className="p-2 rounded-3" style={{ "background-color": "#EDEDF7", "min-height": "100vh" }}>
            <div className="row " >

                <div className="col-12 col-md-6 " >


                </div>
                {/*<div className="col-12 col-md-6 text-end  pt-1" >*/}
                {/*    <button onClick={() => savefilename(rec)} className="btn btn-primary btn-sm text-decoration-none me-2">Kaydet</button>*/}

                {/*    <button onClick={() => window.changeComponent(<FileSettingList />)} className="btn btn-secondary btn-sm text-decoration-none">Vazgeç</button>*/}
                {/*</div>*/}
            </div>

            <div className="row mt-3 p-3 d-flex flex-wrap pe-4" key={postdata.name}>
                <div className="col-12 col-md-6 d-flex flex-column">
                    <div className=" bg-white rounded-3" >
                        <div className="p-3 fw-bold border-bottom mb-3" >
                            Yeni Rapor Oluştur
                        </div>
                        <div className="bg-light m-4  p-3 rounded-3 textvmuted fw-bold align-middle"  >
                            <span className="fa fa-info pe-3" style={{ color: "lightblue", fontSize: "26px" }} ></span>
                            Bu alanda, İsatatistiklerde kullanılmak üzere raporla oluşturabilirsiniz.
                        </div>


                        {isEmpty(postdata.name) != true && < div className="row ms-2 me-2   mb-4 pb-4 border-bottom">
                            <div className="col-12 align-middle   text-center " >
                                <span className="text-success fw-bold"> {postdata.name} </span> Raporu düzenliyorsunuz
                                <button onClick={() => {
                                    setPostData({})


                                }} className="ms-2 btn btn-sm btn-secondary text-decoration-none">Vazgeç</button>
                            </div>
                        </div>}


                        <div className="m-4   pt-3  border-top">
                            Rapor Adı <span className="text-danger">*</span>
                            <input type="text" className="form-control form-control-sm " id="SablonAdi" placeholder="" name="Name" defaultValue={postdata.name} />
                            <span className="textvmuted small">Oluşturacağınız rapora bir isim veriniz</span>
                        </div>
                        <div className="m-4  ">
                            Kolon Değeri <span className="text-danger">*</span>
                            <select className="form-select form-select-sm " id="colNames" name="colNames">
                                <option value="">  </option>
                                {options.map(c => <option value={c} selected={postdata.colNames == c}> {c} </option>)}

                            </select>

                            {/*<Multiselect className=""*/}
                            {/*    options={options}*/}
                            {/*    emptyRecordMsg="Başka Başvuru Tipi Yok"*/}
                            {/*    ref={multiselectRef}*/}
                            {/*    isObject={false}*/}
                            {/*    onSelect={onSelect} // Function will trigger on select event*/}
                            {/*    onRemove={onRemove} // Function will trigger on remove event*/}
                            {/*    selectedValues={postdata.colNames != null ? postdata.colNames.split(",") : []}*/}
                            {/*    placeholder=""*/}
                            {/*/>*/}
                            <span className="textvmuted small">Ekranda hangi kolonların(bilgilerin) görüntüleneceğini seçin</span>
                        </div>


                        <div className="m-4  ">
                            Satır Değerleri <span className="text-danger">*</span>
                            <select className="form-select form-select-sm " id="baseRow" name="baseRow">
                                <option value="">  </option>
                                {options.map(c => <option value={c} selected={postdata.baseRow == c}> {c} </option>)}
                                
                            </select>

                            <span className="textvmuted small">Ekranda hangi kolonların(bilgilerin) görüntüleneceğini seçin</span>
                        </div>





                        <div className="row mt-5 m-3 mt-1 border-top ">
                            <div className="col-md-11 col-12">
                                Uygulanacak Filtre
                                <input type="text" className="form-control form-control-sm" id="filter" placeholder="Filtre" name="filter" defaultValue={postdata.filter} />

                            </div>
                            <div className="col-md-1 col-12 text-end">
                                <br />
                                <button onClick={ClearFilter} className="ms-1 btn btn-sm btn-secondary">Sil</button>

                            </div>

                        </div>



                        <div className="row m-3 mt-1">
                            <div className="col-md-4 col-12 border-top mt-4">
                            </div>
                            <div className="col-md-4 col-12">
                                Filtre Ekleme Yöntemi
                                <select className="form-select form-select-sm " id="andor" name="andor" placeholder="Filtre" disabled={isEmpty(postdata.filter)}>
                                    <option value="AND"> AND </option>
                                    <option value="OR"> OR </option>
                                </select>

                            </div>
                            <div className="col-md-4 col-12 border-top mt-4">
                            </div>

                        </div>



                        <div className="m-3">
                            <div className="row">
                                <div className="col-md-4 col-12">
                                    Filtrelenecek Alan
                                    <select className="form-select form-select-sm " id="filterColSelect" name="filterColSelect">
                                        {options.map(rec => <option value={rec}> {rec} </option>)}
                                    </select>
                                </div>
                                <div className="col-md-2 col-12 ps-0">
                                    Operator
                                    <select className="form-select form-select-sm " id="oper" name="oper" placeholder="Filtre">
                                        <option value="=">{"="} </option>
                                        <option value="!="> {"!="} </option>
                                        <option value=">"> {">"} </option>
                                        <option value="<"> {"<"} </option>
                                        <option value="LIKE"> {"LIKE"} </option>
                                        <option value="NOTLIKE"> {"NOTLIKE"} </option>
                                        <option value="ISNULL"> {"ISNULL"} </option>
                                        <option value="ISNOTNULL"> {"ISNOTNULL"} </option>

                                    </select>
                                </div>
                                <div className="col-md-4 col-12 ps-0">
                                    Filtre Değeri
                                    <input type="text" className="form-control form-control-sm" id="filtervalue" placeholder="Filtre Değeri" name="filter" pattern="[a-zA-Z0-9ÜüŞşÇçÖöĞğİı ]+" />

                                </div>
                                <div className="col-md-2 p-0 col-12 "><br />
                                    <button onClick={AddFilter} className="btn btn-sm btn-primary">Ekle</button>

                                </div>
                            </div>
                        </div>



                        {/*<div className="m-3 mt-5">*/}
                        {/*    <div className="row ">*/}
                        {/*        <div className="col-md-6 col-12">*/}
                        {/*            Sıralamada Uygulanacak  Alan*/}
                        {/*            <select className="form-select form-select-sm" id="siraColSelect" name="siraColSelect" >*/}
                        {/*                {isEmpty(postdata.colNames) == false && postdata.colNames.split(",").map(x => <option value={x} >{x}</option>)}*/}
                        {/*            </select>*/}
                        {/*        </div>*/}
                        {/*        <div className="col-md-6 col-12 text-end"><br />*/}
                        {/*            <button onClick={AddArtan} className="btn btn-sm btn-primary">Artan</button>*/}
                        {/*            <button onClick={AddAzalan} className="ms-1 btn btn-sm btn-info">Azalan</button>*/}
                        {/*            <button onClick={clearSort} className="ms-1 btn btn-sm btn-secondary">Sil</button>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*    <div className=" mt-1">*/}
                        {/*        Uygulanacak Sıralama*/}
                        {/*        <input type="text" className="form-control form-control-sm" id="sort" placeholder="Sıralama" name="sort" disabled defaultValue={postdata.sort} />*/}
                        {/*    </div>*/}
                        {/*</div>*/}


                        <div className="row mt-3">
                            <div className="col-12 text-center pb-2">
                                <button onClick={Add} className="btn  btn-primary m-1">Raporu Kaydet</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/*----------------------------------------------------------------------------------------------------*/}
                <div className="col-12 col-md-6 d-flex flex-column bg-white rounded-3">
                    <div className=" " >
                        <div className="p-3 fw-bold border-bottom" >
                            Oluşturulan Raporlar
                        </div>
                        <div className="bg-light m-3  p-3 mb-2 rounded-3 textvmuted fw-bold align-middle"  >
                            <span className="fa fa-info pe-3" style={{ color: "lightblue", fontSize: "26px" }} ></span>
                            Oluşturduğunuz ekranları silebilir veya özelliklerini değiştirebilirsiniz. Ekran isminin üzerine tıkladıktan sonra “Yeni Ekran Oluşturma” alanından özelliklerini düzenleyebilirsiniz.                       </div>
                        <div className="">
                            {listData != null ? renderTable(listData) : ""}
                        </div>

                    </div>
                </div>
                {/*----------------------------------------------------------------------------------------------------*/}
            </div >


        </div >



    );


}
