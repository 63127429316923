import axios from "axios";


import React, { useState, useEffect } from 'react';
import { useGlobalState, isEmpty, showErrorMessage, showSuccessMessage, showConfirmMessage, setVisible } from '../GlobalState.js';
import background from "../Dashboard/loading.gif";
import Application from "./Application.js";
import ApplicationList from "./ApplicationList.js";


export default function EimzaOnaylarimMobil(props) {

    var eimza = props.eimza;
    var imzalayan = props.imzalayan;
    var page = 0;
    const colors = { grey: "#f3f2f7", green: "#106485" }

    // primeAPI'de kullanılacak tekil operasyon numarası
    var operationId = "";
    // imza atarken kullanılacak telefon numarası. 5334440099 şeklinde olmalıdır
    var phoneNumber = "";
    // kullanıcıya gösterilen mobil imza parmak izi değeri
    var fingerPrint = "";
    // işlemin başarıyla tamamlanıp tamamlanmadığını gösterir
    var isSuccess = false;

    // mobil imza için kullanılacak operatörler. Id değerinde yazan ifade API'ye gönderilir. Bu ifadeler değiştirilmemelidir.
    const operators = [
        { id: "TURKCELL", name: "Turkcell" },
        { id: "VODAFONE", name: "Vodafone" },
        { id: "AVEA", name: "Türk Telekom" },
    ];


    var selectedOperator = operators[0];

    // imza atarken kullanılacak e-imza tür
    const signatureTypes = [
        { id: "pades", title: "Pades" },
        { id: "cades", title: "Cades" },
    ];

    // kullanıcının seçtiği imza türü
    var selectedSignatureType = signatureTypes[0];


    function getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    var saved_eimza = getCookie("mobile_eimza")
    if (isEmpty(saved_eimza) == false) {
        phoneNumber = saved_eimza.split("$")[1];
        var operid = saved_eimza.split("$")[0];
        selectedOperator = operators.find(x => x.id == operid);
    }



    // https://github.com/uuidjs/uuid kullanılmak istenmediğinde onun yerine aşağıdaki fonksiyon kullanılabilir
    function uuidv4(): string {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c: string) => {
            const randomValues = new Uint8Array(1);
            crypto.getRandomValues(randomValues);
            const byte = randomValues[0];
            return (c ^ (byte & (15 >> (parseInt(c, 10) / 4)))).toString(16);
        });
    }



    function MobileSign() {
        // Parmak izi değerinin alınması için mobil imza işlemi bitmeden operationId'nin bilinmesi gerekmektedir. Bu nedenle operationId client side'da oluşturulmuştur.
        operationId = uuidv4();
        fingerPrint = "";
        var phoneNumber = document.getElementById("phoneNumber").value;
        var selectedOperator = document.getElementById("operatorSelect").value;
        if (phoneNumber.startsWith("0") == false) {
            phoneNumber = "0" + phoneNumber;
        }
        var checkbox = document.getElementById("benihatirla");
        if (checkbox.checked == true) {
            document.cookie = "mobile_eimza="+selectedOperator + "$" + phoneNumber;
        }
        const mobileSignRequest = {
            operationId: operationId,
            phoneNumber: phoneNumber,
            operator: selectedOperator,
            signatureType: selectedSignatureType.id,
            citizenshipNo: imzalayan.tc,
            appid: props.eimza.appId,
            eimzaId: props.eimza.id
        };
        document.getElementById("imzaspin").hidden = false;
        props.showMessage("İmzalama işlemi devam ediyor");
        // mobil imza işlemi yapılır
        axios
            .post("/MobileSign", mobileSignRequest)
            .then((mobileSignResponse) => {
                document.getElementById("imzaspin").hidden = true;
                console.log("Sizin sunucu katmanına MobileSign isteği gönderildi."+ mobileSignResponse);
                var mobileSignResult = mobileSignResponse.data;
                isSuccess = mobileSignResult.isSuccess;
                if (mobileSignResult.error) { 
                    props.showErrorMessage("İmza işlemi tamamlanamadı. " + mobileSignResult.error);
                } else {
                    document.getElementById("imzaKapat").hidden = false;
                    document.getElementById("imzalabutton").hidden = true;
                    props.showSuccessMessage("İmzalama Başarılı", "Atmış olduğunuz e-imza 5070 sayılı e-imza kanunu kapsamında ıslak imza ile eşdeğerdir");
                }
            })
            .catch((error) => {
               
                props.showErrorMessage("MobileSign isteği gönderilemedi. " + error);
                document.getElementById("imzaspin").hidden = true;
            });
        // mobil imza işlemi sürerken işleme ilişkin parmak izi değeri alınır
        axios
            .post("/GetFingerPrint", { operationId: operationId })
            .then((getFingerResponse) => {
                console.log("getFingerResponse", getFingerResponse);
                fingerPrint = getFingerResponse.data.fingerPrint;
            })
            .catch((error) => {
                console.log("Sizin sunucu katmanına GetFingerPrint isteği gönderilemedi. Mesaj: " + error + " Detaylar için console'a bakınız.");
                console.log("Sizin sunucu katmanına GetFingerPrint isteği gönderilemedi.", error);
            });
    }

    function DownloadFile() {
        axios
            .get("/Onaylarim/DownloadSignedFileFromOnaylarimApi?operationId=" + operationId, { responseType: "blob" })
            .then((e) => {
                if (e.data.error) {
                    console.log("Hata oluştu. " + e.data.error);
                } else {
                    let filename = "dosya.pdf";
                    const contentDisposition = e.headers["Content-Disposition"];
                    if (contentDisposition) {
                        const match = contentDisposition.match(/filename[^;\n]*=(UTF-\d['"]*)?((['"]).*?[.]$\2|[^;\n]*)?/gi);
                        if (match && match[1]) {
                            const a1 = match[1].split("''")[1];
                            if (a1) {
                                filename = decodeURI(a1);
                            }
                        }
                    }
                    const fileURL = window.URL.createObjectURL(new Blob([e.data]));
                    const fileLink = document.createElement("a");
                    fileLink.href = fileURL;
                    fileLink.setAttribute("download", filename);
                    document.body.appendChild(fileLink);
                    fileLink.click();
                }
            })
            .catch((error: AxiosError) => {
                if (error.response) {
                    console.log("Hata oluştu. " + error.response.data);
                } else {
                    console.log("Hata oluştu. " + error.code);
                }
            })
            .catch((error: Error | AxiosError) => {
                console.log("Hata oluştu. " + error.message);
            });
    }


    return <div className="row">
        <div className="col-12">
            <div class="text-sm text-gray-700 mt-8  mt-2">
                Operatör Seçiniz
            </div>
            <div class="flex flex-col mt-1">
                <select className="form-select form-select-sm  shadow-none" id="operatorSelect" defaultValue={selectedOperator.id}>
                    {operators.map(opr => <option value={opr.id}>{opr.name}</option>)}
                </select>
                <div class="mt-2 max-w-sm">
                    <input type="text" name="phoneNumber" id="phoneNumber"
                        class="form-control form-control-sm  shadow-none" placeholder="(05XX)XXXXXXX" maxLength={11} defaultValue={phoneNumber} />
                </div>
                <div class="mt-2 max-w-sm text-secondary">
                    TC: {imzalayan.tc}
                </div>

                <div class="mt-2 max-w-sm">
                    <input class="form-check-input" type="checkbox" id="benihatirla" onClick={() => {
                        var checkbox = document.getElementById("benihatirla");
                        if (checkbox.checked != true) {
                            document.cookie = "mobile_eimza=; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
                        }
                    }
                    } defaultChecked={!isEmpty(phoneNumber)} /> Beni Hatırla
                </div>

                <div className="mt-2">
                    <button onClick={MobileSign} type="button" className="btn btn-sm btn-primary" id="imzalabutton">
                        İmzala
                    </button>
                    <button onClick={() => {
                        props.changeComponent(<Application key={props.appData.id} targetEimza={eimza} appData={props.appData} changeComponent={props.changeComponent} backButton={<ApplicationList changeComponent={props.changeComponent} />} />)
                    }}
                        type="button" className="btn btn-sm btn-warning border  ms-2" > İptal </button>
                </div >
            </div >
        </div >
    </div >


}