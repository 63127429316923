import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/js/bootstrap.min.js'
import { ajaxFail, fvisible, fdisabled, fzorunlu, showSuccessMessage, showErrorMessage } from '../GlobalState.js';
import * as UserPermConstants from '../User/UserPermConstants';
import { useGlobalState, capitalizeWords } from '../GlobalState.js';
import Apply2 from "./Apply2.js";
import Apply4 from "./Apply4.js";
import ApplicationAuthorPanel from "./ApplicationAuthorPanel.js";
import axios from "axios";
import bootstrap from 'bootstrap/dist/js/bootstrap.min.js'
import Apply1 from './Apply1.js';

export default function Apply3(props) {

    const [sabitsorulistData, setsabitsorulistData] = useState(null);

    let prevVal = "";
    const [role, setRole] = useGlobalState('role');

    const zorunloBosBirakma = role.indexOf(UserPermConstants.Yeni_Basvuruda_Zorunlu_Alanlari_Bos_Birakabilme) == -1;

    const [userData, setUserdata] = useGlobalState('userData');
    var applicationdata = props.applicationdata;

    if (applicationdata.arsaSahibi == null)
        applicationdata.arsaSahibi = userData.name + " " + userData.family;
    if (applicationdata.arsaSahibiTC == null)
        applicationdata.arsaSahibiTC = userData.tc;
    if (applicationdata.arsaSahibiTel == null)
        applicationdata.arsaSahibiTel = userData.tel;


    useEffect(() => {
        initModal();
        fetchSabitSoruList();
        var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
        var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
            return new bootstrap.Popover(popoverTriggerEl)
        })
    }, [])

    const initModal = () => {
        var locModal = document.getElementById('myModal');
        var btnclosedown = document.getElementById('downclosemodal');
        if (btnclosedown != null)
            btnclosedown.addEventListener('click', (e) => {
                locModal.style.display = "none";
                locModal.classList.add("hide");
            });
    }

    const SendFiles = (event) => {
        document.getElementById("fileuploadsp").hidden = false;
        var selectedFile = { file: event.target.files[0] }
        var formData = new FormData();
        formData.append("file", selectedFile.file);
        formData.append("caption", selectedFile.file.name);
        formData.append("appid", applicationdata.id);
        axios.post(
            "submitbasvuruvekaletfile",
            formData,
            {
                headers: {
                    "Content-type": "multipart/form-data",
                },
            }
        ).then(res => {
            document.getElementById("fileuploadsp").hidden = true;
            document.getElementById("fileuploadsp").hidden = true;
            applicationdata.vekaletDosyaname = selectedFile.file.name
            document.getElementById("vekaletdosyaname").innerHTML = '<span class="ms-3" id="vekaletdosyaname"><a class="text-decoration-none" href="/getbasvuruvekaletfile?appid=' + applicationdata.id + '" download="' + applicationdata.vekaletDosyaname + '">' + applicationdata.vekaletDosyaname + ' </a></span>';

            var locModal = document.getElementById('myModal');
            locModal.style.display = "none";
            locModal.classList.add("hide");

        })
            .catch(err => { showErrorMessage(err); })
    }

    const showalert = () => {
        if (fvisible("arsaSahibiTel", sabitsorulistData, applicationdata))
            showSuccessMessage("Bu bölümde vekalet sahibinin telefon numarası DEĞİL, taşınmaz sahibinin kendi numarası olmak zorundadır. Aksi halde başvurunuz kabul edilmeyecektir.")
    }

    const fetchSabitSoruList = () => {
        fetch("getsabitsorulist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                response.json().then(value => {
                    setsabitsorulistData(value);

                })
            }
        });
    }


    const onSubmit = () => {

        if (applicationdata.vekaletenBasvuru == true && applicationdata.vekaletDosyaname == null) {
            showErrorMessage("Vekalet dosyanızı yukleyiniz.");
            return;
        }

        if (applicationdata.vekaletenBasvuru == true) {
            applicationdata.vekilName = capitalizeWords(userData.name + " " + userData.family);
            applicationdata.vekilTC = userData.tc;
            applicationdata.vekilTel = userData.tel;
        }


        document.getElementById("buttonSpinner").hidden = false;

        applicationdata.arsaSahibi = capitalizeWords(applicationdata.arsaSahibi);
        fetch("saveapplication", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(applicationdata)
        }).then(async response => {
            document.getElementById("buttonSpinner").hidden = true;
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(async a => {
                    props.changeComponent(<Apply4 applicationdata={a} changeComponent={props.changeComponent} />)
                });
            }
        });
    }



    const handleInputChange = (event) => {


        var target = event.target;
        var value = target.type === 'checkbox' ? target.checked : target.value;
        if (target.name == "arsaSahibiTel" && value.length > 0 && value.startsWith("5") == false) {
            showErrorMessage("Telefon Numarası 5 ile başlamalı ve sadece 10 rakamdan oluşmalıdır.");
            target.value = "5";
            value = "5"
        }
        applicationdata[target.name] = value;

        if (target.name == "vekaletenBasvuru") {
            document.getElementById("arsasahibiBilgileri").disabled = !value;
            if (value != true) {
                applicationdata.arsaSahibi = userData.name + " " + userData.family;
                applicationdata.arsaSahibiTC = userData.tc;
                applicationdata.arsaSahibiTel = userData.tel;
            }
            else {
                applicationdata.arsaSahibi = "";
                applicationdata.arsaSahibiTC = "";
                applicationdata.arsaSahibiTel = "";
            }


            document.getElementById("arsaSahibi").value = applicationdata.arsaSahibi;
            document.getElementById("arsaSahibiTel").value = applicationdata.arsaSahibiTel;
            document.getElementById("arsaSahibiTC").value = applicationdata.arsaSahibiTC;

        }



    }



    setTimeout(showalert, 700);

    return <div  >

        <div className="row" >
            <div className="  col-md-2 col-12 " >
                <div className="  w-75 fw-bold border-bottom border-3 " >
                    1. Başvuru Tipi

                </div>
            </div>
            <div className=" col-md-2 col-12  " >
                <div className="  w-75 fw-bold border-bottom border-3 " >
                    2. Konum
                </div>
            </div>
            <div className=" col-md-2 col-12 text-primary" >
                <div className="  w-75 fw-bold border-bottom border-3 " >
                    3. Müellifler
                </div>
            </div>
            <div className=" col-md-2 col-12    " >
                <div className="  w-75 fw-bold border-bottom border-3 " >
                    4. Diğer Sorular
                </div>
            </div>
            <div className=" col-md-2 col-12    " >
                <div className="  w-75 fw-bold border-bottom border-3 " >
                    5. Dosyalar
                </div>
            </div>
            <div className=" col-md-2 col-12     " >
                <div className="  w-75 fw-bold border-bottom border-3 " >
                    6. Onay
                </div>
            </div>
        </div>

        <div className="row m-3" >
            <div className="col-12" >
                <div className="  align-items-center justify-content-center p-1" >
                    {fvisible("arsaSahibiTel", sabitsorulistData, applicationdata) && <div className="row mb-3" >
                        <div className="col-12">
                            <span className="text-danger">
                                Bu bölümde vekalet sahibinin telefon numarası DEĞİL, taşınmaz sahibinin kendi numarası olmak zorundadır. Aksi halde başvurunuz kabul edilmeyecektir.
                            </span>
                        </div>
                    </div>}

                    <div className="row mb-2" >
                        {fvisible("arsaSahibi", sabitsorulistData, applicationdata) && <div className="col-12 "  >
                            <div class="form-check">
                                <input className="form-check-input" type="checkbox" id="vekaletenBasvuru" name="vekaletenBasvuru" onChange={handleInputChange} defaultChecked={applicationdata.vekaletenBasvuru == true} />
                                <label className="form-check-label" for="flexCheckChecked">
                                    Başvuruyu vekaleten başkası adına yapıyorum
                                </label>
                            </div>
                        </div>}
                    </div>

                    <fieldset disabled={applicationdata.vekaletenBasvuru != true} id="arsasahibiBilgileri">
                        <div className="row " disabled>
                            {window.selectedAppType.tasinamazSahibiAktif == true && <div className="col-md-4 col-12 ">
                                <label htmlFor="arsaSahibi" className="form-label">Taşınmaz Sahibi {fzorunlu("arsaSahibi", sabitsorulistData, applicationdata)}</label>
                                <input type="text" className="form-control form-control-sm" required={window.selectedAppType.tasinamazSahibiZorunlu == true && zorunloBosBirakma} id="arsaSahibi" defaultValue={applicationdata.arsaSahibi} placeholder="" name="arsaSahibi" onChange={handleInputChange} disabled={fdisabled("arsaSahibi", sabitsorulistData, applicationdata)} />
                            </div>}
                            {window.selectedAppType.tasinamazSahibiAktif == true && <div className="col-md-4 col-12 ">
                                <label htmlFor="arsaSahibiTC" className="form-label">Taşınmaz Sahibi TC veya Vergi Kimlik Numarası {window.selectedAppType.tasinamazSahibiZorunlu == true ? '*' : ''}</label>
                                <input type="text" pattern="[0-9]{1,20}" maxLength="11" className="form-control form-control-sm" required={window.selectedAppType.tasinamazSahibiZorunlu == true && zorunloBosBirakma} id="arsaSahibiTC" defaultValue={applicationdata.arsaSahibiTC} placeholder="" name="arsaSahibiTC" onChange={handleInputChange} disabled={fdisabled("arsaSahibiTC", sabitsorulistData, applicationdata)} />
                            </div>}
                            {window.selectedAppType.tasinamazSahibiAktif == true && <div className="col-md-4 col-12 ">
                                <label htmlFor="arsaSahibiTel" className="form-label">Taşınmaz Sahibi Telefon Numarası {fzorunlu("arsaSahibiTel", sabitsorulistData, applicationdata)}</label>
                                <input type="text" pattern="[5-5]{1}[0-9]{2}[0-9]{7}" maxLength="10" placeholder="5__ ___ ____" required={window.selectedAppType.tasinamazSahibiZorunlu == true && zorunloBosBirakma} className="form-control form-control-sm" id="arsaSahibiTel" defaultValue={applicationdata.arsaSahibiTel} name="arsaSahibiTel" onChange={handleInputChange} disabled={fdisabled("arsaSahibiTel", sabitsorulistData, applicationdata)} />
                            </div>}
                        </div>
                        <div className="row mt-3" disabled>
                            <div className="col-12 ">
                                <button onClick={() => {
                                    document.getElementById('myModal').style.display = "block";
                                    document.getElementById('myModal').classList.remove("hide");
                                }} className="btn btn-link btn-sm text-decoration-none m-0 p-0">
                                    Vekalet Dosyası Ekle</button>
                                <button className="btn btn-sm btn-secondary ms-2  text-decoration-none fw-bold" id="mypopover" data-bs-trigger="focus" data-bs-toggle="popover" title="AÇIKLAMA" data-bs-content="Taşınmaz sahibi adına başvuru yapabileceğinizi gösteren belgedir. Bu belge, noter onaylı vekaletname olabileceği gibi Yapı Denetim Başvuruları ve İmar Uygulamalarına yönelik  başvurularda taşınmaz sahibi ile aranızda yaptığınız sözleşme de olabilir.">?</button>

                                <span className="ms-3" id="vekaletdosyaname">
                                    {applicationdata.vekaletDosyaname != null && <a className="text-decoration-none" href={"/getbasvuruvekaletfile?appid=" + applicationdata.id} id="vekaletdosyaname" download={applicationdata.vekaletDosyaname}>{applicationdata.vekaletDosyaname} </a>}
                                </span>
                            </div>
                        </div>
                    </fieldset>
                    <div className="row mt-3 mb-2 pb-4 border-bottom" >



                    </div>
                    {window.selectedAppType.muellifAktif == true && <ApplicationAuthorPanel appData={applicationdata} />}

                    <div className="row mt-3 border-top pt-2" >
                        <div className="col-6  text-start">
                            <button type="button" className="btn btn-sm btn-primary mb-2 " onClick={() => {
                                  if (window.selectedAppType.mevkiAktif == true || window.selectedAppType.mahalleAktif == true || window.selectedAppType.adaAktif == true
                                    || window.selectedAppType.sokakAktif == true || window.selectedAppType.noAktif == true)
                                    props.changeComponent(<Apply2 applicationdata={applicationdata} changeComponent={props.changeComponent} />)
                                  else
                                      props.changeComponent(<Apply1 applicationdata={applicationdata} changeComponent={props.changeComponent} />)

                             }}>Geri Dön</button>
                        </div>
                        <div className="col-6  text-end">
                            <button type="button" name="actionSave" onClick={() => {
                                if ((document.getElementById("arsaSahibiTel") != null && document.getElementById("arsaSahibiTel").disabled != true && fzorunlu("arsaSahibiTel", sabitsorulistData, applicationdata) == "*" && document.getElementById("arsaSahibiTel").value.length == 0)
                                    || (document.getElementById("arsaSahibi") != null && document.getElementById("arsaSahibi").disabled != true && fzorunlu("arsaSahibi", sabitsorulistData, applicationdata) == "*" && document.getElementById("arsaSahibi").value.length == 0)
                                    || (document.getElementById("arsaSahibiTC") != null && document.getElementById("arsaSahibiTC").disabled != true && fzorunlu("arsaSahibiTC", sabitsorulistData, applicationdata) == "*" && document.getElementById("arsaSahibiTC").value.length == 0)) {
                                    showErrorMessage("Taşınmaz sahibi bilgileri eksik");
                                    return;
                                }
                                if (document.getElementById("arsaSahibiTel") != null && document.getElementById('arsaSahibiTel').checkValidity() == false) {
                                    showErrorMessage("Taşınmaz sahibi Telfon Numarası Hatalı");
                                    return;
                                }
                                if (document.getElementById("arsaSahibiTC") != null && document.getElementById('arsaSahibiTC').checkValidity() == false) {
                                    showErrorMessage("Taşınmaz sahibi TC Numarası Hatalı");
                                    return;
                                }

                                onSubmit();
                            }} className="btn btn-sm btn-primary ">
                                Kaydet ve Devam Et
                                <span class="ms-1 spinner-border spinner-border-sm" id="buttonSpinner" hidden></span>
                            </button>
                        </div>

                    </div>

                </div>
            </div>
        </div >





        <div className="modal" id="myModal" >
            <div class="modal-dialog modal  modal-dialog-centered ">
                <div class="modal-content" >
                    <div class="modal-body" id="mymodalbody" >

                        <div className="row">
                            <div className="col-10">

                                <label for="formFile" class="form-label">Vekalet Dosyası Yukle</label>


                                <br />
                                <input class="form-control form-control-sm mt-2" type="file" accept="*.pdf" id="formFile" onChange={(e) => SendFiles(e)} />
                            </div>

                            <div className="col-2 text-end">
                                <div class="spinner-border  spinner-border-sm" hidden id="fileuploadsp" ></div>
                            </div>


                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" id="downclosemodal" data-bs-dismiss="modal">Kapat</button>
                    </div>
                </div>
            </div>
        </div>



    </div >

}



