import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'
import SurecSta from './SurecSta.js';
import PersonelPerfSta from './PersonelPerfSta.js';
import AppMonthStatistic from './AppMonthStatistic.js';
import BasvuruTamamlamaSta from './BasvuruTamamlamaSta.js';
import BasvuruAdetStat from './TmamlanmaStat.js';
import TahsilEdilenHarcStat from './TahsilEdilenHarcStat.js';
import * as UserPermConstants from '../User/UserPermConstants';
import { useGlobalState, sendget, sendpost } from '../GlobalState.js';
import Report from './Report.js';

export default function StatisticPanel(props) {
    const [activePanel, setActivePanel] = useState(<SurecSta />);
    const [role, setRole] = useGlobalState('role');
    const [reports, setReports] = useState([]);

    const makeBold = (e) => {

        var btns = document.getElementById("buttonsbox").querySelectorAll(".btn");
        btns.forEach(x => { if (x.classList.contains("btn-link") == false) { x.classList.remove("btn-primary"); x.classList.add("text-muted") } })
        if (e == null) return;
        var b = e.target;
        b.classList.remove("text-muted")
        b.classList.add("btn-primary")
    }

    useEffect(() => {
        sendget("reportlist", "myspinner", (resp) => {
            resp.json().then(value => {
                setReports(value)
            })
        })
    }, [])

    return <div className="row p-1 pb-5 pt-2 d-flex" style={{ "background-color": "#ebebeb", "height": "100%" }} >

        <div className="col-md-2 col-12 flex-grow-1" id="buttonsbox">
            <div className="bg-white rounded-3 p-2">
                {role.indexOf(UserPermConstants.Anlik_Istatistikleri_Gorme) > -1 && <div className='row'>
                    <div className="col-12 ps-2">
                        <button type="button" className="text-start btn fw-bold  btn-sm  text-decoration-none " onClick={(e) => { makeBold(e); setActivePanel(<SurecSta />) }}>
                            Anlık Süreç Bilgileri
                        </button>
                    </div>
                </div>}
                {role.indexOf(UserPermConstants.Proje_Istatistiklerini_Gorme) > -1 && <div className='row mt-4'>
                    <div className="col-12 ps-2">
                        <button type="button" className="text-start btn fw-bold  btn-sm  text-decoration-none " onClick={(e) => { makeBold(e); setActivePanel(<BasvuruAdetStat />) }}>
                            Tamamlanma İstatistikleri
                        </button>
                    </div>
                </div>}
                {role.indexOf(UserPermConstants.Kontrolor_Istatistiklerini_Gorme) > -1 && <div className='row mt-4'>
                    <div className="col-12 ps-2">
                        <button type="button" className="text-start btn fw-bold  btn-sm  text-decoration-none " onClick={(e) => { makeBold(e); setActivePanel(<PersonelPerfSta />) }}>
                            Personel Performansı
                        </button>
                    </div>
                </div>}


                {role.indexOf(UserPermConstants.Zamansal_Istatistikleri_Gorme) > -1 && <div className='row mt-4'>
                    <div className="col-12 ps-2">
                        <button type="button" className="btn fw-bold  btn-sm  text-decoration-none " onClick={(e) => { makeBold(e); setActivePanel(<AppMonthStatistic />) }}>
                            Aylık Başvurular
                        </button>
                    </div>
                </div>}

                {/*<div className='row mt-4'>*/}
                {/*    <div className="col-12 ps-2">*/}
                {/*        <button type="button" className="btn fw-bold btn-link btn-sm text-muted text-decoration-none p-0" onClick={() => { setActivePanel(<TahsilEdilenHarcStat />) }}>*/}
                {/*            Tahsil Edilen Harçlar*/}
                {/*        </button>*/}
                {/*    </div>*/}
                {/*</div>*/}




                {reports.map(r => <div className='row mt-4 '>
                    <div className="col-12 ps-2">
                        <button type="button" className="btn fw-bold btn-sm  text-decoration-none " onClick={(e) => {
                            makeBold(e);
                            sendpost("getrport",r.id, "myspinner", (resp) => {
                                resp.json().then(value => {
                                    setActivePanel(<Report data={JSON.parse(value)} report={r} />)
                                })
                            })
                            
                        }}>
                            {r.name}
                        </button>
                    </div>
                </div>)}

            </div>
        </div>
        <div className="col-md-10 col-12 rounded-3 border-1" >

            <div className="bg-white rounded-3 p-3 me-1 align-items-center justify-content-center border" style={{ "height": "100%" }} >
                {activePanel}
            </div>
        </div>
    </div>

}


