import React, { useState, useEffect } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import { ajaxFail, showConfirmMessage, showSuccessMessage } from '../GlobalState.js';

export default function AppGroupSetting(props) {
    var postdata = {};
    const [listData, setListData] = useState(null);
    const [options, setSurecData] = useState([]);

    const multiselectRef = React.createRef();

    const updateRecord = (rec, event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        rec[name] = value;
    }

    const removeRecord = (id) => {
        showConfirmMessage(id + " Numaralı Kaydı Silmek İstedinizden Emin Misiniz?", "", "Evet", "Hayır", () =>
            fetch("removeappgroup", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(id)
            }).then(async response => {
                if (!response.ok) {
                    ajaxFail(response);
                }
                else {
                    response.json().then(value => {
                        setListData(value);
                    })
                }
            }));
    }



    const submitUpdateRecord = (rec) => {

        fetch("updateappgroup", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(rec)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                showSuccessMessage("Güncelleme Başarılı")
            }
        });
    }

    const fetchList = () => {

        fetch("getappgrouplist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    setListData(value);
                })
            }
        });
    }

    const fetchsureclist = () => {

        fetch("sureclist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    setSurecData(value);
                })
            }
        });
    }

    const Add = () => {
        fetch("addappgroup", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postdata)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                document.getElementById("name").value = "";
                fetchList();

            }
        });

    }

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        postdata[target.name] = value;
    }


    const renderTable = (tabledata) => {

        var mytable = <div className="rounded-3 mb-2  ps-2">



            {tabledata.map(apprec =>
                < div className="row rounded-3 pt-2 pb-2" key={apprec.id}>
                    <div className="col-4" >
                        <input type="text" className="form-control form-control-sm border-0" name="name" defaultValue={apprec.name} onChange={(e) => updateRecord(apprec, e)} />
                    </div>
                    <div className="col-6" >
                        <Multiselect className="p-0 ps-1 form-control-sm bg-white"
                            options={options}
                            emptyRecordMsg="Başka Başvuru Durmu Yok"
                            ref={multiselectRef}
                            isObject={true}
                            onSelect={(selectedList, selectedItem) => { apprec.appState = selectedList.map(x => x.id).join() }}
                            onRemove={(selectedList, selectedItem) => { apprec.appState = selectedList.map(x => x.id).join() }}
                            selectedValues={apprec.appState != null ? options.filter(x=>apprec.appState.split(',').includes(x.id+"")==true)  : []}
                            displayValue="name" 
                        />
                    </div>
                    <div className="col-2 text-end" >
                        <button onClick={() => submitUpdateRecord(apprec)} className="btn btn-link btn-sm text-decoration-none m-0 p-0">Güncelle</button>&nbsp;
                        <button onClick={() => removeRecord(apprec.id)} className="btn btn-link btn-sm text-decoration-none m-0 p-0">Sil</button>
                    </div>
                </div>
            )}
            <div className="row rounded-3 mb-2 pb-2">
                <div className="col-4">
                    <input type="text" className="form-control form-control-sm" id="name" placeholder="Yeni Grup" name="name" onChange={handleInputChange} defaultValue="" />
                </div>
                <div className="col-8 text-end">  <button onClick={Add} className="btn btn-sm btn-link text-decoration-none m-0 p-0">Ekle</button></div>


            </div>
        </div>

        return (mytable);
    }

    


    useEffect(() => {
        fetchList();
        fetchsureclist();

    }, [])


    var listTable = "";
    if (listData != null) {
        listTable = renderTable(listData);
    }
    else
        listTable = renderTable([]);

    return (
        <div className="bg-light ">
            <h5>Başvuru Grupları</h5>
            {listTable}
        </div>
    );


}