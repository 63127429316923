import React, { useState, useEffect } from 'react';
import { ajaxFail, isEmpty, showConfirmMessage, showErrorMessage, showSuccessMessage } from '../GlobalState.js';
import { useGlobalState } from '../GlobalState.js';

import $ from 'jquery';

export default function MAKSGorevli(props) {

    const [role, setRole] = useGlobalState('role');
    const [rec, setRec] = useState({});
    const [maksrec, setMaksRec] = useState(props.rec);
    const [takiplist, setTakiplist] = useState(null);
    const userData = props.userData;


    useEffect(() => {
        fetchList();

    }, [])

    const remove = (id) => {

        showConfirmMessage("Silmel istediğinizden emin misniz?", "", "Evet", "Hayır", () => {
            fetch("removemaksgorevli", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(id)
            }).then(async response => {
                if (!response.ok) {
                    ajaxFail(response)
                }
                else {
                    response.json().then(value => {
                        setTakiplist(value)
                    })
                }
            });
        });
    }

    const fetchList = (e) => {
        fetch("maksgorevlilist", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            ,
            body: maksrec.id
        }).then(async response => {


            if (!response.ok) {
                response.text().then(async data => {
                    showErrorMessage(data);
                    setTakiplist([])
                });
            }
            else {
                response.json().then(async data => {
                    setTakiplist(data)
                });
            }
        });
    }



    const getDefult = (name) => {
        if (userData == null)
            return "";
        return userData[name];
    }



    const save = () => {
        rec.maksBasvuruId = maksrec.id;

        fetch("savemaksgorevli", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(rec)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                response.json().then(value => {
                    $('.root').find('input').val('');
                    $('.root').find('select').val('');
                    setTakiplist(value)

                })
            }
        });
    }





    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        rec[target.name] = value;
    }



    var recid = getDefult("id");

    useEffect(() => {

    }, [])




    const getName = (value) => {
        if (value == 1) return value + "- Düzenleyen Teknik Eleman";
        if (value == 2) return value + "- Kontrol Eden "
        if (value == 3) return value + "- Onaylayan "
        return "";

    }



    const renderToList = () => {

        if (takiplist == null)
            return <div className="w-100 mt-4 mb-4 text-center align-items-center justify-content-center" ><div className="spinner-border" ></div></div>


        if (takiplist.length == 0)
            return <div className="w-100 mt-4 mb-4 text-center align-items-center justify-content-center text-danger" >Kayıt Yok</div>

        var mytable =
            <div className="mt-1 ">
                <div className="table-responsive ">
                    <table className='table table-vdstriped border' aria-labelledby="tabelLabel">


                        <tbody id="myTable">
                            {takiplist.map(apprec =>
                                <tr key={apprec.id} style={{ "height": "25px" }}>
                                    <td>
                                        {getName(apprec.gorevliTip)}
                                    </td>
                                    <td>
                                        {apprec.regDate}
                                    </td>
                                    <td>
                                        {apprec.ad}
                                    </td>
                                    <td>
                                        {apprec.soyad}
                                    </td>
                                    <td>
                                        {apprec.tc}
                                    </td>
                                    <td>
                                        {apprec.unvan}
                                    </td>
                                    <td>
                                        {apprec.tarih}
                                    </td>
                                    <td>
                                        <div style={{ "overflow": "hidden", "white-space": "nowrap" }} className="text-end">
                                            {<a href="#" className=" text-decoration-none " onClick={() => { remove(apprec.id) }}> <i className="fa fa-trash align-middle" style={{ "font-size": "20px" }}></i></a>}
                                        </div>

                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        return (mytable);
    }


    if (maksrec == null || maksrec.id == null) {
        return <div className="text-start  text-muted ">
            Görevli eklemek için önce başvuruyu kayediniz
        </div>
    }



    return (
        <div className="mb-2 root">


            <div className="row">
                <div className="text-start col-12 fw-bold text-primary">
                    Görevli
                </div>

            </div>
            <div className="row mt-1">
                <div className="text-start col-3">
                    <label className="form-label">Görevli Tipi</label>
                    <select class="form-select form-select-sm" defaultValue={getDefult("gorevliTip")} id="gorevliTip" name="gorevliTip" onChange={handleInputChange} >
                        <option selected></option>
                        <option value="1">Düzenleyen Teknik Eleman</option>
                        <option value="2">Kontrol Eden</option>
                        <option value="3">Onaylayan</option>

                    </select>

                </div>
                <div className="text-start col-3">
                    <label className="form-label">Ad </label>
                    <input type="text" className="form-control form-control-sm" id="ad" name="ad" defaultValue={userData["name"]} onChange={handleInputChange} />
                </div>
                <div className="text-start col-3">
                    <label className="form-label">Soyad </label>
                    <input type="text" className="form-control form-control-sm" id="soyad" name="soyad" defaultValue={userData["family"]} onChange={handleInputChange} />
                </div>

                <div className="text-start col-3">
                    <label className="form-label">TC </label>
                    <input type="text" className="form-control form-control-sm" id="tc" name="tc" defaultValue={userData["tc"]} onChange={handleInputChange} />
                </div>
             </div>
            <div className="row mt-1">
               
                <div className="text-start col-3">
                    <label className="form-label">Unvan </label>
                    <select class="form-select form-select-sm" id="unvan" name="unvan" onChange={handleInputChange} >
                        <option value="" selected></option>
                        {userData.userType.split(",").map(x => <option value={x}>{x}</option>)}
                    </select>

                </div>
                <div className="text-start col-3">
                    <label className="form-label">Tarih </label>
                    <input type="text" className="form-control form-control-sm" id="tarih" name="tarih" defaultValue={userData["tarih"]} onChange={handleInputChange} />
                </div>

                <div className="text-end col-6">
                    <br />
                    <button className="btn btn-sm btn-link" onClick={() => { save() }} ><i className="fa fa-plus align-middle" style={{ "font-size": "20px" }}></i></button>
                </div>
            </div>
            {renderToList()}
        </div>

    );


}