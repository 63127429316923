import React, { useState, useEffect } from 'react';
import { ajaxFail, isEmpty, showErrorMessage } from '../GlobalState.js';
import { useGlobalState } from '../GlobalState.js';
import ApplicationAuthorPanel from './ApplicationAuthorPanel.js';
import UserLabel from "../User/UserLabel.js";
import axios from "axios";
import * as UserPermConstants from '../User/UserPermConstants';

export default function Vekalet(props) {
    var postdata = {};
    const [listData, setListData] = useState(null);
    const [filename, setFileName] = useState(null);
    const [role, setRole] = useGlobalState('role');

    const mapping = props.mapping;
    const updateRecord = (rec, event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        rec[name] = value;
    }

    const removeRecord = (id) => {
        fetch("removevekalet", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(id)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                response.json().then(value => {
                    setListData(value);
                })
            }
        });
    }

    const save = (rec) => {
       
        if (isEmpty(rec.muvekkilFamily) || isEmpty(rec.muvekkilName) || isEmpty(rec.muvekkilTC)) {
            showErrorMessage("Müvekkil Bilgileri Boş Olamaz");
            return;
        }
        fetch("savevekalet", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(rec)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                response.json().then(value => {
                    setListData(value);
                })
            }
        });
    }







    //const submitUpdateRecord = (rec) => {

    //    fetch("updatemevki", {
    //        method: 'POST',
    //        headers: {
    //            'Accept': 'application/json',
    //            'Content-Type': 'application/json'
    //        },
    //        body: JSON.stringify(rec)
    //    }).then(async response => {
    //        if (!response.ok) {
    //            ajaxFail(response)
    //        }
    //        else {
    //            alert("Güncelleme Başarıyla Tamamlanmştır")
    //        }
    //    });
    //}

    const fetchList = () => {

        fetch("getvekaletlistformapping", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(mapping.id)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                response.json().then(value => {
                    setListData(value);
                })
            }
        });
    }

    const getFileName = () => {

        fetch("getvekaletfilename", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(mapping.id)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                response.text().then(value => {
                    setFileName(value.replaceAll("\"",""));
                })
            }
        });
    }



    const Add = () => {
        postdata.authorMappingID = mapping.id;

        postdata.vekilUserName = mapping.userName;

        if (isEmpty(document.getElementById("muvekkilFamily").value)||
           isEmpty(document.getElementById("muvekkilTC").value)||
            isEmpty(document.getElementById("muvekkilName").value)) {
            showErrorMessage("Tüm Bilgiler Doldurulmalıdır");
            return;
        }
        if (document.getElementById('muvekkilTC').checkValidity() == false) {
            showErrorMessage("TC numarası yanlış girlimş.");
            return;
        }



        fetch("addvekalet", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postdata)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                response.json().then(value => {
                    document.getElementById("muvekkilFamily").value = "";
                    document.getElementById("muvekkilTC").value = "";
                    document.getElementById("muvekkilName").value = "";
                    setListData(value);
                })
            }
        });

    }

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        postdata[target.name] = value;
    }

    const handleFile = (resp) => {
        fetchList();
        if (resp.data != null && resp.data.length > 0) {
            showErrorMessage("Eklenmeyen (Hatalı) Satırlar: \n" + resp.data)
        }


    }

    const renderTable = (tabledata) => {

        var mytable = <div>

            <h6 className="text-primary" >Müvekkil Listesi (Yerine imza atılan)</h6>

            {tabledata.map(apprec =>
                < div className={"row rounded-3 border-top" } key={apprec.id}>
                    {/*<div className="col-1" >*/}
                    {/*    {apprec.id}*/}
                    {/*</div>*/}
                    {/*<div className="col-1" >*/}
                    {/*    {apprec.vekilUserName + " - " + apprec.authorMappingID}*/}
                    {/*</div>*/}

                    <div className="col-2" >
                        <input type="text" className="form-control form-control-sm border-0" name="name" defaultValue={apprec.muvekkilTC} onChange={(e) => updateRecord(apprec, e)} />
                    </div>
                    <div className="col-2" >
                        <input type="text" className="form-control form-control-sm border-0" name="name" defaultValue={apprec.muvekkilName} onChange={(e) => updateRecord(apprec, e)} />
                    </div>
                    <div className="col-2" >
                        <input type="text" className="form-control form-control-sm border-0" name="name" defaultValue={apprec.muvekkilFamily} onChange={(e) => updateRecord(apprec, e)} />
                    </div>
                    <div className={"col-2 " + (apprec.approved == true ? "text-success" : "")} >
                        {apprec.approved==true?"Onaylandı":"Onay Bekliyor"}
                    </div>
                    <div className="col-2" >
                        {apprec.ekleyen}
                    </div>
                    <div className="col-2 tex-end" >
                        {role.indexOf(UserPermConstants.Vekalet_Onay) >= 0&&
                            <button onClick={() => { apprec.approved = !apprec.approved; save(apprec) }} className="btn btn-link btn-sm text-decoration-none m-0 p-0">{apprec.approved!=true?"Onayla":"Onayı Kaldır"}</button>}&nbsp;&nbsp;
                        <button onClick={() => removeRecord(apprec.id)} className="btn btn-link btn-sm text-decoration-none m-0 p-0">Sil</button>
                    </div>
                </div>
            )}
            <div className="row mt-5 pt-3 border-top" >
                <div className="col-12" >
                    <h6 className="text-primary" >Yeni Müvekkil Ekle</h6>
                </div>
            </div>
            <div className="row mb-2  pb-3" >
                <div className="col-3" >
                    <input type="text" className="form-control form-control-sm " name="muvekkilTC" id="muvekkilTC" pattern="[0-9]{1,20}" maxLength="11" placeholder="Müvekkilin TC Numarası" defaultValue="" onChange={handleInputChange} />
                </div>
                <div className="col-3" >
                    <input type="text" className="form-control form-control-sm " name="muvekkilName" id="muvekkilName" placeholder="Müvekkilin İsmi" defaultValue="" onChange={handleInputChange} />
                </div>
                <div className="col-3" >
                    <input type="text" className="form-control form-control-sm " name="muvekkilFamily" id="muvekkilFamily" placeholder="Müvekkilin Soyismi" defaultValue="" onChange={handleInputChange} />
                </div>
                <div className="col-3 text-end">  <button onClick={Add} className="btn btn-sm btn-primary ">Ekle</button></div>


            </div>


        </div>

        return (mytable);
    }


    const SendFiles = (selectedFiles) => {
        var formData = new FormData();
        document.getElementById("fileregionspinner").hidden = false;  
        document.getElementById("fileregion").hidden = true;  
        formData.append("file", selectedFiles);
        formData.append("caption", mapping.id + "");//send mappingid in caption
        formData.append("appid", props.appData.id);
        axios.post(
            "submitvekaletfile",
            formData,
            {
                headers: {
                    //"Authorization": "YOUR_API_AUTHORIZATION_KEY_SHOULD_GOES_HERE_IF_HAVE",
                    "Content-type": "multipart/form-data",
                },
            }
        ).then(res => {
            document.getElementById("fileregionspinner").hidden = true;
            document.getElementById("fileregion").hidden = false; 
            getFileName();

        })
            .catch(err => {
                showErrorMessage(err);
                document.getElementById("fileregionspinner").hidden = true;
                document.getElementById("fileregion").hidden = false; 
            })

    }

    const handleSelectedFile = (event) => {
        if (event.target.files[0] != null) {
            if (event.target.files[0].size > 20971520) {
                showErrorMessage("dosya boyu en fazla 20 MB olabilir");
                event.target.value = "";
                return;
            }
            SendFiles(event.target.files[0]);
        }
    }




    useEffect(() => {
        fetchList();
        getFileName();
    }, [])


    var listTable = "";
    if (listData != null) {
        listTable = renderTable(listData);
    }
    else
        listTable = renderTable([]);

    return (
        <div >
            <h5 className="text-primary">Vekalet Bilgileri</h5>

            <div className="row">
                <div className="text-start col-3">
                    Kayıt No: {mapping.id}
                </div>

                <div className="text-end col-9">
                    <button type="button" className="btn btn-primary  btn-sm  text-decoration-none me-2" onClick={(e) => { props.setActivePanel(<ApplicationAuthorPanel appData={props.appData} setActivePanel={props.setActivePanel} />) }}>
                        Müellif Listesine Dön
                    </button>
                </div>
            </div>

            <div className="row mt-3 pt-1">
                <div className="text-start col-12">
                    <h6 className="text-primary" >Vekil (Yerine imzayı atan)</h6>
                </div>
            </div>

            <div className="row pb-3 mb-5 border-bottom">
                <div className="text-start col-4">
                    <UserLabel user={{ userName: mapping.userName }} /> ({mapping.muellifType})
                </div>
                <div className="text-start col-4" id="fileregion">
                {(filename == null || filename == "") ? <span className="text-danger">
                    Vekalet Dosyası Yuklenmemiş
                    </span> :
                        <span className="text-success">
                        <a className="text-decoration-none align-middle" href={'getvekaletdosya?mappingid=' + mapping.id}> {filename } </a>
                        </span>
                    }
                </div>
                <div className="text-start col-4" id="fileregionspinner" hidden={true}>
                    <div class="spinner-border spinner-border-sm" role="status">
                    </div>
                </div>

                <div className="text-end col-4">
                    <input type="file" id={"VekaletFile"} accept=".pdf" style={{ "display": "none" }} onChange={handleSelectedFile} />

                    <button type="button" className="btn btn-primary  btn-sm  text-decoration-none me-2" disabled={props.appData.archived==true} onClick={() => { document.getElementById("VekaletFile").click(); }}>
                        Vekalet Dosyası Ekle
                    </button>
                </div>
            </div>




            {props.appData.archived!=true?listTable:""}



        </div>
    );


}