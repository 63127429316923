import React, { useState, useEffect } from 'react';
import { ajaxFail, isEmpty, showConfirmMessage, showErrorMessage, showSuccessMessage } from '../GlobalState.js';

export default function EimzaNameSetting(props) {
    var postdata = {};
    const [listData, setListData] = useState(null);

    const updateRecord = (rec, event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        rec[name] = value;
    }

    const removeRecord = (id) => {
        showConfirmMessage(id + " Numaralı Kaydı Silmek İstedinizden Emin Misiniz?", "", "Evet", "Hayır", () =>
            fetch("removeeimzaname", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(id)
            }).then(async response => {
                if (!response.ok) {
                    ajaxFail(response);
                }
                else {
                    fetchList();
                }
            }))
    }

    const gerial = () => {
        var uuid = document.getElementById("eimzauuid");
        if (uuid == null || isEmpty(uuid.value)) {
            showErrorMessage("UUID Boş olamaz");
            return;
        }


        fetch("rolbackeimza", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(uuid.value)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.text().then(value => {
                    showSuccessMessage(value);
                })
            }
        });
    }


    const submitUpdateRecord = (rec) => {

        fetch("updateeimzaname", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(rec)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                showSuccessMessage("Güncelleme Başarılı")
            }
        });
    }
    const fetchList = () => {

        fetch("geteimzanamelist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    setListData(value);
                })
            }
        });
    }

    const Add = () => {
        fetch("addeimzaname", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(document.getElementById("name").value)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                document.getElementById("name").value = "";
                fetchList();
            }
        });

    }


    const renderTable = (tabledata) => {
        var mytable =
            <div className="table-responsive mainpagescroll" style={{ "height": "85vh" }}>
                <table className='table table-striped  table-sm' aria-labelledby="tabelLabel">
                    <thead style={{ "position": "sticky", "top": 0, "z-index": "1" }} className="bg-light">
                        <tr>
                            <th width="50px">No</th>
                            <th >Eimza Süreç İsmi</th>
                            <th width="190px">Kayıt Tarihi</th>
                            <th width="130px">Ekleyen</th>
                            <th width="100px"></th>
                        </tr>
                    </thead>
                    <tbody id="myTable">
                        {tabledata.map(apprec =>
                            <tr key={apprec.id}>
                                <td>{apprec.id}</td>
                                <td>
                                    <input type="text" className="form-control form-control-sm" placeholder="" name="name" defaultValue={apprec.name} onChange={(e) => updateRecord(apprec, e)} />
                                </td>
                                <td>{apprec.tarih}</td>
                                <td>{apprec.ekleyen}</td>

                                <td>
                                    <button onClick={() => submitUpdateRecord(apprec)} className="btn btn-link btn-sm text-decoration-none m-0 p-0">Güncelle</button>&nbsp;
                                    <button onClick={() => removeRecord(apprec.id)} className="btn btn-link btn-sm text-decoration-none m-0 p-0">Sil</button>
                                </td>
                            </tr>
                        )}
                        <tr className="bg-white">
                            <td></td>
                            <td>
                                <input type="text" className="form-control form-control-sm" id="name" placeholder="Yeni Duyuru" name="message" defaultValue="" />
                            </td>

                            <td></td>
                            <td></td>
                            <td>  <button onClick={Add} className="btn btn-sm btn-link text-decoration-none m-0 p-0">Ekle</button></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        return (mytable);
    }





    useEffect(() => {
        fetchList();
    }, [])


    var listTable = "";
    if (listData != null) {
        listTable = renderTable(listData);
    }
    else
        listTable = renderTable([]);

    return (
        <div className="  rounded ">

            <h5 className="ps-1">Eimza Dosya Geri Yükleme</h5>
            <div className="row mb-4 ">
                <div className="col-9 bg-light p-2">
                    <input type="text" className="form-control form-control-sm" placeholder="UUID" name="eimzauuid" id="eimzauuid" defaultValue="" />
                </div>
                <div className="col-3  bg-light p-2 text-end">
                    <button onClick={gerial} className="btn btn-sm btn-primary mb-2 rounded-3  ">Geri Al</button>
                </div>
            </div>

            <h5 className="ps-1">Eimza Süreç İsimleri</h5>
            <div className="row">
                <div className="col-12">
                    {listTable}
                </div>
            </div>


        </div>

    );


}