import React, { useState, useEffect } from 'react';

import { ajaxFail, isEmpty, showErrorMessage, showSuccessMessage } from '../GlobalState.js';
import CBSADAMatching from './CBSADAMatching.js';
import CBSAdaSetting from './CBSAdaSetting.js';


export default function CBSAdaSorgula(props) {
    const [listData, setListData] = useState(null);
    var recivedRecCount = 0;
    useEffect(() => {

    }, [])

    const sendRecord = (rec) => {
        fetch("addcbsada", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(rec)
        }).then(async response => {
            if (!response.ok) {
                response.text().then(async data => {
                    showSuccessMessage(data);
                });
            }
            else {
                showSuccessMessage("Ada/Parsel kayıdı veri tabanına eklenmiştir");
            }
        }).catch(err => {
            showErrorMessage(err);
        })
    }


    const fetchList = () => {

        if (isEmpty(document.getElementById("ada").value) || isEmpty(document.getElementById("parsel").value)) {
            showErrorMessage("Ada ve Parsel Numarasını giriniz");
            return;
        }

        var durum = "";
        var raktif = document.getElementById("Aktif").checked;
        var rpasif = document.getElementById("Pasif").checked;
        if (raktif == true)
            durum = " and durum=3"
        else if (rpasif == true)
            durum = " and durum=2"

        var cql_filter = "adano=" + document.getElementById("ada").value + " and parselno=" + document.getElementById("parsel").value + durum;
        document.getElementById("loadspinner").hidden = false;

        fetch("cbsadasorgula?filter=" + cql_filter, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    document.getElementById("loadspinner").hidden = true;
                    setListData(value);
                })
            }
        });
    }

    const getDurumName = (d) => {
        if (d == 0) return "Bilinmiyor";
        if (d == 1) return "Taslak";
        if (d == 2) return "Pasif";
        if (d == 3) return "Aktif";
    }


    const renderTable = (tabledata) => {

        var array10 = tabledata.slice(0, 10000);
        var mytable =
            <div className="table-responsive  mainpagescroll table-sm " style={{ "height": "85vh" }}>
                <table className='table table-vdstriped  table-sm' aria-labelledby="tabelLabel">
                    <thead style={{ "position": "sticky", "top": 0, "z-index": "1" }} className="bg-light">
                        <tr>
                            <th className="text-start" style={{ "width": "10%", overflow: "hidden" }}>No</th>
                            <th className="text-start" style={{ "width": "20%", overflow: "hidden" }}>Tapu Cins Aciklama</th>
                            <th className="text-start" style={{ "width": "20%", overflow: "hidden" }}>Tapu Zeminref</th>
                            <th className="text-start" >Tapu Mahalleref</th>
                            <th className="text-start" style={{ "width": "10%", overflow: "hidden" }}>Tapu Alan</th>
                            <th className="text-start" >Ada/Parsel No</th>
                            <th className="text-start" >Durum</th>
                            <th className="text-start" ></th>
                        </tr>
                    </thead>
                    <tbody id="myTable">
                        {array10.map(apprec =>
                            <tr style={{ "height": "15px" }}>
                                <td >{apprec.tapukimlikno}</td>
                                <td title={apprec.tapucinsaciklama}  >{apprec.tapucinsaciklama}</td>
                                <td >{apprec.tapuzeminref}</td>
                                <td >{apprec.tapumahalleref.includes(" ") ? apprec.tapumahalleref.split(" ")[1] : apprec.tapumahalleref}</td>
                                <td >{apprec.tapualan}</td>
                                <td >{apprec.adano}/{apprec.parselno}</td>
                                <td >{getDurumName(apprec.durum)}</td>
                                <td ><button type="button" class="btn btn-link text-decoration-none btn-sm" onClick={() => sendRecord(apprec)}>Ekle</button></td>

                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        return (mytable);

    }


    var listTable = "";
    if (listData != null) {
        listTable = renderTable(listData);
    }

    return (
        <div>


            <div className="row mb-1">
                <div className="col-5">
                    <h5 className="ps-1">TKGM'deki Veriyi Sorgula</h5>
                </div>
                <div className="col-2 text-center" id="reccount">
                    {listData != null ? listData.length : 0} kayıt.
                    <span className="col-1 text-center" id="loadspinner" hidden>
                        <div class="spinner-border spinner-border-sm ps-1"></div>
                    </span>
                </div>


                <div className="col-12 col-md-5 text-end pt-1">
                    <button onClick={() => props.changeComponent(<CBSAdaSetting cbsdata={props.cbsdata} changeComponent={props.changeComponent} />)} className=" btn btn-primary btn-sm">Geri Don</button>
                </div>
            </div>

            <div className="row mt-3 border rounded-3 m-1 pb-2" >
                <div className="col-12 col-md-2">
                    <label className="form-label">Ada</label>
                    <input type="number" className="form-control form-control-sm" id="ada" name="ada" defaultValue="" />
                </div>
                <div className="col-12 col-md-2">
                    <label className="form-label">Parsel</label>
                    <input type="number" className="form-control form-control-sm" id="parsel" name="parsel" defaultValue="" />
                </div>

                <div className="col-12 col-md-6">
                    <label className="form-label">Durum</label><br />
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="Durum" id="Pasif" value="2" />
                        <label class="form-check-label" for="inlineRadio1">Pasif</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="Durum" id="Aktif" value="3" />
                        <label class="form-check-label" for="inlineRadio2">Aktif</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="Durum" id="Hepsi" value="" defaultChecked={true} />
                        <label class="form-check-label" for="inlineRadio3">Hepsi</label>
                    </div>


                    <button onClick={fetchList} className="ms-4 btn btn-primary btn-sm">Sorgula</button>
                </div>


            </div>



            <div className="row" key={listTable}>
                <div className="col-12" id="tablediv" >
                    {listTable}
                </div>
            </div>



        </div>

    );


}