import React, { useState, useEffect } from 'react';
import { ajaxFail, isEmpty, sendpost, showConfirmMessage, showErrorMessage, showSuccessMessage } from '../GlobalState.js';
import { useGlobalState } from '../GlobalState.js';

import $ from 'jquery';

export default function MAKSAdres(props) {

    const [role, setRole] = useGlobalState('role');
    const boxid = props.adressId;
    const bagimsizBolumId = props.bagimsizBolumId;
    const adressId = props.adressId;
    const setRec = props.setRec;
    var adres = "";
    var bid = "";

    useEffect(() => {

    }, [])

    const handleIlChange = () => {
        var il = document.getElementById("il" + boxid).value;
        var req = {requestType:2,parentId:il}
        sendpost("getmaksadrescomponent", req, "adresspiner", (response) => {
            response.json().then(value => {
                var ilce = document.getElementById("ilce" + boxid);
                ilce.innerHTML = "<option selected value={-1}></option>";
                var data = JSON.parse(value);
                data.forEach(i => {
                    var opt = document.createElement('option');
                    opt.value = i.Id;
                    opt.innerHTML = i.Ad;
                    ilce.appendChild(opt);
                })
            })
        })
    }
    const handleIlceChange = () => {
        var ilce = document.getElementById("ilce" + boxid).value;
        var req = { requestType: 64, parentId: ilce }
        sendpost("getmaksadrescomponent", req, "adresspiner", (response) => {
            response.json().then(value => {
                var mahalle = document.getElementById("mahalle" + boxid);
                mahalle.innerHTML = "<option selected value={-1}></option>";
                var data = JSON.parse(value);
                data.forEach(i => {
                    var opt = document.createElement('option');
                    opt.value = i.Id;
                    opt.innerHTML = i.Ad;
                    mahalle.appendChild(opt);
                })
            })
        })
    }


    const handleMahalleChange = () => {
        var ilce = document.getElementById("mahalle" + boxid).value;
        var req = { requestType: 128, parentId: ilce }
        sendpost("getmaksadrescomponent", req, "adresspiner", (response) => {
            response.json().then(value => {
                var yol = document.getElementById("yol" + boxid);
                yol.innerHTML = "<option selected value={-1}></option>";
                var data = JSON.parse(value);
                data.forEach(i => {
                    var opt = document.createElement('option');
                    opt.value = i.Id;
                    opt.innerHTML = i.Ad;
                    yol.appendChild(opt);
                })
            })
        })
    }



    const handleYolChange = () => {
        var ilce = document.getElementById("yol" + boxid).value;
        var req = { requestType: 1024, parentId: ilce }
        sendpost("getmaksadrescomponent", req, "adresspiner", (response) => {
            response.json().then(value => {
                var mahalle = document.getElementById("bina" + boxid);
                mahalle.innerHTML = "<option selected value={-1}></option>";
                var data = JSON.parse(value);
                data.forEach(i => {
                    var opt = document.createElement('option');
                    opt.value = i.Id;
                    opt.innerHTML = i.Ad;
                    mahalle.appendChild(opt);
                })
            })
        })
    }

       const handleBinaChange = () => {
        var ilce = document.getElementById("bina" + boxid);
        var req = { requestType: 4096, parentId: ilce.value }
        sendpost("getmaksadrescomponent", req, "adresspiner", (response) => {
            response.json().then(value => {
                var mahalle = document.getElementById("bagimsiz" + boxid);
                mahalle.innerHTML = "<option selected value={-1}></option>";
                var data = JSON.parse(value);
                var data = JSON.parse(value);
                if (data.length == 0) {
                    var opt = document.createElement('option');
                    opt.value = ilce.value; 
                    opt.innerHTML = ilce.options[ilce.selectedIndex].text;
                    mahalle.appendChild(opt);

                    return;
                }
                data.forEach(i => {
                    var opt = document.createElement('option');
                    opt.value = i.Id;
                    opt.innerHTML = isEmpty(i.Ad) ? ilce.options[ilce.selectedIndex].text : i.Ad; 
                    mahalle.appendChild(opt);
                })
            })
        })
    } 

    const handleBagimsizChange = () => {
        var v = document.getElementById("bagimsiz" + boxid).value;
        var req = { requestType: 1001, parentId: v }
        sendpost("getmaksadrescomponent", req, "adresspiner", (response) => {
            response.json().then(value => {
                var data = JSON.parse(value);
                adres = data[v + ""].AcikAdres;
                bid = v;
            })
        })
    } 

    const saveAdres = () => {
        if (isEmpty(adres) || isEmpty(bid) || bid == -1) {
            showErrorMessage("Adres Alınamadı")
        }
        document.getElementById(adressId).value = adres;
        document.getElementById(bagimsizBolumId).value = bid;
        setRec(bid,adres)
       
    } 

   


    return (<div>
        <button type="button" className="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target={"#Modal" + boxid}>
            Adres Seç
        </button>


        <div className="modal fade" tabindex="-1"  id={"Modal" + boxid}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <span className="modal-title h5">Adres Seç</span>
                        <span className="spinner-border spinner-border-sm ms-3" id="adresspiner" hidden>   </span>
                         <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                                İl
                        <select className="form-select form-select-sm" onChange={handleIlChange} id={"il" + boxid}>
                                    <option selected value={-1}></option>
                                    <option value="1">Adana</option>
                                    <option value="2">Adıyaman</option>
                                    <option value="3">Afyonkarahisar</option>
                                    <option value="4">Ağrı</option>
                                    <option value="68">Aksaray</option>
                                    <option value="5">Amasya</option>
                                    <option value="6">Ankara</option>
                                    <option value="7">Antalya</option>
                                    <option value="75">Ardahan</option>
                                    <option value="8">Artvin</option>
                                    <option value="9">Aydın</option>
                                    <option value="10">Balıkesir</option>
                                    <option value="74">Bartın</option>
                                    <option value="72">Batman</option>
                                    <option value="69">Bayburt</option>
                                    <option value="11">Bilecik</option>
                                    <option value="12">Bingöl</option>
                                    <option value="13">Bitlis</option>
                                    <option value="14">Bolu</option>
                                    <option value="15">Burdur</option>
                                    <option value="16">Bursa</option>
                                    <option value="17">Çanakkale</option>
                                    <option value="18">Çankırı</option>
                                    <option value="19">Çorum</option>
                                    <option value="20">Denizli</option>
                                    <option value="21">Diyarbakır</option>
                                    <option value="81">Düzce</option>
                                    <option value="22">Edirne</option>
                                    <option value="23">Elazığ</option>
                                    <option value="24">Erzincan</option>
                                    <option value="25">Erzurum</option>
                                    <option value="26">Eskişehir</option>
                                    <option value="27">Gaziantep</option>
                                    <option value="28">Giresun</option>
                                    <option value="29">Gümüşhane</option>
                                    <option value="30">Hakkâri</option>
                                    <option value="31">Hatay</option>
                                    <option value="76">Iğdır</option>
                                    <option value="32">Isparta</option>
                                    <option value="34">İstanbul</option>
                                    <option value="35">İzmir</option>
                                    <option value="46">Kahramanmaraş</option>
                                    <option value="78">Karabük</option>
                                    <option value="70">Karaman</option>
                                    <option value="36">Kars</option>
                                    <option value="37">Kastamonu</option>
                                    <option value="38">Kayseri</option>
                                    <option value="71">Kırıkkale</option>
                                    <option value="39">Kırklareli</option>
                                    <option value="40">Kırşehir</option>
                                    <option value="79">Kilis</option>
                                    <option value="41">Kocaeli</option>
                                    <option value="42">Konya</option>
                                    <option value="43">Kütahya</option>
                                    <option value="44">Malatya</option>
                                    <option value="45">Manisa</option>
                                    <option value="47">Mardin</option>
                                    <option value="33">Mersin</option>
                                    <option value="48">Muğla</option>
                                    <option value="49">Muş</option>
                                    <option value="50">Nevşehir</option>
                                    <option value="51">Niğde</option>
                                    <option value="52">Ordu</option>
                                    <option value="80">Osmaniye</option>
                                    <option value="53">Rize</option>
                                    <option value="54">Sakarya</option>
                                    <option value="55">Samsun</option>
                                    <option value="56">Siirt</option>
                                    <option value="57">Sinop</option>
                                    <option value="58">Sivas</option>
                                    <option value="63">Şanlıurfa</option>
                                    <option value="73">Şırnak</option>
                                    <option value="59">Tekirdağ</option>
                                    <option value="60">Tokat</option>
                                    <option value="61">Trabzon</option>
                                    <option value="62">Tunceli</option>
                                    <option value="64">Uşak</option>
                                    <option value="65">Van</option>
                                    <option value="77">Yalova</option>
                                    <option value="66">Yozgat</option>
                                    <option value="67">Zonguldak</option>
                                </select>
                           
                                İlçe
                        <select class="form-select form-select-sm" id={"ilce" + boxid} onChange={handleIlceChange}>
                                    <option selected value={-1}></option>
                                   
                                </select>
                          
                                Mahalle
                        <select class="form-select form-select-sm" id={"mahalle" + boxid} onChange={handleMahalleChange}  >
                                    <option selected value={-1}></option>
                                   
                                </select>
                           
                                Yol
                        <select class="form-select form-select-sm" onChange={handleYolChange} id={"yol" + boxid}>
                                    <option selected value={-1}></option>
                                    
                                </select>
                           
                       
                                Bina
                        <select className="form-select form-select-sm" onChange={handleBinaChange} id={"bina" + boxid}>
                                    <option selected value={-1}></option>
                                   
                                </select>

                        Bağımsız Bolum
                        <select className="form-select form-select-sm" onChange={handleBagimsizChange} id={"bagimsiz" + boxid}>
                            <option selected value={-1}></option>

                        </select>



                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Vazgeç</button>

                        <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={saveAdres}>Kaydet</button>
                    </div>
                </div>
            </div>
        </div>



    </div >
    );
}


