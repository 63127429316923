import React, { useState, useEffect } from 'react';
import { ajaxFail, showConfirmMessage, showSuccessMessage } from '../GlobalState.js';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default function MakaleSetting(props) {
    var postdata ="";
    const [listData, setListData] = useState(null);

    const updateRecord = (rec, event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        rec[name] = value;
    }

    const removeRecord = (id) => {
        showConfirmMessage(id + " Numaralı Kaydı Silmek İstedinizden Emin Misiniz?", "", "Evet", "Hayır", () =>
            fetch("removemakale", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(id)
            }).then(async response => {
                if (!response.ok) {
                    ajaxFail(response);
                }
                else {
                    fetchList();
                }
            }));
    }



    const submitUpdateRecord = (rec) => {

        fetch("updatemakale", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(rec)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                showSuccessMessage("Güncelleme Başarılı")
            }
        });
    }
    const fetchList = () => {

        fetch("getmakalelist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    setListData(value);
                })
            }
        });
    }

    const Add = () => {
        fetch("addmakale", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ "title": document.getElementById("title").value, "text": postdata })
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
             
                document.getElementById("title").value = "";
                fetchList();
            }
        });

    }


    const renderTable = (tabledata) => {
        var mytable =

            <table className='table table-striped  table-sm' aria-labelledby="tabelLabel">
                <thead>
                    <tr>
                        <th width="2%">No</th>
                        <th width="18%">Başlık</th>
                        <th width="50%">Açıklama</th>
                        <th width="10%">Kayıt Tarihi</th>
                        <th width="10%">Ekleyen</th>
                        <th width="10%"></th>
                    </tr>
                </thead>
                <tbody id="myTable">
                    {tabledata.map(apprec =>
                        <tr key={apprec.id}>
                            <td>{apprec.id}</td>
                            <td>

                                <input type="text" className="form-control form-control-sm" placeholder="" name="title" defaultValue={apprec.title} onChange={(e) => updateRecord(apprec, e)} />
                            </td>
                            <td>
                                <td style={{ "resize": "vertical", "overflow-y": "scroll", "height": "120px", "min-width": "500px" }} className="p-2">
                                    <ReactQuill className="h-100" theme="snow" onChange={(e) => apprec.text = e} defaultValue={apprec.text} />
                                </td>
                            {/*    <textarea className="form-control form-control-sm" placeholder="" name="text" defaultValue={apprec.text} onChange={(e) => updateRecord(apprec, e)} />*/}
                            </td>

                            <td>{apprec.sendDate}</td>
                            <td>{apprec.userName}</td>

                            <td className="text-end">
                                <button onClick={() => submitUpdateRecord(apprec)} className="btn btn-link btn-sm text-decoration-none m-0 p-0">Güncelle</button>&nbsp;
                                <button onClick={() => removeRecord(apprec.id)} className="btn btn-link btn-sm text-decoration-none m-0 p-0">Sil</button>
                            </td>
                        </tr>
                    )}
                    <tr className="bg-white">
                        <td></td>
                        <td>
                            <input type="text" className="form-control form-control-sm" placeholder="Başlık" id="title" name="title" defaultValue="" />
                        </td>
                        <td>
                            <ReactQuill className="" theme="snow" value="" onChange={(e) => postdata = e} />
                           
                        </td>

                        <td></td>
                        <td></td>

                        <td className="text-end">  <button onClick={Add} className="btn btn-sm btn-link text-decoration-none m-0 p-0">Ekle</button></td>
                    </tr>
                </tbody>
            </table>

        return (mytable);
    }





    useEffect(() => {
        fetchList();
    }, [])


    var listTable = "";
    if (listData != null) {
        listTable = renderTable(listData);
    }
    else
        listTable = renderTable([]);

    return (
        <div className="  rounded ">

            <h5 className="ps-1">Duyurular</h5>
            <div className="row">
                <div className="col-12">
                    {listTable}
                </div>
            </div>
        </div>

    );


}