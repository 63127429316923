import React, { useState, useEffect } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import { isEmpty, showConfirmMessage, showErrorMessage, showSuccessMessage, useGlobalState } from '../GlobalState.js';
import axios from "axios";
import { ajaxFail } from '../GlobalState.js';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


export default function HelpSettingPanel(props) {
    var postdata = {};
    postdata["FileType"] = "pdf";
    var selectedRecord = null;
    const [listData, setListData] = useState(null);
    const [typeOptions, setTypeOptions] = useState([]);
    var multiselectRef = React.createRef();
    const [role, setRole] = useGlobalState('role');

    useEffect(() => {
      
        
    }, [])

    useEffect(() => {
        fetchUserTypeList();
        getHelpList();
        initModal();
        
    }, [])

    const sil = (rec) => {
        showConfirmMessage(rec.id + " Numaralı Kaydı Silmek İstedinizden Emin Misiniz?", "", "Evet", "Hayır", () =>
            fetch("removehelpList", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(rec.id)
            }).then(async response => {
                if (!response.ok) {
                    ajaxFail(response);
                }
                else {
                    response.json().then(value => {
                        setListData(value);
                    })
                }
            }));
    }


    const SendFiles = (event) => {
        document.getElementById("fileuploadsp").hidden = false;
        var selectedFile = { file: event.target.files[0]}
        var formData = new FormData();
        formData.append("file", selectedFile.file);
        formData.append("caption", selectedFile.file.name);
        formData.append("appid", selectedRecord.id);
        axios.post(
            "submithelpfile",
            formData,
            {
                headers: {
                    "Content-type": "multipart/form-data",
                },
            }
        ).then(res => {
            document.getElementById("fileuploadsp").hidden = true;
            var locModal = document.getElementById('myModal');
            locModal.style.display = "none";
            locModal.classList.add("hide");
            setListData(res.data);
        })
            .catch(err => { showErrorMessage(err); })
    }


    const initModal = () => {
        var locModal = document.getElementById('myModal');
        var btnclosedown = document.getElementById('downclosemodal');
        btnclosedown.addEventListener('click', (e) => {
            locModal.style.display = "none";
            locModal.classList.add("hide");
        });
    }



    const onSelect = (selectedList, selectedItem) => {
        postdata["roles"] = multiselectRef.current.getSelectedItems().map(c => c.appType).join(", ")
    }
    const onRemove = (selectedList, removedItem) => {
        postdata["roles"] = multiselectRef.current.getSelectedItems().map(c => c.appType).join(", ")
    }

    const fetchUserTypeList = () => {

        fetch("getapptypelist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    setTypeOptions(value);
                })
            }
        });
    }


    const getHelpList = () => {
        fetch("gethelplist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    setListData(value)
                   
                })
            }
        });
    }

    const updateRecord = (rec, event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        rec[name] = value;
    }

    const updatehelplist = (rec)=> {
        fetch("updatehelplist", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(rec)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    setListData(value)
                    showSuccessMessage("Güncelleme Başarılı")
                })
            }
        });
    }


    const Add = () => {
        fetch("addhelplist", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postdata)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                document.getElementById("caption").value = "";
                document.getElementById("title").value = "";
                
                document.getElementById("url").value = "";
                multiselectRef.current.resetSelectedValues();
                getHelpList();
            }
        });
    }

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        postdata[target.name] = value;
    }

    var controlPanel = "";




    const renderTable = (tabledata) => {
        var mytable =

            <table className='table table-vdstriped table-sm text-start' aria-labelledby="tabelLabel">
                <thead className="text-start">
                    <tr>
                        <th width="3%" className="text-start">No</th>
                        <th width="10%" className="text-start">Başlık</th>
                        <th width="30%" className="text-start">Açıklama</th>
                        <th width="20%" className="text-start">Video Link</th>
                        <th width="17%" className="text-start">Kullanıcı Tipi</th>
                        <th width="10%" className="text-start">Dosya</th>
                        <th width="10%" className="text-start">Ekleyen</th>
                        {/*<th width="10%" className="text-start">Kayıt Tarihi</th>*/}
                        <th width="10%" className="text-start">Ayarlar</th>
                    </tr>
                </thead>
                <tbody id="myTable">
                    {tabledata.map(apprec =>
                        <tr key={apprec.id}>
                            <td>{apprec.id}</td>
                            <td >
                                <input type="text" className="form-control form-control-sm  " style={{ "background-color": "transparent", "min-width": "50px" }} placeholder="Başlık" name="title" onChange={(e) => updateRecord(apprec, e)} defaultValue={apprec.title} />

                            </td>
                            

                            <td style={{ "resize": "vertical", "overflow-y": "scroll", "height": "120px", "min-width": "500px" }} className="p-2">


                                <ReactQuill className="h-100" theme="snow" onChange={(e) => apprec.caption = e} defaultValue={apprec.caption} />

                                    {/*  <input type="text" className="form-control form-control-sm" placeholder="Başvuru Turu" name="message" defaultValue={apprec.message} onChange={(e) => updateRecord(apprec, e)} />*/}

                                </td>

                                {/*<input type="text" className="form-control form-control-sm  " style={{ "background-color": "transparent", "min-width": "100px" }} placeholder="Açıklama" name="caption" onChange={(e) => updateRecord(apprec, e)} defaultValue={apprec.caption} />*/}

                            
                            <td>
                                <input type="text" className="form-control form-control-sm  " style={{ "background-color": "transparent", "min-width": "100px" }} placeholder="Video Link" name="url" onChange={(e) => updateRecord(apprec, e)} defaultValue={apprec.url} />
                            </td>
                            <td>
                                <Multiselect className="p-0"
                                    options={typeOptions.map(b => b.appType)}
                                    emptyRecordMsg="Başka Başvuru Tipi Yok"

                                    isObject={false}
                                    onSelect={(selectedList, selectedItem) => { apprec.roles = selectedList.join() }}
                                    onRemove={(selectedList, selectedItem) => { apprec.roles = selectedList.join() }}
                                    placeholder=""
                                    selectedValues={!isEmpty(apprec.roles) ? apprec.roles.split(',') : []}
                                />

                              </td>

                            <td><a href={'gethelpfile?id=' + apprec.id} download={apprec.fileName}> {apprec.fileName} </a></td>
                            <td>{apprec.userName}</td>
                            {/*<td>{apprec.postDate}</td>*/}
                            <td className="text-start">  <button onClick={() => sil(apprec)} className="btn btn-link btn-sm text-decoration-none m-0 p-0">Sil</button><br />
                                <button  onClick={() => {
                                    document.getElementById('myModal').style.display = "block";
                                    document.getElementById('myModal').classList.remove("hide");
                                    selectedRecord = apprec;
                                }} className="btn btn-link btn-sm text-decoration-none m-0 p-0 text-start">
                                    Dosya Ekle</button><br />

                                <button onClick={() => { updatehelplist(apprec); }} className="btn btn-link btn-sm text-decoration-none m-0 p-0">Güncelle</button>
                            </td>
                        </tr>
                    )}
                    <tr className="bg-white">
                        <td></td>
                        <td >
                            <input type="text" className="form-control form-control-sm  " id="title" placeholder="Başlık" name="title" onChange={handleInputChange}  />

                        </td>

                        <td>
                            <input type="text" className="form-control form-control-sm" id="caption" placeholder="Açıklama" name="caption" onChange={handleInputChange} />
                        </td>
                        <td>
                            <input type="text" className="form-control form-control-sm" id="url" placeholder="Link" name="url" onChange={handleInputChange} />
                        </td>
                        <td>
                            <Multiselect className="bg-white text-dark form-select-sm m-0 p-0"
                                options={typeOptions} // Options to display in the dropdown
                                //selectedValues={appData.başvuruTipi} // Preselected value to persist in dropdown
                                onSelect={onSelect} // Function will trigger on select event
                                onRemove={onRemove} // Function will trigger on remove event
                                displayValue="appType" // Property name to display in the dropdown options
                                emptyRecordMsg="Başka Başvuru Tipi Yok"
                                ref={multiselectRef}
                            />
                        </td>
                        <td></td>
                       {/* <td></td>*/}
                        <td></td>
                        <td>  <button onClick={Add} className="btn btn-sm btn-link text-decoration-none m-0 p-0">Ekle</button></td>
                    </tr>
                </tbody>
            </table>

        return (mytable);
    }

    var listTable = <div class="spinner-border  " ></div>;
    if (listData != null) {
        listTable = renderTable(listData);
    }

    return (
        <div className=" mt-2 rounded">

            <h5>Yardım Dosyaları</h5>
            <div className="row">
                <div className="col-12 text-center">
                    {listTable}
                </div>
            </div>
            <div className="modal" id="myModal" >
                <div class="modal-dialog modal  modal-dialog-centered ">
                    <div class="modal-content" >
                        <div class="modal-body" id="mymodalbody" >

                                <div className="row">
                                    <div className="col-10">

                                        <label for="formFile" class="form-label">Dosya Yukle</label>
                                        <br />
                                    <input class="form-control form-control-sm mt-2" type="file" id="formFile" onChange={(e) => SendFiles(e)} />
                                </div>

                                <div className="col-2 text-end">
                                  <div class="spinner-border  spinner-border-sm" hidden id="fileuploadsp" ></div>
                                 </div>

 
                                </div>
                           
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger" id="downclosemodal" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );


}