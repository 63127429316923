import React, { useState, useEffect } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import { isEmpty, useGlobalState } from '../GlobalState.js';
import axios from "axios";
import { ajaxFail } from '../GlobalState.js';
import HelpVideoPanel from './HelpVideoPanel.js';
import $ from 'jquery';
import { logout, passFilter, removeTurkcLow } from '../GlobalState.js';

export default function MevzuatPanel(props) {
    var postdata = {};
    postdata["FileType"] = "pdf";
    const [listData, setListData] = useState(null);


    useEffect(() => {

        getHelpList();

    }, [])


    const filterTable = (event) => {
        var textbox = document.getElementById("ara")
        if (textbox == null)
            return;
        var value = textbox.value.toLowerCase();

        $("#myTable div.filterable").filter(function () {
            var keywords = value.split(" ");
            var ret = true;
            var t1 = removeTurkcLow($(this).text());
            for (let index = 0; index < keywords.length; ++index) {
                var t2 = removeTurkcLow(keywords[index]);
                if (t1.indexOf(t2) == -1)
                    return $(this).toggle(false);
            }
            return $(this).toggle(true);
        });

    }





    const getHelpList = () => {
        fetch("getyonetmenliklist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    setListData(value)

                })
            }
        });
    }

    const getFileIcon = (filname) => {
        if (filname.endsWith("pdf")) return <i class="fa fa-file-pdf-o text-danger" style={{ "font-size": "32px" }}></i>
        if (filname.endsWith("doc") || filname.endsWith("docx")) return <i class="fa fa-file-word-o text-primary" style={{ "font-size": "32px" }}></i>
        if (filname.endsWith("xls") || filname.endsWith("xlsx")) return <i class="fa fa-file-excel-o text-success" style={{ "font-size": "32px" }}></i>
        if (filname.endsWith("zip") || filname.endsWith("rar")) return <i class="fa fa-file-zip-o text-info" style={{ "font-size": "32px" }}></i>
        return <i class="fa fa-file-text-o" style={{ "font-size": "32px" }}></i>
    }

    const renderSampleFormTable = (tabledata) => {
        var mytable = <div className="row p-2" >

            {tabledata.map(apprec => <div className="col-12 col-md-4 mb-4 mt-1  filterable " key={apprec.id} style={{ "font-family": "Inter" }}>

                <div className="rounded-3 p-3 bg-light  rounded-4 ">
                    <div>
                        <div className="row align-bottom d-flex align-items-end text-secondary mt-2">
                            <div className="col-10  align-bottom">
                                {isEmpty(apprec.fileName) == false &&
                                    <a className="text-decoration-none" href={'getyonetmenlik?id=' + apprec.id} download={apprec.fileName}>
                                        {getFileIcon(apprec.fileName)} {apprec.fileName}
                                    </a>
                                }

                            </div>
                        </div>
                        <div className="fw-bold text-justify mt-2" style={{ "font-size": "16px", "color": "#78829d", "text-align": "justify" }}>
                            <div className="col-12" > <div className="mt-1" dangerouslySetInnerHTML={{ __html: apprec.caption }} /></div>
                        </div>

                        <div className=" text-start mt-2" style={{ "font-size": "15px" }}>
                            <a href={apprec.url} target="_blank" className="text-decoration-none"> {apprec.url} </a>
                        </div>

                        <div className="row  text-secondary mt-3">
                            <div className="col-10  ">
                                <img className="rounded-circle" style={{ width: "32px", height: "32px" }} src={"/getuserpic?user=" + apprec.userName} />
                                <span style={{ "font-size": "14px", "font-weight": "600", "color": "#4b5675" }}>  {apprec.userName}</span> <span style={{ "font-size": "14px", "font-weight": "600", "color": "#99a1b7" }}>{apprec.postDate}</span>
                            </div>
                            <div className="col-2 text-end  ">
                                {apprec.id}
                            </div>
                        </div>

                    </div>
                </div>
            </div>)}
        </div>

        return (mytable);
    }



  




    const renderTable = (tabledata) => {
        var mytable = <div className="row p-2" >

            {tabledata.map(apprec => <div className="col-12 col-md-4 mb-4 mt-1  filterable " key={apprec.id} style={{ "font-family": "Inter" }}>

                <div className="rounded-3 p-3 bg-light  rounded-4 overflow-auto">
                    <div>
                        <div className="fw-bold text-justify mt-2 " style={{ "font-size": "16px", "color": "#78829d", "text-align": "justify" }}>
                            <div className="col-12" > <div className="mt-1" dangerouslySetInnerHTML={{ __html: apprec.caption }} /></div>
                        </div>
                        <div className="row align-bottom d-flex align-items-end text-secondary mt-2 overflow-auto">
                            <div className="col-10  align-bottom overflow-auto">
                                {isEmpty(apprec.fileName) == false &&
                                    <a className="text-decoration-none" href={'getyonetmenlik?id=' + apprec.id} download={apprec.fileName}>
                                        {getFileIcon(apprec.fileName)} {apprec.fileName}
                                    </a>
                                }

                            </div>
                        </div>


                        <div className=" text-start mt-2" style={{ "font-size": "15px" }}>
                            <a href={apprec.url} target="_blank" className="text-decoration-none"> {apprec.url} </a>
                        </div>

                        <div className="row  text-secondary mt-3">
                            <div className="col-10  ">
                                <img className="rounded-circle" style={{ width: "32px", height: "32px" }} src={"/getuserpic?user=" + apprec.userName} />
                                <span style={{ "font-size": "14px", "font-weight": "600", "color": "#4b5675" }}>  {apprec.userName}</span> <span style={{ "font-size": "14px", "font-weight": "600", "color": "#99a1b7" }}>{apprec.postDate}</span>
                            </div>
                            <div className="col-2 text-end  ">
                                {apprec.id}
                            </div>
                        </div>

                    </div>
                </div>
            </div>)}
        </div>

        return (mytable);
    }

    var mevzuatTable = <div class="spinner-border  " ></div>;
    if (listData != null) {
        mevzuatTable = renderTable(listData);
    }
 


    return (
        <div className="mt-2 rounded bg-light p-2 rounded-3 " style={{ "font-family": "Inter" }}>
            <div className="mb-3" style={{ "font-size": 18, "font-weight": "600", "color": "#252f4a" }}>Mevzuat Paneli</div>
            <div className="m-1 rounded-3 border   bg-white shadow-sm" >

                <div className="row  align-middle d-flex align-items-center " >
                    <div className="col-12 col-md-1 "></div>
                    <div className="col-12 col-md-7 pt-5 pb-4">
                        <div className="align-middle ms-1 me-5" style={{ "font-size": 23, "font-weight": "600", "color": "#252f4a" }}>
                            Aradığınız Yönetmeliği Hızlıca Bulun

                            <div className="row border rounded-3 ms-1 mt-4 p-1 d-flex align-items-center" >
                                <div className="col-1 d-flex align-items-center"><span className='fa fa-circle-o  text-primary' style={{ "font-size": 16 }}> </span></div>
                                <div className="col-10 ">
                                    <input type="text" className="form-control form-control-sm border-0 shadow-none" id="ara" defaultValue="" placeholder="" name="ara" onKeyUp={filterTable} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-4 text-center">
                        <img src={require('../Header/textlogo.png').default} style={{ "max-width": "auto", "max-height": "128px", "object-fit": "cover" }} />
                    </div>
                </div>

            </div>
            <div id="myTable">
                <div className="mt-5 bg-white border rounded-3 p-4 shadow-sm mb-4" id="formlardiv">
                    <div className="mb-2 border-bottom pb-3" style={{ "font-size": 18, "font-weight": "600", "color": "#252f4a" }}>Mevzuat</div>
                    {mevzuatTable}
                </div>
            </div>

        </div>

    );


}