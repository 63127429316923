import React, { useState, useEffect } from 'react';
import KadastralVeri from '../Application/KadastralVeri.js';
import { ajaxFail, showConfirmMessage, showErrorMessage, showSuccessMessage, removeTurkcLow } from '../GlobalState.js';
import CBSADAMatching from './CBSADAMatching.js';
import $ from 'jquery';

import bootstrap from 'bootstrap/dist/js/bootstrap.min.js'

export default function AdaSetting(props) {
    var postdata = {};
    const [listData, setListData] = useState(null);
    const [MahalleList, setMahalleList] = useState([]);



    const updateRecord = (rec, event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        rec[name] = value;
    }

    useEffect(() => {
        var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
        var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
            return new bootstrap.Popover(popoverTriggerEl)
        })
    }, [])

    var sortField = null;
    const [sortDirection, setSortDirection] = useState({});

    const myCompare = (a, b) => {
        var av = a[sortField];
        var bv = b[sortField];
        var ret = 1;
        if (sortDirection[sortField] == "ZA")
            ret = -1;

        if (av == null && bv != null)
            return -1 * ret;
        if (av != null && bv == null)
            return ret;


        if (typeof av === 'string' || av instanceof String)
            if (typeof bv === 'string' || bv instanceof String)
                return av.localeCompare(bv) * ret;
        if (av < bv) {
            return -1 * ret;
        }
        if (av > bv) {
            return ret;
        }
        return 0;
    }

    const sortList = (fildname) => {
        sortField = fildname;
        if (sortDirection[sortField] == null || sortDirection[sortField] == "ZA") {
            sortDirection[sortField] = "AZ";
        }
        else {
            sortDirection[sortField] = "ZA";
        }
        var temp = [...listData]; //clone the array to update the component on setApplist
        temp.sort(myCompare);
        setListData(temp);
    }

    const removeRecord = (id) => {
        showConfirmMessage(id + " Numaralı Kaydı Silmek İstedinizden Emin Misiniz?", "", "Evet", "Hayır", () =>
            fetch("removeada", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(id)
            }).then(async response => {
                if (!response.ok) {
                    ajaxFail(response);

                }
                else {
                    fetchList();
                }
            }));
    }


    const validateNumberAndForwardSlash = (event) => {
        var key = event.which;
        if (key >= 48 && key <= 57 || key == 47) {
            return true;
        } else {

            event.preventDefault()
        }
    }


    const submitUpdateRecord = (rec) => {

        fetch("updateada", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(rec)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                showSuccessMessage("Güncelleme Başarılı")
            }
        });
    }

    const fetchList = () => {
        var mahalle = JSON.parse(document.getElementById("mahalleId").value);
        document.getElementById("mahallesecimmsg").innerHTML = "";

                postdata["mahalleId"] = mahalle.id;

        fetch("getadalistbyid?mevki=" + mahalle.mevkiId + "&mahalle=" + mahalle.id, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    setListData(value);
                })
            }
        });
    }

    const Add = () => {
        if (document.getElementById("mahalleId").selectedIndex == -1) {
            showErrorMessage("Mahalle Seçilmemiş");
            return;
        }
        var mahalle = JSON.parse(document.getElementById("mahalleId").value);
        postdata["mahalleId"] = mahalle.id;

        fetch("addada", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postdata)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                document.getElementById("name").value = "";
                document.getElementById("koordinat").value = "";
                fetchList();

            }
        });

    }

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        postdata[target.name] = value;
    }

    const getMahalleName = (id) => {
        for (var i = 0; i < MahalleList.length; i++) {
            if (MahalleList[i].id == id)
                return MahalleList[i].name;
        }
    }


    const filterTable = (event) => {
        var textbox = event.target;
        if (textbox == null)
            return;
        var value = textbox.value;

        $("#myTable .row ").filter(function () {
            var rowtext = $(this).children().eq(1).children().eq(0).val()
            var t1 = removeTurkcLow(rowtext);
            var t2 = removeTurkcLow(value);
            if (t1.indexOf(t2) == -1)
                return $(this).toggle(false);
            return $(this).toggle(true);
        });
        if (document.getElementById("totalrec") != null && $("#myTable div:visible") != null)
            document.getElementById("totalrec").innerHTML = $("#myTable div:visible").length + " kayıt";

    }




    const renderTable = (tabledata) => {

        var mytable = <div>
            < div className="row rounded-3">
                <div className="col-3  fw-bold" >
                    <button onClick={() => sortList("mevkiName")} className="btn btn-link btn-sm text-decoration-none m-0 p-0">Mahalle</button>

                </div>
                <div className="col-2  fw-bold" >
                    <button onClick={() => sortList("name")} className="btn btn-link btn-sm text-decoration-none m-0 p-0">Ada/Parsel</button>

                </div>
                <div className="col-4  fw-bold" >
                    <button onClick={() => sortList("koordinat")} className="btn btn-link btn-sm text-decoration-none m-0 p-0">Koordinat</button>
                </div>
                {/*<div className="col-1 " >*/}
                {/*Kara Liste*/}
                {/*</div>*/}
                <div className="col-2  " >
                    Kara Liste
                </div>
                <div className="col-1  " >
                </div>
            </div>

            < div id="myTable">
                {tabledata.map(apprec =>
                    < div className="row rounded-3" key={apprec.id}>
                        <div className="col-3 mt-1" >
                            <input type="text" className="form-control form-control-sm border-0" name="name" value={getMahalleName(apprec.mahalleId)} />
                        </div>
                        <div className="col-2 mt-1" >
                            <input type="text" className="form-control form-control-sm border-0" name="name" defaultValue={apprec.name} onChange={(e) => updateRecord(apprec, e)} />
                        </div>
                        <div className="col-4 mt-1" >
                            <input type="text" className="form-control form-control-sm border-0" name="koordinat" defaultValue={apprec.koordinat} onChange={(e) => updateRecord(apprec, e)} />
                        </div>
                       {/* <div className="col-1 mt-1" >*/}
                       {/*     <input type="checkbox" className="form-check-input" name="karaListe" defaultChecked={apprec.karaListe} onChange={(e) => updateRecord(apprec, e)} />*/}
                       {/*</div>*/}
                        <div className="col-2 mt-1" >
                            <input className="form-control form-control-sm" type="date" name="karaListeTarih" id="karaListeTarih" defaultValue={apprec.karaListeTarih} placeholder="2024/01/01" onChange={(e) => updateRecord(apprec, e)}  />

                        </div>
                        <div className="col-1 text-end" >
                            <button onClick={() => submitUpdateRecord(apprec)} className="btn btn-link  text-decoration-none m-0 p-0"><i class="fa fa-save" title="Güncelle"></i></button>&nbsp;
                            <button onClick={() => removeRecord(apprec.id)} className="btn btn-link  text-danger text-decoration-none m-0 p-0"><i class="fa fa-trash-o" title="Sil"></i></button>
                        </div>
                    </div>
                )}
            </div>
        </div>

        return (mytable);
    }

    const fetchMahalleList = () => {

        fetch("getmahallelist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    var i = 0;
                    var c = document.getElementById("mahalleId");
                    c.innerHTML = "";
                    var opt = document.createElement('option');
                    opt.value = JSON.stringify("");
                    opt.innerHTML = "Mahalle Seçiniz";
                    opt.disabled = true;
                    opt.selected = true;
                    c.appendChild(opt);

                    for (i = 0; i < value.length; i++) {
                        var opt = document.createElement('option');
                        opt.value = JSON.stringify(value[i]);
                        opt.innerHTML = value[i].name;;
                        c.appendChild(opt);
                    }
                    setMahalleList(value);

                })
            }
        });
    }



    useEffect(() => {
        fetchMahalleList();

    }, [])


    var listTable = "";
    if (listData != null && listData.length > 0) {
        listTable = renderTable(listData);
    }


    return (
        <div className="">
            <div className="bg-light m-2 p-3 rounded-3">
                <div className="row mb-3 pb-3 border-bottom">
                    <div className="col-6 " >
                        <h5>Ada/Parsel</h5>
                    </div>
                    <div className="col-6 text-end" >
                        <button onClick={() => props.changeComponent(<CBSADAMatching changeComponent={props.changeComponent} />)} className="btn btn-sm btn-success text-decoration-none me-1">TKGM ile Eşleştir</button>
                        <button className="btn btn-secondary me-3 btn-sm text-decoration-none fw-bold" id="mypopover" data-bs-trigger="focus" data-bs-toggle="popover" title="AÇIKLAMA" data-bs-content="TKGM ada/parsel verisi ile UNIVERSE'de kullanılan BAŞVURUYA ESAS ada/parsel verisini karşılaştırır. Toplu olarak veri ekleyip silebileceğiniz gibi tek tek de işlem yapabilirsiniz. Dikkat: Bu işlem uzun sürebilmektedir. Lütfen işlem tamamlanmadan sayfayı kapatmayınız.">?</button>

                        <button onClick={() => props.changeComponent(<KadastralVeri changeComponent={props.changeComponent} />)} className="btn btn-primary btn-sm text-decoration-none ">Geri Dön</button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 fw-bold mb-4">
                        Manuel Ada/Parsel Ekle
                    </div>

                </div>

                <div className="row  mb-2 pb-4 border-bottom">
                    <div className="col-3">
                        <select className="form-select form-select-sm" id="mahalleId" name="mahalleId" required={true} onChange={fetchList} >
                        </select>
                    </div>
                    <div className="col-3">
                        <input type="text" className="form-control form-control-sm" id="name" placeholder="Ada/Parsel" name="name" onChange={handleInputChange} defaultValue="" onKeyPress={validateNumberAndForwardSlash} />
                    </div>
                    <div className="col-5">
                        <input type="text" className="form-control form-control-sm" id="koordinat" placeholder="Koordinat" name="koordinat" onChange={handleInputChange} defaultValue="" />
                    </div>
                    <div className="col-1 text-end">  <button onClick={Add} className="btn btn-sm btn-primary text-decoration-none">Ekle</button></div>
                </div>


                <div className="row  mt-2">
                    <div className="col-3" id="totalrec">
                        {listData != null ? listData.length : 0} kayıt
                    </div>
                    <div className="col-3 text-center">
                        <input className="form-control form-control-sm " id="mySearchInput" type="text" placeholder="Ara" onKeyUp={filterTable} />
                    </div>


                </div>



               

                {listTable}
            </div>
            <div className="text-center mt-5 textvmuted" id="mahallesecimmsg"><h2>Lütfen öncelikle mahalle seçimi yapınız</h2>  </div>
        </div>
    );


}