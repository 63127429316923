import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/js/bootstrap.min.js'
import * as UserPermConstants from '../User/UserPermConstants';
import { isEmpty, showErrorMessage, useGlobalState } from '../GlobalState.js';
import Apply4 from "./Apply4.js";
import Apply6 from "./Apply6.js";
import FilePanel from "./FilesPanel.js";

import Application from "./Application.js";
import NotePanel from './NotePanel.js';

export default function Apply5(props) {
    const [role, setRole] = useGlobalState('role');

    useEffect(() => {


    }, [])


    var applicationdata = props.applicationdata;

    return <div  >

        <div className="row" >
            <div className="  col-md-2 col-12 " >
                <div className="  w-75 fw-bold border-bottom border-3 " >
                    1. Başvuru Tipi

                </div>
            </div>
            <div className=" col-md-2 col-12 " >
                <div className="  w-75 fw-bold border-bottom border-3 " >
                    2. Konum
                </div>
            </div>
            <div className=" col-md-2 col-12    " >
                <div className="  w-75 fw-bold border-bottom border-3 " >
                    3. Müellifler
                </div>
            </div>
            <div className=" col-md-2 col-12    " >
                <div className="  w-75 fw-bold border-bottom border-3 " >
                    4. Diğer Sorular
                </div>
            </div>
            <div className=" col-md-2 col-12    text-primary " >
                <div className="  w-75 fw-bold border-bottom border-3 " >
                    5. Dosyalar
                </div>
            </div>
            <div className=" col-md-2 col-12     " >
                <div className="  w-75 fw-bold border-bottom border-3 " >
                    6. Onay
                </div>
            </div>
        </div>

        <div className="row m-3" >
            <div className="col-12" >
                <div className="  align-items-center justify-content-center p-1" >


                        <div className="mt-1 row rounded-5" >
                            <FilePanel appData={applicationdata} />
                        </div>





                        <div className="row mt-3 border-top pt-2" >
                            <div className="col-6 text-start">
                                <button type="button" className="btn btn-sm btn-primary mb-2 " onClick={() => { props.changeComponent(<Apply4 applicationdata={applicationdata} changeComponent={props.changeComponent} />) }}>Geri Dön</button>
                            </div>

                            <div className="col-6 text-end">
                            <button type="button" name="actionSave" className="btn btn-sm btn-primary mb-2 " onClick={() => {
                                var eksikdiv = document.getElementById("eksikdosyalar");
                                if (role.indexOf(UserPermConstants.Yeni_Basvuruda_Zorunlu_Alanlari_Bos_Birakabilme) > -1  || eksikdiv == null || isEmpty(eksikdiv.innerHTML) || window.selectedAppType == null || window.selectedAppType.eksiksiz!=true )
                                    props.changeComponent(<Apply6 applicationdata={applicationdata} changeComponent={props.changeComponent} />)
                                else showErrorMessage("Başvuruya devam edebilmek için kırmızı olarak işaretlenen zorunlu dosyaları yüklemeniz gerekmektedir.")
                            }}>
                                Kaydet ve Devam Et
                                
                            </button>
                            </div>
                        </div>

                   
                </div>
            </div>
        </div >
    </div >

}



