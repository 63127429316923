import React, { useState, useEffect } from 'react';
//import SablonMappingPanel from './SablonMappingPanel';
import { useLocation } from 'react-router-dom'
import SharedUsersPanel from './SharedUsersPanel.js';
import SendNotificationPanel from './SendNotificationPanel.js';
import Application from './Application.js';
import { showErrorMessage, showSuccessMessage, useGlobalState } from '../GlobalState.js';
import * as UserPermConstants from '../User/UserPermConstants';
export default function Sharing(props) {

    const [listData, setListData] = useState(null);
    const [useroptions, setOptions] = useState([]);
    let location = useLocation();
    const [role, setRole] = useGlobalState('role');
    const me = <Sharing changeComponent={props.changeComponent} />


    const [appData, setData] = useState(props.rec != null ? props.rec : location.state.rec);

    const fetchSharingList = () => {
        fetch("sablonlist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                response.text().then(value => {
                    showErrorMessage(response.statusText)
                })
            }
            else {
                response.json().then(value => {
                    setListData(value);


                })
            }
        });
    }


    const copyToClipboard = () => {
        var copyText = document.getElementById("plink");
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        navigator.clipboard.writeText(copyText.value);
        showSuccessMessage("Link kopyalandı " + copyText.value);
    }





    useEffect(() => {
        fetchSharingList();
    }, [])



    return (
        <div className="row p-4 pt-3" style={{ "background-color": "#ebebeb" }}>
            <div className="row rounded-3 m-0 p-0">
                <div className="col-12 text-end m-0 p-0">
                    <button type="button" className="btn btn-sm btn-primary mb-1 " onClick={() => { props.changeComponent(<Application appData={appData} changeComponent={props.changeComponent} backButton={props.backButton} />) }}>Geri Dön</button>
                </div>
            </div>
            <div className="row rounded-3 p-2 m-1 bg-white  pb-3">
                <h5 style={{ color: "#0098db" }}>Başvuru No {appData.id}</h5>
                {role.indexOf(UserPermConstants.Link_ile_Kart_Paylasimi) > -1 && <><h6>Paylaşım Linki</h6>
                    <div className="col-md-6 col-12 mb-1">
                        <input type="text" className="form-control form-control-inline form-control-sm" id="plink" placeholder="Şablon Adı" name="Name"
                            value={window.location.protocol + '//' + window.location.host + "/applicationcard?id_st=" + appData.id + "$" + appData.securityToken} />
                    </div>
                    <div className="col-md-6 col-12"> <a target="_blank" href={"/applicationcard?id_st=" + appData.id + "$" + appData.securityToken} className="btn btn-primary btn-sm  me-2 text-decoration-none ">Aç</a>
                        <button onClick={copyToClipboard} className="btn btn-sm btn-primary me-2">Kopyala</button>
                    </div>
                </>}
            </div>

            {/*<div className="row rounded-3  p-2 m-1 mt-3 bg-white">*/}
            {/*    <div className='col-12  p-0 mb-2' >*/}
            {/*        <h5 style={{ color: "#0098db" }}>İlgili Kullanıcılar</h5>*/}
            {/*    </div>*/}
            {/*    <div className='col-6  p-0 mb-2' >*/}
            {/*        Başvuru Yapan*/}
            {/*    </div>*/}
            {/*    <div className='col-6  p-0 mb-2' >*/}
            {/*        <img className="rounded-circle" style={{ width: "32px", height: "32px" }} src={"/getuserpic?user=" + appData.basvuruYapan} /> &nbsp;*/}
            {/*        {appData.basvuruYapan}*/}
            {/*    </div>*/}
            {/*    <div className='col-6  p-0 mb-2' >*/}
            {/*        Müteahhit*/}
            {/*    </div>*/}
            {/*    <div className='col-6  p-0 mb-2' >*/}
            {/*        <img className="rounded-circle" style={{ width: "32px", height: "32px" }} src={"/getuserpic?user=" + appData.mimar} /> &nbsp;*/}

            {/*        {appData.mimar}*/}
            {/*    </div>*/}
            {/*    <div className='col-6  p-0 mb-2' >*/}
            {/*        Mimar*/}
            {/*    </div>*/}
            {/*    <div className='col-6  p-0 mb-2' >*/}
            {/*        <img className="rounded-circle" style={{ width: "32px", height: "32px" }} src={"/getuserpic?user=" + appData.muteahhit} /> &nbsp;*/}
            {/*        {appData.muteahhit}*/}
            {/*    </div>*/}
            {/*</div>*/}

            {role.indexOf(UserPermConstants.Kullaniciyla_Kart_Paylasimi) > -1 && <> <div className='row  bg-white rounded-3 p-2 m-1 mt-4' >
                <div className='col-12  p-0' >
                    <SharedUsersPanel appData={appData} />
                </div>
            </div>

                {role.indexOf(UserPermConstants.Manuel_Bildirim_Gonderme) > -1 && <div className='row  bg-white rounded-3   p-2 m-1 mt-4 mb-4' >
                    <div className='col-12  p-0 ' >
                        <SendNotificationPanel appData={appData} />
                    </div>
                </div>}
                
                
                </>}
        </div>
    );


}
