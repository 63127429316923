import React, { useState, useEffect } from 'react';
import { ajaxFail, showSuccessMessage, showConfirmMessage } from '../GlobalState.js';

export default function SureclerPanel(props) {
    var postdata = {};
    const [listData, setListData] = useState(null);

    const updateRecord = (rec, event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        rec[name] = value;
    }

    const removeRecord = (id) => {
        showConfirmMessage(id + " Numaralı Kaydı Silmek İstedinizden Emin Misiniz?", "", "Evet", "Hayır", () =>
            fetch("removesurec", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(id)
            }).then(async response => {
                if (!response.ok) {
                    ajaxFail(response)
                }
                else {
                    fetchList();
                }
            }));
    }



    const submitUpdateRecord = (rec) => {

        fetch("updatesurec", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(rec)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                showSuccessMessage("Güncelleme Başarılı")
            }
        });
    }
    const fetchList = () => {

        fetch("sureclist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                response.json().then(value => {
                    setListData(value);
                })
            }
        });
    }

    const Add = () => {
        fetch("addsurec", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(document.getElementById("surecAdi").value)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                document.getElementById("surecAdi").value = "";
                fetchList();
            }
        });

    }


    const renderTable = (tabledata) => {
        var mytable =
            <div className="table-responsive mainpagescroll" style={{ "height": "85vh" }}>
            <table className='table table-striped table-sm' aria-labelledby="tabelLabel">
                    <thead style={{ "position": "sticky", "top": 0, "z-index": "1" }} className="bg-light">
                    <tr>
                        <th>No</th>
                        <th>Süreç Adı</th>
                        <th>Aşama</th>
                        <th>Kayıt Tarihi</th>
                        <th>Ekleyen</th>
                        <th>Ayarlar</th>
                    </tr>
                </thead >
                <tbody id="myTable">
                    {tabledata.map(apprec =>
                        <tr key={apprec.id}>
                            <td>{apprec.id}</td>
                            <td>
                                <input type="text" className="form-control form-control-sm"  name="name" defaultValue={apprec.name} onChange={(e) => updateRecord(apprec, e)} />
                            </td>
                            <td>
                                {apprec.stage}
                            </td>
                            <td>{apprec.createTime}</td>
                            <td>{apprec.userName}</td>

                            <td>
                                <button onClick={() => submitUpdateRecord(apprec)} className="btn btn-link btn-sm text-decoration-none m-0 p-0">Güncelle</button>&nbsp;
                                {apprec.additional==true?<button onClick={() => removeRecord(apprec.id)} className="btn btn-link btn-sm text-decoration-none m-0 p-0">Sil</button>:""}
                            </td>
                        </tr>
                    )}
                    <tr className="bg-white">
                        <td></td>
                        <td>
                            <input type="text" className="form-control form-control-sm" id="name" placeholder="Yeni Süreç" id="surecAdi" name="surecAdi" defaultValue="" />
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>  <button onClick={Add} className="btn btn-sm btn-link text-decoration-none m-0 p-0">Ekle</button></td>
                    </tr>
                </tbody>
            </table>
            </div>
        return (mytable);
    }





    useEffect(() => {
        fetchList();
    }, [])


    var listTable = "";
    if (listData != null) {
        listTable = renderTable(listData);
    }
    else
        listTable = renderTable([]);

    return (
        <div >

            <h5 className="ps-1">Süreçler</h5>
            <div className="row">
                <div className="col-12">
                    {listTable}
                </div>
            </div>
        </div>

    );
}