import { useLocation } from 'react-router-dom'
import React, { useState, useEffect } from 'react';
import { ajaxFail } from '../GlobalState.js';

export default function UserPersonelData(props) {
    const [userdata, setUserData] = useState(props.userdata);
    const [filds, setDynamicFields] = useState(null);

    var dynamicdata = {}
    var dynamicFields = "";


    if (userdata != null && userdata.dynamicData) {
        dynamicdata = JSON.parse(userdata.dynamicData);
    }


    useEffect(() => {
        getFieldList();

    }, [])

    const handleDynamicChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        dynamicdata[target.name] = { "value": value, "type": target.type };
    }

    

    const writeDynamicDataToObject = () => {
        userdata.dynamicData = JSON.stringify(dynamicdata);
    }


    const getFieldList = () => {
        fetch("getuserfields", {
            method: 'GET',
            headers: {
                'Accept': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                response.json().then(async data => {
                    setDynamicFields(data);

                });
            }
        });
    }


    if (filds != null) { //!!!!!dont set the dynamicFields in fetch repoonce. it is in another scope and not see the handlechange
        dynamicFields =

            filds.map(rec => <div className="row mt-3"> <div className="col-12">
                <label htmlFor={rec.name} className="form-label m-0">{rec.name}</label><br />
                {rec.type != "select" ?
                    <input type={rec.type} className={rec.type != "checkbox" ? "form-control" : "form-check-input"}
                        id={rec.name} name={rec.name} defaultChecked={dynamicdata[rec.name] != null ? dynamicdata[rec.name].value : rec.initValue} defaultValue={dynamicdata[rec.name] != null ? dynamicdata[rec.name].value : rec.initValue} onChange={handleDynamicChange} />
                    :
                    <select className="form-select form-select-sm" id={rec.name} name={rec.name} placeholder="Soru Türü" onChange={handleDynamicChange} defaultValue={dynamicdata[rec.name] != null ? dynamicdata[rec.name].value : rec.initValue}>
                        {rec.initValue != null ? rec.initValue.split(",").map(rec =>
                            <option value={rec} >{rec}</option>) : ""}
                    </select>

                }

            </div></div>)
    }






    //if (userdata != null && userdata.dynamicData) {

    //    var ddata = JSON.parse(userdata.dynamicData);
    //    var keys = Object.keys(ddata);
    //    dynamicFields =
    //        keys.map(k =>
    //            <div className="col-6">
    //                <label htmlFor={k} className="form-label">{k}</label><br />
    //                {ddata[k].type != "select-one" ?
    //                    <input type={ddata[k].type} className={ddata[k].type != "checkbox" ? "form-control" : "form-check-input"}
    //                        id={k} placeholder={k} name={k} defaultValue={ddata[k].value} defaultChecked={ddata[k].value} onChange={handleDynamicChange} />
    //                    :
    //                    <select className="form-select form-select-sm" id={k} name={k} onChange={handleDynamicChange} >
    //                        {ddata[k].value != null ? ddata[k].value.split(",").map(rec =>
    //                            <option value={rec}>{rec}</option>) : ""}
    //                    </select>

    //                }
    //            </div>

    //        )
    //}





    return <div >
        <div className="row">

            <div className="col-3">
                <h5>Diğer Bilgileri</h5>
            </div>
        </div>
        <div className="row">

            {dynamicFields}
        </div>


        <button type="submit" onMouseDown={ writeDynamicDataToObject} className="btn btn-primary btn-sm mt-4">Güncelle</button>
    </div>


}




