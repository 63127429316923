import React, { useState, useEffect } from 'react';
import { ajaxFail, chartColors } from '../GlobalState.js';

import ReactDOM from 'react-dom';

export default function TmamlanmaStat(props) {

    const [appType, setAppType] = useState(null);
    const [userapps, setUserApps] = useState(null);

    var userdataarray = [];
    var basvurutipidataarray = [];
    var userstat = {}
    var basvurutipistat = {}
    var mevkistat = {}
    var mahallestat = {}


    const handleInputChange = (event) => {
        const target = event.target;
        setAppType(target.value);
    }


    var sortField = null;
    var sortDirection = {}
    const myCompare = (a, b) => {
        if (sortField == null)
            sortField = "transactionUUID"
        var av = a[sortField];
        var bv = b[sortField];
        if (av == null && bv == null)
            return 0;
        if (av == null && bv != null)
            return -1 * sortDirection[sortField];
        if (av != null && bv == null)
            return 1 * sortDirection[sortField];

        if (typeof av === 'string' || av instanceof String)
            if (typeof bv === 'string' || bv instanceof String)
                return av.localeCompare(bv) * sortDirection[sortField];

        if (av < bv) {
            return -1 * sortDirection[sortField];
        }
        if (av > bv) {
            return 1 * sortDirection[sortField];
        }
        return 0;
    }


    const sortList = (fildname, data) => {
        sortField = fildname;
        if (sortDirection[sortField] == null || sortDirection[sortField] == -1) {
            sortDirection[sortField] = 1;
        }
        else {
            sortDirection[sortField] = -1;
        }
        let sortablelist = [];
        for (var rec in data) {
            var obj = data[rec];
            obj.indexname = rec.name;
            sortablelist.push(obj);
        }
        sortablelist.sort(myCompare);

        let temp = {}
        sortablelist.forEach(function (item) {
            temp[item.name] = item;
        })
        ReactDOM.render(generateTable(temp), document.getElementById("tablePart"))
    }



    const getUserApps = () => {
        fetch("getuserapps", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                response.text().then(value => {
                    alert(response.statusText)
                })
            }
            else {
                response.json().then(value => {
                    var data = JSON.parse(value)
                    var basvurutipleri = new Set()
                    var c = document.getElementById("basvurutipleri");
                    for (var i = 0; i < data.length; i++) {
                        if (basvurutipleri.has(data[i].basvuruTipi) == false) {
                            basvurutipleri.add(data[i].basvuruTipi);
                            var opt = document.createElement('option');
                            opt.value = data[i].basvuruTipi;
                            opt.innerHTML = data[i].basvuruTipi;
                            c.appendChild(opt);
                        }

                    }
                    c.selectedIndex = -1;
                    setUserApps(data)
                })
            }
        });
    }



    useEffect(() => {

        const script = document.createElement("script");
        script.src = "https://www.gstatic.com/charts/loader.js";
        script.async = true;
        document.getElementById("myscript").appendChild(script);
        script.onload = () => {
            window.google.charts.load('current', { packages: ['corechart'] });
            window.google.charts.setOnLoadCallback(getUserApps);

        }

    }, [props.counter]);


    const generateTable = (data) => {
        var keys = Object.keys(data);
        return <div className="table-responsive">
            <table className='table  mt-3 table-vdstriped p-0' aria-labelledby="tabelLabel">
                <thead >
                    <th>

                    </th>
                    <th className="text-start">
                        <button className="btn btn-link btn-sm text-decoration-none" onClick={() => sortList("name", data)} > İsim </button>
                    </th>
                    <th className="text-start">
                        <button className="btn btn-link btn-sm text-decoration-none" onClick={() => sortList("toplamBasvuru", data)} >  Toplam Başvuru </button>
                    </th>
                    <th className="text-start">
                        <button className="btn btn-link btn-sm text-decoration-none" onClick={() => sortList("gunOrtalama", data)} >   Günlük Ortalama (işgünü) </button>
                    </th>
                    <th className="text-start">
                        <button className="btn btn-link btn-sm text-decoration-none" onClick={() => sortList("ayOrtalama", data)} >  Aylık Ortalama </button>
                    </th>
                    {/*<th className="text-start">*/}
                    {/*    <button className="btn btn-link btn-sm text-decoration-none" onClick={() => sortList("yilOrtalama", data)} >  Yıllık Ortalama </button>*/}
                    {/*</th>*/}
                </thead>
                <tbody id="myTable">
                    {keys.map((rec, idx) => data[rec].toplamBasvuru > 0 &&
                        <tr>
                            <td className="text-start">
                                {idx + 1}
                            </td>
                            <td className="text-start">
                                {data[rec].name}
                            </td>
                            <td className="text-start">
                                {data[rec].toplamBasvuru}
                            </td>
                            <td className="text-start">
                                {data[rec].gunOrtalama}
                            </td>
                            <td className="text-start">
                                {data[rec].ayOrtalama}
                            </td>
                            {/*<td className="text-start">*/}
                            {/*    {data[rec].yilOrtalama}*/}
                            {/*</td>*/}
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    }


    const drawChart = (mydata, target) => {
        var dataarray = [];

        if (mydata != userstat)
            document.getElementById("basvurutipleri").hidden = true;
        else
            document.getElementById("basvurutipleri").hidden = false;

        dataarray.push(['', 'Toplam Başvuru', { role: 'annotation' }, { role: 'annotation' }, { role: 'style' },
            'Günluk Ortalama (işgünü) ', { role: 'annotation' }, { role: 'annotation' }, { role: 'style' },
            'Aylık Ortalama', { role: 'annotation' }, { role: 'annotation' }, { role: 'style' }//,
            // 'Yıllık Ortalama', { role: 'annotation' }, { role: 'annotation' }, { role: 'style' }
        ]);

        var nodata = true;
        for (var rec in mydata) {
            if (mydata[rec].toplamBasvuru > 0) {
                nodata = false;
                dataarray.push([mydata[rec].name,
                mydata[rec].toplamBasvuru, mydata[rec].toplamBasvuru, "",
                    'stroke-color: #000000; stroke-width: 1;',
                Number(mydata[rec].gunOrtalama),
                Number(mydata[rec].gunOrtalama),
                    "", 'stroke-color: #000000; stroke-width: 1;',
                Number(mydata[rec].ayOrtalama),
                Number(mydata[rec].ayOrtalama),
                    "", 'stroke-color: #000000; stroke-width: 1;'//,
                    //Number(mydata[rec].yilOrtalama),
                    //Number(mydata[rec].yilOrtalama), "",
                    //    'stroke-color: #000000; stroke-width: 1;'
                ]
                );
            }
        }
        if (nodata == true) {
            document.getElementById("tablePart").innerHTML = "";
            document.getElementById(target).innerHTML = "";

            return;
        }


        var data = window.google.visualization.arrayToDataTable(dataarray);
        var options = {
            colors: chartColors,
            chartArea: {
                // leave room for y-axis labels
                top: 20,
                width: '90%',
                height: '80%'
            },
            hAxis: {
                slantedTextAngle: 45,
                textStyle: {
                    fontSize: 14

                },
                baselineColor: "EEF8FB"

            },
            vAxis: {
                gridlines: {
                    color: "EEF8FB"
                },

                minorGridlines: {
                    color: 'transparent'

                },
                textStyle: {
                    fontSize: 16 // or the number you want
                }
                ,
                baselineColor: "DDF7FD"

            },
            annotations: {
                stem: {
                    length: 0
                },
                alwaysOutside: true,
                textStyle: {
                    auraColor: 'none',
                    color: '#000000',
                }

            },
            animation: {
                duration: 1200,
                easing: 'out',
                "startup": true
            },

            width: '100%',
            legend: { position: "top" },

        };


        var chart = new window.google.visualization.ColumnChart(document.getElementById(target));

        chart.draw(data, options);
        ReactDOM.render(generateTable(mydata)
            ,
            document.getElementById("tablePart")
        );


    }







    const generateReport = (data) => {
        userstat = {}
        basvurutipistat = {}
        mevkistat = {}
        mahallestat = {}


        for (var i = 0; i < data.length; i++) {
            userstat[data[i].userName] = { toplamBasvuru: 0, ilkBasvuruTamamlandi: "", name: data[i].isim + " " + data[i].Family }
            basvurutipistat[data[i].basvuruTipi] = { toplamBasvuru: 0, name: data[i].basvuruTipi }
            mevkistat[data[i].mevki] = { toplamBasvuru: 0, name: data[i].mevki }
            mahallestat[data[i].mahalle] = { toplamBasvuru: 0, name: data[i].mahalle }
        }

        for (var i = 0; i < data.length; i++) {
            //ignore all exept tamamlanalar
            if (data[i].basvuruDurumu != 9 && data[i].basvuruDurumu != 8)
                continue;



            if (appType == null || appType == "" || data[i].basvuruTipi == appType)
                if (data[i].Completed != null && data[i].Completed != "") {
                    userstat[data[i].userName].toplamBasvuru++;
                    var username = data[i].userName;

                    if (userstat[username].ilkBasvuruTamamlandi == "" || data[i].Completed < userstat[username].ilkBasvuruTamamlandi) {
                        userstat[username].ilkBasvuruTamamlandi = data[i].Completed;
                    }
                    var h = new Date(userstat[username].ilkBasvuruTamamlandi.trim() + ":00");
                    var diffTime = Date.now() - h.getTime();
                    var diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;
                    userstat[username].gunOrtalama = ((userstat[username].toplamBasvuru / diffDays) * (30 / 21)).toFixed(1);
                    userstat[username].ayOrtalama = ((userstat[username].toplamBasvuru / diffDays) * 30).toFixed(1);
                    // userstat[username].yilOrtalama = (userstat[username].toplamBasvuru / (diffDays / 365 * 255)).toFixed(1);

                }
            //userstat[data[i].userName].toplamSure += diffDays;
            basvurutipistat[data[i].basvuruTipi].toplamBasvuru++;
            mevkistat[data[i].mevki].toplamBasvuru++;
            mahallestat[data[i].mahalle].toplamBasvuru++;
            // basvurutipistat[data[i].basvuruTipi].toplamSure += diffDays;
        }
        drawChart(userstat, 'userstaticpanel2')
    }

    if (userapps != null)
        generateReport(userapps)

    return (
        <div className="border  rounded-2 mt-1 w-100" style={{ "height": "100%" }}>
            <div id="myscript"></div>
            <h4>Tamamlanma İstatistikleri</h4>

            <div className="p-1 mb-3">
                <button className={"btn   btn-sm  rounded ps-1 pe-1 me-1  btn-primary"} onClick={() => drawChart(userstat, 'userstaticpanel2')}>
                    Kontrolör
                </button>
                <button className={"btn   btn-sm  rounded ps-1 pe-1 me-1  btn-primary"} onClick={() => drawChart(basvurutipistat, 'userstaticpanel2')}>
                    Başvuru Tipi
                </button>
                <button className={"btn   btn-sm  rounded ps-1 pe-1 me-1  btn-primary"} onClick={() => drawChart(mevkistat, 'userstaticpanel2')}>
                    Mevki
                </button>
                <button className={"btn   btn-sm  rounded ps-1 pe-1 me-1  btn-primary"} onClick={() => drawChart(mahallestat, 'userstaticpanel2')}>
                    Mahalle
                </button>
            </div>
            <div style={{ "max-width": "300px" }} className="p-1 mb-3">
                <select className="form-select form-select-sm" id="basvurutipleri" name="basvuruTipi" required={true} onChange={handleInputChange}>
                    <option value=""></option>
                </select>
            </div>


            <div id="userstaticpanel2" style={{ "height": "600px" }} className="w-100  text-center">
                <div class="spinner-border" ></div>
            </div>

            <div id="tablePart" className="w-100  text-center">
                <div class="spinner-border"></div>
            </div>


        </div>

    );
}





