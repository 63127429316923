import React, { useState, useEffect } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import { ajaxFail, showConfirmMessage, showSuccessMessage } from '../GlobalState.js';

export default function SabitSoruSetting(props) {
    var postdata = {};
    const [listData, setListData] = useState([]);
    const [denetimListData, setDenetimListData] = useState([]);
    const multiselectRef = React.createRef();
    const multiselectRefNew = React.createRef();

    const fetchSabitSoruList = () => {
        fetch("getsabitsorulist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                response.json().then(value => {
                    setListData(value);
                })
            }
        });
    }
    const fetchDenetimList = () => {
        fetch("denetimlist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                response.json().then(value => {
                    setDenetimListData(value);
                    fetchSabitSoruList();
                })
            }
        });
    }

    const updateRecord = (rec, event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        rec[name] = value;
    }

    const renderTable = (tabledata) => {
        var mytable =
            <div className="table-responsive mainpagescroll" style={{ "height": "85vh" }}>
                <table className='table table-vdstriped  table-sm' aria-labelledby="tabelLabel">
                    <thead style={{ "position": "sticky", "top": 0, "z-index": "1" }} className="bg-light">
                        <tr>
                            {/* <th>ID</th>*/}
                            <th>Soru Adı</th>
                            <th>Pasif</th>
                            <th>Gizli</th>
                            <th>İsteğe bağlı</th>
                            <th>Başvuru Tipi</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody id="myTable">
                        {tabledata.map(apprec =>
                            <tr key={apprec.id}>
                                {/* <td>{apprec.id}</td>*/}
                                <td>
                                    <select class="form-select" onChange={handleInputChange} name="name" onChange={(e) => updateRecord(apprec, e)}>
                                        <option value="boylam" selected={apprec.name == "boylam"}>boylam</option>
                                        <option value="enlem" selected={apprec.name == "enlem"}>enlem</option>
                                        <option value="mevki" selected={apprec.name == "mevki"}>mevki</option>
                                        <option value="mahalle" selected={apprec.name == "mahalle"}>mahalle</option>
                                        <option value="idariMahalle" selected={apprec.name == "idariMahalle"}>idariMahalle</option>
                                        <option value="ada" selected={apprec.name == "ada"}>ada</option>
                                        <option value="arsaSahibi" selected={apprec.name == "arsaSahibi"}>arsaSahibi</option>
                                        <option value="arsaSahibiTel" selected={apprec.name == "arsaSahibiTel"}>arsaSahibiTel</option>
                                        <option value="arsaSahibiTC" selected={apprec.name == "arsaSahibiTC"}>arsaSahibiTC</option>
                                        <option value="sokak" selected={apprec.name == "sokak"}>sokak</option>
                                        <option value="no" selected={apprec.name == "no"}>no</option>
                                        <option value="m2" selected={apprec.name == "m2"}>m2</option>
                                    </select>
                                </td>
                                <td className="text-center">
                                    <input type="checkbox" className="form-check-input" name="enable" defaultChecked={apprec.enable} onChange={(e) => updateRecord(apprec, e)} />
                                </td>
                                <td className="text-center">
                                    <input type="checkbox" className="form-check-input" name="visible" defaultChecked={apprec.visible} onChange={(e) => updateRecord(apprec, e)} />
                                </td>
                                <td className="text-center">
                                    <input type="checkbox" className="form-check-input" name="zorunlu" defaultChecked={apprec.zorunlu} onChange={(e) => updateRecord(apprec, e)} />
                                </td>
                                <td>
                                    <Multiselect className="p-0 ps-1 form-control-sm bg-white"
                                        options={denetimListData.map(x => x.name)}
                                        emptyRecordMsg="Başka Kayıt Yok"
                                        ref={multiselectRef}
                                        //displayValue="name"
                                        isObject={false}
                                        onSelect={(selectedList, removedItem) => apprec["basvuruTipi"] = selectedList.length == 0 ? null : selectedList.join()} // Function will trigger on select event
                                        onRemove={(selectedList, removedItem) => apprec["basvuruTipi"] = selectedList.length == 0 ? null : selectedList.join()} // Function will trigger on remove event
                                        selectedValues={apprec.basvuruTipi == null ? [] : apprec.basvuruTipi.split(",")}
                                    />
                                </td>
                                <td>
                                    <button onClick={() => { update(apprec); }} className="btn btn-link btn-sm text-decoration-none m-0 p-0">Guncelle</button>
                                    &nbsp;&nbsp;
                                    <button onClick={() => { sil(apprec); }} className="btn btn-link btn-sm text-decoration-none m-0 p-0">Sil</button>
                                </td>
                            </tr>
                        )}
                        <tr className="bg-white">
                            <td width="25%">
                                <select class="form-select" onChange={handleInputChange} name="name" id="sabitsorular">
                                    <option selected></option>
                                    <option value="boylam">boylam</option>
                                    <option value="enlem">enlem</option>
                                    <option value="mevki">mevki</option>
                                    <option value="mahalle">mahalle</option>
                                    <option value="idariMahalle">idariMahalle</option>
                                    <option value="ada">ada</option>
                                    <option value="arsaSahibi">arsaSahibi</option>
                                    <option value="arsaSahibiTel">arsaSahibiTel</option>
                                    <option value="arsaSahibiTC">arsaSahibiTC</option>
                                    <option value="sokak">sokak</option>
                                    <option value="no">no</option>
                                    <option value="m2">m2</option>
                                </select>

                            </td >
                            <td className="text-center" width="5%">
                                <input type="checkbox" className="form-check-input" name="enable" id="enable" defaultChecked={false} onChange={handleInputChange} />
                            </td >
                            <td className="text-center" width="5%">
                                <input type="checkbox" className="form-check-input" name="visible" id="visible" defaultChecked={false} onChange={handleInputChange} />
                            </td>
                            <td className="text-center" width="5%">
                                <input type="checkbox" className="form-check-input" name="zorunlu" id="zorunlu" defaultChecked={false} onChange={handleInputChange} />
                            </td>

                            <td width="50%">
                                <Multiselect className="p-0 ps-1 form-control-sm bg-white"
                                    options={denetimListData.map(x => x.name)}
                                    emptyRecordMsg="Başka Kayıt Yok"
                                    //displayValue="name"
                                    isObject={false}
                                    ref={multiselectRefNew}
                                    onSelect={(selectedList, removedItem) => { postdata["basvuruTipi"] = selectedList.length == 0 ? null : selectedList.join() }} // Function will trigger on select event
                                    onRemove={(selectedList, removedItem) => { postdata["basvuruTipi"] = selectedList.length == 0 ? null : selectedList.join() }} // Function will trigger on remove event
                                />
                            </td>

                            <td width="10%">  <button onClick={() => add(null)} className="btn btn-sm btn-link text-decoration-none m-0 p-0">Ekle</button>

                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        return (mytable);
    }

    const sil = (rec) => {
        showConfirmMessage(rec.id + " Numaralı Kaydı Silmek İstedinizden Emin Misiniz?", "", "Evet", "Hayır", () =>
            fetch("removesabitsorusetting", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(rec)
            }).then(async response => {
                if (!response.ok) {
                    ajaxFail(response)
                }
                else {
                    response.json().then(value => {
                        setListData(value);
                    })
                }
            }));

    }

    const add = () => {
        fetch("addsabitsorusetting", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postdata)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                response.json().then(value => {
                    multiselectRefNew.current.resetSelectedValues();
                    document.getElementById("sabitsorular").selectedIndex = -1;
                    document.getElementById("enable").checked = false;
                    document.getElementById("visible").checked = false;
                    document.getElementById("zorunlu").checked = false;
                    setListData(value);
                })
            }
        });
    }

    const update = (rec) => {
        fetch("updatesabitsorusetting", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(rec)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                response.json().then(value => {
                    showSuccessMessage("Guncelleme Başarılı")
                   // setListData(value);  //if active it will prevent sequntional update... new list override pending update
                })
            }
        });
    }

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        postdata[target.name] = value;

    }


    useEffect(() => {
        fetchDenetimList();
    }, [])


    var listTable = "";
    if (listData != null) {
        listTable = renderTable(listData);
    }
    else
        listTable = renderTable([]);

    return (
        <div className="border  rounded border-light">

            <h5>Sabit Sorular</h5>
            <div className="row">
                <div className="col-12">
                    {listTable}
                </div>
            </div>
        </div>

    );


}