import { useLocation } from 'react-router-dom'
import React, { useState, useEffect } from 'react';
import { showErrorMessage } from '../GlobalState';


export default function UserMeslekData(props) {
    const [userdata, setUserData] = useState(props.userdata);


    const handleInputChange = (event) => {
        const target = event.target.name;
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        userdata[target] = value;

        if (event.target.type === 'checkbox' && event.target.id === "karaliste")
            document.getElementById("karalistesure").disabled = !event.target.checked;

        if (event.target.type === 'checkbox' && event.target.id === "denetimYetkisi" && value == true) {
            showErrorMessage("Yetki Turleri Denetim Yetkileri Panelinden Belirlenmelidir")
        }

    }

    return <div >
        <div className="row">
            <h5>Meslek Bilgileri</h5>
            <div className="col-3">

            </div>
            <div className="col-2">
             </div>
        </div>

        <div className="row pt-4">
            <div className="col-3">
                <label htmlFor="UserName" className="form-label">Meslek</label>
            </div>
            <div className="col-8">
                <input  type="text" className="form-control col-9" id="meslek" placeholder="Meslek" name="meslek" onChange={handleInputChange} defaultValue={userdata.meslek} />
            </div>
        </div>

        <div className="row pt-4">
            <div className="col-3">
                <label htmlFor="UserName" className="form-label">Firma</label>
            </div>
            <div className="col-8">
                <input  type="text" className="form-control col-9" id="firma" placeholder="Firma" name="firma" onChange={handleInputChange} defaultValue={userdata.firma} />
            </div>
        </div>

        <div className="row pt-4">
            <div className="col-3">
                <label htmlFor="Name" className="form-label">Pozisyonu</label>
            </div>
            <div className="col-8">
                <input type="text" className="form-control" id="pozisyonu" placeholder="Pozisyonu" name="pozisyonu" onChange={handleInputChange} defaultValue={userdata.pozisyonu} />

            </div>
        </div>


        <div className="row pt-4">
            <div className="col-3">
                <label htmlFor="Family" className="form-label">Firma Konumu</label>
            </div>
            <div className="col-8">
                <input type="text" className="form-control" id="firmaKonumu" placeholder="Firma Konumu" name="firmaKonumu" onChange={handleInputChange} defaultValue={userdata.firmaKonumu} />
            </div>
        </div>

        <div className="row pt-4">
            <div className="col-3">
                <label htmlFor="Family" className="form-label">Firma Web Sitesi</label>
            </div>
            <div className="col-8">
                <input type="text" className="form-control" id="firmaWebSitesi" placeholder="Firma Web Sitesi" name="firmaWebSitesi" onChange={handleInputChange} defaultValue={userdata.firmaWebSitesi} />
            </div>
        </div>

        <button type="submit" className="btn btn-primary btn-sm mt-4">Güncelle</button>
    </div>


}




