import React, { useState, useEffect } from 'react';
import { ajaxFail, isEmpty } from '../GlobalState.js';
export default function ToDoList(props) {
    var postdata = {};
    const [listData, setListData] = useState([]);
    //const colors = ["#fff8dd", "#e8fff3", "#fff5f8", "#f8f5ff"]
    const bordercolors = ["#50cc88", "#009cf7", "#ffc800", "#f1416d"]
    const colors = ["#fff0ee", "#ffffff"]
    
    var duyurucount = listData.length;
    const updateRecord = (rec, event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        rec[name] = value;
    }

    const removeRecord = (id) => {
        fetch("removetodoList", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(id)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                response.json().then(value => {
                    setListData(value);
                })
            }
        });
    }



    const submitUpdateRecord = (rec) => {

        fetch("updatetodolist", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(rec)
        }).then(async response => {
            if (!response.ok) {
                response.text().then(value => {
                    alert(response.statusText)
                })
            }
            else {
                alert("Güncelleme Başarıyla Tamamlanmştır")
            }
        });
    }

    const fetchList = () => {

        fetch("gettodolist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                response.json().then(value => {
                    setListData(value);
                })
            }
        });
    }

    const Add = () => {
        fetch("addtodolist", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postdata)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                document.getElementById("taskName").value = "";
                fetchList();

            }
        });

    }

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        postdata[target.name] = value;
    }

    const changeSize = (event) => {
        const target = event.target;
        if (isEmpty(target.style.height))
            target.style.height = target.scrollHeight + "px";
        else
            target.style.height = null;
    }
    

    const renderTable = (tabledata) => {

        var mytable = <div className="overflow-auto vdscrollbar" style={{ maxHeight: "300px" }}>
            <div className="row pt-1 pb-1 m-1 rounded-3">
                <div className="col-md-11 col-12 m-0 p-0">
                    <input type="text" className="form-control form-control-sm" id="taskName" placeholder="Yeni" name="taskName" onChange={handleInputChange} defaultValue="" />
                </div>
                <div className="col-12 col-md-1 text-end">  <button onClick={Add} className="btn btn-sm btn-link text-decoration-none p-0 shadow-none" style={{ fontSize:"17px" }}><i className="fa fa-plus-circle"></i></button></div>
            </div>


            {tabledata.map((apprec,idx) =>
                < div className="row pt-2 pb-2 m-1 mb-1 rounded-3" style={{ "background-color": colors[idx % colors.length] }} key={apprec.id}>

                    <div className="col-md-10 col-12 " >
                        <textarea className="form-control form-control-sm border-0" rows={1} style={{ "background-color": "transparent" }} name="taskName" defaultValue={apprec.taskName} onChange={(e) => updateRecord(apprec, e)} onClick={ changeSize} />
                    </div>

                    <div className="col-md-2 col-12 text-end" >
                        <button onClick={() => submitUpdateRecord(apprec)} className="btn btn-link btn-sm text-decoration-none p-0 shadow-none" style={{ fontSize: "16px" }}><i className="fa fa-floppy-o"></i></button>
                        <button onClick={() => removeRecord(apprec.id)} className="btn btn-link btn-sm text-decoration-none p-0 shadow-none" style={{ fontSize: "16px" }}><i className="fa fa-trash-o"></i></button>
                    </div>
                </div>
            )}
        </div>

        return (mytable);
    }





    useEffect(() => {
        fetchList();
    }, [])


    var listTable = "";
    if (listData != null) {
        listTable = renderTable(listData);
    }
    else
        listTable = renderTable([]);

    return (
        <div className=" rounded-3 1">
            <div className="row">
                <div className="col-7">
                    <h6 style={{color: "#0098db"}}>Yapılacaklar Listesi</h6>
                </div>
                <div className="col-5 justify-content-end align-items-end text-end text-secondary"><b>{duyurucount}</b> kayıt</div>
            </div>

           
                    {listTable}
        </div>

    );


}