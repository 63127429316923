import React, { Component, useEffect, useState } from 'react';
import { logout, ajaxFail } from '../GlobalState.js';

export default function UserLabel(props) {
    const [myuser, setUser] = useState(props.user);

    var size = props.picSize != null ? props.picSize : 32;

    size=size+"px"
    useEffect(() => {
        if (myuser.family == null)
            getuserinfo();
    }, [])

    const getuserinfo = () => {

        fetch("getuserpublcinfo", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(props.user.userName)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                response.json().then(async data => {
                    if (data != null && data.length > 0) {
                        data = JSON.parse(data);
                        setUser(data);
                        if (props.setNameFamily) {
                            props.setNameFamily(data)
                        }
                    }

                });
            }
        });


    }



    return <span  >
       {/* < img className="rounded-circle" style={{ width: size, height: size }} src={"/getuserpic?user=" + props.user.userName} /> &nbsp;*/}
         {myuser.name} {myuser.family} {props.showUserName == true ? "("+props.user.userName+")":""}
    </span>


}



