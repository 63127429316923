import React, { useState, useEffect } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import { showConfirmMessage, showErrorMessage, showSuccessMessage, useGlobalState } from '../GlobalState.js';
import { ajaxFail, removeTurkcLow } from '../GlobalState.js';
import $ from 'jquery';

import axios from "axios";

export default function FileSettingPanel(props) {
    var postdata = {};
    postdata["FileType"] = "";
    const [listData, setListData] = useState([]);
    const [fileGurups, setFileGrupList] = useState([]);
    const [typeOptions, setTypeOptions] = useState([]);
    var multiselectRef = React.createRef();
    var multiselectRef2 = React.createRef();
    var sortField = null;
    const [role, setrol] = useGlobalState('role');
    const [roles, setRoles] = useState([]);
    const multiselectRefNewRole = React.createRef();



    const updateRecord = (rec, event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        rec[name] = value;
    }

    const update = (rec) => {
        fetch("updatesablonfile", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(rec)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                response.json().then(value => {
                    showSuccessMessage("Guncelleme Başarılı")
                    setListData(value);
                })
            }
        });
    }

    const sil = (rec) => {
        showConfirmMessage(rec.id + " Numaralı Kaydı Silmek İstedinizden Emin Misiniz?", "", "Evet", "Hayır", () =>
            fetch("removesablonfile", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(rec.id)
            }).then(async response => {
                if (!response.ok) {
                    ajaxFail(response);
                }
                else {
                    response.json().then(value => {
                        setListData(value);
                    })
                }
            }));

    }

    const onSelect1 = (selectedList, selectedItem) => {
        postdata["BasvuruTipi"] = multiselectRef.current.getSelectedItems().join(", ")
    }
    const onRemove1 = (selectedList, removedItem) => {
        postdata["BasvuruTipi"] = multiselectRef.current.getSelectedItems().join(", ")
    }
    const onSelect2 = (selectedList, selectedItem) => {
        postdata["fileType"] = selectedList.join(", ")
    }
    const onRemove2 = (selectedList, removedItem) => {
        postdata["fileType"] = selectedList.join(", ")
    }


    const getAppTypes = () => {
        fetch("denetimlist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    var opt = [];
                    for (var x of value) {
                        opt.push(x.name)
                    }
                    setTypeOptions(opt);
                })
            }
        });
    }



    const fetchFileList = () => {
        fetch("sablonfilenamelist", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    setListData(value);
                })
            }
        });
    }

    const renderTable = (tabledata) => {
        var mytable =
            <div className="table-responsive mainpagescroll" style={{ "height": "85vh" }}>
            <table className='table table-vdstriped table-sm' aria-labelledby="tabelLabel">
                    <thead style={{ "position": "sticky", "top": 0, "z-index": "1" }} className="bg-light ">
                    <tr>
                        <th width="3%">id</th>
                        <th width="30%">Açıklama</th>
                        <th width="25%">Başvuru Türü</th>
                        <th width="20%">Dosya İsmi</th>
                        <th width="20%">Tarih</th>
                        <th width="2%"></th>
                    </tr>
                </thead>
                <tbody id="myTable">
                    {tabledata.map(apprec =>
                        <tr key={apprec.id}>
                            <td>{apprec.id}</td>
                            <td>
                                
                                <input type="text" className="form-control form-control-sm" placeholder="Açıklama" name="caption" defaultValue={apprec.caption} onChange={(e) => updateRecord(apprec, e)}  />

                            </td>
                            <td>
                                <Multiselect className="text-dark form-select-sm m-0 p-0 w-100"
                                    options={typeOptions}
                                    emptyRecordMsg="Başka kayıt yok"
                                    isObject={false}
                                    readonly
                                    selectedValues={apprec.basvuruTipi != null && apprec.basvuruTipi.length > 0 ? apprec.basvuruTipi.split(',') : []}
                                    onSelect={(selectedList, removedItem) => { apprec.basvuruTipi = selectedList.length == 0 ? null : selectedList.join() }} 
                                    onRemove={(selectedList, removedItem) => { apprec.basvuruTipi = selectedList.length == 0 ? null : selectedList.join() }} 
                                    hidePlaceholder={true}
                                />
                                {/*{apprec.basvuruTipi != null && apprec.basvuruTipi.length > 0 ? apprec.basvuruTipi.split(',') : ""}*/}
                            </td>
                            <td>{apprec.fileName}</td>
                            <td>{apprec.tarih}</td>
                            <td>
                                <button onClick={() => sil(apprec)} className="btn btn-link btn-sm text-decoration-none">Sil</button>
                                &nbsp;<button onClick={() => { update(apprec); }} className="btn btn-link btn-sm text-decoration-none m-0 p-0">Guncelle</button>
                                &nbsp;<a href={"./getsablonfile?id=" + apprec.id} download={apprec.fileName} className="text-primary text-decoration-none">İndir</a>
                            </td>
                        </tr>
                    )}
                    <tr className="bg-white">
                        <td></td>
                        <td>
                            <input type="text" className="form-control form-control-sm" id="caption" placeholder="Açıklama" name="caption" onChange={handleInputChange} />
                        </td>
                        <td >

                            <Multiselect className="text-dark form-select-sm m-0 p-0"
                                options={typeOptions}
                                emptyRecordMsg="Başka Başvuru Tipi Yok"
                                isObject={false}
                                ref={multiselectRef2}
                               // onSelect={onSelect2} 
                              //  onRemove={onRemove2} 
                                hidePlaceholder={true}

                            />
                        </td>
                        <td >
                        </td>
                        <td>
                        </td>

                        <td>
                            <button className="btn btn-sm fw-bold p-1 ps-2 pe-2 me-2"
                                style={{ "background-color": "#E1F0FF", "color": "#36A4FF" }} onClick={() => { document.getElementById("selectedsablonFile").click(); }} > <i class="fa fa-plus" style={{ "font-size": "18px" }}></i>
                            </button>
                            <input type="file" id="selectedsablonFile" style={{ "display": "none" }} accept="" onChange={(e) => handleSelectedFile(e)} />
                        </td>
                    </tr>
                </tbody>
            </table>
            </div>
        return (mytable);
    }


    const SendFiles = (file) => {

        var formData = new FormData();
        formData.append("dosya", file);
        formData.append("fileName", file.name);
        formData.append("caption", document.getElementById("caption").value);
        formData.append("basvuruTipi", multiselectRef2.current.getSelectedItems().join(", "));
        axios.post(
            "submitsablonfile",
            formData,
            {
                headers: { "Content-type": "multipart/form-data", },
            }
        ).then(res => {
            document.getElementById("caption").value = "";
            multiselectRef2.current.resetSelectedValues();
            fetchFileList();


        })
            .catch(err => { showErrorMessage(err); })
    }



    const handleSelectedFile = (event) => {
        if (event.target.files[0] != null) {
            SendFiles(event.target.files[0]);
        }
    }

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        postdata[target.name] = value;
    }

    useEffect(() => {
        fetchFileList();
        getAppTypes();
    }, [])


    var listTable = renderTable(listData);

    return (
        <div >
            <h5>Şablon Dosyalar</h5>
            <div className="row">
                <div className="col-12">
                    {listTable}
                </div>
            </div>
        </div>

    );
}