import React, { useState, useEffect } from 'react';
import KadastralVeri from '../Application/KadastralVeri.js';

import $ from 'jquery';

import { ajaxFail, cutString, removeTurkcLow, showConfirmMessage, toUpperCaseTurkce } from '../GlobalState.js';
import bootstrap from 'bootstrap/dist/js/bootstrap.min.js'

import CBSMahalleMatching from './CBSMahalleMatching.js';
import MahalleSetting from './MahalleSetting.js';

export default function CBSMahalleSetting(props) {
    const [listData, setListData] = useState(null);
    var sortField = null;
    const [sortDirection, setSortDirection] = useState({});

    useEffect(() => {
        fetchList();
        var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
        var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
            return new bootstrap.Popover(popoverTriggerEl)
        })
    }, [])


    const getDurumName = (d) => {
        if (d == 0) return "Bilinmiyor";
        if (d == 1) return "Taslak";
        if (d == 2) return "Pasif";
        if (d == 3) return "Aktif";
    }

    const myCompare = (a, b) => {
        var av = a[sortField];
        var bv = b[sortField];
        var ret = 1;
        if (sortDirection[sortField] == "ZA")
            ret = -1;

        if (av == null && bv != null)
            return -1 * ret;
        if (av != null && bv == null)
            return ret;


        if (typeof av === 'string' || av instanceof String)
            if (typeof bv === 'string' || bv instanceof String)
                return av.localeCompare(bv) * ret;
        if (av < bv) {
            return -1 * ret;
        }
        if (av > bv) {
            return ret;
        }
        return 0;
    }

    const sortList = (fildname) => {
        sortField = fildname;
        if (sortDirection[sortField] == null || sortDirection[sortField] == "ZA") {
            sortDirection[sortField] = "AZ";
        }
        else {
            sortDirection[sortField] = "ZA";
        }
        var temp = [...listData]; //clone the array to update the component on setApplist
        temp.sort(myCompare);
        setListData(temp);
    }

    const refreshList = () => {
        setListData(null);
        fetch("fetchcbsmahallelist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    setListData(value);
                })
            }
        });
    }


    const fetchList = () => {

        fetch("getcbsmahallelist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    setListData(value);
                })
            }
        });
    }

    const renderTable = (tabledata) => {
        var mytable =
            <div className="table-responsive  mainpagescroll table-sm " style={{ "height": "85vh" }}>
                <table className='table table-vdstriped  table-sm' aria-labelledby="tabelLabel">
                    <thead style={{ "position": "sticky", "top": 0, "z-index": "1" }} className="bg-white">
                        <tr>
                            <th className="text-start">
                                <button onClick={() => sortList("tapukimlikno")} className="btn btn-link btn-sm text-decoration-none m-0 p-0">ID</button>
                                
                            </th>
                            <th className="text-start">
                                <button onClick={() => sortList("tapumahallead")} className="btn btn-link btn-sm text-decoration-none m-0 p-0">MAHALLE</button>
                                
                            </th>
                            <th className="text-start">
                                <button onClick={() => sortList("durum")} className="btn btn-link btn-sm text-decoration-none m-0 p-0">DURUM</button>
                                
                            </th>
                            <th className="text-start">
                                <button onClick={() => sortList("coordinates")} className="btn btn-link btn-sm text-decoration-none m-0 p-0">KOORDİNAT</button>
                            </th>
                        </tr>
                    </thead>
                    <tbody id="myTable">
                        {tabledata.map(apprec =>
                            <tr style={{ "height": "15px" }}>
                                <td >{apprec.tapukimlikno}</td>
                                <td >    {apprec.tapumahallead}</td>
                                <td >    {getDurumName(apprec.durum)}</td>
                                <td className="small">    {cutString(apprec.coordinates, 100)}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        return (mytable);
    }



    const showhelp = (event) => {


    }


    const filterTable = (event) => {
        var textbox = event.target;
        if (textbox == null)
            return;
        var value = textbox.value;

        $("#myTable tr").filter(function () {
            var rowtext = $(this).text()
            var t1 = removeTurkcLow(rowtext);
            var t2 = removeTurkcLow(value);
            if (t1.indexOf(t2) == -1)
                return $(this).toggle(false);
            return $(this).toggle(true);
        });
        if (document.getElementById("totalrec") != null && $("#myTable tr:visible") != null)
            document.getElementById("totalrec").innerHTML = $("#myTable tr:visible").length + " kayıt";

    }




    var listTable = <div class="d-flex justify-content-center"><div class="spinner-border" role="status"></div></div>;
    if (listData != null) {
        listTable = renderTable(listData);
    }

    return (
        <div className=" m-2 p-3 rounded-3">
            <div className="row mb-1">
                <div className="col-3 " >
                    <h5>TKGM VERİSİ - (MAHALLE)</h5>
                </div>
                <div className="col-8 textvmuted" >
                </div>
                <div className="col-1 text-end" >
                    <button onClick={() => props.changeComponent(<KadastralVeri changeComponent={props.changeComponent} />)} className="btn btn-primary btn-sm text-decoration-none ">Geri Dön</button>
                </div>
            </div>
            <div className="row mb-1">
                <div className="col-12 textvmuted " style={{ "text-align": "justify" }} >
                    <div className="bg-light p-3 rounded-3 textvmuted fw-bold align-middle" >
                        <span className="fa fa-info pe-3" style={{ color: "lightblue", fontSize: "26px" }} ></span>
                     Bu ekranda, tapu kadastrodan gelen veriler indirilerek Universe içerisinde ayrı bir alana kaydedilmektedir. Burada kaydedeceğiniz veriler başvuru esnasında kullandığınız veriler DEĞİLDİR. Burada yaptıklarınız başvuru sırasında kullanılan parsel verilerinizi ETKİLEMEZ/DEĞİŞTİRMEZ. Başvuru esnasında kullandığınız veriler UNIVERSE'de kullanılan Kadastral Veriler başlığı altında yer almaktadır.
                        
                    </div>
               </div>
            </div>

            <div className="row mb-3 mt-3">
                <div className="col-3" id="totalrec">
                    {listData != null ? listData.length : 0} kayıt
                </div>
                <div className="col-6 text-center">
                    <input className="form-control form-control-sm " id="mySearchInput" type="text" placeholder="Ara" onKeyUp={filterTable} />
                </div>

                <div className="col-3 text-end">
                    {/* <button onClick={() => props.changeComponent(<CBSMahalleMatching cbsdata={listData} />)} className="btn btn-primary btn-sm me-1">Eşleştir</button>*/}
                    <button onClick={refreshList} className="btn btn-primary btn-sm">TKGM'deki VERİYİ İNDİR</button>
                    <button className="btn btn-secondary ms-1 btn-sm text-decoration-none fw-bold" id="mypopover" data-bs-toggle="popover" title="AÇIKLAMA" data-bs-trigger="focus" data-bs-content="Aşağıdaki mahalle verilerinin tamamı silinerek TKGM'deki mahalle verileri indirilir. İşlemi yarım bırakırsanız veriler eksik indirilebilir ve yeniden indirmeniz gerekir. Bu veriler UNIVERSE'de kullanılan BAŞVURUYA ESAS mahalle verileriniz DEĞİLDİR. Bu işlem, başvuruda kullanılan mahalle listesinizde herhangi bir değişiklik yapmaz.">?</button>

                </div>
            </div>
            <div className="row border-top pt-2" key={listTable}>
                <div className="col-12" id="tablediv" >
                    {listTable}
                </div>
            </div>



        </div>

    );


}