import React, { Component } from 'react';
import AppCard from './AppCard.js';
import * as UserPermConstants from '../User/UserPermConstants';
import $ from 'jquery';
import { passFilter, showErrorMessage, showSuccessMessage } from '../GlobalState.js';
import UserStatisticCard from './UserStatisticCard.js';

export default class ApplicationListCardView extends Component {
    sortField = "";



    constructor(props) {
        super(props);

        this.userName = props.userName;
        this.role = props.role;
        this.state = {
            applist: null, colnames: ["id", "basvuruTarihi", "basvuruTipi", "ada", "mahalle", "mevki", "mimar"]
        };
        this.getApplicationList = this.getApplicationList.bind(this);
        this.getUserApps = this.getUserApps.bind(this);
        this.refreshList = this.refreshList.bind(this);
        this.generateOpenList = this.generateOpenList.bind(this);
        this.renderToCards = this.renderToCards.bind(this);
        this.fetchDenetimList = this.fetchDenetimList.bind(this);
        this.getAppTypes = this.getAppTypes.bind(this);
        this.getdenetimpermsraw = this.getdenetimpermsraw.bind(this);

        this.getSablon = this.getSablon.bind(this)
        this.filterTable = this.filterTable.bind(this)
        this.handleSablonChange = this.handleSablonChange.bind(this)
        this.handleDenetimChange = this.handleDenetimChange.bind(this)
        this.getKontrolorName = this.getKontrolorName.bind(this)
        this.generateAyYil = this.generateAyYil.bind(this)
        

        this.filter = null;
        this.mysablons = null;
        this.changeComponent = props.changeComponent;
        this.userApps = null;
        this.kabulPerms = null;

        this.selectedDenetim = -1;
        this.appTypes = [];
        this.userDenetimPermissions = null
        this.denetimlist = null;
        this.bordercolors = ["#50cc88", "#009cf7", "#ffc800", "#f1416d"];

        this.droppedUserName = null;
    }

    refreshList(data) {
        this.setState({
            applist: data
        })
    }
    componentDidMount() {
        this.getSablon();
        this.fetchDenetimList();
        this.getdenetimpermsraw();
        this.getAppTypes();
        this.getUserApps();
        //this.getApplicationList();
        this.fetchUsersWithPermision();
        this.fetchkabullistperm();
    }

    fetchkabullistperm() {
        fetch("kabullistpermsnames", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(this.userName)
        }).then(async response => {
            if (!response.ok) {
               
            }
            else {
                response.json().then(value => {
                    this.kabulPerms = value;
                })
            }
        });
    }

    fetchUsersWithPermision = () => {
        fetch("userswithperm", {
            method: 'POST',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            body: JSON.stringify("")
        }).then(async response => {
            if (!response.ok) {

            }
            else {
                response.json().then(async data => {
                    this.userDenetimPermissions = data;
                });
            }
        });

    }

    myCompare = (a, b) => {
        if (a[this.sortField] < b[this.sortField]) {
            return -1;
        }
        if (a[this.sortField] > b[this.sortField]) {
            return 1;
        }
        return 0;
    }




    myCompareBasvuruTipi = (a, b) => {
        var s1 = a[this.sortField];
        var s2 = b[this.sortField];
        if (s1 == null) return -1;
        if (s2 == null) return 1;

        var sa = s1.replaceAll('"name":', "").replaceAll(']', "").replaceAll('[', "").replaceAll('{', "").replaceAll('}', "").replaceAll('"', "");
        var sb = s2.replaceAll('"name":', "").replaceAll(']', "").replaceAll('[', "").replaceAll('{', "").replaceAll('}', "").replaceAll('"', "");

        if (sa < sb) {
            return -1;
        }
        if (sa > sb) {
            return 1;
        }
        return 0;
    }


    sortList = (fildname) => {
        this.sortField = fildname;
        var temp = this.state.applist;
        if (fildname.toUpperCase() == "BASVURUTIPI")
            temp.sort(this.myCompareBasvuruTipi);
        else
            temp.sort(this.myCompare);
        this.setState({ applist: temp });
    }



    fetchDenetimList() {
        fetch("denetimlist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                response.text().then(value => {
                    showSuccessMessage(response.statusText)
                })
            }
            else {
                response.json().then(data => {

                    this.denetimlist = data;
                })
            }
        });
    }




    getSablon() {
        fetch("getmysablon", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                const error = response.statusText;
                console.log(error);

            }
            else {

                response.json().then(async data => {
                    data = data.filter(r => r.forTableView == true);
                    this.mysablons = data;
                    if (data != null && data.length > 0) {
                        data = this.mysablons[0];
                        if (window.localStorage.getItem('sablonisyuku') != null && this.mysablons[window.localStorage.getItem('sablonisyuku')] != null)
                            data = this.mysablons[window.localStorage.getItem('sablonisyuku')];
                        if (data.filter != null)
                            this.filter = data.filter;
                        if (data.colNames != null && data.colNames.length > 0) {
                            var d = data.colNames.replaceAll('"', "");
                            if (d.length > 0)
                                this.setState({ ...this.state, colnames: d.split(",") });
                        }
                    }


                });
            }
        });
    }


    getUserApps() {
        fetch("getuserapps", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                response.text().then(value => {
                    showErrorMessage(response.statusText)
                })
            }
            else {
                response.json().then(value => {
                    this.userApps = JSON.parse(value);
                    this.getApplicationList()
                })
            }
        });
    }


    getdenetimpermsraw() {
        fetch("getdenetimpermsraw", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                response.text().then(value => {
                    showErrorMessage(response.statusText)
                })
            }
            else {
                response.json().then(value => {
                    this.denetimperms = value;
                })

            }
        });
    }




    handleDenetimChange() {
        this.selectedDenetim = document.getElementById("denetimSelect").value;
        this.setState({ ...this.state, selectedDenetim: this.selectedDenetim });
    }

    handleSablonChange(sab) {

        window.localStorage.setItem('sablonisyuku', sab);
        var data = this.mysablons[sab];
        if (data.filter != null)
            this.filter = data.filter;
        if (data.colNames != null && data.colNames.length > 0) {
            var d = data.colNames.replaceAll('"', "");
            if (d.length > 0)
                this.setState({ ...this.state, colnames: d.split(",") });
        }
    }


    appsauthorlist = (applist) => {
        var ids = applist.map(x => x.id).join();
        fetch("appsauthorlist", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(ids)

        }).then(async response => {
            if (!response.ok) {
            }
            else {
                response.json().then(async data => {
                    applist.forEach((k) => { k.müellif = "" });
                    data.forEach((k) => { applist.find(x => x.id == k.appID).müellif += k.userName + "(" + k.muellifType + ")  " });

                    this.userApps.forEach((k) => { k.müellif = "" });
                    data.forEach((k) => { var r = this.userApps.find(x => x.id == k.appID); if (r) r.müellif += k.userName + "(" + k.muellifType + ")  " });

                    this.setState({ ...this.state, applist: applist });
                });
            }
        });
    }

    getcontrollerlist = (applist) => {
        var ids = applist.map(x => x.id).join();
        fetch("getcontrollerlist", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(ids)

        }).then(async response => {
            if (!response.ok) {
            }
            else {
                response.json().then(async data => {
                    try {
                        data = JSON.parse(data)
                        applist.forEach((k) => { k.kontrolor = "" });
                        this.userApps.forEach((k) => { k.kontrolor = "" });


                        data.forEach((k) => {
                            if (k.id == k.activeDenetim) {
                                applist.find(x => x.id == k.appID).kontrolor = k.userName
                                applist.find(x => x.id == k.appID).kontrolorNameFamily = k.Name + " " + k.Family;
                                this.userApps.find(x => x.appID == k.appID).kontrolor = k.Name + " " + k.Family;
                                this.userApps.find(x => x.appID == k.appID).kontrolorNameFamily = k.Name + " " + k.Family;
                            }

                        });

                        applist.forEach((k) => { k.tümKontrolorler = "" });
                        this.userApps.forEach((k) => { k.tümKontrolorler = "" });
                        data.forEach((k) => {
                            if (k.id == k.activeDenetim) {
                                var a = applist.find(x => x.id == k.appID);
                                if (a != null) {
                                    if (a.tümKontrolorler != null && a.tümKontrolorler.length > 0)
                                        a.tümKontrolorler += ", ";
                                    a.tümKontrolorler += k.name + " " + k.family;
                                }
                                if (a != null) {

                                    a = this.userApps.find(x => x.appID == k.appID);
                                    if (a.tümKontrolorler != null && a.tümKontrolorler.length > 0)
                                        a.tümKontrolorler += ", ";
                                    a.tümKontrolorler += k.name + " " + k.family;
                                }
                            }
                        });

                        this.setState({ ...this.state, applist: applist });
                        this.appsauthorlist(applist)
                    }
                    catch(error){
                        this.setState({ ...this.state, applist: [] });
                        
                    }
                });
            }
        });
    }



    getApplicationList() {
        fetch("applicationlist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                const error = response.statusText;
                console.log(error);
                this.setState({ status: response.statusText })
            }
            else {
                response.json().then(async data => {
                    var notremoved = data.filter(x => x.removed != true).sort((a, b) => a.basvuruTarihi<b.basvuruTarihi);
                    this.getcontrollerlist(notremoved);
                });
            }
        });
    }




    getAppTypes() {

        fetch("sureclist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                response.text().then(value => {
                    showErrorMessage(response.statusText)
                })
            }
            else {
                response.json().then(value => {
                    this.appTypes = value;

                })
            }
        });
    }


    filterTable(event) {
        const target = event.target;
        const value = target.value.toLowerCase();
        $("#myTable td.align-top div.border").filter(function () {
            var keywords = value.split(" ");
            for (let index = 0; index < keywords.length; ++index)
                if ($(this).text().toLowerCase().indexOf(keywords[index]) == -1)
                    return $(this).toggle(false);
            return $(this).toggle(true);
        });
    }



    generateAyYil(currentUser, istatistic,tarih) {
        istatistic[currentUser].Toplam++;
        if (tarih == null) {
            //istatistic[currentUser].Sonay++;
            //istatistic[currentUser].Sonyil++;
            return;
        }

        else {
            var h = new Date(tarih);
            var diffTime = Date.now() - h
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;
            if (diffDays < 30) {
                istatistic[currentUser].Sonay++;
            }
            if (diffDays < 365) {
                istatistic[currentUser].Sonyil++;
            }
        }
    }



    generateOpenList(data, users) {
        var appglist = [];
        appglist.push([]); 
        var istatistic = [];

        data = data.filter(apprec => passFilter(apprec, this.filter, this.userName) == true)

        var filteredUserApps = this.userApps.filter(rec => rec.deliverDate != null && rec.deliverDate != "" && rec.Completed == null)
        filteredUserApps = filteredUserApps.filter(apprec => passFilter(apprec, this.filter, this.userName, this.kabulPerms) == true)
        if (this.selectedDenetim != -1)
            filteredUserApps = filteredUserApps.filter(rec => rec.denetimID == null || rec.denetimID == this.selectedDenetim)



        for (var i = 0; i < data.length; i++) {
            if (data[i].basvuruDurumu == UserPermConstants.Basvuru_Durumu_Havale_Bekliyor) {
                if (data[i].basvuruTipi == null || this.selectedDenetim == -1 || data[i].basvuruTipi == this.denetimlist.find(d => d.id == this.selectedDenetim).name)
                    appglist[0].push(data[i]);
            }
        }

        for (var j = 0; j < users.length; j++) {
            appglist.push([])
            var currentUser = users[j]

            if (istatistic[currentUser] == null) {
                istatistic[currentUser] = { "Gunluk_Ortalama": 0, "Kontrol_Ediliyor": 0, "Sirada_Bekliyor": 0, "Duzeltme_Bekleniyor": 0,  "Sonay": 0, "Sonyil":0,"Toplam":0 };
            }
            for (var i = 0; i < filteredUserApps.length; i++) {
                if (currentUser != filteredUserApps[i].userName)
                    continue;
                var pass = false;



                if ((filteredUserApps[i].basvuruDurumu == UserPermConstants.Basvuru_Durumu_Kontrol_Ediliyor ||
                    filteredUserApps[i].basvuruDurumu == UserPermConstants.Basvuru_Durumu_Kontrol_Sirada_Bekliyor) 
                ) {
                    if (filteredUserApps[i].Started != null && filteredUserApps[i].Started != "") {
                        if (filteredUserApps[i].Completed == null || filteredUserApps[i].Completed == "") {
                            istatistic[currentUser].Kontrol_Ediliyor++;
                            pass = true;
                        }

                    }
                    else {
                        istatistic[currentUser].Sirada_Bekliyor++;
                        pass = true;
                    }
                            
                }
                if (filteredUserApps[i].basvuruDurumu == UserPermConstants.Basvuru_Durumu_Kontrol_Tamamlandi
                    || filteredUserApps[i].basvuruDurumu == UserPermConstants.Basvuru_Durumu_Ruhsat_Verildi
                    || filteredUserApps[i].basvuruDurumu == UserPermConstants.Basvuru_Durumu_Iade_Edildi
                    || filteredUserApps[i].basvuruDurumu == UserPermConstants.Basvuru_Durumu_Onay_Verildi
                ) {
                    
                    this.generateAyYil(currentUser, istatistic, filteredUserApps[i].deliverDate)

                }
                if (filteredUserApps[i].basvuruDurumu == UserPermConstants.Basvuru_Durumu_Duzeltme_Bekleniyor) {
                    if (filteredUserApps[i].Completed == null || filteredUserApps[i].Completed == "") {

                        this.generateAyYil(currentUser, istatistic, filteredUserApps[i].sonDuzeltmeTarihi)
                        istatistic[currentUser].Duzeltme_Bekleniyor++;
                        pass = true;
                        
                    }

                }
                if (pass == true)
                    appglist[j + 1].push(filteredUserApps[i]);
            }
        }

        return [appglist, istatistic];
    }

    findRowCount = (sab, userapps,applist) => {
        if (applist == null || userapps==null)
            return 0;
        var havale = applist.filter(rec =>rec.removed != true && rec.basvuruDurumu == UserPermConstants.Basvuru_Durumu_Havale_Bekliyor);
        havale = havale.filter(rec => passFilter(rec, this.mysablons[sab].filter, this.userName, this.kabulPerms) == true);

        var r = userapps.filter(apprec =>
            apprec.removed != true&&
            passFilter(apprec, this.mysablons[sab].filter, this.userName) == true && (this.selectedDenetim == null || this.selectedDenetim == -1 || this.denetimlist.find(d => d.id == this.selectedDenetim).name == apprec.basvuruTipi));

        r = r.filter(rec => rec.deliverDate != null && rec.deliverDate != "" && rec.Completed == null && rec.basvuruDurumu != UserPermConstants.Basvuru_Durumu_Havale_Bekliyor)
        return r.length + havale.length;
    }

    getKontrolorName = (userName) => {
        var r = this.state.applist.find(x => x.kontrolor == userName);
        if (r == null) return userName + "*";
        return r.kontrolorNameFamily
    }

    allowDrop = (event) => {
        event.preventDefault();
    }



    drop = (event, userName) => {
        event.preventDefault();
        var data = event.dataTransfer.getData("text").split(' ');
        var appid = Number(data[0])
        var currentHolder = null;
        var currentMappingID = null;
        if (event.ctrlKey == false && data.length > 1) {
            currentHolder = this.userDenetimPermissions.filter(x => x.userName == data[1])[0]
            currentMappingID = Number(data[2])
        }
        //if (event.ctrlKey==false&& dapp.basvuruDurumu != UserPermConstants.Basvuru_Durumu_Havale_Bekliyor &&
        //    dapp.basvuruDurumu != UserPermConstants.Basvuru_Durumu_Onay_Bekliyor) {
        //    alert("o o");

        //}

        this.setState({ ...this.state, "droppedAppId": appid, "currentMappingID": currentMappingID, "currentHolder": currentHolder, "userdenetimlist": this.userDenetimPermissions.filter(x => x.userName == userName && this.denetimlist.find(d => d.id == x.role) != null).map(x => <option value={x.role}>{this.denetimlist.find(d => d.id == x.role).name}</option>), "droppedUser": this.userDenetimPermissions.filter(x => x.userName == userName)[0] });
        document.getElementById("modalbutton").click();
    }

    gericek = (id) => {
        //if (mappedusers.length == 1 && window.confirm("Başvuruyu, havale sürecine geri göndermek istediğinize emin misiniz?") == false)
        //    return;
        fetch("removeuserprojectmapping", {
            method: 'POST',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            body: JSON.stringify(this.state.currentMappingID)
        }).then(async response => {
            if (!response.ok) {

            }
            else {
                response.json().then(async data => {
                    this.fetchDenetimList();
                    this.getdenetimpermsraw();
                    this.getAppTypes();
                    this.getUserApps();
                    this.getApplicationList();
                    this.fetchUsersWithPermision();
                });
            }
        });
    }


    mapusertoproject = () => {

        var mappingdata = { "appID": this.state.droppedAppId, "userName": this.state.droppedUser.userName, "denetimId": document.getElementById("mission").value }

        if (mappingdata.denetimId == null || mappingdata.userName == null || mappingdata.denetimId == ''
            || mappingdata.userName.length == 0) {
            showErrorMessage("Başvuru Tipi veya Kontrolör Hatalı")
            return;
        }

        fetch("mapusertoproject", {
            method: 'POST',
            headers: {
                'Accept': 'application/json', 'Content-Type': 'application/json'
            },
            body: JSON.stringify(mappingdata)
        }).then(async response => {
            if (!response.ok) {

            }
            else {
                response.json().then(value => {
                    if (value == null) {
                        showErrorMessage("Yetkisiz İşlem !")
                        return;
                    }
                    if (value.id == -1) {
                        showErrorMessage(value.appData)
                        return;
                    }
                    if (this.state.currentHolder == null) {
                        this.fetchDenetimList();
                        this.getdenetimpermsraw();
                        this.getAppTypes();
                        this.getUserApps();
                        this.getApplicationList();
                        this.fetchUsersWithPermision();
                    }
                    else {
                        this.gericek();
                    }
                });
            }
        });
    }


    renderToGroupCards(tabledata) {




        var seldenetimlist = [...this.denetimperms];
        if (this.selectedDenetim != -1)
            seldenetimlist = this.denetimperms.filter(r => r.denetimId == this.selectedDenetim);
        var users = [... new Set(seldenetimlist.map(r => r.userName))]; //remove duplicates

        var restb = this.generateOpenList(tabledata, users);

        var tablegdata = restb[0];
        var istats = restb[1];




        //<div className="mt-1" style={{ overflow: "scroll", width: "100%", height: "80vh", display: "block", "overflow-x": "auto", "white-space": "nowrap" }}>
        var mytable = <div >
            <div className="table-responsive mainpagescroll mt-1" style={{ "height": "74vh" }} id="tableparent">
                <table className="table" id="myTable" >

                    <thead style={{ "position": "sticky", "top": 0 }} className="bg-light ">
                        <tr className="m-0 p-0 text-center">
                            {tablegdata.map((grec, idx) =>
                                idx == 0 ? <th className="m-0 p-0 align-top" style={{ "width": "1%", "min-width": "17vw" }}>

                                    <div className="border-bottom border-3 ms-2 me-2  border-info fw-bold">
                                        <a class="text-decoration-none text-primary" data-bs-toggle="collapse" type="button" aria-expanded="false" data-bs-target=".multi-collapse" aria-controls={" Userstatistic1 Userstatistic2"}>
                                            Havale Bekleynler

                                        </a>
                                        ({tablegdata[0].length})
                                    </div>
                                </th> :
                                    grec.length > 0 && < th className="m-0 p-0  align-top" style={{ "width": "1%", "min-width": "17vw" }} onDragOver={this.allowDrop} onDrop={(event) => this.drop(event, users[idx - 1])}>
                                        <div className={"border-3 ms-2 me-2 align-top fw-bold"} style={{ "border-bottom": "4px solid " + this.bordercolors[idx % 4] }} >
                                            {this.getKontrolorName(users[idx - 1])}
                                            ({tablegdata[idx].length})


                                        </div>
                                        {/* <div className={"border-3 ms-3 me-3 mb-2"} style={{ "border-bottom": "4px solid " + this.bordercolors[idx % 4] }}>*/}
                                        {this.role.indexOf(UserPermConstants.Kontrolor_Istatistiklerini_Gorme) >= 0 && <div className="mb-2">
                                            {istats[users[idx - 1]] != null && <UserStatisticCard Statistic={istats[users[idx - 1]]} color={this.bordercolors[idx % 4]} userid={idx - 1} />}
                                        </div>}

                                    </th>

                            )}

                        </tr>
                    </thead>
                    <tbody >

                        <tr className="" >
                            {tablegdata.map((grec, idx) => (grec.length > 0 || idx == 0) &&
                                <td className="align-top">
                                    {grec.map(apprec => <AppCard appTypes={this.appTypes} apprec={apprec} colnames={this.state.colnames} kontrolUserName={idx > 0 ? users[idx - 1] : null} drop={this.drop} changeComponent={this.changeComponent} />
                                    )}
                                </td>

                            )} </tr>
                    </tbody>
                </table>

            </div>
        </div>
        return (mytable);
    }


    renderToCards(tabledata) {
        var mytable =
            <div className="mt-1">
                <div className='row p-2' id="myTable">
                    {tabledata.map(apprec =>
                        passFilter(apprec, this.filter, this.userName, this.kabulPerms) == true &&
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 p-0">
                            <AppCard appTypes={this.appTypes} apprec={apprec} colnames={this.state.colnames} changeComponent={this.changeComponent} />
                        </div>
                    )}
                </div>
            </div>
        return (mytable);
    }


    render() {
        var tabledata = <div class="row" > <div class="col-12 text-center"><div className="spinner-border " role="status"></div></div></div>;
        if (this.state.applist != null &&  this.kabulPerms!=null) {
            tabledata = this.renderToGroupCards(this.state.applist);
        }


        var selectedSablonIndex = window.localStorage.getItem('sablonisyuku');
        if (selectedSablonIndex == null)
            selectedSablonIndex = 0;

        return <div style={{ "background-color": "#f5f8fa" }} >
            <div  >
                <div className="  align-items-center justify-content-center" >
                    <div class="row rounded p-2 ms-2 me-2 justify-content-left bg-white" >
                        <div class="col-md-3">
                            {this.denetimlist != null ? <select className="form-select form-select-sm" id="denetimSelect" name="denetimSelect" onChange={this.handleDenetimChange}>
                                <option value={-1} selected></option> {this.denetimlist.map((s, idx) => <option value={s.id} > {s.name}</option>)}
                            </select> : ""}
                        </div>
                        <div class="col-md-4">
                            <input className="form-control form-control-sm" id="myInput" type="text" placeholder="Arama..." onKeyUp={this.filterTable} />
                        </div>

                    </div>
                </div>
                <div className="p-2">
                    <div className="overflow-auto w-100 mainpagescroll" style={{ "white-space": "nowrap" }}>
                        {this.mysablons != null ? this.mysablons.map((s, idx) => <button className={"btn  me-2 text-decoration-none border mb-1 mt-1" + (selectedSablonIndex == idx ? " btn-primary" : " btn-link")} onClick={() => this.handleSablonChange(idx)}> {s.name} ({this.findRowCount(idx, this.userApps, this.state.applist)})</button>) : ""}
                    </div>
                </div>

                {tabledata}
            </div>



            <button id="modalbutton" hidden={true} className="text-decoration-none btn  btn-sm btn-primary p-1" data-bs-toggle="modal" data-bs-target="#mylModal"> </button>
            <div class="modal  fade" id="mylModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-md">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Başvuru Havel ({this.state.droppedAppId})</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>

                        <div class="modal-body">
                            {this.state.droppedUser != null && <div class="row">
                                <div class="col-4">
                                    {this.state.currentHolder == null ? "" : this.state.currentHolder.name + " " + this.state.currentHolder.family + " (" + this.state.currentHolder.userName + ") "}
                                    {this.state.currentHolder == null ? "" : <span><br /><i class="fa fa-arrow-down"></i><br /></span>}
                                    {this.state.droppedUser.name + " " + this.state.droppedUser.family + " (" + this.state.droppedUser.userName + ")"}
                                </div>
                                <div class="col-8">
                                    <select className="form-select form-select-sm" id="mission" name="denetimId">
                                        {this.state.userdenetimlist}

                                    </select>
                                </div>
                            </div>}
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-sm btn-primary" data-bs-dismiss="modal" onClick={this.mapusertoproject}>Havale Et</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    }

};
