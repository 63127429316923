import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import * as UserPermConstants from '../User/UserPermConstants';
import ApplicationList from "../Application/ApplicationList.js";
import { showConfirmMessage, showErrorMessage, useGlobalState } from '../GlobalState.js';

export default function OnayYetkilisiPanel(props) {

    const [appData, setData] = useState(props.rec);
    const [userName, setUserName] = useGlobalState('userName');

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked.toString() : target.value;
        appData[target.name] = value;
    }
    const addNot = () => {
        if (document.getElementById("not").value == null ||
            document.getElementById("not").value.length == 0)
            return;

        fetch("addnote", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ appId: appData.id, note: document.getElementById("not").value })
        }).then(async response => {
            if (!response.ok) {
                showErrorMessage(response.statusText)
            }
            else {
                var c = document.getElementById("not");
                if (c != null)
                    c.value = "";
            }
        });
    }

    const saveApp = () => {
        addNot();
        fetch("saveapplication", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(appData)
        }).then(async response => {
            if (!response.ok) {
                showErrorMessage("saveapplication: " + response.statusText)
            }
            else {
                props.changeComponent(<ApplicationList changeComponent={props.changeComponent} />)
            }
        });
    }
    const apponayla = () => {
        showConfirmMessage("Başvuruyu, onaylamak istediğinize emin misiniz?", "", "Evet", "Hayır", () => {
         appData.basvuruDurumu = UserPermConstants.Basvuru_Durumu_Onay_Verildi;
            saveApp();
        });
    }
    const appret = () => {
        showConfirmMessage("Başvuruyu, havale sürecine geri göndermek istediğinize emin misiniz?","", "Evet", "Hayır", () => {
            appData.basvuruDurumu = UserPermConstants.Basvuru_Durumu_Havale_Bekliyor;
            saveApp();
        });
    }

    const appkntorlgeri = () => {
        showConfirmMessage("Başvuruyu, bir önceki aşamaya/kişiye geri göndermek istediğinize emin misiniz?", "", "Evet", "Hayır", () => {
            appData.basvuruDurumu = UserPermConstants.Basvuru_Durumu_Kontrol_Sirada_Bekliyor;
            saveApp();
        });

    }

    const appiade = () => {
       
            var comment = window.prompt("Lütfen iade etme nedenini açıklayınız")
            if (comment == null || comment.length == 0) {
                showErrorMessage("Açıklama Boş Olamaz");
                return;
            }
        appData.iadeNotu = comment;
            appData.iadeEden = userName;
            appData.basvuruDurumu = UserPermConstants.Basvuru_Durumu_Iade_Edildi;
            saveApp();


       


    }

    return (
        <div >
            <fieldset disabled={appData.basvuruDurumu != UserPermConstants.Basvuru_Durumu_Kontrol_Tamamlandi}>
                <div className="row">
                    <div className="col-12  pb-4">
                        <h5 className="text-primary">Onay Paneli</h5>
                    </div>
                    <div className="row mb-1">
                        <div className="col-12">

                            <input type="text" className="form-control form-control-sm w-75 d-inline-block" id="not" placeholder="Açıklama" name="not" defaultValue="" />

                        </div>
                    </div>
                    <div className="row mb-1 mt-3">
                        <div className="col-12 p-1 btn-group  flex-wrap">

                            <button className="btn btn-success rounded m-1 btn-sm" onClick={apponayla}>Onayla</button>
                            <button className="btn btn-primary  rounded m-1 btn-sm" onClick={appret}>Havale Sürecine Dön</button>
                            <button className="btn btn-danger  rounded m-1 btn-sm" onClick={appkntorlgeri}>Geri Gönder</button>
                            <button className="btn btn-danger  rounded m-1 btn-sm" onClick={() => showConfirmMessage("Başvuruyu, Muellife geri göndermek istediğinize emin misiniz?", "", "Evet", "Hayır", appiade)}>İade Et</button>

                        </div>
                    </div>
                </div>
            </fieldset >
        </div>
    );
}



