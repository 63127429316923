import React, { useState, useEffect } from 'react';
import { ajaxFail, useGlobalState } from '../GlobalState.js';

export default function KurumLogo(props) {
    var [globalData, setGlobalData] = useGlobalState('globalData');
   
    
    useEffect(() => {
       
    }, []);

    return (
        <div key={globalData}>
            <img src={require('../Header/textlogowh.png').default} width="128px"/>
            {/*<img src={require('./klogo.png').default}  />*/}<br/>
           
        </div>
    );
}
