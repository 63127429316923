import React, { useState, useEffect } from 'react';
import { ajaxFail, chartColors, useGlobalState} from '../GlobalState.js';
import ReactDOM from 'react-dom';
export default function AppMonthStatistic(props) {

    const [role, setRole] = useGlobalState('role');
    const [reccount, setReccount] = useState(0);

    var sortField = null;
    var sortDirection = {}
    const myCompare = (a, b) => {
        if (sortField == null)
            sortField = "transactionUUID"
        var av = a[sortField];
        var bv = b[sortField];
        if (av == null && bv == null)
            return 0;
        if (av == null && bv != null)
            return -1 * sortDirection[sortField];
        if (av != null && bv == null)
            return 1 * sortDirection[sortField];

        if (typeof av === 'string' || av instanceof String)
            if (typeof bv === 'string' || bv instanceof String)
                return av.localeCompare(bv) * sortDirection[sortField];

        if (av < bv) {
            return -1 * sortDirection[sortField];
        }
        if (av > bv) {
            return 1 * sortDirection[sortField];
        }
        return 0;
    }


    const sortList = (fildname, data) => {
        sortField = fildname;
        if (sortDirection[sortField] == null || sortDirection[sortField] == -1) {
            sortDirection[sortField] = 1;
        }
        else {
            sortDirection[sortField] = -1;
        }
        let sortablelist = [];

        data.forEach(function (item) {
            var obj = {};
            obj.ay = item[0];
            obj.toplam = item[1];
            sortablelist.push(obj);   
        })
        sortablelist.sort(myCompare);

        let temp = [["this will be removed by generatetable"]]
        sortablelist.forEach(function (item) {
            temp.push([item.ay, item.toplam]);
        })
        ReactDOM.render(generateTable(temp), document.getElementById("tablePart"))
    }


    const generateTable = (data) => {
        data.shift();
        return <div className="table-responsive">
            <table className='table  mt-3 table-vdstriped p-0' aria-labelledby="tabelLabel">
                <thead >
                    <th>

                    </th>
                    <th className="text-start">
                        <button className="btn btn-link btn-sm text-decoration-none" onClick={() => sortList("ay", data)} > Ay </button>
                    </th>
                    <th className="text-start">
                        <button className="btn btn-link btn-sm text-decoration-none" onClick={() => sortList("toplam", data)} > Toplam Başvuru </button>
                    </th>
                </thead>
                <tbody id="myTable">
                    {data.map((rec, idx) =>
                        <tr>
                            <td className="text-start">
                                {idx + 1}
                            </td>
                            <td className="text-start">
                                {rec[0]}
                            </td >
                            <td className="text-start">
                                {rec[1]}
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    }



    const drawChart = (dataarray, target, ftitle) => {
        var data = window.google.visualization.arrayToDataTable(dataarray);
        var options = {
            chartArea: {
                // leave room for y-axis labels
                width: '90%',
                height: '90%'
            },
            lineWidth: 4,
            
            curveType: 'function',
            width: '100%',
            /*title: ftitle,*/
            legend: { position: 'none' },
            vAxis: {
                minValue: 0,
                gridlines: {
                    color: "EEF8FB"
                },

                minorGridlines: {
                    color: 'transparent'

                },
               
            },
 animation: {
                    duration: 1200,
                    easing: 'out',
                    "startup": true
                },

        };
        var chart = new window.google.visualization.AreaChart(document.getElementById(target));
        chart.draw(data, options);
        ReactDOM.render(generateTable(dataarray), document.getElementById("tablePart"));
    }

    useEffect(() => {
        
            const script = document.createElement("script");
            script.src = "https://www.gstatic.com/charts/loader.js";
            script.async = true;
            document.getElementById("myscript").appendChild(script);
        script.onload = () => {
            var sp = document.getElementById("loadspin");
            if (sp != null)
                sp.hidden = false;
                window.google.charts.load("current", { packages: ['corechart'] });
                window.google.charts.setOnLoadCallback(populateProjectData);
            
        }

    }, [])


    const populateProjectData = () => {
        fetch("countprojectbytype", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {

                response.json().then(async data => {
                    data = JSON.parse(data);
                    var dataarray = [];
                    var colors = chartColors;
                    var aylar = ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temuz', 'Ağustos', 'Eylul', 'Ekim','Kasım','Aralık'];


                    dataarray.push(['Durum', 'Toplam', { role: "style" }]);
                    var temp = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                    var rec = 0;
                    for (var i = 0; i < data.length; i++) {
                        if (data[i].basvuruTarihi != null && data[i].basvuruTarihi!="") {
                            var dt = Number(data[i].basvuruTarihi.substring(5, 7));
                            temp[dt] = temp[dt] + data[i].Toplam;
                            rec += data[i].Toplam;
                        }
                    }

                    for (var i = 0; i < 12; i++) {
                        dataarray.push([aylar[i], temp[i+1], colors[i]]);
                    }

                    drawChart(dataarray, 'mychart', '2022 Genel Durum');
                    setReccount(rec)
                });
            }
        });
    }

   


    //appData.basvuruDurumu != "Kontrol Ediliyor"
    return (
        <div className=" p-1 mt-1 me-2 border rounded-1" >
            <div id="myscript"></div>
            <div className="row">
                <div className="col-7">
                    <h4>Aylık Başvuru Sayısı</h4>
                </div>
                <div className="col-5 justify-content-end align-items-end text-end text-secondary">Toplam <b>{reccount}</b> başvuru</div>
            </div>
            <div className="row ">
                <div className="col-12 ps-2 m-0 text-center">
                    <div id="mychart" style={{ "height": "600px" }}>
                        <div class="spinner-border" id="mevki_snipper"></div>
                    </div>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-12 ps-2 m-0 text-center">
                    <div id="tablePart" className="w-100  text-center"><div class="spinner-border" id="loadspin" hidden></div></div>
                </div>
            </div>

           
        </div>
    );
}





