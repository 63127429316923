import React, { useState, useEffect } from 'react';
import UserStatistics from '../User/UserStatistics.js';
import $ from 'jquery';
import * as UserPermConstants from '../User/UserPermConstants';
import ApplicationList from "../Application/ApplicationList.js";
import UserLabel from "../User/UserLabel.js";
import { ajaxFail, isEmpty, getDenetimSure, showConfirmMessage, showErrorMessage, sendpost } from '../GlobalState.js';
import SablonHavalePanel from './SablonHavalePanel.js';

export default function HavaleYetkiliciPanel(props) {
    const [appData, setData] = useState(props.rec);



    var mappingdata = { userName: "", appID: appData.id };

    const [counter, RefreshCounter] = useState(0);
    const [basvuruTipiAdimlari, setBasvuruTipiAdimlari] = useState([]);
    const [userwork, setUserWork] = useState({});
    const [userwithperms, setUserwithperms] = useState([]);
    const [relatedControlors, setRelatedControlors] = useState(null);
    const [denetimlist, setDenetimlist] = useState([]);
    const [mappedusers, setMappedUsers] = useState(null);

    const surebg = ["#c9f7f5", "#e1f0ff", "#fff4de", "#ffe2e5"]
    const suretxt = ["text-info", "text-primary", "text-warning", "text-danger"]
    var minSira = 1000, maxSira = 0;

    useEffect(() => {
        getWorkLoadList();
        
    }, [])



    if (mappedusers != null && mappedusers.length > 0) {

        mappedusers.filter(x => isEmpty(x.Completed) == true).forEach(x => {
            if (x.sira == null) {
                minSira = 0;
                x.sira = 0;
            }
            if (x.sira < minSira) {
                minSira = x.sira
            }
            if (x.sira > maxSira) {
                maxSira = x.sira
            }
        })

        var maxCount = mappedusers.filter(x => x.sira == maxSira);
        var minCount = mappedusers.filter(x => x.sira == minSira);
        if (maxCount != null && maxCount.length > 1) {
            maxSira++;
        }
        if (minSira > 0 && minCount != null && minCount.length > 1) {
            minSira--;
        }

    }

    const getWorkLoadList = () => {
        fetch("getuserworkloads", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
                this.setState({ status: response.statusText })
            }
            else {
                response.json().then(async data => {
                    var userstat = {}
                    for (var i = 0; i < data.length; i++) {
                        userstat[data[i].userName] = { "duzeltme": 0, "kontrol": 0 }
                    }
                    for (var i = 0; i < data.length; i++) {
                        if (data[i].denetimID == UserPermConstants.Basvuru_Durumu_Duzeltme_Bekleniyor)
                            userstat[data[i].userName].duzeltme++;
                        else if (data[i].denetimID == UserPermConstants.Basvuru_Durumu_Kontrol_Ediliyor || data[i].denetimID == UserPermConstants.Basvuru_Durumu_Kontrol_Sirada_Bekliyor)
                            userstat[data[i].userName].kontrol++;
                    }
                    setUserWork(userstat);
                   fetchDenetimList();
                    
                });
            }
        });
    }



    
    const fetchMappedUsersToRelatedApps = (e) => {
        if (props.relatedapps == null || props.relatedapps.length == 0 || relatedControlors != null) {
            setRelatedControlors([]);
            return;
        }
            
        var rids = props.relatedapps.filter(x => x.id != appData.id).map(x => x.id);
        if (rids.length == 0) {
            setRelatedControlors([]);
            return;
        }
        fetch("mappeduserstoproject", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(rids.join(','))
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(async data => {
                    data = JSON.parse(data);
                    setRelatedControlors(data);
                });
            }
        });
    }



    const fetchMappedUsers = (e) => {
        document.getElementById("yetkiliuserlist").selectedIndex = -1;
        fetch("mappeduserstoproject", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body:appData.id+""
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(async data => {
                    data = JSON.parse(data);
                    setMappedUsers(data);
                });
            }
        });
    }






    const gericek = (id) => {
        const func1 = () => {
            fetch("removeuserprojectmapping", {
                method: 'POST',
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                body: JSON.stringify(id)
            }).then(async response => {
                if (!response.ok) {
                    ajaxFail(response);
                }
                else {
                    response.json().then(async data => {
                        data = JSON.parse(data);
                        setMappedUsers(data)
                        props.refreshParent();
                        document.getElementById("message").value = "";
                        mappingdata = { userName: "", denetimId: "" }
                        document.getElementById("mission").selectedIndex = -1;
                        document.getElementById("yetkiliuserlist").selectedIndex = -1;
                    });
                }
            });
        }
        
        if (mappedusers.length == 1)
            showConfirmMessage("Başvuruyu geri çekmek istediğinize emin misiniz?", "", "Evet", "Hayır", func1);
        else
            func1();

    };


    const fetchUsersWithPermision = (id) => {
        fetch("userswithperm", {
            method: 'POST',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            body: JSON.stringify(id)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(async data => {
                    setUserwithperms(data)
                    if (document.getElementById("yetkiliuserlist"))
                        document.getElementById("yetkiliuserlist").selectedIndex = -1;
                });
            }
        });

    }


    const updateuserprojectmapping = (id) => {
        fetch("updateuserprojectmapping", {
            method: 'POST',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            body: JSON.stringify(id)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(async data => {
                    data = JSON.parse(data);
                    setMappedUsers(data);
                    props.refreshParent();
                });
            }
        });

    }




    const mapusertoproject = (e) => {

        e.preventDefault();
        if (isEmpty(mappingdata.denetimId) || isEmpty(mappingdata.userName)) {
            showErrorMessage("Başvuru Tipi veya Kontrolör Hatalı");
            return;
        }
        var message = document.getElementById("message").value;
        if (isEmpty(message) == false) {
            mappingdata.message = message;
        }

        fetch("mapusertoproject", {
            method: 'POST',
            headers: {
                'Accept': 'application/json', 'Content-Type': 'application/json'
            },
            body: JSON.stringify(mappingdata)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    if (value == null) {
                        showErrorMessage("Yetkisiz İşlem !")
                        return;
                    }
                    if (value.id == -1) {
                        showErrorMessage(value.appData)
                        return;
                    }
                    setData(value);
                    document.getElementById("message").value = "";
                    mappingdata = { userName: "", appID: appData.id }
                    //document.getElementById("mission").selectedIndex = -1;
                    document.getElementById("yetkiliuserlist").selectedIndex = -1;
                    props.refreshParent();
                    fetchMappedUsers();
                });
            }
        });
    }

    const getbasvurutipiadiımlari = (id) => {
        sendpost("basvurutipisadimlist", id, "", (response) => {
            response.json().then(value => {
                setBasvuruTipiAdimlari(value);

            })

        })
    }

    const fetchDenetimList = () => {
        fetch("denetimlist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {

                    var basvurutipiid = value.find(x => x.name == appData.basvuruTipi).id;
                    getbasvurutipiadiımlari(basvurutipiid);

                    setDenetimlist(value.filter(x => x.isadimi == true))


                    fetchMappedUsers();
                    fetchUsersWithPermision(document.getElementById("mission").value)
                })
            }
        });
    }


    const saveApp = () => {
        fetch("saveapplication", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(appData)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else
                props.changeComponent(<ApplicationList changeComponent={props.changeComponent} />)
        });

    }
    const appret = () => {
        showConfirmMessage("Başvuruyu müellife iade etmek istediğinizden emin misiniz?","", "Evet Iade Et", "Hayır Geri Dön", () => {
            setTimeout(() => {
                var sebeb = window.prompt("İade etme nedenini açıklayınız");
                if (isEmpty(sebeb)) {
                    showErrorMessage("İade nedeni yazılmalıdır.")
                    return;
                }
                appData.iadeNotu = sebeb;
                appData.basvuruDurumu = UserPermConstants.Basvuru_Durumu_Iade_Edildi;
                saveApp();


            }, 100)
        });


    }

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked.toString() : target.value;
        mappingdata[target.name] = value;
        mappingdata["denetimId"] = document.getElementById("mission").value;
    }

    const handleGorevInputChange = (event) => {
        if (event.target.value != -1) {
            document.getElementById("yetkiliuserlist").disabled = false;
            fetchUsersWithPermision(event.target.value);
        }
        else
            document.getElementById("yetkiliuserlist").disabled = true;
    }



    const refreshPanel = () => {
        window.setTimeout(() => { RefreshCounter(counter + 1) }, 1000)
    }


    const getUserName = (username) => {

        var r = mappedusers.find(x => x.userName == username);
        if (r == null)
            return username;
        return r.uname + " " + r.Family;

    }


    var selectdf = -1;
    var dt = denetimlist.find(x => x.name == appData.basvuruTipi);
    if (dt != null)
        selectdf = dt.id;



    return (<div>
 <div className="row">
                    <div className="col-9 pb-4">
                        <h5 className="text-primary">Havale Paneli</h5 >
                        {window.innerWidth < 680 && <div>
                            <span className="text-muted"> Başvuru Tipi:</span>  <span className="text-muted"> {appData.basvuruTipi}</span>
                        </div>}
                    </div>
 <div className="col-3 text-end">
                        <button  className="btn btn-danger  me-2 btn-sm" onClick={appret}>İade Et</button>
                    </div >
                </div>
         
        <fieldset disabled={appData.basvuruDurumu != UserPermConstants.Basvuru_Durumu_Havale_Bekliyor &&
            appData.basvuruDurumu != UserPermConstants.Basvuru_Durumu_Kontrol_Ediliyor &&
            appData.basvuruDurumu != UserPermConstants.Basvuru_Durumu_Kontrol_Sirada_Bekliyor &&
            appData.basvuruDurumu != UserPermConstants.Basvuru_Durumu_Kontrol_Tamamlandi
        }>
            <div>
               
                <div className="row " id="havalepanel">
                    <div className="p-2 ms-2 mb-1" >
                        <form action="submitapplication" method="POST" onSubmit={mapusertoproject}>
                            <div class="row">
                                <div class="col-md-3 col-6"><label htmlFor="mission" className="form-label">İş Adimi</label> </div>
                                <div class="col-md-3 col-6"> <label htmlFor="yetkiliuserlist" className="form-label">Kontrolör</label> </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 col-6 mt-1">
                                    <div className="form-group">
                                        <select className="form-select form-select-sm" id="mission" name="denetimId" onChange={handleGorevInputChange}  >
                                            <option value={-1} > Seçiniz </option>
                                            {denetimlist.filter(x => basvuruTipiAdimlari.length == 0 || basvuruTipiAdimlari.some(b => b.isAdimi == x.id)).map(rec => <option value={rec.id} > {rec.name} </option>)}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-3 col-6 mt-1">
                                    <div className="form-group  flex-wrap">

                                        <select disabled={denetimlist.length == 0 || userwithperms.length == 0} className="form-select form-select-sm" id="yetkiliuserlist" name="userName" onChange={handleInputChange} >
                                            {userwithperms.map(rec => <option value={rec.userName}> {rec.name + " " + rec.family + " (" + (userwork[rec.userName] != null ? userwork[rec.userName].kontrol + " - " + userwork[rec.userName].duzeltme : 0) + ")"} </option>)}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-3 col-12 mt-1">
                                    <div className="form-group">
                                        <input type="text" className="form-control form-control-sm" id="message" placeholder="Havale Notu" name="message"  />
                                    </div>
                                </div>
                                <div class="col-md-3 col-12">
                                    <div class="btn-group  flex-wrap" role="group">
                                        <button type="submit" className="btn btn-primary btn-sm m-1">Havale Et</button>
                                        <button type="button" class="m-1 btn btn-primary btn-sm" onClick={refreshPanel} data-bs-toggle="modal" data-bs-target="#myModal">
                                            İstatistik
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </form>
                    </div>


                    {mappedusers == null ? <div className="w-100 mt-4 mb-4 text-center align-items-center justify-content-center" ><div className="spinner-border" ></div></div> :


                        <div className="col-12 mainpagescroll table-responsive ">
                            {mappedusers.length > 0 && <table className='table  table-sm' aria-labelledby="tabelLabel">
                                <thead>
                                    <tr >

                                        <td> </td>
                                        <th className='text-start'>Sıra</th>
                                        <th className='text-start'>Kontrolör</th>
                                        <th className='text-start'>İş Adımı</th>
                                        <th className='text-start'>Havale </th>
                                        <th className='text-start'>Kontrole Başlama</th>
                                        <th className='text-start'>Kontrolü Bitirme</th>
                                        <th className='text-start'>Kalan Süre</th>
                                        <th className='text-start'></th>

                                    </tr>
                                </thead>
                                <tbody >
                                    {mappedusers.map((rec, idx) => <tr style={rec.sira % 2 == 0 ? { "background-color": "#f3f9ff" } : {}}>
                                        <td>
                                            <div hidden={isEmpty(rec.Completed) == false}>
                                                <div className='row m-0 p-0'>
                                                    <div className='col-6 ps-0 pe-0'>
                                                        <button hidden={rec.sira <= minSira} type="button" class="btn btn-link p-0 m-0" onClick={(e) => { rec.sira--; updateuserprojectmapping(rec); }} >
                                                            <span class="material-icons">
                                                                remove_circle
                                                            </span>
                                                        </button>
                                                    </div>
                                                    <div className='col-6 ps-0'>
                                                        <button type="button" class="btn btn-link p-0 m-0" hidden={rec.sira >= maxSira /*|| mappedusers.filter(x => x.sira == rec.sira).length <= 1*/} onClick={(e) => { rec.sira++; updateuserprojectmapping(rec); }}>
                                                            <span class="material-icons">
                                                                add_circle
                                                            </span>
                                                        </button>
                                                    </div>
                                                </div>

                                            </div>
                                        </td>

                                        <td> {rec.sira}</td>

                                        <td>
                                            <img class="rounded-circle" style={{ width: "28px", height: "28px" }} src={"/getuserpic?user=" + getUserName(rec.userName)} /> {getUserName(rec.userName)} ({rec.userName})</td>
                                        <td> {rec.Name}
                                        </td>

                                        <td> {rec.mappingDate}  </td>
                                        <td>  {rec.Started == null ? "" : rec.Started} </td>
                                        <td> {rec.Completed == null ? "" : rec.Completed}</td>
                                        <td> {(rec.Completed == null && rec.deliverDate != null) ? getDenetimSure(rec, denetimlist, appData) : ""}</td>
                                        <td width="10%">
                                            {rec.Completed == null && <button type="button" className="btn btn-primary btn-sm" onClick={() => { gericek(rec.id) }} >Geri Çek</button>}
                                        </td></tr>
                                    )}

                                </tbody>
                            </table>}
                        </div>}
                </div >

            </div >

            <div className="modal" id="myModal" >
                <div class="modal-dialog modal-xl modal-dialog-scrollable modal-dialog-centered">
                    <div class="modal-content" >


                        <div class="modal-header">
                            <h4 class="modal-title">Görev Dağlımı</h4>
                            <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                        </div>


                        <div class="modal-body" >
                            <UserStatistics counter={counter} />
                        </div>


                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                        </div>

                    </div>
                </div>
            </div>


            {denetimlist.length > 0 && <SablonHavalePanel denetimlist={denetimlist} appData={appData} fetchMapping={fetchMappedUsers} />}

            <button className="btn btn-primary btn-sm mb-1 shadow-none" type="button" data-bs-toggle="collapse" data-bs-target="#previousContolors" aria-expanded="false" aria-controls="previousContolors" onClick={fetchMappedUsersToRelatedApps}>
                İlgili Başvuruların Kontrolörleri
            </button>
            <div className="row border-top pt-1 collapse" id="previousContolors">
                <div className="col-12 table-responsive mainpagescroll">
                    {relatedControlors == null ? <div className="spinner-border spinner-border-sm" ></div> :
                        <div className="col-12 mainpagescroll table-responsive ">
                            {relatedControlors.length > 0 && <table className='table  table-sm' aria-labelledby="tabelLabel">
                                <thead>
                                    
                                        <th className='text-start '>Kontrolör</th>
                                        <th className='text-start'>Başvuru No</th>
                                        <th className='text-start'>İş Adımı</th>
                                        <th className='text-start'>Havale </th>
                                        <th className='text-start'>Kontrole Başlama</th>
                                        <th className='text-start'>Kontrolü Bitirme</th>

                                    
                                </thead>
                                <tbody >
                                    {relatedControlors.map((rec, idx) => <tr style={rec.sira % 2 == 0 ? { "background-color": "#f3f9ff" } : {}}>

                                        <td>
                                            <img class="rounded-circle" style={{ width: "28px", height: "28px" }} src={"/getuserpic?user=" + getUserName(rec.userName)} /> {getUserName(rec.userName)} ({rec.userName})</td>
                                        <td>
                                            {rec.appID}
                                        </td>
                                        <td>
                                            {rec.Name}
                                        </td>

                                        <td> {rec.mappingDate}  </td>
                                        <td>  {rec.Started == null ? "" : rec.Started} </td>
                                        <td> {rec.Completed == null ? "" : rec.Completed}</td>
                                        <td> {(rec.Completed == null && rec.deliverDate != null) ? getDenetimSure(rec, denetimlist, appData) : ""}</td>
                                    </tr>
                                    )}

                                </tbody>
                            </table>}
                        </div>             
                
                }
                </div>
            </div>

        </fieldset>
    </div>
    );


}



