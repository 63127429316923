import React, { useState, useEffect } from 'react';
import * as UserPermConstants from '../User/UserPermConstants';
import Application from "./Application.js"
import { useGlobalState, exportExcel, passFilter, removeTurkcLow, isEmpty, showErrorMessage, showConfirmMessage } from '../GlobalState.js';
import $ from 'jquery';
import { ajaxFail } from '../GlobalState.js';
import { userInfo } from 'os';

export default function ApplicationList(props) {

    const [role, setRole] = useGlobalState('role');
    const [userName, setUserName] = useGlobalState('userName');
    var changeComponent = props.changeComponent;

    const [applist, setApplist] = useState(null);
    const [showArchived, setShowArchived] = useState(false);

    const [spinnerClass, setSpinnerClass] = useState("fa fa-refresh");

    const [appGroups, setAppGroups] = useState([]);
    const [appfieldlist, setAppfieldlist] = useState(null);


    const [devamstat, setDevamstat] = useState(null);
    const [filter, setFilter] = useState(null);
    const [surecList, setSurecList] = useState([]);
    const [mysablonlist, setMysablonlist] = useState(null);
    const [colnames, setColnames] = useState(["id", "basvuruTarihi", "basvuruTipi", "basvuruDurumu", "ada", "mahalle", "mevki", "basvuruYapan"]);
    const [sortDirection, setSortDirection] = useState({});
    const [userKabulListPerms, setuserKabulListPerms] = useState([]);

    var sortField = null;

    // const [archivedAppList, setArchivedAppList] = useGlobalState('archivedAppList');
    // const [archivedAppDate, setArchivedAppDate] = useGlobalState('archivedAppDate');

    useEffect(() => {
        if (surecList.length > 0)
            getSablon();
    }, [surecList])

    //useEffect(() => {
    //    if (archivedAppDate == "  ")//this is not defult value to prevent first call
    //        getSablon();
    //}, [archivedAppDate]);

    useEffect(() => {

        fetchSurecList();
        getAppGroupList();
        fetchkabullistperm();
        fetchappfieldlist();
    }, [])




    const stopSpinner = () => {
        setSpinnerClass("fa fa-refresh")
    }

    const startSpinner = () => {
        setSpinnerClass("spinner-grow spinner-grow-sm")
    }

    const fetchappfieldlist = () => {
        fetch("appfieldlist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    setAppfieldlist(value);
                })
            }
        });
    }


    const myCompare = (a, b) => {
        var av = a[sortField];
        var bv = b[sortField];
        if (sortField == "basvuruDurumu") {
            av = getDurumName(av);
            bv = getDurumName(bv);
        }
        var ret = 1;
        if (sortDirection[sortField] == "ZA")
            ret = -1;
        if (av == null && a.appData != null && a.appData.length > 2) {
            var oa = a.appDataObject[sortField];
            if (oa != null && oa.value != null)
                av = oa.value;
        }
        if (bv == null && b.appData != null && b.appData.length > 2) {
            var ob = b.appDataObject[sortField];
            if (ob != null && ob.value != null)
                bv = ob.value;
        }
        if (av == null && bv != null)
            return -1 * ret;
        if (av != null && bv == null)
            return ret;
        if (typeof av === 'string' || av instanceof String)
            if (typeof bv === 'string' || bv instanceof String)
                return av.localeCompare(bv) * ret;

        if (av < bv) {
            return -1 * ret;
        }
        if (av > bv) {
            return ret;
        }
        return 0;
    }
    const myCompareRev = (a, b) => {
        return -1 * myCompare(a, b)
    }

    const showTitle = (name) => {
        if (name == "id") return "Id";
        if (name == "securityToken") return "securityToken";
        if (name == "boylam") return "Boylam";
        if (name == "enlem") return "Enlem";
        if (name == "basvuruTipi") return "Başvuru Tipi";
        if (name == "mevki") return "Mevki";
        if (name == "mahalle") return "Mahalle";
        if (name == "ada") return "Ada/Parsel";
        if (name == "başvuruSahibi") return "Başvuru Sahibi";
        if (name == "basvuruTarihi") return "Başvuru Tarihi";
        if (name == "basvuruYapan") return "Başvuru Yapan";
        if (name == "basvuruDurumu") return "Başvuru Durumu";
        if (name == "onKontrol") return "Ön Kontrol";
        if (name == "appdata") return "Appdata";
        if (name == "arsaSahibiTel") return "Taşınmaz Sahibi Tel";
        if (name == "arsaSahibiTC") return "Taşınmaz Sahibi TC";
        if (name == "sokak") return "Sokak";
        if (name == "no") return "No";
        if (name == "arsaSahibi") return "Taşınmaz Sahibi";
        if (name == "idariMahalle") return "İdari Mahalle";
        if (name == "removed") return "Silinen";
        if (name == "ucret") return "Ücret";
        if (name == "eimzagonder") return "E-İmza Gönder";
        if (name == "updateCount") return "Updatecount";
        if (name == "duzeltmeSayi") return "Düzeltme Sayısı";
        if (name == "removeComment") return "Removecomment";
        if (name == "iadeNedeni") return "İade Nedeni";
        if (name == "iadeEden") return "İade Eden";
        if (name == "sonDuzeltmeTalebiTarihi") return "Son Düzeltme Talep Tarihi";
        if (name == "sonDuzeltmeTarihi") return "Son Düzeltme Tarihi";
        if (name == "kontrolBaslamaTarihi") return "Kontrol Başlama Tarihi";
        if (name == "kontrolBitmeTarihi") return "Kontrol Bitme Tarihi";
        if (name == "onayVerilmeTarihi") return "Onay Verilme Tarihi";
        if (name == "kontrolSiraTarihi") return "Kontrol Sıra Tarihi";
        if (name == "eimzaAcilmaTrihi") return "Eimza Açılma Tarihi";
        if (name == "iadeTarihi") return "İade Tarihi";
        if (name == "taslakOlusturmaTarihi") return "Taslak Oluşturma Tarihi";
        if (name == "kabulEdilmeTarihi") return "Kabul Edilme Tarihi";
        if (name == "surecTamamlanmaTarihi") return "Süreç Tamamlanma Tarihi";
        if (name == "lastUpdateTime") return "Son Güncelleme Tarihi";
        if (name == "basvuruSahibi") return "Başvuru Sahibi";
        if (name == "vekaletenBasvuru") return "Vekaleten Başvuru";
        if (name == "vekaletDosyaname") return "Vekalet Dosya İsmi";
        if (name == "vekilName") return "Vekil İsmi";
        if (name == "vekilTC") return "Vekil TC";
        if (name == "vekilTel") return "Vekil Telefon";
        if (name == "temp") return "Temp";
        if (name == "archived") return "Arşiv";
        if (name == "kontrolor") return "Kontrolör";
        if (name == "müellif") return "Müellif";
        if (name == "tümKontrolorler") return "Tüm Kontrolörler";

        return name;
    }


    const sortList = (fildname) => {
        sortField = fildname;
        if (sortDirection[sortField] == null || sortDirection[sortField] == "ZA") {
            sortDirection[sortField] = "AZ";
        }
        else {
            sortDirection[sortField] = "ZA";
        }
        var temp = [...applist]; //clone the array to update the component on setApplist
        temp.sort(myCompare);
        setApplist(temp);
    }
    const getSortedList = (data, temp) => {
        if (data.sort != null && data.sort.length > 0) {
            var sf = data.sort.split(",");
            if (sf.length > 0) {
                for (var i = 0; i < sf.length; i++) {
                    if (sf[i][0] == '-') {
                        sortField = sf[i].substring(1);
                        temp.sort(myCompareRev);
                    }
                    else {
                        sortField = sf[i];
                        temp.sort(myCompare);
                    }
                }
            }

        }
        return temp;
    }

    const getAppGroupList = () => {

        fetch("getappgrouplist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    value.unshift({ name: "Tümü" })
                    setAppGroups(value);
                })
            }
        });
    }




    const getSablon = () => {
        startSpinner();
        fetch("getmysablon", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (!response.ok) {
                ajaxFail(response);
            }

            else {
                var list = null;
                try {
                    response.json().then(async slist => {
                        list = slist.filter(r => r.forListView == true);
                        if (list != null && list.length > 0) {
                            var data = list[0];
                            if (window.localStorage.getItem('sablon') != null && list[window.localStorage.getItem('sablon')] != null)
                                data = list[window.localStorage.getItem('sablon')];
                            else
                                window.localStorage.setItem('sablon', data);
                            if (data.filter != null && data.filter.length > 0)
                                setFilter(data.filter);
                            if (data.colNames != null && data.colNames.length > 0) {
                                var d = data.colNames.replaceAll('"', "");
                                if (d.length > 0)
                                    setColnames(d.split(","));
                            }
                        }
                        setMysablonlist(list);
                        getApplicationList(list, "0");
                    });
                }
                catch (ex) {
                    //getApplicationList(null, "0$" +archivedAppDate);

                }


            }
        });
    }



    const appsauthorlist = (applist, sablonlist) => {

        fetch("appsauthorlist", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify("ALL")

        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(async data => {

                    data.forEach((k) => {
                        var app = applist.find(x => x.id == k.appID);
                        if (app == null)
                            return;
                        var m = k.userName + "(" + k.muellifType + ")";
                        if (app.muellifCount < 2) {
                            if (app.müellif != null && app.müellif.length > 0)
                                app.müellif += ", ";
                            app.müellif += m
                        }
                        app.allmuelliflist += m + "  ";
                        app.muellifCount += 1;
                    });
                    stopSpinner();
                    setApplist(applist.slice(0))
                });
            }
        });
    }





    const getcontrollerlist = (applist, sablonlist) => {
        var ids = applist.map(x => x.id).join();
        if (ids.length == 0) {
            stopSpinner();
            setApplist(applist)
            return;

        }
        fetch("getcontrollerlist", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(ids)

        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(async data => {
                    if (isEmpty(data) == false) {
                        data = JSON.parse(data)
                        data.forEach((k) => {
                            var apprec = applist.find(x => x.id == k.appID);

                            if (k.sira == k.activeDenetim) {
                                if (isEmpty(apprec.kontrolor) == false) {
                                    apprec.kontrolor += ", ";
                                    apprec.kontrolorNameFamily += ", ";
                                }
                                apprec.kontrolor += k.userName;
                                apprec.kontrolorNameFamily += k.Name + " " + k.Family;
                            }
                            if (k.userName == userName) {
                                if (isEmpty(apprec["Kontrol Durumu"]))
                                    apprec["Kontrol Durumu"] = "";

                                if (isEmpty(k.Started) && k.duzeltmeDurum != true && k.sira == k.activeDenetim) {
                                    apprec["Kontrol Durumu"] += getDurumName(UserPermConstants.Basvuru_Durumu_Kontrol_Sirada_Bekliyor)+" "
                                }

                                if (isEmpty(k.Started) == false && isEmpty(k.Completed) && k.sira == k.activeDenetim) {
                                    apprec["Kontrol Durumu"] += getDurumName(UserPermConstants.Basvuru_Durumu_Kontrol_Ediliyor)+" "
                                }

                                if (isEmpty(k.Completed) == false) {
                                    apprec["Kontrol Durumu"] += getDurumName(UserPermConstants.Basvuru_Durumu_Kontrol_Tamamlandi)+" "
                                }

                                if (isEmpty(k.Started) && isEmpty(k.duzeltmeIste) == false && k.duzeltmeDurum == true && k.sira == k.activeDenetim) {
                                    apprec["Kontrol Durumu"] += getDurumName(UserPermConstants.Basvuru_Durumu_Duzeltme_Bekleniyor)+" "
                                }
                            }

                        });
                    }
                    stopSpinner();
                    setApplist(applist)

                });
            }
        });
    }

    const fillAuthoresAndControllers = (applist) => {
        applist.forEach(x => {
            if (x.appData != null) x.appDataObject = JSON.parse(x.appData)

            x.tümKontrolorler = ""
            x.müellif = "";
            x.muellifCount = 0;
            x.totallContolorCount = 0;
            x.activeContolorCount = 0;
            x.allmuelliflist = "";
            x.kontrolorNameFamily = ""
            x.kontrolor = ""
            x.müellifKullanıcıAdı = ""

            if (x.temp != null) {
                var persons = x.temp.split("$");
                x.başvuruSahibi = persons[0];

                if (isEmpty(persons[1]) == false) {
                    var muelliflist = persons[1].split(",");

                    muelliflist.filter(k => isEmpty(k) == false).forEach((k) => {
                        var mdata = k.split("%");
                        if (x.temp.includes(userName+"%"))
                            x.müellifKullanıcıAdı += mdata[0] + " ";
                        var m = mdata[1]
                        if (x.muellifCount < 2) {
                            if (!isEmpty(x.müellif))
                                x.müellif += ", ";
                            x.müellif += m
                        }
                        x.allmuelliflist += m + "  ";
                        x.muellifCount += 1;
                    });
                }



                if (isEmpty(persons[2]) == false) {
                    var kdata = persons[2].split(",");
                    kdata.filter(k => isEmpty(k) == false).forEach((k) => {
                        var mdata = k.split("%");
                        if (isEmpty(x.tümKontrolorler) == false)
                            x.tümKontrolorler += ", ";
                        x.tümKontrolorler += mdata[1];
                    });
                }

            }
        });
    }


    const showArchiveList = () => {
        var show = document.getElementById("archiveCheckBox").checked;
        startSpinner();
        if (show == true) {
            fetch("applicationlist?removedstatus=archived", {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }).then(async response => {
                if (!response.ok) {
                    ajaxFail(response);
                }
                else {

                    response.json().then(async data => {

                        fillAuthoresAndControllers(data);

                        setApplist(applist.concat(data));
                        stopSpinner()
                    });
                }
            });
        }
        else {
            var noarch = applist.filter(x => x.archived != true)
            setApplist(noarch);
            stopSpinner()
        }
    }


    const getApplicationList = (sablonlist, removed) => {
        fetch("applicationlist?removedstatus=" + removed, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {



            if (!response.ok) {
                ajaxFail(response);
            }
            else {

                response.json().then(async applist => {

                    /* var lastUpdateTime = archivedAppDate;*/

                    fillAuthoresAndControllers(applist);


                    /*   setArchivedAppDate(lastUpdateTime);*/


                    //if (sablonlist != null
                    //    && window.localStorage.getItem('sablon') != null
                    //    && sablonlist[window.localStorage.getItem('sablon')] != null
                    //) {
                    //    var templist = getSortedList(sablonlist[window.localStorage.getItem('sablon')], archivedAppList);
                    //    setArchivedAppList(templist);
                    //}
                    //else
                    //    setArchivedAppList([...archivedAppList]);
                    ////setApplist(archivedAppList);

                    //if (archivedAppList.length > 0)
                    getcontrollerlist(applist, sablonlist);
                    //else
                    //                      stopSpinner();


                });
            }
        });
    }


    const filterTable = (event) => {
        var textbox = document.getElementById("mySearchInput")
        if (textbox == null)
            return;
        var value = removeTurkcLow(textbox.value);
        var keywords = value.split(" ");

        $("#myTable tr").filter(function () {
            var rowtext = "";
            $(this).children().each(function () {
                rowtext += $(this).text() + " ";
            });

            var t1 = removeTurkcLow(rowtext);

            for (let index = 0; index < keywords.length; ++index) {
                if (t1.indexOf(keywords[index]) == -1) {
                    return $(this).toggle(false);
                }
            }
            return $(this).toggle(true);
        });


        document.getElementById("totalrec").innerHTML = $("#myTable tr:visible").length + " kayıt";
    }

    const getcurrentFilters = () => {
        var cl = colnames;
        var val = []
        val.push(document.getElementById("mySearchInput").value);
        for (var c = 0; c < cl.length; c++) {
            val.push(document.getElementById(cl[c]).value);
        }
        return val;
    }


    //const applyFilter = (filters) => {
    //    var cl = colnames;
    //    if (isEmpty(filters[0]) == false)
    //        document.getElementById("mySearchInput").value = filters[0];
    //    for (var c = 0; c < cl.length; c++) {
    //        if (isEmpty(filters[c + 1]) == false && document.getElementById(cl[c]) != null)
    //            document.getElementById(cl[c]).value = filters[c + 1];
    //    }
    //}


    const fetchkabullistperm = () => {
        fetch("kabullistpermsnames", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(userName)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    setuserKabulListPerms(value);
                })
            }
        });
    }



    const filterColumn = (root) => {
        var cl = colnames;
        $(root).find("#myTable tr").filter(function () {

            for (var c = 0; c < cl.length; c++) {
                var keywordElem = root.getElementById(cl[c]);
                var td = $(this).children().eq(c);
                if (keywordElem != null && isEmpty(keywordElem.value) == false) {
                    var keyword = keywordElem.value;
                    var pass = true;
                    if (keyword.startsWith("-")) {
                        pass = false
                        keyword = keyword.substr(1);
                    }
                    if (keyword.length == 0) break;
                    td.attr('style', 'background: #fff8dd !important');
                    var t1 = removeTurkcLow(td.text());
                    var t2 = removeTurkcLow(keyword);
                    if ((t1.indexOf(t2) == -1) == pass)
                        return $(this).toggle(false);
                }
                else
                    td.removeAttr('style');
            }
            return $(this).toggle(true);
        });

        if (root.getElementById("totalrec") != null && $("#myTable tr:visible") != null)
            root.getElementById("totalrec").innerHTML = $("#myTable tr:visible").length + " kayıt";
    }


    const handleGroupChange = () => {
        var sab = document.getElementById("groupSelect").value;
        var data = appGroups[sab];
        setDevamstat(data);

    }

    const handleSablonChange = (sab) => {

        window.localStorage.setItem('sablon', sab);
        var data = mysablonlist[sab];
        if (data.filter != null)
            setFilter(data.filter);
        if (data.colNames != null && data.colNames.length > 0) {
            var d = data.colNames.replaceAll('"', "");
            if (d.length > 0) {
                var temp = [...applist];
                temp = getSortedList(data, temp);
                setApplist(temp);
                setColnames(d.split(","));
            }
        }
    }

    const fetchSurecList = () => {

        fetch("sureclist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);

            }
            else {
                response.json().then(value => {
                    value.hashtable = {};
                    value.forEach(element => value.hashtable[element.id] = element.name);
                    setSurecList(value);
                })
            }
        });
    }



    const setAppRemovedState = (app, isremoved, delete_comment) => {
        app.removed = isremoved;
        app.removeComment = delete_comment;
        fetch("saveapplication", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(app)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                getApplicationList(mysablonlist, "0");
            }
        });
    }



    const deleteApp = (app) => {

        if (app.removed != true) {
            var comment = window.prompt("Lütfen Silme Nedenini Açıklayınız")
            if (comment == null || comment.length == 0) {
                showErrorMessage("Açıklama Boş Olamaz", "Lütfen Silme Nedenini Yazınız");
                return;
            }
            setAppRemovedState(app, true, comment);
            return;
        }


        fetch("removeapp", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(app.id)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(async data => {
                    setApplist(applist.filter(x => x.id != app.id));
                });
            }
        });


    }


    const getDurumName = (i) => {

        var r = surecList.find(element => element.id == i);
        if (r == null) return i;
        return r.name;
    }

    const reduceTotalNames = (str) => {
        if (isEmpty(str)) return str;
        var parts = str.split(',')
        if (parts.length <= 2)
            return str;
        var vis = parts[0] + " , " + parts[1];
        vis = vis + ", +" + (parts.length - 2);
        return <>  {vis}   <span hidden>{str}</span></>;
    }

    const findRowCount = (sab, tabledata) => {
        var r = tabledata.filter(apprec =>
            passFilter(apprec, mysablonlist[sab].filter, userName, userKabulListPerms, appfieldlist) == true && (devamstat == null || devamstat.appState == undefined || devamstat.appState.split(",").includes(apprec.basvuruDurumu + "")));
        return r.length;
    }

    const generateColumnHeader = (c) => {

        var t = showTitle(c)
        if (t.length > 14) {
            t = t.substr(0, 12) + "...";
        }
        return t;
    }





    const renderToList = (tabledata) => {


        if (tabledata == null || tabledata.length == 0)
            return <div className="w-100 text-center" >Kayıt Yok</div>;
        var devamarray = [];
        if (devamstat != null && devamstat.appState != undefined)
            devamarray = devamstat.appState.split(",");


        // apprec.removed != true  is required becous after removing an app we dont read the app list again
        var filteredList = tabledata.filter(apprec => apprec.removed != true && passFilter(apprec, filter, userName, userKabulListPerms, appfieldlist) == true && (devamarray.length == 0 || devamarray.includes(apprec.basvuruDurumu + "")))

        var rows = filteredList.map(apprec =>
            <tr key={apprec.id} role="button" className="v-hover" onClick={() => { changeComponent(<Application key={apprec.id} appData={apprec} changeComponent={changeComponent} backButton={<ApplicationList changeComponent={props.changeComponent} filters={getcurrentFilters()} />} />) }} >
                {colnames.map(c => {
                    if (apprec[c] != null) {
                        if (c == "basvuruDurumu") return <td >{surecList.hashtable[apprec[c]]}</td>
                        else if (c == "müellif") {
                            return <td title={apprec.allmuelliflist}>
                                {apprec[c]}{apprec.muellifCount > 2 ? (", +" + (apprec.muellifCount - 2)) : ""}
                            </td>
                        }
                        else if (c == "kontrolor") {
                            return <td title={apprec.kontrolorNameFamily}>{reduceTotalNames(apprec.kontrolorNameFamily)}</td>
                        }
                        else if (c == "tümKontrolorler") {
                            return <td title={apprec.tümKontrolorler}>{reduceTotalNames(apprec.tümKontrolorler)}</td>
                        }
                        else return <td >{apprec[c]}</td>
                    }
                    try {
                        var appDData = apprec.appDataObject;
                        if (appDData != null && appDData[c] != null) {
                            if (appDData[c].type != null && appDData[c].type == "checkbox") {
                                return <td className="text-center"><input type="checkbox" className="form-check-input p-0 " checked={appDData[c].value} /></td>
                            }
                            return <td >{appDData[c].value}</td>
                        }
                    }
                    catch (ex) {
                        return <td>{ex.toString()}</td>
                    }

                    return <td></td>
                })}

                <td style={{ "overflow": "hidden", "white-space": "nowrap", "font-size": "20px" }}>
                    {/*{apprec.removed != true && <a href="#" onClick={() => { changeComponent(<Application key={apprec.id} appData={apprec} changeComponent={changeComponent} backButton={<ApplicationList changeComponent={props.changeComponent} filters={getcurrentFilters()} />} />) }} className=" text-decoration-none material-icons align-middle" >edit</a>}*/}
                    {apprec.removed == true && role != null && role.indexOf(UserPermConstants.Basvuru_Silme) > -1 && <a href="/#" onClick={(event) => { event.stopPropagation(); setAppRemovedState(apprec, false) }} className="text-decoration-none align-middle" ><i className="fa fa-refresh align-middle"></i></a>}
                    {((userName == apprec.basvuruYapan && apprec.basvuruDurumu == UserPermConstants.Basvuru_Durumu_Taslak) || (role != null && role.indexOf(UserPermConstants.Basvuru_Silme) > -1)) && <a href="/#" onClick={(event) => {
                        event.stopPropagation();
                        showConfirmMessage("Silmek Istediğinzden Emin misiniz?", "Taslak Olmayan Başvuruları Silmek Beklenmeyen Hatalara Sebep Olabilir.", "Evet Sil", "Hayır İptal Et", () => { setTimeout(() => deleteApp(apprec), 100) });

                    }} className="text-decoration-none material-icons align-middle"  >delete</a>}
                </td>
            </tr>
        );

        var selectedSablonIndex = window.localStorage.getItem('sablon');
        if (selectedSablonIndex == null)
            selectedSablonIndex = 0;

        var mytable =
            <div className="" key={selectedSablonIndex}>
                <div className="p-2">
                    <div className="overflow-auto w-100 mainpagescroll pb-1" style={{ "white-space": "nowrap" }}>
                        {mysablonlist.map((s, idx) => <button className={"btn  me-2 text-decoration-none border  mt-1" + (selectedSablonIndex == idx ? " btn-primary" : " btn-link")} onClick={() => handleSablonChange(idx)}> {s.name} ({findRowCount(idx, tabledata)})</button>)}
                    </div>
                </div>




                <div className="table-responsive mainpagescroll" style={{ "transform": "rotateX(180deg)" }}>
                    <table className='table  table-vdstriped  mt-0 mb-0' style={{ "transform": "rotateX(180deg)" }} id="listtable">
                        <thead className="p-0">
                                {colnames.map((c, idx) =>
                                    <th style={{ verticalAlign: "bottom" }} className="pt-0 m-0">
                                        <div>
                                            <button className="btn btn-link btn-sm text-decoration-none m-0 p-0" onClick={() => sortList(c)} title={c}> {generateColumnHeader(c)}</button>
                                        </div>
                                        <div>
                                            <input type="text" className="form-control form-control-sm p-0 m-0" id={c} placeholder="" name={c} onChange={() => filterColumn(document)} />
                                        </div>
                                    </th>
                                )}

                                <th id="totalrec" key={rows.length}>{rows.length} kayıt</th>
                        </thead>
                        <tbody id="myTable">
                            {rows}
                        </tbody>
                    </table>
                </div>
            </div>
        return (mytable);
    }

    const applyFilter = (filters) => {
        var cl = colnames;
        if (isEmpty(filters[0]) == false)
            document.getElementById("mySearchInput").value = filters[0];
        for (var c = 0; c < cl.length; c++) {
            if (isEmpty(filters[c + 1]) == false && document.getElementById(cl[c]) != null)
                document.getElementById(cl[c]).value = filters[c + 1];
        }
    }


    var tabledata = <div class="d-flex justify-content-center"><div class="spinner-border" role="status"></div></div>

    if (applist != null && appfieldlist != null) {
        tabledata = renderToList(applist);
    }
    if (props.filters != null && document.getElementById("myTable") != null) {
        applyFilter(props.filters)
        filterTable();
        filterColumn(document);
    }

    return <div id="applistroot">
        {/*<div className="w-100 mt-4 mb-4 text-center align-items-center justify-content-center" hidden={allready == true} ><div className="spinner-border" ></div></div>*/}
        <div className="  align-items-center justify-content-center p-1" >

            <div className="mt-1 p-2 pb-1 row" >
                <div class="col-md-3 col-12">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="archiveCheckBox" onChange={showArchiveList} />
                        <label class="form-check-label" for="flexCheckDefault">
                            Arşiv Dosyaları Göster
                        </label>
                    </div>
                </div>
                <div class="col-md-1 col-12 text-end">
                    Gruplar
                </div>

                <div className="col-12 col-md-3">

                    {appGroups != null ? <select className="form-select form-select-sm" id="groupSelect" name="groupSelect" onChange={handleGroupChange}>
                        {appGroups.map((s, idx) => <option value={idx} > {s.name}</option>)}
                    </select> : ""}
                </div>
                <div className="col-12 col-md-3">

                    <input className="form-control form-control-sm" id="mySearchInput" type="text" placeholder="Arama" onKeyUp={filterTable} />

                </div>
                <div className="col-12 col-md-2 text-end pt-1">
                    <a onClick={() => {
                        startSpinner();
                        getApplicationList(mysablonlist, "0");
                    }} href="#" className="text-primary"> <i className={spinnerClass} id="refreshButton"></i></a>
                    <a download="report.csv" id="downloadlink" onClick={() => { exportExcel("listtable", "downloadlink") }} href="#" className="text-decoration-none text-success ms-2">
                        <i className="fa fa-file-excel-o"></i>
                    </a>
                </div>
            </div>



            {tabledata}

        </div>
    </div>


};
