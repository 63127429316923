import React, { useState, useEffect } from 'react';
import { ajaxFail, isEmpty, sendpost } from '../GlobalState.js';
import Multiselect from 'multiselect-react-dropdown';
import bootstrap from 'bootstrap/dist/js/bootstrap.min.js'


export default function DenetimPermissionPanel(props) {
    
    const [listData, setListData] = useState([]);
    const [denetimList, setdenetimList] = useState(props.denetimList.filter(x=>x.isadimi==true));
    const multiselectRefNew = React.createRef();
    var username = props.username;

    const fetchList = () => {
        fetch("getdenetimperms", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(username)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    setListData(value);
                   
                })
                
            }
        });
    }

   



   


    const Add = (item) => {
        var postdata = {};
        postdata.UserName = username;
        postdata.DenetimId = item.id;
        sendpost("mapdenetimtouser", postdata, "spin1");
       
    }

    const Sil = (denetim) => {
        var postdata = {}
        postdata.UserName = username;
        postdata.denetimId = denetim.id;
        sendpost("removedenetimfromuser", postdata, "spin1");
    }




    useEffect(() => {
        fetchList();
        var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
        var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
            return new bootstrap.Popover(popoverTriggerEl)
        })
    }, [])
   
    var selectedids = listData.map(x => x.id);

    return (
        <div >
            <div className="row">
                <div className="col-8">
                    <h6>İş Adımı Yetkileri
                        <button type="button" className="btn btn-secondary btn-sm fw-bold pt-0 pb-0 mt-0 ms-2" id="mypopover2" data-bs-toggle="popover" data-bs-trigger="focus" title="AÇIKLAMA" data-bs-content="Kontrolörlere hangi iş adımlarının havale edilebileceğini seçebilirsiniz. Her kontrolöre sadece burada yer alan iş adımlarından havale yapılabilir.">?</button>
                   </h6>
                </div>

                <div className="col-4 text-end">
                    <span className="spinner-border spinner-border-sm ms-3" id="spin1" hidden>   </span>
                </div>
            </div>
            <Multiselect className="p-0 ps-1 form-control-sm bg-white mb-2"
                options={denetimList}
                emptyRecordMsg="Başka Kayıt Yok"
                displayValue="name"
                isObject={true}
                closeOnSelect={true}
                ref={multiselectRefNew}
                selectedValues={listData}
                avoidHighlightFirstOption={true}
                onSelect={(selectedList, item) => { Add(item) }} 
                onRemove={(selectedList, item) => { Sil(item) }}
            />
        </div>

    );


}