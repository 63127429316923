import React, { useState, useEffect } from 'react';
import * as UserPermConstants from '../User/UserPermConstants';
import { isEmpty, showSuccessMessage, useGlobalState } from '../GlobalState.js';
import { ajaxFail } from '../GlobalState.js';
import PDFView from './PDFView';
import EImzaList from './EImzaList.js';

export default function EImzaDetails(props) {
    const [e, setEimza] = useState(props.eimza);
    const [role, setRole] = useGlobalState('role');

    if (isEmpty(e.detectedPos) == false)
        e.detectedPosObject = JSON.parse(e.detectedPos)


    useEffect(() => {
        document.getElementById("activepaneldiv").scrollTo(0, 0)
    }, [])

    const getValue = (v) => {
        if (isEmpty(v)) return null;
        return v;

    }




    const updateeimza = (detectedPos) => {
        e.detectedPos = detectedPos;
        e.detectedPosObject = null;
        fetch("updateeimza", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'

            },
            body: JSON.stringify(e)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(async data => {
                     setEimza(data);
                    if (detectedPos == "UPDATEME")
                        return;

                    if ((isEmpty(data.paftaRuhsatOnayKod) == false && hasloc(data,"#ruhsatno") == false) ||
                        (isEmpty(data.paftaRuhsatTarih) == false && hasloc(data, "#tarih") == false) ||
                        (isEmpty(data.kot) == false && hasloc(data, "#kot") == false) ||
                        (isEmpty(data.fikra) == false && hasloc(data, "#fikra") == false) ||
                        (isEmpty(data.bend) == false && hasloc(data, "#bend") == false) ||
                        (isEmpty(data.madde) == false && hasloc(data, "#madde") == false)
                    ) {
                        props.setActivePanel(<PDFView Eimza={e} setActivePanel={props.setActivePanel} source="DETAL_UPDATE" />);
                        return;
                    } 


                    showSuccessMessage("Guncelleme Başarılı");
                    
                   
                    
                });
            }
        });
    }


    const hasloc = (data,text) => {
        if (data.detectedPosObject == null) return false
        var list = data.detectedPosObject.filter(x => x.text == text);
        if (list.length > 0) {
            return true
        }
        return false;
    }


    const getloc = (text) => {
        if (e.detectedPosObject == null) return <span className='text-danger'> Tespit Edilmedi</span>


        var list = e.detectedPosObject.filter(x => x.text == text);
        if (list.length > 0) {
            return <span className='text-success'> {list[0].x} , {list[0].y} </span>
        }
        return <span className='text-danger'> Tespit Edilmedi</span>
    }

    return <div >
        <div className="row mb-1"  >
            <div className="col-3">
                <h6 className="text-primary ">E-İmza Ayarları</h6 >
            </div>
            <div className="col-6 text-center text-info">
                E-İmza ID: {e.id}
            </div>
            <div className="col-3 text-end">
                {isEmpty(e.imzatarih) == false && <a className="text-decoration-none align-middle me-2" href={'getimzalidosya?eimzaid=' + e.id} download="eimza.pdf"><i className="fa fa-cloud-download" style={{ "font-size": "20px" }}></i>  </a>}
                {role.indexOf(UserPermConstants.Imzaya_Açma) > -1 && <a className="text-decoration-none btn btn-link border-0 p-0 me-2" onClick={() => props.setActivePanel(<PDFView Eimza={e} setActivePanel={props.setActivePanel} appData={props.appData}/>)} > <i class=" fa fa-file-pdf-o" aria-hidden="true"></i></a>}
                <button className="btn btn-sm btn-link text-decoration-none p-0 me-1" type="button" onClick={() => props.setActivePanel(<EImzaList appData={props.appData} setActivePanel={props.setActivePanel} />)} title="kapat"> <i class="fa fa-close" style={{ "font-size": "18px" }}></i></button>

            </div>
        </div>

        <div class="row">

            <div class="col-12 text-end ">
                <button type="button" id="uygulabutton" class="btn btn-sm btn-primary" onClick={() => updateeimza(e.detectedPos)}>Uygula</button>
                <div id={"downloadspinner"} className="spinner-border spinner-border-sm" hidden>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12  fw-bold text-primary">
                Yasal Yazı Ayarları
            </div>

            <div class="row mt-1">
                <div class="col-2 ">X
                    <input type="number" className="form-control form-control-sm" id="x_text" defaultValue={e.x_text} name="x_text" onChange={(ev) => e.x_text = getValue(ev.target.value)} />
                </div>

                <div class="col-2">Y
                    <input type="number" className="form-control form-control-sm" id="y_text" defaultValue={e.y_text} name="y_text" onChange={(ev) => e.y_text = getValue(ev.target.value)} />
                </div>
                <div class="col-2 "> Alan Eni
                    <input type="number" className="form-control form-control-sm" id="w_text" defaultValue={e.w_text} name="w_text" onChange={(ev) => e.w_text = getValue(ev.target.value)} />
                </div>
                <div class="col-2 "> Alan Boyu
                    <input type="number" className="form-control form-control-sm" id="h_text" defaultValue={e.h_text} name="h_text" onChange={(ev) => e.h_text = getValue(ev.target.value)} />
                </div>

                <div class="col-2"> Font
                    <input type="number" className="form-control form-control-sm" id="font_size" defaultValue={e.font_size} name="y_qr" onChange={(ev) => e.font_size = getValue(ev.target.value)} />
                </div>

            </div>

            <div class="row mt-4">
                <div class="col-12  fw-bold text-primary">
                    QR Kod Ayarları
                </div>
                <div class="col-2">X
                    <input type="number" className="form-control form-control-sm" id="x_qr" defaultValue={e.x_qr} name="x_qr" onChange={(ev) => e.x_qr = getValue(ev.target.value)} />
                </div>

                <div class="col-2">Y
                    <input type="number" className="form-control form-control-sm" id="y_qr" defaultValue={e.y_qr} name="y_qr" onChange={(ev) => e.y_qr = getValue(ev.target.value)} />
                </div>

                <div class="col-2">Ölçek
                    <input type="number" className="form-control form-control-sm" id="scale_qr" defaultValue={e.scale_qr} name="scale_qr" onChange={(ev) => e.scale_qr = getValue(ev.target.value)} />
                </div>



            </div>
            <div class="row mt-5">
                <div class="col-12  fw-bold text-primary">
                    Yapı Kullanma Numaraları ve Değerleri
                </div>
            </div>
            <div class="row  mt-1">
                <div class="col-3 ">
                    10. Belgenin onay tarihi
                </div>
                <div class="col-3">
                    <input type="text" className="form-control form-control-sm" id="x_qr" defaultValue={e.yapiBelgeOnay} name="yapiBelgeOnay" onChange={(ev) => e.yapiBelgeOnay = getValue(ev.target.value)} />
                </div>
            </div>
            <div class="row  mt-1">
                <div class="col-3">
                    11. Belge no
                </div>
                <div class="col-3">
                    <input type="text" className="form-control form-control-sm" id="x_qr" defaultValue={e.yapiBelgeNo} name="yapiBelgeNo" onChange={(ev) => e.yapiBelgeNo = getValue(ev.target.value)} />
                </div>
            </div>
            <div class="row  mt-1">
                <div class="col-3">
                    136. Onay kodu
                </div>
                <div class="col-3">
                    <input type="text" className="form-control form-control-sm" id="x_qr" defaultValue={e.yapiOnayKod} name="yapiOnayKod" onChange={(ev) => e.yapiOnayKod = getValue(ev.target.value)} />
                </div>
            </div>



            <div class="row mt-5">
                <div class="col-12  fw-bold text-primary">
                    Ruhsat Numaraları ve Değerleri
                </div>
            </div>
            <div class="row  mt-1">
                <div class="col-3">
                    10.Ruhsatın onay tarihi
                </div>
                <div class="col-3">
                    <input type="text" className="form-control form-control-sm" id="x_qr" defaultValue={e.ruhsatOnayTarih} name="ruhsatOnayTarih" onChange={(ev) => e.ruhsatOnayTarih = getValue(ev.target.value)} />
                </div>
            </div>
            <div class="row  mt-1">
                <div class="col-3">
                    11.Ruhsat no
                </div>
                <div class="col-3">
                    <input type="text" className="form-control form-control-sm" id="x_qr" defaultValue={e.ruhsatNo} name="ruhsatNo" onChange={(ev) => e.ruhsatNo = getValue(ev.target.value)} />
                </div>
            </div>
            <div class="row  mt-1">
                <div class="col-3">
                    27.Ruhsatın geçerlilik tarihi
                </div>
                <div class="col-3">
                    <input type="text" className="form-control form-control-sm" id="x_qr" defaultValue={e.ruhsatTarih} name="ruhsatTarih" onChange={(ev) => e.ruhsatTarih = getValue(ev.target.value)} />
                </div>
            </div>

            <div class="row  mt-1">
                <div class="col-3">
                    121.Onay kodu
                </div>
                <div class="col-3">
                    <input type="text" className="form-control form-control-sm" id="x_qr" defaultValue={e.ruhsatOnayKod} name="ruhsatOnayKod" onChange={(ev) => e.ruhsatOnayKod = getValue(ev.target.value)} />
                </div>
            </div>


            <div class="row mt-5">
                <div class="col-12  fw-bold text-primary">
                    Yıkım Ruhsatı Değerleri 
                </div>
            </div>
            <div class="row  mt-1">
                <div class="col-3">
                    12.Yıkım ruhsatının onay tarihi
                </div>
                <div class="col-3">
                    <input type="text" className="form-control form-control-sm" id="x_qr" defaultValue={e.yikimRuhsatOnayTarihi} name="yikimRuhsatOnayTarihi" onChange={(ev) => e.yikimRuhsatOnayTarihi = getValue(ev.target.value)} />
                </div>
            </div>
            <div class="row  mt-1">
                <div class="col-3">
                    13.Yıkım ruhsat no
                </div>
                <div class="col-3">
                    <input type="text" className="form-control form-control-sm" id="x_qr" defaultValue={e.yikimRuhsatNo} name="yikimRuhsatNo" onChange={(ev) => e.yikimRuhsatNo = getValue(ev.target.value)} />
                </div>
            </div>
            <div class="row  mt-1">
                <div class="col-3">
                    19. Yıkım ruhsatının geçerlilik tarihi
                </div>
                <div class="col-3">
                    <input type="text" className="form-control form-control-sm" id="x_qr" defaultValue={e.yikimRuhsatGecerlilikTarihi} name="yikimRuhsatGecerlilikTarihi" onChange={(ev) => e.yikimRuhsatGecerlilikTarihi = getValue(ev.target.value)} />
                </div>
            </div>
            <div class="row  mt-1">
                <div class="col-3">
                    73.Onay kodu
                </div>
                <div class="col-3">
                    <input type="text" className="form-control form-control-sm" id="x_qr" defaultValue={e.yikimOnayKod} name="yikimOnayKod" onChange={(ev) => e.yikimOnayKod = getValue(ev.target.value)} />
                </div>
            </div>




            <div class="row mt-5">
                <div class="col-12  fw-bold text-primary">
                    Yanan ve Yıkılan Yapılar Formu 
                </div>
            </div>
            <div class="row  mt-1">
                <div class="col-3">
                    7.Formun onay tarihi
                </div>
                <div class="col-3">
                    <input type="text" className="form-control form-control-sm" id="x_qr" defaultValue={e.yananFormOnayTarih} name="yananFormOnayTarih" onChange={(ev) => e.yananFormOnayTarih = getValue(ev.target.value)} />
                </div>
            </div>
            <div class="row  mt-1">
                <div class="col-3">
                    8..Form no
                </div>
                <div class="col-3">
                    <input type="text" className="form-control form-control-sm" id="x_qr" defaultValue={e.yananFormNo} name="yananFormNo" onChange={(ev) => e.yananFormNo = getValue(ev.target.value)} />
                </div>
            </div>
            <div class="row  mt-1">
                <div class="col-3">
                    67.Onay kodu
                </div>
                <div class="col-3">
                    <input type="text" className="form-control form-control-sm" id="x_qr" defaultValue={e.yananOnayKod} name="yananOnayKod" onChange={(ev) => e.yananOnayKod = getValue(ev.target.value)} />
                </div>
            </div>



            <div class="row mt-5">
                <div class="col-10  fw-bold text-primary">
                    Pafta Değerleri
                </div>
                <div class="col-2   text-primary text-end">
                    <a className="text-primary" onClick={() => updateeimza("UPDATEME")} href="#" title="Yenile"> <i className="	fa fa-refresh"></i></a>
                </div>

            </div>
            <div class="row  mt-1">
                <div class="col-3">
                    #Ruhsat Tarih
                </div>
                <div class="col-3">
                    <input type="text" className="form-control form-control-sm" defaultValue={e.paftaRuhsatTarih} id="paftaRuhsatTarih" name="paftaRuhsatTarih" onChange={(ev) => e.paftaRuhsatTarih = getValue(ev.target.value)} />
                </div>
                <div class="col-6">
                    {getloc("#tarih")}
                </div>
            </div>
            <div class="row  mt-1">
                <div class="col-3">
                    #Ruhsat No
                </div>
                <div class="col-3">
                    <input type="text" className="form-control form-control-sm" defaultValue={e.paftaRuhsatOnayKod} id="paftaRuhsatOnayKod" name="paftaRuhsatOnayKod" onChange={(ev) => e.paftaRuhsatOnayKod = getValue(ev.target.value)} />
                </div>
                <div class="col-6">
                    {getloc("#ruhsatno")}
                </div>
            </div>




            <div class="row mt-5">
                <div class="col-10  fw-bold text-primary">
                    Kot Krokisi Bilgileri
                </div>

            </div>

            <div class="row  mt-1">
                <div class="col-3">
                    #Madde
                </div>
                <div class="col-3">
                    <input type="text" className="form-control form-control-sm" defaultValue={e.madde} id="madde" name="madde" onChange={(ev) => e.madde = getValue(ev.target.value)} />
                </div>
                <div class="col-6">
                    {getloc("#madde")}
                </div>
            </div>

            <div class="row  mt-1">
                <div class="col-3">
                    #Bend
                </div>
                <div class="col-3">
                    <input type="text" className="form-control form-control-sm" defaultValue={e.bend} id="bend" name="bend" onChange={(ev) => e.bend = getValue(ev.target.value)} />
                </div>
                <div class="col-6">
                    {getloc("#bend")}
                </div>
            </div>

            <div class="row  mt-1">
                <div class="col-3">
                    #Fikra
                </div>
                <div class="col-3">
                    <input type="text" className="form-control form-control-sm" defaultValue={e.fikra} id="fikra" name="fikra" onChange={(ev) => e.fikra = getValue(ev.target.value)} />
                </div>
                <div class="col-6">
                    {getloc("#fikra")}
                </div>
            </div>

            <div class="row  mt-1">
                <div class="col-3">
                    #Kot
                </div>
                <div class="col-3">
                    <input type="text" className="form-control form-control-sm" defaultValue={e.kot} id="kot" name="kot" onChange={(ev) => e.kot = getValue(ev.target.value)} />
                </div>
                <div class="col-6">
                    {getloc("#kot")}
                </div>
            </div>

            <div class="row mt-5">
                <div class="col-10  fw-bold text-primary">
                    İŞYERİ TESLİM TUTANAĞI
                    

                </div>

            </div>

            <div class="row  mt-1">
                <div class="col-3">
                    #Teslim Tarihi
                </div>
                <div class="col-3">
                    <input type="text" className="form-control form-control-sm" defaultValue={e.kot} id="teslimtarihi" name="teslimtarihi" onChange={(ev) => e.teslimtarihi = getValue(ev.target.value)} />
                </div>
                <div class="col-6">
                    {getloc("#teslimtarihi")}
                </div>
            </div>


        </div>



    </div>

}
