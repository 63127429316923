import React, { useState, useEffect } from 'react';
import { isEmpty, showConfirmMessage, useGlobalState } from '../GlobalState.js';
import FilesPanel from "./FilesPanel.js";
import * as UserPermConstants from '../User/UserPermConstants';
import { ajaxFail } from '../GlobalState.js';
import EImzaList from './EImzaList.js';
import EImzaTekrar from './EImzaTekrar.js';
import PDFView from './PDFView.js';
import EImzaDetails from './EImzaDetails.js';
import MevkiSetting from '../Setting/MevkiSetting.js';
import MahalleSetting from '../Setting/MahalleSetting.js';
import CBSMahalleSetting from '../Setting/CBSMahalleSetting.js';
import AdaSetting from '../Setting/AdaSetting.js';
import CBSAdaSetting from '../Setting/CBSAdaSetting.js';
import SettingIdariMahalle from '../Setting/SettingIdariMahalle.js';


export default function KadastralVeri(props) {

    const [role, setRole] = useGlobalState('role');
    const [userName, setuserName] = useGlobalState('userName');

    const [info, setInfo] = useState(["","","","",""]);

    useEffect(() => {
        getcbsinfo();
    }, [])


    const getcbsinfo = (rec) => {

        fetch("getcbsinfo", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.text().then(value => {
                    setInfo(value.replaceAll('"','').split(" "));
                })
            }
        });
    }




    return (
        <div className="p-2 rounded-3" style={{ "background-color": "#EDEDF7", "min-height": "100vh" }}>

            <div className="row mt-3 p-3" >
                <div className="col-12 col-md-6 ">
                    <div className=" bg-white rounded-3 shadow-sm" >
                        <div className="p-3 fw-bold  text-center" >
                            <div className="row  pt-2  mb-4  ">
                                <div className="col-4 align-middle fw-bold pt-4 text-center " >
                                    TAPU KADASTRODAN GELEN KADASTRAL VERİ
                                </div>
                                <div className="col-8" >
                                    <div className="bg-light p-3 rounded-3 textvmuted fw-bold align-middle" >
                                        <span className="fa fa-info pe-3" style={{ color: "lightblue", fontSize: "26px" }} ></span>
                                        Tapu Kadastronun web servis ile paylaştığı veridir
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="row  m-3 border-top pt-2">
                            <div className="col-6 " >
                                <button onClick={() => 1 == 1} className="btn btn-link btn-sm text-decoration-none " disabled> MEVKİ</button>
                              
                            </div>
                            <div className="col-6 pt-2" >-
                            </div>
                        </div>
                        <div className="row  m-3 border-top  pt-2">
                            <div className="col-6 " >
                                <button onClick={() => props.changeComponent(<CBSMahalleSetting changeComponent={props.changeComponent} />)} className="btn btn-link btn-sm text-decoration-none">MAHALLE</button>
                               
                            </div>
                            <div className="col-6" >{info[3]}
                            </div>
                        </div>
                        <div className="row  m-3 border-top  pt-2 border-bottom pb-5">
                            <div className="col-6 " >
                                <button onClick={() => props.changeComponent(<CBSAdaSetting changeComponent={props.changeComponent} />)} className="btn btn-link btn-sm text-decoration-none "> ADA/PARSEL</button>
                             
                            </div>
                            <div className="col-6" >{info[1]}
                            </div>
                        </div>
                      

                    </div>
                </div>
                <div className="col-12 col-md-6">
                    <div className="bg-white rounded-3  shadow-sm" >
                            <div className="p-3 fw-bold  text-center" >
                                <div className="row  pt-2  mb-4  ">
                                    <div className="col-4 align-middle fw-bold pt-4 text-center " >
                                        UNIVERSE'DE KULLANILAN KADASTRAL VERİ
                                    </div>
                                    <div className="col-8" >
                                        <div className="bg-light p-3 rounded-3 textvmuted fw-bold align-middle" >
                                            <span className="fa fa-info pe-3" style={{ color: "lightblue", fontSize: "26px" }} ></span>
                                            Universe sisteminde başvurularda kullanılan ada/parsel verisidir
                                        </div>
                                    </div>
                                </div>
                        </div>
                        <div className="row  m-3 border-top pt-2">
                            <div className="col-6 " >
                                <button onClick={() => props.changeComponent(<MevkiSetting changeComponent={props.changeComponent}/>)} className="btn btn-link btn-sm text-decoration-none ">MEVKİ</button>
                            </div>
                            <div className="col-6" >{info[4]}
                            </div>
                        </div>
                        <div className="row  m-3 border-top  pt-2">
                            <div className="col-6 " >
                                <button onClick={() => props.changeComponent(<MahalleSetting changeComponent={props.changeComponent} />)} className="btn btn-link btn-sm text-decoration-none ">MAHALLE</button>
                            </div>
                            <div className="col-6" >{info[2]}
                            </div>
                        </div>
                        <div className="row  m-3 border-top  pt-2">
                            <div className="col-6 " >
                                <button className="btn btn-link btn-sm text-decoration-none " onClick={() => { props.changeComponent(<SettingIdariMahalle changeComponent={props.changeComponent} />) }}> İDARİ MAHALLE</button>
                            </div>
                            <div className="col-6" >
                                <div className="col-6" >{info[5]} </div>
                            </div>
                        </div>

                        <div className="row  m-3 border-top  pt-2 border-bottom pb-5">
                            <div className="col-6 " >
                                <button onClick={() => props.changeComponent(<AdaSetting changeComponent={props.changeComponent} />)} className="btn btn-link btn-sm text-decoration-none ">ADA/PARSEL</button>
                            </div>
                            <div className="col-6" >{info[0]}
                            </div>
                        </div>



                    </div>
                </div>
            </div>
        </div>

    );
}



