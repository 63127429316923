import React, { useState, useEffect } from 'react';
import { ajaxFail, chartColors, exportExcel } from '../GlobalState.js';
import ReactDOM from 'react-dom';
export default function Report(props) {

    const [reccount, setReccount] = useState(0);
    const [refcounter, setRefcount] = useState(props.counter);
    var timer = null;
    var timercount = 0;
    const [denetimlist, setDenetimList] = useState([]);
    const [downloadUrl, setDownloadUrl] = useState("#");
    

    const tableData = props.data;
    const report = props.report;

    var sortField = null;
    var sortDirection = {}
    const myCompare = (a, b) => {
        if (sortField == null)
            sortField = "transactionUUID"
        var av = a[sortField];
        var bv = b[sortField];
        if (av == null && bv == null)
            return 0;
        if (av == null && bv != null)
            return -1 * sortDirection[sortField];
        if (av != null && bv == null)
            return 1 * sortDirection[sortField];

        if (typeof av === 'string' || av instanceof String)
            if (typeof bv === 'string' || bv instanceof String)
                return av.localeCompare(bv) * sortDirection[sortField];

        if (av < bv) {
            return -1 * sortDirection[sortField];
        }
        if (av > bv) {
            return 1 * sortDirection[sortField];
        }
        return 0;
    }


    const sortList = (fildname, data) => {
        sortField = fildname;
        if (sortDirection[sortField] == null || sortDirection[sortField] == -1) {
            sortDirection[sortField] = 1;
        }
        else {
            sortDirection[sortField] = -1;
        }
        let sortablelist = [];

        data.forEach(function (item) {
            var obj = {};
            obj.basvuruDurumu = item[0];
            obj.Toplam = item[1];
            sortablelist.push(obj);
        })
        sortablelist.sort(myCompare);

        let temp = [["this will be removed by generatetable"]]
        sortablelist.forEach(function (item) {
            temp.push([item.basvuruDurumu, item.Toplam]);
        })
        ReactDOM.render(generateTable(temp), document.getElementById("tablePart"))
    }



   


    const fetchDenetimList = () => {
        fetch("sureclist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {

                response.json().then(value => {
                    setDenetimList(value);


                })
            }
        });
    }


    useEffect(() => {
        fetchDenetimList();
    }, []);

    const populateProjectData = () => {
        fetch("countprojectbytype", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {

                response.json().then(async data => {
                    data = JSON.parse(data);
                    var dataarray = [];
                    var maxDurumId = 10;
                    var rec = 0;



                    var temp = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                    for (var i = 0; i < data.length; i++) {
                        var dt = data[i].BasvuruDurumu;
                        if (maxDurumId < dt)
                            maxDurumId = dt;
                        temp[dt] = temp[dt] + data[i].Toplam;
                        rec += data[i].Toplam;
                    }


                    dataarray.push(['Durum', 'Toplam', { role: 'annotation' }, { role: "style" }]);
                    for (var i = 1; i < maxDurumId; i++) {
                        if (i == 9 || i == 8) continue;//ignore sureç tamamlanan apps
                        var d = denetimlist.find(rec => rec.id == i);
                        dataarray.push([d == null ? i : d.name, temp[i], temp[i], ' stroke-width: 1; color:' + chartColors[(i - 1) % chartColors.length] + "; stroke-color: #000000;"]);
                    }

                    drawChart(dataarray, 'userstaticpanel2');
                    setReccount(rec)
                });
            }
        });
    }


    var columns;
    const generateTable = () => {

        const columnSet = new Set();
        
        tableData.forEach(row => {
            Object.keys(row).forEach(key => { if (key != report.baseRow) columnSet.add(key) });
        });
        
        columns = Array.from(columnSet);
        columns.unshift(report.baseRow)

        return <div className="table-responsive">
            <table className='table table-vdstriped' aria-labelledby="tabelLabel" id="reportTable">
                <thead >
                    
                    {columns.map(c => <th className="text-start ps-1">{c}</th>)}
                </thead>
                <tbody id="myTable">
                    {tableData.map((rec, idx) => 
                        <tr>
                            {columns.map(c => <td className="text-start">{rec[c]}</td>)}
                           
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    }




    const drawChart = (dataarray, target) => {
        var data = window.google.visualization.arrayToDataTable(dataarray);
        var options = {
            colors: chartColors,
            chartArea: {
                // leave room for y-axis labels
                top: 20,
                width: '100%',
                height: '70%'
            },
            hAxis: {
                slantedTextAngle: 45,
                textStyle: {
                    fontSize: 14

                },
                baselineColor: "EEF8FB",
                gridlines: {
                    color: "transparent"
                },

            },
            vAxis: {
                gridlines: {
                    color: "EEF8FB"
                },

                minorGridlines: {
                    color: 'transparent'

                },
                textStyle: {
                    fontSize: 16 // or the number you want
                }
                ,
                baselineColor: "DDF7FD"

            },
            annotations: {
                stem: {
                    length: 0
                },
                alwaysOutside: true,
                textStyle: {
                    auraColor: 'none',
                    color: '#000000',
                }

            },
            animation: {
                duration: 1200,
                easing: 'out',
                "startup": true
            },

            width: '100%',
            legend: { position: "none" },

        };


        var chart = new window.google.visualization.ColumnChart(document.getElementById(target));

        chart.draw(data, options);
        ReactDOM.render(generateTable(), document.getElementById("tablePart"));

    }

   

    return (
        <div>
            <a download="report.csv" id="downloadlink" onClick={() => { exportExcel("reportTable", "downloadlink") }} href="#" className="btn btn-link btn-sm text-decoration-none text-success"><i class="fa fa-file-excel-o" aria-hidden="true"></i></a>
            {generateTable() }
        </div>

    );
}





