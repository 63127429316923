import React, { useState, useEffect } from 'react';
import { ajaxFail, isEmpty, showConfirmMessage, showErrorMessage, showSuccessMessage } from '../GlobalState.js';
import { useGlobalState } from '../GlobalState.js';

import $ from 'jquery';

export default function MaksOrtakAlan(props) {

    const [role, setRole] = useGlobalState('role');
    const [rec, setRec] = useState({});
    const [maksrec, setMaksRec] = useState(props.rec);
    const [takiplist, setTakiplist] = useState(null);



    useEffect(() => {
        fetchList();

    }, [])

    const remove = (id) => {

        showConfirmMessage("Silmel istediğinizden emin misniz?", "", "Evet", "Hayır", () => {
            fetch("removemaksortakalan", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(id)
            }).then(async response => {
                if (!response.ok) {
                    ajaxFail(response)
                }
                else {
                    response.json().then(value => {
                        setTakiplist(value)
                    })
                }
            });
        });
    }

    const fetchList = (e) => {
        fetch("maksortakalanlist", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            ,
            body: JSON.stringify(maksrec.id)
        }).then(async response => {


            if (!response.ok) {
                response.text().then(async data => {
                    showErrorMessage(data);
                    setTakiplist([])
                });
            }
            else {
                response.json().then(async data => {
                    setTakiplist(data)
                });
            }
        });
    }



    const getDefult = (name) => {
        if (rec == null)
            return "";
        return rec[name];
    }



    const save = () => {
        rec.maksBasvuruId = maksrec.id;

        fetch("savemaksortakalan", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(rec)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                response.json().then(value => {
                    $('.root').find('input').val('');
                    setTakiplist(value)

                })
            }
        });
    }





    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        rec[target.name] = value;
    }



    var recid = getDefult("id");

    useEffect(() => {

    }, [])








    const renderToList = () => {

        if (takiplist == null)
            return <div className="w-100 mt-4 mb-4 text-center align-items-center justify-content-center" ><div className="spinner-border" ></div></div>


        if (takiplist.length == 0)
            return <div className="w-100 mt-4 mb-4 text-center align-items-center justify-content-center text-danger" >Kayıt Yok</div>

        var mytable =
            <div className="mt-1 ">
                <div className="table-responsive ">
                    <table className='table table-vdstriped border' aria-labelledby="tabelLabel">
 
                        <tbody id="myTable">
                            {takiplist.map(apprec =>
                                <tr key={apprec.id} style={{ "height": "25px" }}>
                                    <td>
                                        {getName(apprec.ortakAlanKod)}
                                    </td>
                                    <td>
                                        {apprec.regDate}
                                    </td>
                                  
                                    <td>
                                        <div style={{ "overflow": "hidden", "white-space": "nowrap" }} className="text-end">
                                            {<a href="#" className=" text-decoration-none " onClick={() => { remove(apprec.id) }}> <i className="fa fa-trash align-middle" style={{ "font-size": "20px" }}></i></a>}
                                        </div>

                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        return (mytable);
    }


    if (maksrec == null || maksrec.id == null) {
        return <div className="text-start  text-muted ">
            Ortak alan eklemek için önce başvuruyu kayediniz
        </div>
    }



    const getName = (value) => {
        if (value == 1) return value + "- Asansör";
        if (value == 2) return value + "- Bekçi kulübesi"
        if (value == 3) return value + "- Açık otopark"
        if (value == 4) return value + "- Kapalı otopark"
        if (value == 5) return value + "- Kapıcı dairesi"
        if (value == 6) return value + "- Kömürlük"
        if (value == 7) return value + "- Ortak Depo";
        if (value == 8) return value + "- Sığınak"
        if (value == 9) return value + "- Su deposu"
        if (value == 10) return value + "- Yangın merdiveni"
        if (value == 11) return value + "- Yüzme havuzu"
        if (value == 12) return value + "- Diğer"
        return "";

    }


    return (
        <div className="mb-2 root">


            <div className="row">
                <div className="text-start col-12 fw-bold text-primary">
                    Ortak Alan
                </div>

            </div>
            <div className="row mt-1">
                <div className="text-start col-2">
                    <label className="form-label">Ortak Alan Kod </label>
                    <select class="form-select form-select-sm" defaultValue={getDefult("ortakAlanKod")} id="ortakAlanKod" name="ortakAlanKod" onChange={handleInputChange} >
                        <option selected></option>
                        <option value="1">Asansör</option>
                        <option value="2">Bekçi kulübesi</option>
                        <option value="3">Açık otopark</option>
                        <option value="4">Kapalı otopark</option>
                        <option value="5">Kapıcı dairesi</option>
                        <option value="6">Kömürlük</option>
                        <option value="7">Ortak Depo</option>
                        <option value="8">Sığınak</option>
                        <option value="9">Su deposu</option>
                        <option value="10">Yangın merdiveni</option>
                        <option value="11">Yüzme havuzu</option>
                        <option value="12">Diğer</option>

                    </select>

                </div>
                <div className="text-end col-10">
                    <button className="btn btn-sm btn-link" onClick={() => { save() }} ><i className="fa fa-plus align-middle" style={{ "font-size": "20px" }}></i></button>
                </div>
            </div>

            {renderToList()}
        </div>

    );


}