import React, { useState, useEffect } from 'react';
import { isEmpty, showErrorMessage, useGlobalState } from '../GlobalState.js';
import * as UserPermConstants from '../User/UserPermConstants';
import { ajaxFail } from '../GlobalState.js';
import Vekalet from './Vekalet.js';
import EImzaList from './EImzaList.js';
import $ from 'jquery';

export default function EImzaTekrar(props) {
    var appData = props.appData;
    const eimza = props.eimza;
    const VizeKontrol = props.VizeKontrol;
    const [imzalayanlist, setimzalayanlist] = useState([]);

    const [role, setRole] = useGlobalState('role');
    const [imzalayabilenler, setImzalayabilenler] = useState([]);
    const [vizekontrolValue, setvizekontrolValue] = useState(null);

    const colors = ["text-warning", "text-success"]
    var [showFromAllApps, setShowFromAllApps] = useState(false);

    useEffect(() => {
        getimzalayabilenler();
        getimzalistesi();
        $('#activepaneldiv').animate({ scrollTop: 0 }, 500);
        //     document.getElementById("activepaneldiv").scrollTo(0, 0)
    }, [])

    const getimzalistesi = () => {
      //  document.getElementById("imzalistspin" + e.id).hidden = false;
        fetch("imzalayanlist", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(eimza.id + "")
        }).then(async response => {
          //  document.getElementById("imzalistspin" + e.id).hidden = true;
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    setimzalayanlist(value);
                })
            }
        });
    }



    const adayEkle = (rec) => {
        var imazalayan = { eimzaid: props.eimza.id + "", sira: 0, userName: rec.userName, tc: rec.tc, rol: rec.selectedRol, name: rec.Name, family: rec.Family }
        if (VizeKontrol != true && imzalayanlist.filter(x => x.userName == rec.userName && x.rol == imazalayan.rol).length > 0) {
            showErrorMessage("Adayı imza listesinde bulunmaktadır.");
            return;
        }


        if (VizeKontrol == true && isEmpty(vizekontrolValue)) {
            showErrorMessage("Vize Kontol Türünü Seçiniz");
            return;
        }
        if (!isEmpty(vizekontrolValue))
            imazalayan.vizeKontrolTur = vizekontrolValue;


        fetch("imzalayanekle", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(imazalayan)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    setimzalayanlist(value);
                })
            }
        });
    }


    const adaySil = (rec) => {
        document.getElementById("snipper1").hidden = false;
        fetch("imzalayancikar", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(rec)
        }).then(async response => {
            document.getElementById("snipper1").hidden = true;
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    setimzalayanlist(value);
                })
            }
        });
    }



    const getimzalayabilenler = () => {
        fetch("imzalaybilenler", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(appData.id)
        }).then(async response => {
            if (!response.ok) { ajaxFail(response); }
            else {
                response.json().then(value => {
                   
                    setImzalayabilenler(JSON.parse(value));
                })

            }
        });
    }



    const adaylar = () => {
        imzalayabilenler.forEach(rec => {
            rec.selectedRol = rec.Rol;
            if (rec.Rol != null && rec.Rol.includes(',')) {
                rec.selectedRol = rec.Rol.split(",")[0];
            }
        })
        return <div className="m-2 p-3  border border-rounded rounded-3 bg-white" >
            <h6>E-İmza Adayları (Eklenebilir Kişiler)</h6>
            <div className='' >
                <input className="form-check-input" type="checkbox" name="Kabul" defaultChecked={false} onChange={(e) => setShowFromAllApps(e.target.checked)} /> Aynı Konuma Yapılan Diğer Başvuruların da Adaylarını Göster
            </div>

            {VizeKontrol == true&&
            <div className="row mt-3" >
                <div className="col-12 col-md-2 " >
                    Vize Kontol Türü
                     </div>         
                    <div className="col-12 col-md-10" >
                        <div >
                            <select className="form-select form-select-sm selectpicker" style={{ "maxWidth": "400px" }} onChange={(e) => { setvizekontrolValue(e.target.value) }} hidden={VizeKontrol != true}>

                                <option value=""></option>
                                <option value="Toprak">Toprak</option>
                                <option value="Zemin aplikasyon">Zemin aplikasyon</option>
                                <option value="Temel">Temel</option>
                                <option value="Bodrum">Bodrum</option>
                                <option value="Subasman">Subasman</option>
                                <option value="Kat">Kat</option>
                                <option value="Çatı">Çatı</option>
                                <option value="Su yalıtımı">Su yalıtımı</option>
                                <option value="Isı yalıtımı">Isı yalıtımı</option>
                                <option value="Kanalizasyon">Kanalizasyon/Fosseptik</option>
                                <option value="Mekanik tesisat">Mekanik tesisat</option>
                                <option value="Elektrik">Elektrik/İletişim tesisatı</option>
                            </select>
                        </div>
                </div>

                </div>}
            

            <div className="mt-1">
                <div className="table-responsive">
                    <table className='table mt-3 table-vdstriped' aria-labelledby="tabelLabel">
                        <thead >
                            <th className='text-start'> İsim
                            </th>
                            <th className='text-start'>
                                Soyisim
                            </th >
                            <th className='text-start'>
                                TC
                            </th>
                            <th className='text-start'>
                                Rol
                            </th>
                            <th className='text-start'>
                                E-İmza
                            </th>
                        </thead>
                        <tbody id="myTable">
                            {imzalayabilenler.filter(rec => (showFromAllApps == true || rec.appID == appData.id)).map(rec =>

                                <tr className="p-1">
                                    <td>
                                        {rec.Name}
                                    </td>
                                    <td>
                                        {rec.Family}
                                    </td>
                                    <td>
                                        {rec.tc}
                                    </td>
                                    <td>
                                        {(rec.Rol != null && rec.Rol.includes(',')) ?
                                            <select className="form-select form-select-sm d-inline-block me-2" onChange={(e) => rec.selectedRol = e.target.value} >
                                                {rec.Rol.split(",").map(u => <option value={u}>{u}</option>)}
                                            </select>
                                            : rec.Rol
                                        }
                                    </td>
                                    <td>
                                        {rec.eimza == true ? "Evet" : "Hayır"}
                                    </td>
                                    <td>
                                        <div style={{ "overflow": "hidden", "white-space": "nowrap" }} className="text-end">
                                            {rec.vekil != null && rec.mappingID >= 0 && <button className={"btn  btn-link text-decoration-none pe-1 " + colors[rec.vekil]} title="Vekalet Bilgisi" onClick={(e) => { props.setActivePanel(<Vekalet appData={appData} setActivePanel={props.setActivePanel} mapping={{ id: rec.mappingID, userName: rec.userName, muellifType: rec.Rol }} />) }}  > <i class="fa fa-vimeo-square" aria-hidden="true"></i>   </button>}&nbsp;
                                            <a hidden={rec.tc == null || rec.tc.length == 0 || rec.eimza != true} href="#" onClick={() => { adayEkle(rec) }} className=" text-decoration-none "><i className="fa fa-plus-circle align-middle" style={{ "font-size": "22px" }}></i></a>
                                        </div>
                                    </td>


                                    {/*<td>*/}
                                    {/*    <div style={{ "overflow": "hidden", "white-space": "nowrap" }}>*/}
                                    {/*        <a hidden={rec.tc == null || rec.tc.length == 0 || rec.eimza != true} href="#" onClick={() => { adayEkle(rec) }} className=" text-decoration-none "><i className="fa fa-plus-circle align-middle" style={{ "font-size": "22px" }}></i></a>*/}
                                    {/*    </div>*/}
                                    {/*</td>*/}
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div >
    }





    return <div className="p-0">
        <div className="row">
            <div className="col-10">
                <h6 className="text-primary fw-bold">{VizeKontrol==true?"Vize Kontrolü":"Adayları Güncelle"} ({props.eimza.id})</h6>
            </div >
            <div className="col-2 text-end">
                <button className="btn btn-sm btn-link text-decoration-none p-0 me-1" type="button" onClick={() => props.setActivePanel(props.source)} title="kapat"> <i class="fa fa-close" style={{ "font-size": "18px" }}></i></button>

            </div >
        </div >
        <div className="table-responsive m-2 p-3 mt-2  border border-rounded rounded-3 bg-white">
            <div className="row">
            <div className="col-8">
              <h6>E-İmza Listesi (Seçilen Kişiler)</h6>
            </div >
                <div className="col-4 text-end">
                    <span class="spinner-border spinner-border-sm ps-1" id="snipper1" hidden></span>
            </div >
            </div >
           
        <table className='table  mt-3 table-vdstriped p-0' aria-labelledby="tabelLabel">
            <tbody id="myTable">
                {imzalayanlist.map((rec, idx) =>

                    <tr className={"p-1 " + ((rec.imzatarih != null && rec.imzatarih.length > 0) ? "text-success fw-bold" : "")}>
                        <td>
                            {idx + 1}
                        </td>
                        <td>
                            {rec.name}
                        </td>
                        <td>
                            {rec.family}
                        </td>
                        <td>
                            {rec.tc}
                        </td>
                        <td>
                            {rec.rol} 
                        </td>
                        <td>
                            {rec.vizeKontrolTur}
                        </td>
                        <td>
                            {rec.imzatarih}
                        </td>
                        <td>
                            {rec.imzatarih == null && <div style={{ "overflow": "hidden", "white-space": "nowrap" }}>
                                <a href="#" onClick={() => { adaySil(rec) }} className=" text-decoration-none "><i className="fa  fa-trash align-middle" style={{ "font-size": "20px" }}></i></a>
                            </div>}
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
    </div>



    <div>{adaylar()}

    </div >

</div>
}
