import React, { useState, useEffect } from 'react';
import { showErrorMessage, showSuccessMessage } from '../GlobalState';

export default function SendNotificationPanel(props) {

    const [appData, setData] = useState(props.appData);

    const sendMessage = () => {
        fetch("postmessage", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ id: appData.id, body: document.getElementById("messagebody").value, title: document.getElementById("messagetitle").value  })
        }).then(async response => {
            if (!response.ok) {
                showErrorMessage(response.statusText)
            }
            else {
                document.getElementById("messagebody").value = "";
                document.getElementById("messagetitle").value = "";
                showSuccessMessage("Bildirim Gönderilmiştir");

            }
        });
    }

    return (
        <div className="mb-1 p-1 ">
            <h5 style={{ color: "#0098db" }}>
                Bildirim Gönder
            </h5>
            <div className="row">
                <div className="col-md-6  col-12" >
                    <div className="mb-1">
                        <input type="text" className="form-control form-control-sm" id="messagetitle" placeholder="Başlık" name="messagetitle" defaultValue="" />
                     </div>
                </div>
            </div>
            <div className="row">
                <div className=" col-12" >
                    <div className="mb-1">
                        <textarea class="form-control" id="messagebody" rows="3"></textarea>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 col-12" >
                    <div className="mb-1">
                         <button onClick={() => sendMessage()} className="btn btn-primary btn-sm ">Gönder</button>
                    </div>
                </div>
            </div>
        </div >
    );
}



