import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/js/bootstrap.min.js'

import Apply5 from "./Apply5.js";
import Application from "./Application.js";
import NotePanel from './NotePanel.js';
import { ajaxFail, showSuccessMessage } from '../GlobalState.js';

export default function Apply6(props) {
    useEffect(() => {


    }, [])


    var applicationdata = props.applicationdata;






    const onSubmit = (e) => {
        e.preventDefault();
        document.getElementById("buttonSpinner").hidden = false;
        fetch("saveapplication", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(applicationdata)
        }).then(async response => {
            document.getElementById("buttonSpinner").hidden = true;
            if (!response.ok) {
                ajaxFail(response);
            }
            else {

                response.json().then(async a => {
                    props.changeComponent(<Application appData={a} changeComponent={props.changeComponent} />)
                    showSuccessMessage("Başvurunuz henüz gönderilmedi.Başvurunuzu kontrol ettikten sonra 'Gönder' tuşuna basınız.");
                });
            }
        });
    }










    return <div  >

        <div className="row" >
            <div className="  col-md-2 col-12 " >
                <div className="  w-75 fw-bold border-bottom border-3 " >
                    1. Başvuru Tipi

                </div>
            </div>
            <div className=" col-md-2 col-12  " >
                <div className="  w-75 fw-bold border-bottom border-3 " >
                    2. Konum
                </div>
            </div>
            <div className=" col-md-2 col-12    " >
                <div className="  w-75 fw-bold border-bottom border-3 " >
                    3. Müellifler
                </div>
            </div>
            <div className=" col-md-2 col-12    " >
                <div className="  w-75 fw-bold border-bottom border-3 " >
                    4. Diğer Sorular
                </div>
            </div>
            <div className=" col-md-2 col-12    " >
                <div className="  w-75 fw-bold border-bottom border-3 " >
                    5. Dosyalar
                </div>
            </div>
            <div className=" col-md-2 col-12    text-primary " >
                <div className="  w-75 fw-bold border-bottom border-3 " >
                    6. Onay
                </div>
            </div>
        </div>

        <div className="row m-3" >
            <div className="col-12" >
                <div className="  align-items-center justify-content-center p-1" >
                    <form action="submitapplication" className="mt-3" method="POST" onSubmit={onSubmit}>

                        <div className="mt-1 row rounded-5" >
                            <NotePanel appData={applicationdata} />
                        </div>
                        <div className="row mt-3 border-top pt-2" >
                            <div className="col-6 text-start">
                                <button type="button" className="btn btn-sm btn-primary mb-2 " onClick={() => { props.changeComponent(<Apply5 applicationdata={applicationdata} changeComponent={props.changeComponent} />) }}>Geri Dön</button>
                            </div>

                            <div className="col-6 text-end">
                                <button type="submit" name="actionSave" value="Save" className="btn btn-sm btn-primary mb-2 ">
                                    Taslak Kaydet
                                    <span class="ms-1 spinner-border spinner-border-sm" id="buttonSpinner" hidden></span>
                                </button>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </div >
    </div >

}



