import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom'
import { myGlobalState, useGlobalState } from '../GlobalState.js';
import { ajaxFail } from '../GlobalState.js';

export default function ForgotPassword(props) {
    var logindata = {
        UserName: "",
        Password: ""
    };


    const onSubmit = (e) => {
        e.preventDefault();
        changeLoginButton();
        fetch("forgotpassword", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(document.getElementById("emailadd").value)
        }).then(async response => {
            if (!response.ok) {


                setActivepannel("Kullanıcı Adı Bulunamadı.");
                //ajaxFail(response);

            }
            else {
                setActivepannel("Yeni Şifreniz Email Adresinize Gönderimiştir.");
            }
        });

    }


  



    const changeLoginButton = (e) => {
        document.getElementById("buttonlabel").innerHTML = "Lutfen Bekleyin  "
        document.getElementById("buttonSpinner").hidden = false;

    }



    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        logindata[target.name] = value;
    }

    const [activepannel, setActivepannel] = useState(<form className="row" method="POST" onSubmit={onSubmit}>
        <div className="mb-4 mt-5 col-12">
            <label htmlFor="UserName" className="form-label text-muted "><b>Kullanıcı Adı veya Mail Adresinizi Girin</b></label>
            <input type="text" className="form-control border-0 bg-light" id="emailadd" placeholder="" name="emailadd" onChange={handleInputChange} required />
        </div>

        <div className="col-12">
            <button type="submit" className="btn btn-primary btn-sm w-100 rounded-3  mt-3">
                <span id="buttonlabel">Devam Et</span>
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" id="buttonSpinner" hidden></span>
            </button>
        </div>
    </form>);
        

    useEffect(() => {

    }, []);

    const [role, setRole] = useGlobalState('role');
    const [userName, setuserName] = useGlobalState('userName');














    return <div className="row ">
        <div className="col-3 " ></div>
        <div className="col-6 " >
            <div className="text-center mt-5 pt-5">
                {activepannel}
            </div>
        </div>
        <div className="col-3 " ></div>
    </div>








}


