import React, { useState, useEffect } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import { isEmpty, useGlobalState } from '../GlobalState.js';
import axios from "axios";
import { ajaxFail } from '../GlobalState.js';
import HelpPanel from './HelpPanel.js';

export default function HelpVideoPanel(props) {


    const [helpRec, setHelpRec] = useState(props.helpRec);


    useEffect(() => {


    }, [])








    return (
        <div className="rounded">

            <div className="row mt-3">
                <div className="col-10">
                    <h5 className="text-primary">Yardım Video -{helpRec.id}</h5>
                </div>
                <div className="col-2 text-end">
                    <button className="btn btn-sm btn-link text-decoration-none p-0 me-1" type="button" onClick={() => props.changeComponent(<HelpPanel changeComponent={props.changeComponent} />)} title="kapat"> <i class="fa fa-close" style={{ "font-size": "18px" }}></i></button>
                 
                </div>
            </div>


 
            <div className="row mt-3">
                <div className="col-10">
                    {helpRec.caption}
                </div>
                <div className="col-2 text-end">
                    {helpRec.postDate}
                </div>
            </div>

            <div className="row p-3" >
                <div className="col-12 " style={{ resize: "vertical", overflow:"hidden" }}>

                    <iframe width="100%" height="100%" style={{ minHeight: "480px" }}
                        src={helpRec.url}>
                    </iframe>
                </div>

            </div>

        </div>

    );


}