import React, { useState, useEffect } from 'react';
import EImza from './EImza';
import ApplicationAuthorPanel from './ApplicationAuthorPanel';
import bootstrap from 'bootstrap/dist/js/bootstrap.min.js'
import $ from 'jquery';


import { useGlobalState, showConfirmMessage, showErrorMessage } from '../GlobalState.js';
import * as UserPermConstants from '../User/UserPermConstants';
import { ajaxFail } from '../GlobalState.js';
import Vekalet from './Vekalet.js';
import PDFView from './PDFView';

export default function EImzaList(props) {
    var appData = props.appData;
    const [role, setRole] = useGlobalState('role');
    const [imzalayabilenler, setImzalayabilenler] = useState(null);
    const [imzalayanlist, setimzalayanlist] = useState([]);
    var [eimzatasklist, setEimzatasklist] = useState([]);
    var [showFromAllApps, setShowFromAllApps] = useState(false);
    const [userName, setuserName] = useGlobalState('userName');

    var [eimzaNameList, setEimzaNameList] = useState([]);
    const colors = ["text-warning", "text-success"]

    useEffect(() => {
        if (props.targetEimza == null)
            document.getElementById("activepaneldiv").scrollTo(0, 0)
        getvakaletdurums();
        getyeniimzalistesi();
        fetcheimzatasks();
        fetchEimzaNameList();


    }, [])




    const getvakaletdurums = () => {
        fetch("getvakaletdurums", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(appData.id),
        }).then(async response => {

            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(async data => {
                    getimzalayabilenler(data);

                });
            }
        });
    }

    const imzalayanekle = (imazalayan) => {
        fetch("imzalayanekle", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(imazalayan)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    setimzalayanlist(value);
                })
            }
        });
    }

    const adayEkle = (rec) => {
        var imazalayan = { eimzaid: 0, sira: 0, userName: rec.userName, tc: rec.tc, rol: rec.selectedRol, name: rec.Name, family: rec.Family }
        if (imzalayanlist.filter(x => x.userName == imazalayan.userName && x.rol == imazalayan.rol).length > 0) {
            showErrorMessage("Adayı imza listesinde bulunmaktadır.");
            return;
        }
        setimzalayanlist([...imzalayanlist, imazalayan]);

    }

    const adaylariKopyala = (eimzaid) => {
        if (eimzaid < 0)
            showErrorMessage("Bir Eimza Sürecini Seçiniz");
        if (imzalayanlist.length > 0) {
            showErrorMessage("Kopyalama İşlemi İçin Yeni E-İmza Listesi Boş Olmalıdır.", "Kopaylamadan sonra listeyi güncelleyebilirsiniz");
            return;
        }

        fetch("imzalayanlist", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(eimzaid)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    value.forEach(x => { x.eimzaid = 0; x.sira = 0; x.id = 0; x.imzatarih = null; x.vizeKontrolTur = null; x.uuid = null; })
                    setimzalayanlist([...imzalayanlist, ...value]);

                    
                })
            }
        });

        
        /*var imazalayan = { eimzaid: "NEW" + appData.id, sira: eimzaid, userName: "", tc: "COPY", rol: "", name: "", family: "" }

        imzalayanekle(imazalayan)*/
    }


    const adaySil = (idx) => {
        imzalayanlist.splice(idx, 1)
        setimzalayanlist([...imzalayanlist]);
    }

    const getyeniimzalistesi = () => {
        fetch("imzalayanlist", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify("NEW" + appData.id)
        }).then(async response => {
            if (!response.ok) { ajaxFail(response); }
            else {
                response.json().then(value => {
                    setimzalayanlist(value);
                })

            }
        });
    }

    const getimzalayabilenler = (vekils) => {
        fetch("imzalaybilenler", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(appData.id)
        }).then(async response => {
            if (!response.ok) { ajaxFail(response); }
            else {
                response.json().then(value => {
                    var data = JSON.parse(value);
                    for (var i = 0; i < data.length; i++) {
                        var v = vekils.find(x => x.userName == data[i].userName);
                        if (v != null)
                            data[i].vekil = v.appID;
                    }

                    setImzalayabilenler(data);
                    setTimeout(() => {
                        var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
                        var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
                            return new bootstrap.Popover(popoverTriggerEl)
                        })
                    }, 1400)
                })

            }
        });
    }



    const adaylar = () => {

        imzalayabilenler.forEach(rec => {
            rec.selectedRol = rec.Rol;
            if (rec.Rol != null && rec.Rol.includes(',')) {
                rec.selectedRol = rec.Rol.split(",")[0];
            }
        })

        return <div className="p-3  border border-rounded rounded-3 bg-white" >
            <h5>E-İmza Adayları</h5>
            <div className='' >
                <input className="form-check-input" type="checkbox" name="Kabul" defaultChecked={false} onChange={(e) => setShowFromAllApps(e.target.checked)} /> Aynı Konuma Yapılan Diğer Başvuruların da Adaylarını Göster
            </div>



            <div className="mt-1">
                <div className="table-responsive">
                    <table className='table mt-3 table-vdstriped' aria-labelledby="tabelLabel">
                        <thead >
                            <th className='text-start'> İsim
                            </th>
                            <th className='text-start'>
                                Soyisim
                            </th >
                            <th className='text-start'>
                                TC
                            </th>
                            <th className='text-start'>
                                Kullanıcı Tipi
                            </th>
                            <th className='text-start'>
                                Başvuru
                            </th>
                            <th className='text-start'>
                                E-İmza
                            </th>

                            <th className='text-start'>

                            </th>
                        </thead>
                        <tbody id="myTable">
                            {imzalayabilenler.filter(rec => (showFromAllApps == true || rec.appID == appData.id)).map(rec =>

                                <tr className="p-1">
                                    <td>
                                        {rec.Name}
                                    </td>
                                    <td>
                                        {rec.Family}
                                    </td>
                                    <td>
                                        {rec.tc}
                                    </td>
                                    <td>
                                        {(rec.Rol != null && rec.Rol.includes(',')) ?
                                            <select className="form-select form-select-sm d-inline-block me-2" onChange={(e) => rec.selectedRol = e.target.value} >
                                                {rec.Rol.split(",").map(u => <option value={u}>{u}</option>)}
                                            </select>
                                            : rec.Rol
                                        }
                                    </td>
                                    <td >
                                        {rec.appID}
                                    </td>
                                    <td >
                                        {rec.eimza == true ? "Evet" :
                                            <div> E-imzam Yok
                                                <button className="btn btn-secondary ms-1 btn-sm text-decoration-none fw-bold p-1 pt-0 pb-0" id="mypopover2" data-bs-toggle="popover" data-bs-trigger="focus" title="AÇIKLAMA" data-bs-content="Kullanıcıların e-imza atabilmeleri için, profil sayfalarındaki “İletişim Bilgileri” bölümünden “E-İmza Kullanabilirim” seçeneğini işaretlemeleri ve “Güncelle” butonuna basmaları gerekmektedir.">?</button>

                                            </div>


                                        }
                                    </td>

                                    <td>
                                        <div style={{ "overflow": "hidden", "white-space": "nowrap" }} className="text-end">

                                            {/*{rec.vekil != null && rec.mappingID >= 0 && <button className={"btn  btn-link text-decoration-none pe-1 " + colors[rec.vekil]} title="Vekalet Bilgisi" onClick={(e) => { props.setActivePanel(<Vekalet appData={appData} setActivePanel={props.setActivePanel} mapping={{ id: rec.mappingID, userName: rec.userName, muellifType: rec.Rol }} />) }}  > <i class="fa fa-vimeo-square" aria-hidden="true"></i>   </button>}*/}

                                            {rec.vekil != null && <button className={"btn  btn-link text-decoration-none pe-1 " + colors[rec.vekil]} title="Vekalet Bilgisi" onClick={(e) => { props.setActivePanel(<ApplicationAuthorPanel appData={appData} setActivePanel={props.setActivePanel} />) }}  > <i class="fa fa-vimeo-square" aria-hidden="true"></i>   </button>}

                                            &nbsp;

                                            <a hidden={rec.tc == null || rec.tc.length == 0 || rec.eimza != true} href="#" onClick={() => { adayEkle(rec) }} className=" text-decoration-none "><i className="fa fa-plus-circle align-middle" style={{ "font-size": "22px" }}></i></a>
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    }

    const imzalistesi = () => {
        return <div  >
            <h5>Yeni E-İmza Listesi</h5>
            <div className="mt-1" key={eimzatasklist.length}>
                <div className="table-responsive">
                    <table className='table mt-3 table-vdstriped' aria-labelledby="tabelLabel">
                        <thead >
                            <th className='text-start'> Sıra
                            </th>
                            <th className='text-start'> İsim
                            </th>
                            <th className='text-start'>
                                Soyisim
                            </th >
                            <th className='text-start'>
                                TC
                            </th>
                            <th className='text-start'>
                                Kullanıcı Tipi
                            </th>
                            <th className='text-start'>
                                İmza Tarihi
                            </th>
                        </thead>
                        <tbody id="myTable">
                            {imzalayanlist.map((rec, idx) =>

                                <tr>
                                    <td>
                                        {idx + 1}
                                    </td>
                                    <td>
                                        {rec.name}
                                    </td>
                                    <td>
                                        {rec.family}
                                    </td>
                                    <td>
                                        {rec.tc}
                                    </td>
                                    <td>
                                        {rec.rol}
                                    </td>

                                    <td>
                                        {rec.imzatarih}
                                    </td>
                                    <td>
                                        <div style={{ "overflow": "hidden", "white-space": "nowrap" }}>
                                            <a href="#" onClick={() => { adaySil(idx) }} className=" text-decoration-none "><i className="fa  fa-trash align-middle" style={{ "font-size": "20px" }}></i></a>
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    }



    const fetcheimzatasks = () => {
        fetch("getimzatasks", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(appData.id)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(async data => {
                    setEimzatasklist(data);

                    var maxRepeat = 20;
                    var refreshIntervalId = setInterval(() => {
                        if (props.targetEimza != null && document.getElementById("EimzaRec" + props.targetEimza.id) != null) {
                            $('#activepaneldiv').animate({ scrollTop: $("#EimzaRec" + props.targetEimza.id).offset().top }, 500);
                            $("#EimzaRec" + props.targetEimza.id).addClass("border-3 border-primary ");
                            clearInterval(refreshIntervalId);
                        }
                        if (maxRepeat < 0) {
                            clearInterval(refreshIntervalId);
                        }
                        maxRepeat--;
                    }, 200);
                });
            }
        });
    }


    const changeLoginButton = (e) => {
        document.getElementById("buttonlabel").innerHTML = "Süreç Başlatılıyor... "
        document.getElementById("buttonSpinner").hidden = false;
    }

    const fetchEimzaNameList = () => {

        fetch("geteimzanamelist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    setEimzaNameList(value);
                })
            }
        });
    }



    const submiteimzadata = (posrdata) => {
        changeLoginButton();
        if (imzalayanlist.length == 0) {
            showErrorMessage("En az bir İmza adayı ekleyiniz");
        }
        posrdata.adaylar = JSON.stringify(imzalayanlist);
        var buton=document.getElementById("startbutton");
        if (buton) {
            buton.disabled = true;
        }



        fetch("createsigntask", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(posrdata)
        }).then(async response => {
            document.getElementById("buttonlabel").innerHTML = "Yeni E-İmza Sürecini Başlat"
            document.getElementById("buttonSpinner").hidden = true;
            if (buton) {
                buton.disabled = false;
            }

            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(async data => {

                   

                    if (document.getElementById("imzadosya") != null)
                        document.getElementById("imzadosya").value = null;
                    else
                        document.getElementById("fileselection").innerHTML = "";

                    if (data == null || data.length == 0) {
                        showErrorMessage("Dosya #eimza ibaersini içermelidir");
                        return;

                    }
                    if (document.getElementById("veriGirisindenSonraIndirme") != null)
                          document.getElementById("veriGirisindenSonraIndirme").checked = false;
                    setimzalayanlist([]);
                    setEimzatasklist(data);
                    var new_element = data[0];  //this the newly created eimza
                    if (new_element.eimzaTextPos != true) {
                        props.setActivePanel(<PDFView Eimza={new_element} setActivePanel={props.setActivePanel} source="NEW_TASK" appData={appData} changeComponent={props.changeComponent} />)
                    }



                });
            }
        });
    }

    const startNewEimza = () => {
        const fr = new FileReader();

     //   var serviceProvider = window.location.hostname.includes("milas") ? "Arksigner" : "Onaylarim";
        var serviceProvider ="Onaylarim";
        var providerselect = document.getElementById("onaylarimRadio");
        if (providerselect != null) {
            if (providerselect.checked == true)
                serviceProvider = "Onaylarim";
            else
                serviceProvider = "Arksigner";
        }

        var veriGirisindenSonraIndirme = false;
        if (document.getElementById("veriGirisindenSonraIndirme") != null) {
            if (document.getElementById("veriGirisindenSonraIndirme").checked == true) {
                veriGirisindenSonraIndirme = true;
            }
        }

        var imzatur = "PAdES";
        var imzaturelem = document.getElementById("CAdES");
        if (imzaturelem != null && imzaturelem.checked == true) {
            imzatur = "CAdES";
        }


        if (imzalayanlist == null || imzalayanlist.length == 0) {
            showErrorMessage("En az bir imza adayı ekleyiniz")
            return;
        }
        if (props.targetFile != null) {
            submiteimzadata({ id: appData.id, file: "#id:" + props.targetFile.id, caption: props.targetFile.caption, surecName: props.targetFile.typeName, serviceProvider: serviceProvider, imzaType: imzatur, veriGirisindenSonraIndirme: veriGirisindenSonraIndirme })
            return;
        }
        const surecisim = document.getElementById("eimzasureismi").value;
        if (surecisim == null || surecisim == "") {
            showErrorMessage("Bir Süreç İsmi Seçiniz")
            return;
        }

        const file = document.getElementById("imzadosya").files[0];
        if (file == null && props.targetFile == null) {
            showErrorMessage("İmzalanacak Dosyayı Seçiniz.")
            return;
        }
        if (appData.basvuruDurmu != UserPermConstants.Basvuru_Durumu_Onay_Verildi
            || appData.basvuruDurmu != UserPermConstants.Basvuru_Durumu_Ruhsat_Verildi
            || appData.basvuruDurmu != UserPermConstants.Basvuru_Durumu_Kontrol_Tamamlandi) {
            showConfirmMessage("Henüz kontrolünü tamamlamadığınız bir başvuruyu imzaya açmak üzeresiniz.", "Bu işleme devam etmek istiyor musunuz ?", "Evet", "Hayır", () => {
                fr.readAsDataURL(file);
                fr.onloadend = function (evt) {
                    submiteimzadata({ id: appData.id, file: evt.target.result, surecName: surecisim, serviceProvider: serviceProvider, imzaType: imzatur, veriGirisindenSonraIndirme: veriGirisindenSonraIndirme })
                }
            })
        }
        else {
            fr.readAsDataURL(file);
            fr.onloadend = function (evt) {
                submiteimzadata({ id: appData.id, file: evt.target.result, surecName: surecisim, serviceProvider: serviceProvider, imzaType: imzatur, veriGirisindenSonraIndirme: veriGirisindenSonraIndirme })
            }
        }

    }

    if (imzalayabilenler == null) {
        return <div className="w-100 mt-4 mb-4 text-center align-items-center justify-content-center" ><div className="spinner-border" ></div></div>

    }

    return <div>     {(role.indexOf(UserPermConstants.Imzaya_Açma) > -1 || props.targetFile != null) && appData.archived!=true&& < div >
            {adaylar()}

            <div className="p-3 mt-4  border border-rounded rounded-3 bg-white">
                {imzalistesi()}

                <div className="row">
                    <div className="col-12 col-md-2">
                        {props.targetFile == null ? "Eimza Süreç İsmi" : ""}
                    </div >
                    <div className="col-12 col-md-4">
                        {props.targetFile == null && <select className="form-select form-select-sm" id="eimzasureismi" name="eimzasureismi">
                            <option value=""></option> {eimzaNameList.map(x => <option value={x.name}> {x.name}</option>)}
                        </select>}
                    </div >

                    {/*{role.indexOf(UserPermConstants.Tarife_Cetveli) > -1 &&*/}
                    {/*    <div className="col-12 col-md-6" hidden={!window.location.hostname.includes("milas")}>*/}
                    {/*        <div class="form-check form-check-inline ">*/}
                    {/*            <input className="form-check-input" type="radio" name="flexRadioDefault" id="onaylarimRadio" defaultChecked={!window.location.hostname.includes("milas")} />*/}
                    {/*            Onaylarım*/}
                    {/*        </div>*/}
                    {/*        <div className="form-check form-check-inline ">*/}
                    {/*            <input className="form-check-input me-0" type="radio" name="flexRadioDefault" id="flexRadioDefault2" defaultChecked={window.location.hostname.includes("milas")} />ArkSigner*/}
                    {/*        </div>*/}
                    {/*    </div>}*/}
                </div >

                {/*{role.indexOf(UserPermConstants.Tarife_Cetveli) > -1 && <div className="row mt-1" >*/}
                {/*    <div className="col-12 col-md-3 " >*/}
                {/*        <div className="form-check form-check-inline fw-bold">*/}
                {/*            <input className="form-check-input me-0" type="radio" name="imzatype" id="PAdES" defaultChecked={true} />*/}
                {/*            PAdES*/}
                {/*        </div>*/}
                {/*        <div class="form-check form-check-inline fw-bold">*/}
                {/*            <input className="form-check-input" type="radio" name="imzatype" id="CAdES" />*/}
                {/*            CAdES*/}
                {/*        </div>*/}

                {/*    </div>*/}
                {/*    <div className="col-12 col-md-9 text-secondary" >*/}
                {/*        Belge daha önce CAdES formatında imzalanmış ise CAdES olarak, PAdES formatında imzalanmış ise PAdES olarak imzaya açılmalıdır aksi taktirde önceki imzalar geçersiz olur.*/}
                {/*    </div>*/}
                {/*</div>}*/}
                {role.indexOf(UserPermConstants.Imza_Veri_Girisi) > -1 && <div className="row mt-2">
                    <div className="col-12 ">
                        <div class="form-check">
                            <input className="form-check-input" type="checkbox" id="veriGirisindenSonraIndirme" />
                            <label className="form-check-label" for="veriGirisindenSonraIndirme">
                                Veri Girişi Yapılmadan İmzalı Dosya İndirilemesin
                            </label>
                        </div>
                    </div >
                </div >}

                <div className="row mt-2" id="fileselection">
                    <div className="col-12 col-md-6">
                        {props.targetFile == null ?
                            <input accept=".pdf" style={{ maxWidth: "400px" }} class="form-control form-control-sm" type="file" id="imzadosya" />
                            : props.targetFile.id + "   " + props.targetFile.typeName
                        }
                    </div >
                    <div className="col-12 col-md-4">
                        <button className="btn   btn-sm btn-success rounded" onClick={startNewEimza} id="startbutton">
                            <i className="	fa fa-star pe-1"></i>
                            <span id="buttonlabel">Yeni E-İmza Süreci Başlat</span>
                            <span class="ms-1 spinner-border spinner-border-sm" role="status" aria-hidden="true" id="buttonSpinner" hidden></span>
                        </button>
                    </div >
                </div >




                <div className='mt-3' >
                    <button type="button" className=" p-0 btn btn-link text-decoration-none" data-bs-toggle="collapse" data-bs-target="#demo"> Adayları Başka E-imza Sürecinden Kopyala</button>
                    <div id="demo" className="collapse mt-2">
                        <div className='row ' >
                            <div className='col-5' >
                                <select class="form-select form-select-sm" aria-label=".form-select-sm example" id="copycombobox">
                                    <option value="-1" selected></option>
                                    {eimzatasklist.map(ez => <option value={ez.id}  > {ez.id} - {ez.surecname}</option>)}
                                </select>
                            </div>
                            <div className='col-3' >
                                <button type="button" class="btn btn-sm btn-primary" onClick={(value) => adaylariKopyala(document.getElementById("copycombobox").value)}> Kopyala</button>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div >}

        <div className="p-3 mt-5  border border-rounded rounded-3 bg-white">
            <div className="row ">
                <div className="col-8"> <h5>Başlatılan E-imza Süreçleri </h5></div>
                <div className="col-4 text-end">
                    <a onClick={fetcheimzatasks} href="#" className="text-primary"> <i className="	fa fa-refresh pe-1"></i></a>
                </div>
            </div>
            {eimzatasklist.filter(e => e.removed != true && (role.indexOf(UserPermConstants.Imzaya_Açma) > -1 || e.imzatarih != 'İptal Edildi')).map(e => <div className="row "><EImza Eimza={e} key={e.id + " " + e.imzatarih + " " + e.removed} setEimzatasklist={setEimzatasklist} setActivePanel={props.setActivePanel} appData={appData} fetcheimzatasks={fetcheimzatasks} changeComponent={props.changeComponent} /></div>)}
        </div >


        {role.indexOf(UserPermConstants.Eimza_Geri_Alma) > -1 && eimzatasklist.some(e => e.removed == true) && <div className="m-2 p-3 mt-5  border border-rounded rounded-3 bg-secondary">
            <div className="row ">
                <div className="col-12  rounded-3"><a href="#" className="text-decoration-none text-white " data-bs-toggle="collapse" data-bs-target="#silinenliste"> Silinen E-imza Süreçleri </a></div>
            </div>
            <div id="silinenliste" class="collapse">
                {eimzatasklist.filter(e => e.removed == true).map(e =>
                    <div className="row ">
                        <EImza Eimza={e} key={e.id + " " + e.imzatarih + " " + e.removed} setEimzatasklist={setEimzatasklist} setActivePanel={props.setActivePanel} appData={appData} fetcheimzatasks={fetcheimzatasks} changeComponent={props.changeComponent} />
                    </div>)}
            </div>
        </div >}


    </div>
}
