import React, { useState, useEffect } from 'react';
import Application from "./Application.js"
import SharedUsersPanel from './SharedUsersPanel.js';
import ApplicationListCardView from './ApplicationListCardView.js';
import { useGlobalState } from '../GlobalState.js';

export default function UserStatisticCard(props) {
    var istats = props.Statistic;

    const [role, setRole] = useGlobalState('role');
    const [userName, setUserName] = useGlobalState('userName');
    useEffect(() => {

    }, [])





    const allowDrop = (event) => {
        event.preventDefault();
    }

    const drop = (event) => {
        event.preventDefault();

    }

    var dragCount = 0;
    const dragleave = (event) => {
    }
    const enter = (event) => {
    }

    return <div onDragOver={allowDrop} onDrop={drop} onDragEnter={enter} onDragLeave={dragleave}>

        <div className="m-1 ms-2 me-2 p-2 pt-2 pb-2 border border-rounded rounded-3 bg-white" >

            <div className="d-inline fw-bold d-block rounded-3" style={{ "background-color": props.color + "18" }}>
                <a class="text-decoration-none" data-bs-toggle="collapse" href={"#Userstatistic" + props.userid} role="button" aria-expanded="false" aria-controls={"Userstatistic" + props.userid}>

                    <img className="rounded-circle me-1" style={{ width: "24px", height: "24px" }} src={"/getuserpic?user=İ"} /> İSTATİSTİK
                </a>
            </div>


            <div className="mt-1 row text-start collapse multi-collapse pb-0 mb-0" id={"Userstatistic" + props.userid}>


                <div className="col-12 col-md-6 "><span className="text-muted">Kontrol </span>: <span className="text-muted fw-bold">{istats["Kontrol_Ediliyor"]}</span></div>
                <div className="col-12 col-md-6"><span className="text-muted">Sırada </span>: <span className="text-muted fw-bold">{istats["Sirada_Bekliyor"]}</span></div>
                <div className="col-12 col-md-6"><span className="text-muted">Düzeltme </span>: <span className="text-muted fw-bold">{istats["Duzeltme_Bekleniyor"]}</span></div>
                <div className="col-12 col-md-6" title="Zamandan bağımsız Düzeltme İste + Kontrolü Tamamla"><span className="text-muted">Toplam </span>: <span className="text-muted fw-bold">{istats["Toplam"]} </span> <sup><i class="fa fa-info-circle text-primary" style={{ "font-size": "14px" }} ></i></sup></div>

                <div className="col-12 col-md-6" title="Haftalık Ortalama. Son 30 günde (Düzeltme İste + Kontrolü Tamamla)/4"><span className="text-muted">Son 30 </span> : <span className="text-muted fw-bold">{(istats["Sonay"] / 4).toFixed(1)} </span> <sup><i class="fa fa-info-circle text-primary" style={{ "font-size": "14px" }}></i></sup></div>
                <div className="col-12 col-md-6" title="Haftalık Ortalama. Son 365 günde (Düzeltme İste + Kontrolü Tamamla)/52"><span className="text-muted">Son 365 </span>: <span className="text-muted fw-bold">{(istats["Sonyil"] / 52).toFixed(1)} </span> <sup><i class="fa fa-info-circle text-primary" style={{ "font-size": "14px" }}></i></sup></div>



            </div>


        </div>
    </div>
}
