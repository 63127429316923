import React, { Component } from 'react';

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Register from "./User/Register.js";
import Login from "./User/Login.js";

import ApplicationListCardView from "./Application/ApplicationListCardView.js";

import Application from "./Application/Application.js";
import Dashboard from "./Dashboard/Dashboard.js";
import { useGlobalState } from './GlobalState.js';

import Sharing from './Application/Sharing.js';

export default function App(props) {
    const [role, setRole] = useGlobalState('role');

    return (
        <div >
            <Router>
                <div className="container-fluid">
                   {/* {(role == null || role.length == 0) && <Login />}*/}
                        

                    <Routes>
                        <Route path="/register" element={<Register />} />
                        <Route path="/login" element={<Login />} />
                      
                        <Route path="/applicationlistcard" element={<ApplicationListCardView view={"card"} />} />

                       
                        <Route path="/application" element={<Application />} />

                      
                        <Route path="/" element={<Dashboard />} />

                      
                       
                        <Route path="/appsharing" element={<Sharing />} />
                       {/* <Route path="/sharedapplicationlist" element={<SharedApplicationList />} />*/}

                    </Routes>
                </div>
            </Router>
        </div>
    );
}
