import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import NotePanel from './NotePanel.js';
import ApplicationList from "../Application/ApplicationList.js";
import * as UserPermConstants from '../User/UserPermConstants';
import { ajaxFail, isEmpty, showConfirmMessage, showErrorMessage, showSuccessMessage, sendpost} from '../GlobalState.js';
import { useGlobalState } from '../GlobalState.js';
import axios from "axios";

export default function BasvuruKabulPanel(props) {

    const [appData, setData] = useState(props.rec);
    const [listData, setListData] = useState(null);
    const [harcFileList, setHarcFileList] = useState([]);
    const [username, setuserName] = useGlobalState('userName');
    const [role, setRole] = useGlobalState('role');

    var warningList = [];
    warningList = props.relatedApps.filter(x => x.basvuruYapan != appData.basvuruYapan)


    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked.toString() : target.value;
        appData[target.name] = value;
    }

    useEffect(() => {

        fetchList();
        fetchFiles();

    }, [])



    const fetchList = () => {
        fetch("kabullistpermsnames", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(username)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    setListData(value);

                })

            }
        });
    }


    const fetchFiles = () => {
        var c = document.getElementById("harcdosyaSpinner")
        if (c != null) {
            c.hidden = false;
        }
        fetch("harcfilelist", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: appData.id
        }).then(async response => {
            if (c != null) {
                c.hidden = true;
            }
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(async data => {
                    setHarcFileList(data)
                });
            }
        });
    }


    const removeFile = (id) => {
        var c = document.getElementById("harcdosyaSpinner")
        if (c != null) {
            c.hidden = false;
        }
        fetch("removeharcfile", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: id
        }).then(async response => {
            if (c != null) {
                c.hidden = true;
            }
            if (!response.ok) {
                response.text().then(async data => {
                    showErrorMessage(data)
                });
            }
            else {
                fetchFiles();
                showSuccessMessage("Dosya Başarıyla Silindi")
            }
        });
    }


    const uploadHarcDosya = () => {
        var c = document.getElementById("harcdosyaSpinner")
        if (c != null) {
            c.hidden = false;
        }
        const file = document.getElementById("harcdosya").files[0];
        if (file == null) {
            showErrorMessage("Harç Dosyayı Seçiniz.")
            return;
        }
        var formData = new FormData();
        formData.append("file", file);
        formData.append("caption", file.name);
        formData.append("appid", appData.id);
        axios.post(
            "submitharcfile",
            formData,
            {
                headers: {
                    "Content-type": "multipart/form-data",
                },
            }
        ).then(res => {
            if (c != null) {
                c.hidden = true;
            }
            fetchFiles();

        })
            .catch(err => { showErrorMessage(err); })

    }



    const renderHarcTable = () => {
        var mytable =
            <div className="table-responsive mainpagescroll" >
                <table className='table table-striped  table-sm' aria-labelledby="tabelLabel">
                    <thead style={{ "position": "sticky", "top": 0, "z-index": "1" }} className="bg-light">
                        <tr>
                            <th width="50px" className="text-start">Id</th>
                            <th className="text-start">Dosya</th>
                            <th width="190px" className="text-start">Yükleme Tarihi</th>
                            <th width="130px" className="text-start">Ekleyen</th>
                            <th width="100px" className="text-start"></th>
                        </tr>
                    </thead>
                    <tbody id="myTable">
                        {harcFileList.map((apprec, idx) =>
                            <tr key={apprec.id} className={idx == 0 ? "fw-bold" : "text-muted"}>
                                <td className={idx == 0 ? "fw-bold" : "text-muted"}>{apprec.id}</td>
                                <td className={idx == 0 ? "fw-bold" : "text-muted"}>
                                    {apprec.caption}
                                </td>
                                <td className={idx == 0 ? "fw-bold" : "text-muted"}>{apprec.tarih}</td>
                                <td className={idx == 0 ? "fw-bold" : "text-muted"}>{apprec.userName}</td>

                                <td className={idx == 0 ? "fw-bold" : "text-muted"}>
                                    <a href={"/getharcfile?id=" + apprec.id} className="btn btn-link btn-sm text-primary text-decoration-none m-0 p-0">İndir</a>&nbsp;&nbsp;
                                    <button onClick={() => { removeFile(apprec.id) }} className="btn btn-link btn-sm text-decoration-none m-0 p-0">Sil</button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        return (mytable);
    }

    const checkNote = ()=>{

        var c = document.getElementById("kontrolnotpanelnot")
        if (c != null && isEmpty(c.value) == false) {
            showErrorMessage("Önce yazdığınız notu kaydediniz");
            return true;
        }
        return false;
    }


    const apponayla = () => {
        if (checkNote())return;

        var basvuruDurumu = UserPermConstants.Basvuru_Durumu_Havale_Bekliyor
        if (appData.eimzagonder == true) {
            basvuruDurumu = UserPermConstants.Basvuru_Durumu_EIMZA_Bekleniyor;
        }
        sendpost("setaceptancestate", { id: appData.id, basvuruDurumu: basvuruDurumu },"",
            (response) => props.changeComponent(<ApplicationList changeComponent={props.changeComponent} />))


    }
    const appgerigonder = () => {
        if (checkNote()) return;
        sendpost("setaceptancestate", { id: appData.id, basvuruDurumu: UserPermConstants.Basvuru_Durumu_GeriGonderildi },"",
            (response) => props.changeComponent(<ApplicationList changeComponent={props.changeComponent} />))
    }

    const appiade = () => {
        if (checkNote()) return;
        showConfirmMessage("Başvuruyu iade etmek istediğinizden emin misniz?", "", "Evet", "Hayır", () => {
            var iadeneden = prompt("Lutfen İade Nedenini Yazınız");
            if (isEmpty(iadeneden)) {
                showErrorMessage("Iade Nedeni Boş Olamaz");
                return;
            }


            sendpost("setaceptancestate", { id: appData.id, basvuruDurumu: UserPermConstants.Basvuru_Durumu_Iade_Edildi, iadeNotu: iadeneden },"",
                (response) => props.changeComponent(<ApplicationList changeComponent={props.changeComponent} />))
        })

    }

    const apputahakukyap = () => {
        if (checkNote()) return;
        sendpost("setaceptancestate", { id: appData.id, basvuruDurumu: UserPermConstants.Basvuru_Durumu_Ucret_Bekleniyor },"",
            (response) => props.changeComponent(<ApplicationList changeComponent={props.changeComponent} />))
        
    }

    const appucretiste = () => {
        if (checkNote()) return;
        var harc = document.getElementById("ucret").value;
        if (harc == null || harc.length == 0) {
            showErrorMessage("Ucret Giriniz");
            return;
        }
        sendpost("setaceptancestate", { id: appData.id, basvuruDurumu: UserPermConstants.Basvuru_Durumu_Tahakkuk_Bekleniyor,ucret : harc },"",
            (response) => props.changeComponent(<ApplicationList changeComponent={props.changeComponent} />))
       
    }

    if (listData == null) {
        return <div className="w-100 mt-1 mb-1 text-center align-items-center justify-content-center" ><div className="spinner-border" ></div></div>
    }

    if (listData.length > 0 && listData.includes(appData.basvuruTipi) == false) {
        return "Başvuru Kabul Yetkisi Yok.";
    }

    return (
        <div >
            <fieldset id="oncontrolpanel">
                <div className="row">
                    <div className="col-12 pb-4">
                        <h5 className="text-primary">Başvuru Kabul Paneli</h5>
                    </div>


                    <div className="row  mb-1" >

                        {warningList.length > 0 && <div className="col-12 m-2">
                            <span className="text-danger fw-bold">Uyarı, Aynı Ada/Parsel'e başka kullanıcıların aşağıdaki başvurularları bulunmaktadır:</span>
                            <table className='table mt-3  table-striped' aria-labelledby="tabelLabel">
                                <tbody >
                                    {warningList.map(apprec =>
                                        <tr key={apprec.id} className="p-1">
                                            <td >{apprec.id}</td>
                                            <td >{apprec.basvuruTipi}</td>
                                            <td >{apprec.basvuruTarihi}</td>
                                            <td >{apprec.basvuruYapan}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>

                        </div>
                        }

                        <div className="col-12 ms-2">
                            <NotePanel appData={appData} panelid="kontrolnotpanel" />
                        </div>
                    </div>

                    <div className="row  mb-3" >
                        <div className="col-12 col-md-4">
                            <button className="btn btn-danger w-100 btn-sm" onClick={appgerigonder} disabled={appData.basvuruDurumu != UserPermConstants.Basvuru_Durumu_Onay_Bekliyor && appData.basvuruDurumu != UserPermConstants.Basvuru_Durumu_Harc_Odeme_Kontrolu_Bekliyor}>
                                Başvuruyu Geri Gönder
                            </button>
                        </div>
                    </div>
                    <div className="row  mb-1" >
                        <div className="col-12 col-md-4 mb-2">
                            <button className="btn btn-primary w-100  btn-sm" onClick={appucretiste} disabled={appData.basvuruDurumu == UserPermConstants.Basvuru_Durumu_Ucret_Bekleniyor}>Harç Miktarını Belirle</button>
                        </div>

                        <div className="col-12 col-md-4">
                            <input type="number" className="form-control form-control-sm " placeholder="Harç Ucreti" name="ucret" id="ucret" defaultValue={appData.ucret} disabled={appData.basvuruDurumu == UserPermConstants.Basvuru_Durumu_Ucret_Bekleniyor} />
                        </div>
                        {role.indexOf(UserPermConstants.Kontrolor_Dosyasi_Yukleme) > -1 && < div className="col-12 col-md-4">
                            <label for="harcdosya" className="btn btn-sm btn-primary">Harç Dosyası Seç</label>
                            <input style={{ maxWidth: "300px" }} class="form-control form-control-sm" type="file" hidden id="harcdosya" onChange={uploadHarcDosya} />
                            <div class="ms-1 spinner-border spinner-border-sm" id="harcdosyaSpinner" hidden></div>
                        </div>}


                    </div>

                    <div className="row  mb-3" >
                        <div className="col-12 col-md-4">
                            <button className="btn btn-info w-100 text-white  btn-sm" onClick={apputahakukyap} disabled={appData.basvuruDurumu != UserPermConstants.Basvuru_Durumu_Tahakkuk_Bekleniyor}>
                                Tahakkuk Yapıldı</button>
                        </div>
                    </div>

                    <div className="row  mb-3" >
                        <div className="col-12 col-md-4">
                            <button className="btn btn-success w-100   btn-sm" onClick={apponayla} disabled={appData.basvuruDurumu != UserPermConstants.Basvuru_Durumu_Onay_Bekliyor && appData.basvuruDurumu != UserPermConstants.Basvuru_Durumu_Harc_Odeme_Kontrolu_Bekliyor}>
                                Başvuruyu Kabul Et
                            </button>
                        </div>

                    </div>
                </div>
            </fieldset>
            {harcFileList.length > 0 && (role.indexOf(UserPermConstants.HARC_DOSYALARI) > -1 || role.indexOf(UserPermConstants.Kontrolor_Dosyasi_Yukleme) > -1) && <div >
                <h6 className="text-center text-primary">Harç Dosyaları</h6>

                {renderHarcTable()}
            </div>}


            <div className="row  mb-3" >
                <div className="col-12 text-end">
                    <button className="btn btn-danger  btn-sm" onClick={appiade} disabled={appData.basvuruDurumu != UserPermConstants.Basvuru_Durumu_Onay_Bekliyor}>
                        İade Et
                    </button>
                </div>
            </div>

        </div>
    );
}