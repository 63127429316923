import React, { useState, useEffect } from 'react';
import CBSMahalleMatching from './CBSMahalleMatching.js';
import KadastralVeri from '../Application/KadastralVeri.js';
import { ajaxFail, showConfirmMessage, showErrorMessage, showSuccessMessage } from '../GlobalState.js';

import bootstrap from 'bootstrap/dist/js/bootstrap.min.js'
export default function MahalleSetting(props) {
    var postdata = {};
    const [listData, setListData] = useState(null);
    const [MevkiList, setMevkiList] = useState([]);
    var sortField = null;
    const [sortDirection, setSortDirection] = useState({});

    const updateRecord = (rec, event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        rec[name] = value;
    }


    useEffect(() => {
        var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
        var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
            return new bootstrap.Popover(popoverTriggerEl)
        })
    }, [])

    const myCompare = (a, b) => {
        var av = a[sortField];
        var bv = b[sortField];
        var ret = 1;
        if (sortDirection[sortField] == "ZA")
            ret = -1;

        if (av == null && bv != null)
            return -1 * ret;
        if (av != null && bv == null)
            return ret;


        if (typeof av === 'string' || av instanceof String)
            if (typeof bv === 'string' || bv instanceof String)
                return av.localeCompare(bv) * ret;
        if (av < bv) {
            return -1 * ret;
        }
        if (av > bv) {
            return ret;
        }
        return 0;
    }

    const sortList = (fildname) => {
        sortField = fildname;
        if (sortDirection[sortField] == null || sortDirection[sortField] == "ZA") {
            sortDirection[sortField] = "AZ";
        }
        else {
            sortDirection[sortField] = "ZA";
        }
        var temp = [...listData]; //clone the array to update the component on setApplist
        temp.sort(myCompare);
        setListData(temp);
    }


    const removeRecord = (id) => {
        showConfirmMessage(id + " Numaralı Kaydı Silmek İstedinizden Emin Misiniz?", "", "Evet", "Hayır", () =>
            fetch("removemahalle", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify([id])
            }).then(async response => {
                if (!response.ok) {
                    ajaxFail(response);
                }
                else {
                    response.json().then(value => {
                        setListData(value);
                    })
                }
            }));
    }

    const fetchMevkiList = () => {

        fetch("getmevkilist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    var i = 0;
                    var c = document.getElementById("mevkiId");
                    c.innerHTML = "";
                    for (i = 0; i < value.length; i++) {
                        var opt = document.createElement('option');
                        opt.value = value[i].id;
                        opt.innerHTML = value[i].name;;
                        c.appendChild(opt);
                    }
                    c.selectedIndex = -1;
                    setMevkiList(value);
                    fetchList();
                })
            }
        });
    }

   

    const getMevkiName = (rec) => {
        var id = rec.mevkiId
        for (var i = 0; i < MevkiList.length; i++) {
            if (MevkiList[i].id == id) {
                rec.mevkiName = MevkiList[i].name;
                return MevkiList[i].name;
            }
        }
    }

    const submitUpdateRecord = (rec) => {

        fetch("updatemahalle", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(rec)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                showSuccessMessage("Güncelleme Başarılı")
            }
        });
    }

    const fetchList = () => {

        fetch("getmahallelist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    setListData(value);
                })
            }
        });
    }

    const Add = () => {
        if (document.getElementById("mevkiId").selectedIndex == -1) {
            showErrorMessage("Mevki Seçilmemiş");
            return;
        }
        postdata["mevkiId"]=document.getElementById("mevkiId").value;

        fetch("addmahalle", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify([postdata])
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                document.getElementById("name").value = "";
                document.getElementById("koordinat").value = "";
                fetchList();

            }
        });

    }

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        postdata[target.name] = value;
    }


    const renderTable = (tabledata) => {

        var mytable = <div>
            <div className="row  mb-2 pt-2 border-top">
                <div className="col-12 fw-bold">
                    Manuel Mahalle Ekle
                </div>
            </div>
            <div className="row  mb-3 pb-3 border-bottom">
                <div className="col-12 col-md-3">
                    Mevki Seç (Zorunlu)
                    <select className="form-select form-select-sm" id="mevkiId" name="mevkiId"  required={true}   >
                    </select>
                </div>
                <div className="col-12 col-md-3">
                    Mahalle Adını Gir (Zorunlu)
                    <input type="text" className="form-control form-control-sm" id="name" placeholder="" name="name" onChange={handleInputChange} defaultValue="" />
                </div>
                <div className="col-12 col-md-5">
                    Koordinat Gir
                    <input type="text" className="form-control form-control-sm" id="koordinat" placeholder="" name="koordinat" onChange={handleInputChange} defaultValue="" />
                </div>
               
                <div className="col-12 col-md-1">
                    <br />
                    <button onClick={Add} className="btn btn-sm btn-primary text-decoration-none">Ekle</button>
                </div>
            </div>
            < div className="row rounded-3">
                <div className="col-3 m-1 text-center fw-bold" >
                    <button onClick={() => sortList("mevkiName")} className="btn btn-link btn-sm text-decoration-none m-0 p-0">Mevki</button>
                  
                </div>
                <div className="col-3 m-1 text-center fw-bold" >
                    <button onClick={() => sortList("name")} className="btn btn-link btn-sm text-decoration-none m-0 p-0">Mahalle</button>
                    
                </div>
                <div className="col-4 text-center fw-bold" >
                    <button onClick={() => sortList("koordinat")} className="btn btn-link btn-sm text-decoration-none m-0 p-0">Koordinat</button>
                </div>
                <div className="col-2 text-center fw-bold" >
                </div>
            </div>

            {tabledata.map(apprec =>
                < div className="row rounded-3 mt-2" key={apprec.id}>
                    {/*<div className="col-1" >*/}
                    {/*   {apprec.id} */}
                    {/*</div>*/}
                    <div className="col-3" >
                        <select className="form-select form-select-sm" name="mevkiId" defaultValue={apprec.mevkiId} onChange={(e) => updateRecord(apprec, e)} >
                            <option value={-1}></option>
                            {MevkiList.map(x => <option value={x.id}>{x.name}</option>)}
                        </select>

                     </div>
                    <div className="col-3" >
                        <input type="text" className="form-control form-control-sm border-0" name="name" defaultValue={apprec.name} onChange={(e) => updateRecord(apprec, e)} />
                    </div>

                    <div className="col-4" >
                        <input type="text" className="form-control form-control-sm border-0" name="koordinat" defaultValue={apprec.koordinat} onChange={(e) => updateRecord(apprec, e)} />
                    </div>
                    <div className="col-2 text-end" >
                        <button onClick={() => submitUpdateRecord(apprec)} className="btn btn-link btn-sm text-decoration-none m-0 p-0">Güncelle</button>&nbsp;
                        <button onClick={() => removeRecord(apprec.id)} className="btn btn-link btn-sm text-danger text-decoration-none m-0 p-0">Sil</button>
                    </div>
                </div>
            )}
        </div>

        return (mytable);
    }





    useEffect(() => {
        
        fetchMevkiList();
    }, [])


    var listTable = "";
    if (listData != null) {
        listTable = renderTable(listData);
    }
    else
        listTable = renderTable([]);

    return (
        <div className="bg-light m-2 p-3 rounded-3">
            <div className="row mb-3 ">
                <div className="col-6 " >
                    <h5>MAHALLE</h5>
                </div>
                <div className="col-6 text-end" >
                    <button onClick={() => props.changeComponent(<CBSMahalleMatching changeComponent={props.changeComponent} />)} className="btn btn-sm btn-success text-decoration-none me-1">TKGM ile Eşleştir</button>
                    <button className="btn btn-secondary me-3 btn-sm text-decoration-none fw-bold" id="mypopover" data-bs-trigger="focus" data-bs-toggle="popover" title="AÇIKLAMA" data-bs-content="TKGM mahalle verisi ile UNIVERSE'de kullanılan BAŞVURUYA ESAS mahalle verisini karşılaştırır. Toplu olarak veri ekleyip silebileceğiniz gibi tek tek de işlem yapabilirsiniz.">?</button>

                    <button onClick={() => props.changeComponent(<KadastralVeri changeComponent={props.changeComponent} />)} className="btn btn-primary btn-sm text-decoration-none ">Geri Dön</button>
                </div>
            </div>
            {listTable}
        </div>
    );


}