import { useLocation } from 'react-router-dom'
import React, { useState, useEffect } from 'react';
import { ajaxFail, isEmpty, showErrorMessage, showSuccessMessage, useGlobalState } from '../GlobalState.js';
import * as UserPermConstants from '../User/UserPermConstants';


export default function UserComData(props) {
    const [userdata, setUserData] = useState(props.userdata);
    const confirmed = props.confirmed;
    const [role, setRole] = useGlobalState('role');

    const handleInputChange = (event) => {
        const target = event.target.name;
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        userdata[target] = value;
        document.getElementById("telkodgonder").disabled = true;
        document.getElementById("telkodgir").disabled = true;
        document.getElementById("emailkodgonder").disabled = true;
        document.getElementById("emailkodgir").disabled = true;
    }

    const handleBildirimTercihChange = (event) => {
        const target = event.target.name;
        if (userdata.bildirimTercihleri == null)
            userdata.bildirimTercihleri = "";

        userdata.bildirimTercihleri = userdata.bildirimTercihleri.trim();

        if (event.target.checked == true && userdata.bildirimTercihleri.includes(target) == false)
            userdata.bildirimTercihleri = target + " " + userdata.bildirimTercihleri.trim();
        if (event.target.checked == false && userdata.bildirimTercihleri.includes(target) == true)
            userdata.bildirimTercihleri = userdata.bildirimTercihleri.replace(target, "").trim();
    }



    const sendEmail = () => {
        fetch("sendverificationemail", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(userdata.userName)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                showSuccessMessage("Doğrulama Kodu E-Posta Adresinize Gönderilmiştir.")
            }
        });
    }


    const confirmEmail = () => {
        var k = prompt("Lütfen Doğrulama Kodunuzu Girin");
        if (k == null || k.length == 0) return;
        fetch("confirmEmail", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ user: userdata.userName, code: k })
        }).then(async response => {
            if (!response.ok) {
                showErrorMessage(response.statusText + " Doğrulama Başarısız")
            }
            else {
                showSuccessMessage("E-Posta  Adresiniz Başarıyla Doğrulanmıştır");

                if (confirmed != null) {
                    confirmed({ ...userdata, emailConfirmed: true });
                }
                else
                    setUserData({ ...userdata, emailConfirmed: true });
            }
        });
    }

    const confirmSMS = () => {
        var k = prompt("Lütfen Doğrulama Kodunuzu Girin");
        if (k == null || k.length == 0) return;
        fetch("confirmSMS", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ user: userdata.userName, code: k })
        }).then(async response => {
            if (!response.ok) {
                showErrorMessage(response.statusText + " Doğrulama Başarısız")
            }
            else {
                showSuccessMessage("Telefon Numaranız Başarıyla Doğrulanmıştır");
                setUserData({ ...userdata, telConfirmed: true });
                if (props.confirmed != null) {
                    props.confirmed({ ...userdata, telConfirmed: true });
                }
                else
                    setUserData({ ...userdata, telConfirmed: true });
            }
        });
    }



    const sendSms = () => {

        var tel = document.getElementById("Tel").value;
        if (tel == null || tel.length == 0) {
            showErrorMessage("Telefon numarası  boş olamaz")
            return;
        }
        var message = "CODE|" + tel + "|" + userdata.userName+"|";

        fetch("sendsms", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(message)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
                response.text().then(value => {
                    showErrorMessage(" Bir hata oluştu. " + value)
                })
                
            }
            else {
                showSuccessMessage("Doğrulama Kodu Telefon Numaranıza Göderilmiştir. ");
            }
        });
    }

    const onSubmit = (e) => {
        e.preventDefault();

        var input = document.getElementById("email")
        if (input != null && input.checkValidity() == false) {
            showErrorMessage("Email Adresi Hatalı");
            return;
        }
        input = document.getElementById("Tel")
        if (input != null && (input.checkValidity() == false || isEmpty(input.value))) {
            showErrorMessage("Telefon Numarası Hatalı");
            return;
        }

        fetch("updateuser", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(userdata)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response,true)
            }
            else {
                response.text().then(value => {
                })
                showSuccessMessage("Kullanıcı Bilgileri Güncellenmiştir. ");
                document.getElementById("telkodgonder").disabled = false;
                document.getElementById("telkodgir").disabled = false;
                document.getElementById("emailkodgonder").disabled = false;
                document.getElementById("emailkodgir").disabled = false;
            }
        });
    }


    return <div >

        <div className="col-12">
            <label className="form-label me-5">İletişim Tercihleri</label>
            <div className="form-check form-check-inline">
                <input className="form-check-input" type="checkbox" name="sendMessage" id="sendMessage" defaultChecked={userdata && userdata.sendMessage} onChange={handleInputChange} />
                <label className="form-check-label" for="sendMessage">
                    Mesajlarım
                </label>
            </div>
            <div className="form-check form-check-inline">
                <input className="form-check-input" type="checkbox" name="sendEmail" id="sendEmail" defaultChecked={userdata && userdata.sendEmail} onChange={handleInputChange} />
                <label className="form-check-label" for="sendEmail">
                    Email
                </label>
            </div>
            <div className="form-check form-check-inline">
                <input className="form-check-input" type="checkbox" name="sendSMS" id="sendSMS" defaultChecked={userdata && userdata.sendSMS} onChange={handleInputChange} />
                <label className="form-check-label" for="sendSMS">
                    SMS
                </label>
            </div>

        </div>

        <div className="row mb-1 mt-2">
            <div className='col-4' >
                <label htmlFor="Tel" className="form-label m-0">GSM No {userdata.telConfirmed != true && <span class="text-danger"> (Doğrulunmamış)</span>}</label>
            </div>
            <div className='col-4' >
                <input type="text" pattern="[5-5]{1}[0-9]{2}[0-9]{7}" maxLength="10" placeholder="5__ ___ ____" className="form-control" id="Tel" name="tel"  onChange={handleInputChange} defaultValue={userdata.tel} />
            </div>
            <div className='col-4' >
                <button type="button" id="telkodgonder" className="btn  btn-primary btn-sm me-1" onClick={() => { sendSms() }}>
                    Kod Gönder
                </button>
                <button type="button" id="telkodgir" className="btn  btn-primary btn-sm" onClick={() => { confirmSMS() }}>
                    Doğrula
                </button>
            </div>
        </div>
        <div className="row  mt-3">
            <div className='col-4' >
                <label htmlFor="Tel" className="form-label m-0">Email {userdata.emailConfirmed != true && <span class="text-danger"> (Doğrulunmamış)</span>}</label>

            </div>
            <div className='col-4' >
                <input type="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2, 4}$" maxLength="50" className="form-control" id="email" placeholder="email" name="email"  onChange={handleInputChange} defaultValue={userdata.email} />
            </div>
            <div className='col-4' >
                <button type="button" id="emailkodgonder" className="btn  btn-primary btn-sm me-1" onClick={() => { sendEmail() }}>
                    Kod Gönder
                </button>
                <button type="button" id="emailkodgir" className="btn  btn-primary btn-sm" onClick={() => { confirmEmail() }}>
                    Doğrula
                </button>
            </div>

        </div>
        {props.showCode != false && <div className="row mt-3">
            <div className="col-4">
                <label htmlFor="id" className="form-label">Kullanıcı Kodu</label>
            </div>
            <div className="col-4">
                <input type="text" className="form-control form-control-sm" id="id" placeholder="" name="id" onChange={handleInputChange} defaultValue={userdata.id} />
            </div>
        </div>}

        <div className="row mt-5 pt-3 bg-light">

            <div className='col-12  text-primary' >
                Bildirim Tercihleri
            </div>
        </div>

        <div className="row  bg-light pt-2 ">
            <div className='col-4' >
                <input className="form-check-input" type="checkbox" name="Kabul" defaultChecked={userdata && userdata.bildirimTercihleri && userdata.bildirimTercihleri.includes("Kabul")} onChange={handleBildirimTercihChange} /> Başvuru Kabul Süreci
            </div>
            <div className='col-4' >
                <input className="form-check-input" type="checkbox" name="Havale" defaultChecked={userdata && userdata.bildirimTercihleri && userdata.bildirimTercihleri.includes("Havale")} onChange={handleBildirimTercihChange} /> Havale Süreci
            </div>
            <div className='col-4' >
                <input className="form-check-input" type="checkbox" name="Düzeltme" defaultChecked={userdata && userdata.bildirimTercihleri && userdata.bildirimTercihleri.includes("Düzeltme")} onChange={handleBildirimTercihChange} /> Düzeltme Süreci
            </div>
        </div>
        <div className="row bg-light pt-3">
            <div className='col-4' >
                <input className="form-check-input" type="checkbox" name="Onay" defaultChecked={userdata && userdata.bildirimTercihleri && userdata.bildirimTercihleri.includes("Onay")} onChange={handleBildirimTercihChange} /> Onay Süreci
            </div>
            <div className='col-4' >
                <input className="form-check-input" type="checkbox" name="Güncelleme" defaultChecked={userdata && userdata.bildirimTercihleri && userdata.bildirimTercihleri.includes("Güncelleme")} onChange={handleBildirimTercihChange} /> Başvuru Güncelleme Süreci
            </div>
            <div className='col-4' >
                <input className="form-check-input" type="checkbox" name="EİmzaBaşlama" defaultChecked={userdata && userdata.bildirimTercihleri && userdata.bildirimTercihleri.includes("EİmzaBaşlama")} onChange={handleBildirimTercihChange} /> E-İmza Süreci Başlatıldığında
            </div>
        </div>
        <div className="row mb-5 bg-light pt-3 pb-4">
            <div className='col-4' >
                <input className="form-check-input" type="checkbox" name="EİmzaSira" defaultChecked={userdata && userdata.bildirimTercihleri && userdata.bildirimTercihleri.includes("EİmzaSira")} onChange={handleBildirimTercihChange} /> E-İmza Atma Sırası Geldiğinde
            </div>
            <div className='col-4' >
                <input className="form-check-input" type="checkbox" name="EİmzaTamamlanma" defaultChecked={userdata && userdata.bildirimTercihleri && userdata.bildirimTercihleri.includes("EİmzaTamamlanma")} onChange={handleBildirimTercihChange} /> E-İmza Süreci Tamamlanınca
            </div>
        </div>




        <div className="row mb-4 mt-2">
            <div className='col-6' >
                <input className="form-check-input" type="checkbox" name="eimza" id="eimza" defaultChecked={userdata!=null && userdata.eimza==true} onChange={handleInputChange} />  E-İmza Kullanabilirim
            </div>
        </div>


        <div className="row mb-4 mt-2">
            <div className='col-4' >
                <input disabled={role.indexOf(UserPermConstants.Sabitlenen_Profil_Degistirme) == -1} name="edveletConfirmed" id="edveletConfirmed"  className="form-check-input" type="checkbox" defaultChecked={userdata != null && userdata.edveletConfirmed == true} onChange={handleInputChange} />  {userdata != null && userdata.edveletConfirmed == true ? <span className="text-success">E-Devlet Girişi Onaylanmıştır</span> : <span className="text-danger">E-Devlet Girişi Yapılmamıştır</span>}
            </div>
            {role.indexOf(UserPermConstants.Kullanici_Listesini_Gorme_Profil_Bilgilerini) > -1 && <>

                <div className='col-4' >
                    <input className="form-check-input" type="checkbox" name="emailConfirmed" id="emailConfirmed" defaultChecked={userdata && userdata.emailConfirmed == true} onChange={handleInputChange} />  Email Doğrulama
                </div>
                <div className='col-4' >
                    <input className="form-check-input" type="checkbox" name="telConfirmed" id="telConfirmed" defaultChecked={userdata && userdata.telConfirmed} onChange={handleInputChange} /> GSM Doğrulama
                </div></>}
        </div>


        <button type="button" onClick={onSubmit} className="btn btn-primary btn-sm mt-4">Güncelle</button>

    </div>


}




