import React, { useState, useEffect } from 'react';
import UserStatistics from './UserStatistics.js';
import $ from 'jquery';
import { ajaxFail, sendget } from '../GlobalState.js';
import Application from '../Application/Application.js';
import UserDetails from './UserDetails.js';

export default function UserWorkLoad(props) {
    const [workload, setWorkLoad] = React.useState(null);
    const [listtype, setListType] = React.useState(1);
    const [username, setUserName] = useState(props.userdata.userName);

    useEffect(() => {
        getWorkLoadList("username=" + username + "&listtype=1");
    }, []);




    const getApp = (id) => {
        fetch("getapp", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body:id
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    window.changeComponent(<Application appData={value} changeComponent={window.changeComponent} backButton={<UserDetails changeComponent={props.changeComponent} userdata={props.userdata} />} />)
                })
            }
        });
    }


    const getWorkLoadList = (params) => {
        sendget("userapps?" + params, "spn1", (response) => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                response.json().then(async data => {
                    setWorkLoad(data);
                });
            }
        })
    }

    const filterTable = (event) => {
        const target = event.target;
        const value = target.value.toLowerCase();
        $("#myTable tr").filter(function () {
            var keywords = value.split(" ");
            for (let index = 0; index < keywords.length; ++index)
                if ($(this).text().toLowerCase().indexOf(keywords[index]) == -1)
                    return $(this).toggle(false);
            return $(this).toggle(true);
        });
        let rowCount = $('#myTable tr:visible').length;
        document.getElementById("reccount").innerHTML = rowCount + " kayıt"
    }

    
    const changeTab = (t) => {
        document.getElementById("basvuruyapan").classList.remove("active");
        document.getElementById("muellif").classList.remove("active");
        document.getElementById("kontrolor").classList.remove("active");
        document.getElementById("myInput").value = "";
        if (t == 1) {
            document.getElementById("basvuruyapan").classList.add("active");
        }
        if (t == 2) {
            document.getElementById("muellif").classList.add("active");
        }
        if (t == 3) {
            document.getElementById("kontrolor").classList.add("active");
        }
        setListType(t);
        getWorkLoadList("username=" + username + "&listtype=" + t);

    }



    const renderToList = () => {
        var mytable =
            <div className="">
                <table className='table mt-3 table-vdstriped  table-sm' aria-labelledby="tabelLabel" id="myTable" key={workload}>
                    <thead>
                        <th className="text-start"> Kullanıcı</th>
                        <th className="text-start"> Başvuru No</th>
                        <th className="text-start"> Durum</th>
                        <th className="text-start"> Başvuru Tipi</th>
                        <th className="text-start"> Başvuru Tarihi</th>
                        <th className="text-start"> Konum</th>
                    </thead>
                    <tbody id="myworkloadTable">
                        {workload.map(rec =>
                            <tr key={rec.id} role="button" className="v-hover" onClick={() => { getApp(rec.id)}}>

                                <td>{rec.enlem}</td>
                                <td>{rec.id}</td>
                                <td>{rec.temp}</td>
                                <td>{rec.basvuruTipi}</td>
                                <td>{rec.basvuruTarihi}</td>
                                <td>{rec.mahalle + " " + rec.ada}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
      
        document.getElementById("reccount").innerHTML = workload.length+ " kayıt"

        return (mytable);
    }

    

    const renderToListKontrolor = () => {
        var mytable =
            <div className="">
                <table className='table mt-3 table-vdstriped table-sm' aria-labelledby="tabelLabel" id="myTable" key={workload}>
                    <thead>
                        <th className="text-start"> Kullanıcı</th>
                        <th className="text-start"> Id</th>
                        <th className="text-start"> Durum</th>
                        <th className="text-start"> BaşvuruTipi</th>
                        <th className="text-start"> Konum</th>
                        <th className="text-start"> Görev</th>
                        <th className="text-start"> Atama</th>
                        <th className="text-start"> Başlama</th>
                        <th className="text-start"> Tamamlama</th>
                       
                    </thead>
                    <tbody id="myworkloadTable">
                        {workload.map(rec =>
                            <tr key={rec.id} role="button" className="v-hover" onClick={() => { getApp(rec.id) }}>
                                <td>{rec.enlem}</td>
                                <td>{rec.id}</td>
                                <td>{rec.temp}</td>
                                <td>{rec.basvuruTipi}</td>
                               
                                <td>{rec.mahalle + " " + rec.ada}</td>
                                <td>{rec.vekilTel}</td>
                                <td>{rec.vekaletDosyaname}</td>
                                <td>{rec.vekilName}</td>
                                <td>{rec.vekilTC}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        document.getElementById("reccount").innerHTML = workload.length + " kayıt"
        return (mytable);


    }
    const renderToListMuellif = () => {
        var mytable =
            <div className="">
                <table className='table mt-3 table-vdstriped  table-sm' aria-labelledby="tabelLabel" id="myTable" key={workload}>
                    <thead>
                        <th className="text-start"> Kullanıcı</th>
                        <th className="text-start"> BaşvuruNo</th>
                        <th className="text-start"> Durum</th>
                        <th className="text-start"> BaşvuruTipi</th>
                        <th className="text-start"> Başvuru Tarihi</th>
                        <th className="text-start"> Konum</th>
                        <th className="text-start"> Görev</th>
                    </thead>
                    <tbody id="myworkloadTable">
                        {workload.map(rec =>
                            <tr key={rec.id} role="button" className="v-hover" onClick={() => { getApp(rec.id) }}>
                                <td>{rec.enlem}</td>
                                <td>{rec.id}</td>
                                <td>{rec.temp}</td>
                                <td>{rec.basvuruTipi}</td>
                                <td>{rec.basvuruTarihi}</td>
                                <td>{rec.mahalle + " " + rec.ada}</td>
                                <td>{rec.boylam}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        document.getElementById("reccount").innerHTML = workload.length + " kayıt"
        return (mytable);
    }

    var tabledata = "";

    if (workload != null) {
        if (listtype == 1)
            tabledata = renderToList();
        else if (listtype == 2) {
            tabledata = renderToListMuellif();
        }
        else if (listtype == 3) {
            tabledata = renderToListKontrolor();
        }

    }


    return <div >
        <div className="row">
            <div className="col-md-3 col-12">

            </div>
            <div className="col-md-6 col-12">
 <input className="form-control form-control-sm" id="myInput" type="text" placeholder="Arama..." onKeyUp={filterTable} />
            </div>
            <div className="col-md-3 col-12 text-end" id="reccount">
               
            </div>
        </div>


        <ul className="nav nav-tabs mt-2">
            <li className="nav-item">
                <a className="nav-link active" href="#" id="basvuruyapan" onClick={()=>changeTab(1)}>Başvuru Yapan</a>
            </li>
            <li className="nav-item">
                <a className="nav-link" href="#" id="muellif" onClick={() => changeTab(2)}>Muellif</a>
            </li>
            <li className="nav-item">
                <a className="nav-link" href="#" id="kontrolor" onClick={() => changeTab(3)}>Kontrolör</a>
            </li>
        </ul>
        <div className="w-100 text-center">
            <div className="spinner-border spinner-border-sm" id="spn1"></div>
        </div>
        <div >
            {tabledata}

        </div>
        <div  >

            {username == null ? <UserStatistics /> : ""}

        </div>
    </div>

}