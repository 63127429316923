import React, { useState, useEffect } from 'react';
import { useGlobalState, logout, isEmpty } from '../GlobalState.js';
import 'react-calendar/dist/Calendar.css';
import CommonSideBar from '../Header/CommonSideBar.js';
import TopHeader from '../Header/TopHeader.js';
import KurumLogo from '../Dashboard/KurumLogo.js';
import Login from "../User/Login.js";
import Home from "../Dashboard/Home";
import $ from 'jquery';
import UserComData from '../User/UserComData.js';
import crypto from 'crypto-js';
import { ajaxFail } from '../GlobalState.js';
import Application from '../Application/Application.js';



export default function Dashboard(props) {



    const [role, setRole] = useGlobalState('role');
    const [token, setToken] = useGlobalState('token');
    //const [loginfromedevlet, setLoginfromedevlet] = useGlobalState('loginfromedevlet');
    const [userName, setuserName] = useGlobalState('userName');
    const [userData, setUserdata] = useGlobalState('userData');
    const [activepannel, setActivepannel] = useState(null);
    const [showSideBar, setshowSideBar] = useState(window.innerWidth < 680 ? false : true)
    const [onmobile, setOnMobile] = useState(window.innerWidth < 680 ? true : false)
    var [globalData, setGlobalData] = useGlobalState('globalData');


    //$(window).resize(function () {
    //    alert("resize");
    //    if ($(this).width() < 680 ) {
    //        if (showSideBar == true) {
    //            setshowSideBar(false)

    //        }
    //        setOnMobile(true)
    //    }
    //    else {
    //        if (showSideBar == false)
    //            setshowSideBar(true)
    //        setOnMobile(false)
    //    }
    //});

    const fetchGeneralData = () => {
        fetch("getgeneraldata", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(async data => {
                    document.querySelector('meta[name="description"]').setAttribute("content", data.belediyeName);
                    setGlobalData(data);
                   

                });
            }
        });
    }


    const fetchUserData = () => {
        fetch("getuser", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                //ajaxFail(response);
            }
            else {
                response.json().then(async data => {
                    var userdata = JSON.parse(data);
                    setUserdata(userdata);
                });
            }
        });
    }




    useEffect(() => {

        try {
            if (window.appData != null) {
                setActivepannel(< Application appData={window.appData} targetEimza={window.eimza} changeComponent={setActivepannel} />)
            }


            fetchUserData();
            
            var token = window.localStorage.getItem('Token');
            if (isEmpty(token) == false) {
                var key = token.substring(0, 16);
                var c = token.substring(16);
                var decrypted = crypto.AES.decrypt(c, key)
                var decrypted = decrypted.toString(crypto.enc.Utf8);
                decrypted = JSON.parse(decrypted);
                decrypted = decrypted.value;
                var userName = decrypted.split('|')[0];
                setRole(decrypted.split('|')[1]);
                setuserName(userName);
                fetchGeneralData();
            }
        }
        catch (ex) {
            window.localStorage.setItem('Token', '');
        }
    }, []);


    useEffect(() => {
        try {
            if (isEmpty(token))
                return;
            fetchUserData();
            window.localStorage.setItem('Token', token);


            var key = token.substring(0, 16);
            var c = token.substring(16);
            var decrypted = crypto.AES.decrypt(c, key)
            var decrypted = decrypted.toString(crypto.enc.Utf8);
            decrypted = JSON.parse(decrypted);
            decrypted = decrypted.value;
            var userName = decrypted.split('|')[0];
            setRole(decrypted.split('|')[1]);
            setuserName(userName);
            fetchGeneralData();
        }
        catch (ex) {
            window.localStorage.setItem('Token', '');
        }

    }, [token]);






    var match = document.cookie.match(new RegExp('(^| )' + 'ED-Token' + '=([^;]+)'));
    if (match) {
        //setLoginfromedevlet(true)
        var t = match[2];
        var value = decodeURIComponent(t);
        value = Buffer.from(value, 'base64').toString('utf-8')
        var k = value.substring(0, 16)
        value = value.substring(16);
        var c = crypto.AES.encrypt(JSON.stringify({ value }), k).toString();
        document.cookie = 'ED-Token=; Max-Age=-99999999;';
        setToken(k + c);
    }




    if (isEmpty(role) && match == null) {
        return <Login />;
    }

    const telemailconfirmed = (userData) => {
        if (userData != null)
            setUserdata(userData)

    }
    const closeOnMobile = () => {
        if (onmobile == true) {
            setshowSideBar(false);
        }
    }
    const showPanel = (name) => {
        closeOnMobile();
        setActivepannel(name);
    }
    window.changeComponent = showPanel;



    if (userData != null && (userData.telConfirmed != true || userData.emailConfirmed != true)) {
        return <div className="row" style={{ height: "100vh" }}>

            {showSideBar == true && <div className="col-4 col-md-2 p-0 m-0 sideBarContainer collapse show" >
                <div style={{ position: "fixed", "z-index": -1, "font-size": "24px" }}> <a href="#" onClick={() => { setshowSideBar(false) }} className="text-decoration-none text-white"> <i className="fa fa-angle-double-left"></i></a></div>
            </div>
            }
            <div className={showSideBar == true ? "col-8 col-md-10 pt-1" : "col-12"} >
                {showSideBar == false && <div style={{ position: "fixed", "z-index": -1, "font-size": "24px" }}> <a href="#" onClick={() => { setshowSideBar(true) }} className="text-decoration-none text-muted"> <i className="fa fa-navicon"></i></a></div>}
                <TopHeader showPanel={showPanel} />
                <div className="text-center fw-bold text-danger mb-2 mt-4" > Devam Etmek için Telefon Numarınız ve Email Adresinizi Doğrulayın Lutfen </div>
                <div className="text-center fw-bold text-danger mb-4 " > Telefon veya Email Adresinizi Güncelle Butonuyla Değiştirebilrsiniz  </div>

                <UserComData userdata={userData} showCode={false} confirmed={telemailconfirmed} />
            </div>
        </div>
    }

    var mainpanelclass = "col-8 col-md-10 pt-1 overflow-auto";
    var sidemenuclass = "col-4 col-md-2 p-0 m-0 sideBarContainer collapse show";
    if (showSideBar == false) {
        mainpanelclass = "col-12";
        sidemenuclass = "col-0 d-none";
    }
    else if (onmobile == true) {
        mainpanelclass = "col-0 d-none";
        sidemenuclass = "col-12 p-0 m-0 sideBarContainer collapse show";
    }


    return (
        <div className="row" style={{ height: "100vh" }}>
            {showSideBar == true && <div className={sidemenuclass} >
                <div style={{ position: "fixed", "z-index": -1, "font-size": "24px" }}> <a href="#" onClick={() => { setshowSideBar(false) }} className="text-decoration-none text-white"> <i className="fa fa-angle-double-left"></i></a></div>
                <div className="text-center">
                    <a href="#" onClick={() => { setshowSideBar(false) }} className="text-decoration-none text-white">

                        <div><img src='./getfile?sec=klogo.png' width="100px" style={{ maxHeight: "100px" }} id="unilogo" /></div>
                        {globalData != null ? globalData.belediyeName : ""}
                    </a>
                </div>
                <div style={{ "z-index": 2, height: 'calc(100vh - 128px - 80px)' }} className="overflow-auto leftmenudiv" > <CommonSideBar setComponent={showPanel} /> </div>
                <div className="text-center text-white" >
                    <KurumLogo  />
                </div>
            </div>
            }
            <div className={mainpanelclass} style={{ height: "100%" }} id="activepaneldiv">
                {showSideBar == false && <div style={{ position: "fixed", "z-index": -1, "font-size": "24px" }}> <a href="#" onClick={() => { setshowSideBar(true) }} className="text-decoration-none text-muted"> <i className="fa fa-navicon"></i></a></div>}
                <TopHeader showPanel={showPanel} />
                {globalData != null && <div key={globalData} style={{ height: "91%" }}>
                    {activepannel == null ? <Home changeComponent={setActivepannel} /> : activepannel}
                </div>}
            </div>
            

        </div >

    );
}
