import React, { useState, useEffect } from 'react';
import { useGlobalState, changeTurkceToEnglis, showErrorMessage, showSuccessMessage, isEmpty } from '../GlobalState.js';
import ReactToPdf from 'react-to-pdf';
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable'
import UserLabel from "../User/UserLabel.js";
import $ from 'jquery';

export default function RevisionReq(props) {
    var postdata = {};

    var mappingid = props.mappingRec.id;

    const ref = React.createRef();

    const [listData, setListData] = useState([]);
    const [role, setRole] = useGlobalState('role');
    const [userdata, setUserData] = useState(null);
    const [myusername, setmyusername] = useGlobalState('userName');



    const removeRecord = (id) => {
        fetch("removemappingmessage", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(id)
        }).then(async response => {
            if (!response.ok) {
                response.text().then(value => {
                    showErrorMessage(response.statusText)
                })
            }
            else {
                response.json().then(value => {
                    setListData(value);

                    if (value.some(x => x.sendDate >= props.mappingRec.Started))
                        props.setCommentAfterStart(true);
                    else
                        props.setCommentAfterStart(false);
                })
            }
        });
    }



    const submitUpdateRecord = (event, m) => {
        const target = event.target;
        if (target.type === 'checkbox') {
            const value = target.checked;
            m.ischecked = value;
        }

        fetch("updatemappingmessage", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(m)
        }).then(async response => {
            if (!response.ok) {
                response.text().then(value => {
                    showErrorMessage(response.statusText + " " + value)
                })
            }
            else {
                if (target.type != 'checkbox') {
                    showSuccessMessage("Güncelleme Başarılı.")
                }
                
                   
               
            }
        });
    }


    const fetchList = () => {
        fetch("listmappingmessage", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            // body: JSON.stringify(props.appData.id)
            body: JSON.stringify(mappingid)

        }).then(async response => {
            if (!response.ok) {
                response.text().then(value => {
                    showErrorMessage(response.statusText)
                })
            }

            else {
                response.json().then(value => {
                    if (typeof value === 'string' || value instanceof String)
                       value= JSON.parse(value);

                    setListData(value);

                    if (value.some(x => x.sendDate >= props.mappingRec.Started))
                        props.setCommentAfterStart(true);
                    else
                        props.setCommentAfterStart(false);

                })
            }
        });
    }


    const Add = () => {
        fetch("addmappingmessage", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ mappingId: mappingid, message: document.getElementById("revmessage" + mappingid).value })
        }).then(async response => {
            if (!response.ok) {
                response.text().then(value => {
                    showErrorMessage(response.statusText + "  " + value)
                })
            }
            else {
                document.getElementById("revmessage" + mappingid).value = "";
                fetchList();

            }
        });

    }

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        postdata[target.name] = value;
    }


    var lastDuzeltmeNo=null;
    const getDuzeltmeBaslik = (r) => {
        if (r.duzletmeNo == lastDuzeltmeNo)
            return "";
        lastDuzeltmeNo = r.duzletmeNo;

        return <tr key={listData}>
            <td colspan="4" className="text-center"><h6 className="text-primary">{lastDuzeltmeNo}. Düzeltme Talebi</h6></td>
        </tr>
    }

    const updateTextAreasHeight = (event) => {
        var textareas = document.getElementsByTagName("textarea");
        if (textareas != null)
            for (let i = 0; i < textareas.length; i++) {
                //textareas[i].style.height = "";
                textareas[i].style.height = textareas[i].scrollHeight + 2 + "px"
            }

    }

    const renderTable = (tabledata) => {
        
        var mytable = <table className='table table-sm' id="pdfsource" >
            

            <tbody id="myTable" >

                {tabledata.map(apprec => <>{getDuzeltmeBaslik(apprec)}
                    
                    <tr className={apprec.ischecked == true && "text-success"} key={apprec.id}>
                        <td width="2%" className="checkCol">
                            <input disabled={props.mappingRec.userName != myusername || props.mappingRec.Started == null || props.mappingRec.Completed} className="form-check-input" type="checkbox" name="karaliste" id="karaliste" defaultChecked={apprec.ischecked} style={{ width: "16px", height: "16px" }} onChange={(e) => submitUpdateRecord(e, apprec)} />
                        </td>
                        <td width="20%" className="dateCol">
                            <span>{apprec.sendDate}</span>
                        </td>
                        <td width="70%">
                           
                            {props.mappingRec.userName != myusername ? <div style={{ "white-space": "pre-wrap" }}>{apprec.message}</div> :
                                <textarea id={apprec.id} disabled={isEmpty(props.mappingRec.Started) || isEmpty(props.mappingRec.Completed)==false|| apprec.sendDate < props.mappingRec.Started} className="form-control form-control-sm messageCol" onInput={(e) => { e.target.style.height = ""; e.target.style.height = e.target.scrollHeight + 2 + "px" }} type="text" name="message" defaultValue={apprec.message} onChange={(e) => apprec.message = e.target.value} />}

                        </td >

                        <td width="8%" className="buttonCol text-end">

                            {props.mappingRec.Started != null && apprec.sendDate >= props.mappingRec.Started&& <>
                                <button hidden={props.appData.onKontrol != true || props.mappingRec.userName != myusername || props.mappingRec.Completed != null} onClick={(e) => submitUpdateRecord(e,apprec)} className="btn btn-link  text-decoration-none m-0 p-0 ms-1 text-primary"><i class="fa fa-save"></i></button>
                                <button hidden={props.appData.onKontrol != true || props.mappingRec.userName != myusername || props.mappingRec.Completed != null} onClick={() => removeRecord(apprec.id)} className="btn btn-link btn-sm text-decoration-none m-0 p-0 text-muted"><span className="text-decoration-none material-icons align-middle text-danger" >delete</span>
                                </button>
                                </>}
 
                        </td>
                    </tr></>
                )}
            </tbody>
        </table>

        return (mytable);
    }

    

    const toPdfJSPDF = () => {

        var doc = new jsPDF('p', 'pt', "a4");
       // doc.addFont("Arial", "Arial", "normal");
        doc.setFont("Arial", "normal");
        doc.setFontSize(14);
        doc.text(15, 40, "Evrak No:" + props.appData.id + "           Başvuru Tarihi:" + props.appData.basvuruTarihi)
        //doc.text(15, 60, changeTurkceToEnglis(props.appData.mevki) + "       " + changeTurkceToEnglis(props.appData.mahalle) + "       " + changeTurkceToEnglis(props.appData.ada) + "       " + changeTurkceToEnglis(props.appData.basvuruTipi))
        doc.text(15, 60, changeTurkceToEnglis(props.appData.mahalle) + "  -   " + changeTurkceToEnglis(props.appData.ada) + "  -  " + changeTurkceToEnglis(props.appData.arsaSahibi) + "  -  " + changeTurkceToEnglis(props.appData.basvuruTipi)+" Kontrolor Duzeltmeleri") 

        doc.text(15, 85,"")
        var source = document.getElementById("pdfsource").cloneNode(true);
        source.className = "";
        var colElems = source.firstChild.getElementsByClassName("buttonCol");
        for (var i = colElems.length - 1; i >= 0; i--) {
            colElems[i].remove()
        }
        var colElems = source.firstChild.getElementsByClassName("checkCol");
        for (var i = colElems.length - 1; i >= 0; i--) {
            colElems[i].firstChild.className = "";
            colElems[i].firstChild.style["width"] = "12px";
        }

        colElems = source.firstChild.getElementsByClassName("dateCol");
        for (var i = colElems.length - 1; i >= 0; i--) {
            colElems[i].innerText = colElems[i].innerText.replace("/", "");
            colElems[i].innerText = colElems[i].innerText.replace("/", "  ");
            colElems[i].innerText = colElems[i].innerText.replace(":", " : ");
        }


        colElems = source.firstChild.getElementsByClassName("messageCol");
        if (colElems!=null)
        for (var i = colElems.length - 1; i >= 0; i--) {
            colElems[i].parentNode.innerText = colElems[i].innerText;
        }

        source.innerHTML = changeTurkceToEnglis(source.innerHTML);

        source.style["font-size"] = "10px";
        source.style["font-family"] = "Arial";
        source.style["letter-spacing"] = "0.0px";
       // source = source.replaceAll("delete", "")
        doc.html(
            source, {
            'x': 15,
                'y': 60,
               
                width: 520,
                //margin:5,
                windowWidth: 520,
                margin: [40, 0, 40, 0],
                callback: function (pdf) {
                    pdf.setFontSize(12);
                    pdf.text(15, 810, changeTurkceToEnglis("Kontrolor:  " + userdata.name + "   " + userdata.family))
                pdf.save('duzeltme_listesi.pdf');
                }

        });
        // window.open(doc.output("bloburl"), "_blank");
        //doc.output("dataurlnewwindow");
    }


    
   const setNameFamily = (data) => {
       setUserData(data);
    }

    useEffect(() => {
        fetchList();
    }, [])


    var listTable = <div className="spinner-border" ></div>;
    if (listData != null) {
        listTable = renderTable(listData);
    }


    var printbutton = <div className="d-flex justify-content-end mt-2 mb-2">
        <ReactToPdf targetRef={ref} filename="pdf_file.pdf" x={10} y={10} scale={0.7} >
            {({ toPdf }) => (
                <button className="btn btn-primary btn-sm text-decoration-none " onClick={toPdfJSPDF}>PDF Oluştur</button>
            )}
        </ReactToPdf>
    </div>

    setTimeout(updateTextAreasHeight, 1000);

    return (
        <div className="border rounded-1 ps-2 pe-2" >
            <div className="row pt-2"  >
                <div className="col">
                    <h6 className="text-primary p-0 m-0">Düzeltmeler</h6 >
             
                </div>
                <div className="col text-end" id="userinfo">
                    
                    <UserLabel user={{ userName: props.user }} setNameFamily={setNameFamily}/>
                </div>
            </div>


            <div className="row" ref={ref} >

                <div className="col-12 " >
                    
                    {listTable}
                </div>
            </div>
            {(props.mappingRec.Started != null || props.mappingRec.duzeltmeDurum == true) ? <div className="row mt-1"  >
                <div className="col-11">
                    <textarea type="text" maxlength="1000" style={{ "background-color": "transparent" }} className="form-control form-control-sm " id={"revmessage" + mappingid} key={mappingid} placeholder="Yeni Istek" name="revmessage" defaultValue="" />
                </div>
                <div className="col-1">
                    <button disabled={props.mappingRec.Completed != null || props.mappingRec.userName != myusername} onClick={Add} className="btn btn-sm btn-primary text-decoration-none ">Ekle</button>
                </div>

            </div> : ""}
            <div className="row" >
                <div className="col-12">
                    {printbutton}
                </div>
            </div>
        </div>



    );


}