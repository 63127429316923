import React, { useState, useEffect } from 'react';
import { ajaxFail } from '../GlobalState.js';

export default function (props) {


    const [refcounter, setRefcount] = useState(props.counter);


    useEffect(() => {

        const script = document.createElement("script");
        script.src = "https://www.gstatic.com/charts/loader.js";
        script.async = true;
        document.getElementById("myscript").appendChild(script);
        script.onload = () => {
            window.google.charts.load('current', { 'packages': ['bar'] });
            window.google.charts.setOnLoadCallback(getWorkLoadList);
        }

    }, [props.counter]);


    const getWorkLoadList = () => {
        fetch("getuserworkloads", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
                this.setState({ status: response.statusText })
            }
            else {
                response.json().then(async data => {
                    var dataarray = [];
                    var userstat = {}
                    var colors = ["color: #fff8dd", "color: #e8fff3", "color: #fff5f8", "color: #f8f5ff"];
                    dataarray.push(['', 'Toplam', 'Biten', 'Devam Eden', 'Sırada Bekleyen', 'Günllük Ortalama', { role: "style" }]);

                    for (var i = 0; i < data.length; i++) {
                        userstat[data[i].userName] = { toplam: 0, biten: 0, devam: 0, bekleyen: 0, ortalama: 0 }
                    }

                    for (var i = 0; i < data.length; i++) {
                        userstat[data[i].userName].toplam++;
                        if (data[i].started == null && data[i].completed == null)
                            userstat[data[i].userName].bekleyen++;
                        if (data[i].started != null && data[i].completed == null)
                            userstat[data[i].userName].devam++;
                        if (data[i].started != null && data[i].completed != null)
                            userstat[data[i].userName].biten++;
                    }
                    for (var i = 0; i < data.length; i++) {
                        userstat[data[i].userName].ortalama = userstat[data[i].userName].biten / 30.0;
                    }

                    var i = 0;
                    for (var rec in userstat)
                        dataarray.push([rec, userstat[rec].toplam, userstat[rec].biten, userstat[rec].devam, userstat[rec].bekleyen, userstat[rec].ortalama, colors[0]]);

                    drawChart(dataarray, 'userstaticpanel2')
                });
            }
        });
    }

    const drawChart = (dataarray, target) => {
        var data = window.google.visualization.arrayToDataTable(dataarray);
        var options = {
            colors: ['#2E4C6D', '#DADDFC', '#FC997C', '#66806A', '#7E8BD9', '#558D97', '#B4C6A6', '#FFC286', '#FFF1AF'],
            chartArea: {
                // leave room for y-axis labels
                width: '90%'
            },
            hAxis: {
                textStyle: {
                    fontSize: 18

                }

            },
            vAxis: {

                textStyle: {
                    fontSize: 18 // or the number you want
                }

            },
            animation: {
                duration: 1000,
                easing: 'out',
                "startup": true
            },
            height: 100 * dataarray.length,
            width: '100%',
            bars: 'horizontal' // Required for Material Bar Charts.

        };
        var chart = new window.google.charts.Bar(document.getElementById(target));
        chart.draw(data, window.google.charts.Bar.convertOptions(options));

    } 
    return (
        <div className="border shadow rounded-2 border p-1 mt-1 me-2" >
            <div id="myscript"></div>
            <div className="row">
                <div className="col-7">
                    <h4 style={{ "text-shadow": "0px 0px 1px grey", color: "#0098db" }}>İş Yükü</h4>
                </div>

            </div>
            <div id="userstaticpanel2"></div>
        </div>

    );
}





