import React, { useState, useEffect } from 'react';
import Application from "./Application.js"
import SharedUsersPanel from './SharedUsersPanel.js';
import ApplicationListCardView from './ApplicationListCardView.js';
import { useGlobalState } from '../GlobalState.js';

export default function AppCard(props) {
    var apprec = props.apprec;
    const [role, setRole] = useGlobalState('role');
    const [userName, setUserName] = useGlobalState('userName');
    useEffect(() => {

    }, [])
    var colors = ["#fff8dd", "#e8fff3", "#fff5f8", "#f8f5ff"]
    var bordercolors = ["#50cc88", "#009cf7", "#ffc800", "#f1416d"]

    const getBasvuruTuruName = (name) => {
        return name.replaceAll('"name":', "").replaceAll(']', "").replaceAll('[', "").replaceAll('{', "").replaceAll('}', "").replaceAll('"', "")

    }

    const getAppTypeName = (i) => {
        if (props.appTypes == null) return i + "";
        var r = props.appTypes.find(element => element.id == i);
        if (r == null) return i;
        return r.name;
    }

    const drag = (event) => {
        //event.dataTransfer.effectAllowed = "move";
        event.dataTransfer.setDragImage(document.getElementById("card" + apprec.id + " " + apprec.id1), 0, 0)
        event.dataTransfer.setData("text", apprec.id + " " + (props.kontrolUserName != null ? props.kontrolUserName + " " + props.apprec.id1:""));
    }
    const allowDrop = (event) => {
        event.preventDefault();
    }

    const drop = (event) => {
        event.preventDefault();
        document.getElementById("card" + apprec.id + " " + apprec.id1).classList.remove("pt-3", "opacity-75")
        if (props.kontrolUserName != null)
            props.drop(event,props.kontrolUserName)
        
    }
    
    var dragCount = 0;
    const dragleave = (event) => {
        dragCount--;
        event.preventDefault();
        if (dragCount <= 0)
            document.getElementById("card" + apprec.id + " " + apprec.id1).classList.remove("pt-3", "opacity-75")
    }
    const enter = (event) => {
        dragCount++;
        event.preventDefault();
        if (dragCount == 1)
            document.getElementById("card" + apprec.id + " " + apprec.id1).classList.add( "pt-3",  "opacity-75")
    }

    return <div className="p-0" id={"card" + apprec.id + " " + apprec.id1} draggable="true" onDragStart={drag} onDragOver={allowDrop} onDrop={drop}  onDragEnter={enter} onDragLeave={dragleave}>

        <div className=" p-3  border border-rounded rounded-3 bg-white" >

            <div className="d-inline p-1 fw-bold d-block rounded-3"   style={{ "background-color": colors[apprec.id % 4] }}><img className="rounded-circle" style={{ width: "32px", height: "32px" }} src={"/getuserpic?user=" + apprec.basvuruYapan.substring(0,1)} /> {apprec.ada}-{apprec.arsaSahibi == null ? "" : apprec.arsaSahibi.substring(0, Math.min(apprec.arsaSahibi.length, 20)) + (apprec.arsaSahibi.length > 20 ? "..." : "")}</div>

            
            <div className="mt-3 mb-4" >
                <a href="#" className="text-decoration-none text-secondary " onClick={() => { props.changeComponent(<Application appData={apprec} changeComponent={props.changeComponent} backButton={<ApplicationListCardView changeComponent={props.changeComponent} role={role} userName={userName}/>} />) }}>
                    {props.colnames.map(c => {
                        if (c == "basvuruTipi" && apprec[c] != null) {
                            return <div><span className="text-dark">Başvuru Tipi</span>: <span className="text-muted fw-bold">{getBasvuruTuruName(apprec[c]).substring(0, 24)}</span></div>
                        }
                        if (c == "basvuruDurumu" && apprec[c] != null) {
                            return <div><span className="text-dark">Başvuru Durumu</span>: <span className="text-muted fw-bold">{getAppTypeName(apprec[c]).substring(0, 24)}</span></div>
                        }
                        if (apprec[c] != null) {
                            return <div><span className="text-dark">{c.charAt(0).toUpperCase() + c.slice(1)}</span>: &nbsp; <span className="text-muted fw-bold">{String(apprec[c]).substring(0, Math.min(String(apprec[c]).length, 20)) + (String(apprec[c]).length > 20 ? "..." : "")}</span></div>

                        }

                        if (apprec.appData != null && apprec.appData.length > 2) {
                            var appdata = JSON.parse(apprec.appData)
                            if (appdata[c] != null) {
                                if (appdata[c].type == "checkbox")
                                    return <div><span className="text-dark">{c.charAt(0).toUpperCase() + c.slice(1)}</span>: &nbsp; <input className="form-check-input" type="checkbox" checked={appdata[c].value} /> </div>
                                return <div><span className="text-dark">{c.charAt(0).toUpperCase() + c.slice(1)}</span>: &nbsp; <span className="text-muted fw-bold">{String(appdata[c].value).substring(0, Math.min(String(appdata[c].value).length, 20)) + (String(appdata[c].value).length > 20 ? "..." : "")}</span></div>
                            }
                        }
                        return <div><span className="text-dark">{c.charAt(0).toUpperCase() + c.slice(1)}</span>: </div>
                    })}
                </a>

            </div>

           {/* <SharedUsersPanel appData={apprec} showCompact={true} />*/}
        </div>
    </div>
}
