import React, { Component } from 'react';
import AppCard from './AppCard.js';
import UserAppCard from '../Application/UserAppCard.js';
import * as UserPermConstants from '../User/UserPermConstants';
import { passFilter, removeTurkcLow } from '../GlobalState.js';
import { ajaxFail, isEmpty } from '../GlobalState.js';

import $ from 'jquery';


export default class ApplicationListCardView extends Component {
    sortField = "";
    constructor(props) {
        super(props);
        this.role = props.role;
        this.userName = props.userName;
        this.state = {
            showgroups: false,
            status: "init", applist: null, colnames: ["id", "basvuruTarihi", "basvuruTipi", "ada", "mahalle", "mevki", "mimar"]
        };
        this.getApplicationList = this.getApplicationList.bind(this);
        
        this.getAppGroupList = this.getAppGroupList.bind(this);
        this.refreshList = this.refreshList.bind(this);
        this.generateGroupList = this.generateGroupList.bind(this);
        /*this.renderToCards = this.renderToCards.bind(this);*/
        this.getAppTypes = this.getAppTypes.bind(this);
        this.fetchDenetimList = this.fetchDenetimList.bind(this);
        this.fetchkabullistperm = this.fetchkabullistperm.bind(this);

        this.getSablon = this.getSablon.bind(this)
        this.filterTable = this.filterTable.bind(this)
        this.handleSablonChange = this.handleSablonChange.bind(this)
        this.setDevamStat = this.setDevamStat.bind(this)
        this.devamstat = { biten: false, devam: false, bekleyen: false, tumu: false };

        this.filter = null;
        this.mysablons = null;
        this.changeComponent = props.changeComponent;
        this.appGroups = null;
        this.kabulPerms = null;
        this.appTypes = [];
        this.bordercolors = ["#50cc88", "#009cf7", "#ffc800", "#f1416d"];
        this.handleDenetimChange = this.handleDenetimChange.bind(this);
    }

    refreshList(data) {
        this.setState({
            status: "init", applist: data
        })
    }
    componentDidMount() {
        this.getSablon();
        this.fetchDenetimList();
        this.getAppGroupList();
        this.getAppTypes();
        this.fetchkabullistperm();
    }

    myCompare = (a, b) => {
        if (a[this.sortField] < b[this.sortField]) {
            return -1;
        }
        if (a[this.sortField] > b[this.sortField]) {
            return 1;
        }
        return 0;
    }



    setDevamStat = (d) => {
        this.devamstat = d;
        this.setState({ ...this.state, "devamstat": d });
    }

    myCompareBasvuruTipi = (a, b) => {
        var s1 = a[this.sortField];
        var s2 = b[this.sortField];
        if (s1 == null) return -1;
        if (s2 == null) return 1;

        var sa = s1.replaceAll('"name":', "").replaceAll(']', "").replaceAll('[', "").replaceAll('{', "").replaceAll('}', "").replaceAll('"', "");
        var sb = s2.replaceAll('"name":', "").replaceAll(']', "").replaceAll('[', "").replaceAll('{', "").replaceAll('}', "").replaceAll('"', "");

        if (sa < sb) {
            return -1;
        }
        if (sa > sb) {
            return 1;
        }
        return 0;
    }


    sortList = (fildname) => {
        this.sortField = fildname;
        var temp = this.state.applist;
        if (fildname.toUpperCase() == "BASVURUTIPI")
            temp.sort(this.myCompareBasvuruTipi);
        else
            temp.sort(this.myCompare);
        this.setState({ applist: temp, status: "done" });
    }

     fetchkabullistperm  ()  {
        fetch("kabullistpermsnames", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(this.userName)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    this.kabulPerms=value;
                })
            }
        });
    }

    getSablon() {
        var refreshButton = document.getElementById("refreshButton");
        if (refreshButton != null) {
            refreshButton.classList.remove("fa", "fa-refresh")
            refreshButton.classList.add("spinner-grow", "spinner-grow-sm")
        }


        fetch("getmysablon", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
                this.setState({ status: response.statusText })
            }
            else {
                response.json().then(async data => {
                    data = data.filter(r => r.forTableView == true);
                    this.mysablons = data;
                    if (data != null && data.length > 0) {
                        data = this.mysablons[0];
                        if (window.localStorage.getItem('sablon') != null && this.mysablons[window.localStorage.getItem('sablon')] != null)
                            data = this.mysablons[window.localStorage.getItem('sablon')];
                        if (data.filter != null)
                            this.filter = data.filter;
                        if (data.colNames != null && data.colNames.length > 0) {
                            var d = data.colNames.replaceAll('"', "");
                            if (d.length > 0)
                                this.setState({ ...this.state, colnames: d.split(",") });
                        }
                    }


                });
            }
        });
    }


    getAppGroupList() {


        fetch("getappgrouplist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    this.appGroups = value;

                })
            }
        });
    }

    getAppTypes() {

        fetch("sureclist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    this.appTypes = value;
                    this.getApplicationList()
                })
            }
        });
    }

    fetchDenetimList() {
        fetch("denetimlist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(data => {

                    this.denetimlist = data;
                })
            }
        });
    }


    showApplication(id) {
        this.setState({ applist: this.state.applist, status: "appdetails", appid: id });
    }

    handleSablonChange(sab) {
        window.localStorage.setItem('sablon', sab);
        var data = this.mysablons[sab];
        if (data.filter != null)
            this.filter = data.filter;
        if (data.colNames != null && data.colNames.length > 0) {
            var d = data.colNames.replaceAll('"', "");
            if (d.length > 0)
                this.setState({ ...this.state, colnames: d.split(",") });
        }
    }


    appsauthorlist = (applist) => {
        var ids = applist.map(x => x.id).join();
        fetch("appsauthorlist", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(ids)

        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(async data => {
                    applist.forEach((k) => { k.müellif = "" });
                    data.forEach((k) => { applist.find(x => x.id == k.appID).müellif += k.userName + "(" + k.muellifType + ")  " });
                    this.setState({ applist: applist, status: "done" });
                });
            }
        });
    }




     getcontrollerlist = (applist) => {
        var ids = applist.map(x => x.id).join();
        fetch("getcontrollerlist", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(ids)

        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(async data => {
                    applist.forEach(x => {
                        x.tümKontrolorler = ""
                        x.müellif = "";
                        x.muellifCount = 0;
                        x.totallContolorCount = 0;
                        x.activeContolorCount = 0;
                        x.allmuelliflist = "";
                        x.kontrolorNameFamily = ""
                        x.kontrolor = ""
                    });
                    data = JSON.parse(data)
                    data.forEach((k) => {
                        var apprec = applist.find(x => x.id == k.appID);

                        if (k.sira == k.activeDenetim) {
                            if (isEmpty(apprec.kontrolor) == false) {
                                apprec.kontrolor += ", ";
                                apprec.kontrolorNameFamily += ", ";
                            }
                            apprec.kontrolor += k.userName;
                            apprec.kontrolorNameFamily += k.Name + " " + k.Family;
                        }


                        if (isEmpty(apprec.tümKontrolorler) == false)
                            apprec.tümKontrolorler += ", ";
                        apprec.tümKontrolorler += k.Name + " " + k.Family;





                        if (k.userName == this.userName) {

                            if (isEmpty(k.Started) && k.duzeltmeDurum != true && k.sira == k.activeDenetim) {
                                apprec["Kontrol Durumu"] = this.getDurumName(UserPermConstants.Basvuru_Durumu_Kontrol_Sirada_Bekliyor)
                            }

                            if (isEmpty(k.Started) == false && isEmpty(k.Completed) && k.sira == k.activeDenetim) {
                                apprec["Kontrol Durumu"] = this.getDurumName(UserPermConstants.Basvuru_Durumu_Kontrol_Ediliyor)
                            }

                            if (isEmpty(k.Completed) == false) {
                                apprec["Kontrol Durumu"] = this.getDurumName(UserPermConstants.Basvuru_Durumu_Kontrol_Tamamlandi)
                            }

                            if (isEmpty(k.Started) && isEmpty(k.duzeltmeIste) == false && k.duzeltmeDurum == true && k.sira == k.activeDenetim) {
                                apprec["Kontrol Durumu"] = this.getDurumName(UserPermConstants.Basvuru_Durumu_Duzeltme_Bekleniyor)
                            }
                        }





                    });
                    this.appsauthorlist(applist);
                   



                });
            }
        });
    }

     getDurumName = (i) => {
         var r = this.appTypes.find(element => element.id == i);
        if (r == null) return i;
        return r.name;
    }

    //getcontrollerlist = (applist) => {
    //    var ids = applist.map(x => x.id).join();
    //    fetch("getcontrollerlist", {
    //        method: 'POST',
    //        headers: {
    //            'Accept': 'application/json',
    //            'Content-Type': 'application/json'
    //        },
    //        body: JSON.stringify(ids)

    //    }).then(async response => {
    //        if (!response.ok) {
    //            ajaxFail(response);
    //        }
    //        else {
    //            response.json().then(async data => {
    //                data = JSON.parse(data)
    //                applist.forEach((k) => {
    //                    k.kontrolor = "";
    //                    k.tümKontrolorler = "";
    //                    k.başvuruSahibi = k.basvuruSahibi;
    //                });
    //                data.forEach((k) => {
    //                    var a = applist.find(x => x.id == k.appID);
    //                    if (a != null) {
    //                        if (k.id == k.activeDenetim)
    //                            a.kontrolor = k.userName

    //                        if (a.tümKontrolorler != null && a.tümKontrolorler.length > 0)
    //                            a.tümKontrolorler += ", ";
    //                        a.tümKontrolorler += k.name + " " + k.family;
    //                    }

    //                });
    //                this.appsauthorlist(applist)

    //            });
    //        }
    //    });
    //}



    getApplicationList() {
        fetch("applicationlist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            var refreshButton = document.getElementById("refreshButton");
            if (refreshButton != null && refreshButton.classList.contains("fa-refresh") == false) {
                refreshButton.classList.remove("spinner-grow", "spinner-grow-sm")
                refreshButton.classList.add("fa", "fa-refresh")
            }
            if (!response.ok) {
                ajaxFail(response);
                this.setState({ status: response.statusText })
            }
            else {
                response.json().then(async data => {
                    var notremoved = data.filter(x => x.removed != true).sort((a, b) => {
                        return a.basvuruTarihi < b.basvuruTarihi ? 1 : -1;
                    });
                    this.setState({ applist: notremoved, status: "done" });

                    this.getcontrollerlist(notremoved);
                });
            }
        });
    }
    showApplication(id) {
        this.setState({ applist: this.state.applist, status: "appdetails", appid: id });
    }

    filterTable(event) {
        const target = event.target;
        const value = target.value.toLowerCase();
        $("#myTable td.align-top div.border").filter(function () {
            var keywords = value.split(" ");
            var t1 = removeTurkcLow($(this).text())
            for (let index = 0; index < keywords.length; ++index)
                if (t1.indexOf(removeTurkcLow(keywords[index])) == -1)
                    return $(this).toggle(false);
            return $(this).toggle(true);
        });
    }





    generateGroupList(data) {
        var appglist = [];
        data = data.filter(apprec => passFilter(apprec, this.filter, this.userName, this.kabulPerms) == true)
        if (this.selectedDenetim != null)
            data = data.filter(apprec => apprec.basvuruTipi == null || apprec.basvuruTipi.length == 0 || apprec.basvuruTipi == this.selectedDenetim)

        for (var i = 0; i < this.appGroups.length + 1; i++)
            appglist.push([]);
        for (var i = 0; i < data.length; i++) {
            var j = 0;
            for (var j = 0; j < this.appGroups.length; j++)
                if (this.appGroups[j].appState!=null && this.appGroups[j].appState.length > 0)
                    if (this.appGroups[j].appState.split(",").includes(data[i].basvuruDurumu + ""))
                        break;
            appglist[j].push(data[i]);
        }
        return appglist;
    }

    handleDenetimChange() {
        this.selectedDenetim = document.getElementById("denetimSelect").value;
        if (this.selectedDenetim.trim().length == 0)
            this.selectedDenetim = null;

        this.setState({ ...this.state, selectedDenetim: this.selectedDenetim });
    }



    findRowCount = (sab, tabledata) => {

        var r = tabledata.filter(apprec =>
            apprec.removed != true &&
            passFilter(apprec, this.mysablons[sab].filter, this.userName, this.kabulPerms) == true && (this.selectedDenetim == null || this.selectedDenetim.appState == undefined || this.selectedDenetim.appState.split(",").includes(apprec.basvuruDurumu + "")));
        return r.length;
    }

    renderToGroupCards(tabledata) {

        var selectedSablonIndex = window.localStorage.getItem('sablon');
        if (selectedSablonIndex == null)
            selectedSablonIndex = 0;


        var tablegdata = this.generateGroupList(tabledata);






        var mytable = <div key={tabledata}>
            <div className="  align-items-center justify-content-center  p-1" >
                <div class="row rounded-3 ms-2 me-2 p-2 justify-content-left bg-white">

                    <div class="col-md-3">
                        {this.denetimlist != null ? <select className="form-select form-select-sm" id="denetimSelect" name="denetimSelect" onChange={this.handleDenetimChange}>
                            <option value="" selected></option> {this.denetimlist.map((s, idx) => <option value={s.name} > {s.name}</option>)}
                        </select> : ""}
                    </div>
                    <div class="col-md-4">
                        <input className="form-control form-control-sm" id="myInput" type="text" placeholder="Arama..." onKeyUp={this.filterTable} />

                    </div>
                    <div className="col-5 text-end pt-2">
                        <a onClick={() => {
                            this.getSablon();
                            this.fetchDenetimList();
                            this.getAppGroupList();
                            this.getAppTypes();
                        }} href="#" className="text-primary"> <i className="fa fa-refresh" id="refreshButton"></i></a>
                    </div>
                </div>
            </div>

            <div className="p-2">
                <div className="overflow-auto w-100 mainpagescroll" style={{ "white-space": "nowrap" }}>
                    {this.mysablons != null ? this.mysablons.map((s, idx) => <button className={"btn  me-2 text-decoration-none border mb-1 mt-1" + (selectedSablonIndex == idx ? " btn-primary" : " btn-link")} onClick={() => this.handleSablonChange(idx)}> {s.name} ({this.findRowCount(idx, tabledata)})</button>) : ""}
                </div>
            </div>


            {/*<div className="mt-1 " style={{ overflow: "scroll", width: "100%", height: "80vh", display: "block", "overflow-x": "auto", "white-space": "nowrap" }}>*/}
            <div className="table-responsive mt-1 mainpagescroll" style={{ "transform": "rotateX(180deg)" }}>

                <table className="table" id="myTable" style={{ "transform": "rotateX(180deg)" }}>
                    <tr className="m-0 p-0 text-center">{this.appGroups.map((grec, idx) =>
                        <th className="m-0 p-0" style={{ "width": "1%", "min-width": "17vw" }}>
                            <div className="border-4 ms-3 me-3 p-1 fw-bold text-dark" style={{ "border-bottom": "4px solid " + this.bordercolors[grec.id % 4] }} >  {grec.name} ({tablegdata[idx].length})
                            </div>

                            {/* {Object.keys(mevki_statis[idx]).map(mev => <div className="text-muted m-0 p-0"> {(mev=="null"|| mev=="")?"Bilinmeyen":mev} : {mevki_statis[idx][mev]} </div>)}*/}



                        </th>)}
                        <th className="m-0 p-0" style={{ "width": "1%", "min-width": "17vw" }}>
                            <div className="border-bottom border-4 ms-3 me-3 p-1 border-primary"> Diğer ({tablegdata[tablegdata.length - 1].length})</div>
                            {/* {Object.keys(mevki_statis[tablegdata.length - 1]).map(mev => <div className="text-muted m-0 p-0"> {mev == "null" ? "Bilinmeyen" : mev} : {mevki_statis[tablegdata.length - 1][mev]} </div>)}*/}

                        </th>
                    </tr>


                    <tr className="">
                        {tablegdata.map(grec =>
                            <td className="align-top">
                                {grec.map(apprec => <AppCard appTypes={this.appTypes} apprec={apprec} colnames={this.state.colnames} changeComponent={this.changeComponent} />
                                )}
                            </td>

                        )} </tr>
                </table>
            </div>
        </div>
        return (mytable);
    }



    render() {
        var tabledata = <div className="w-100 mt-4 mb-4 text-center align-items-center justify-content-center" >
            <div className="spinner-border" ></div>
        </div>
        if (this.state.applist != null) {
            tabledata = <div className="w-100 mt-4 mb-4 text-center align-items-center justify-content-center text-danger" >Kayıt Yok</div>
            if (this.state.applist.length > 0) {
                if (this.appGroups.length > 0 && this.state.showgroups == false && this.kabulPerms!=null) {
                    tabledata = this.renderToGroupCards(this.state.applist);
                }
            }
        }

        return <div style={{ "background-color": "#f5f8fa" }}>
            {tabledata}
        </div>



    }

};
