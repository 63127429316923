import React, { useState, useEffect } from 'react';
import { useGlobalState } from '../GlobalState.js';
import $ from 'jquery';
import * as UserPermConstants from '../User/UserPermConstants';
import { ajaxFail } from '../GlobalState.js';
import { removeTurkcLow } from '../GlobalState.js';


export default function AddressBook(props) {
    var postdata = {};
    const [listData, setListData] = useState([]);
    const colors = ["#fff8dd", "#e8fff3", "#fff5f8", "#f8f5ff"]
    const bordercolors = ["#50cc88", "#009cf7", "#ffc800", "#f1416d"]
    const [role, setRole] = useGlobalState('role');
    const [userlist, setUserlist] = useState([]);

    const updateRecord = (rec, event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        rec[name] = value;
    }
    var reccount = listData.length + userlist.length;

    const removeRecord = (id) => {
        fetch("removefromaddressbook", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(id)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    setListData(value);
                })
            }
        });
    }


    const getUserList = () => {
        fetch("userlist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
                this.setState({ status: response.statusText })
            }
            else {
                response.json().then(async data => {
                    var ulist = [];
                    //for (var i = 0; i < data.length; i++)
                    //    ulist.push("<option value=" + i + ">" + data[i].name + " " + data[i].family + "</option>");
                    //document.getElementById("userlist").innerHTML = ulist;
                    //document.getElementById("userlist").selectedIndex = -1;
                    setUserlist(data)
                });

            }
        });
    }


    const submitUpdateRecord = (rec) => {

        fetch("updateaddressbook", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(rec)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                alert("Güncelleme Başarıyla Tamamlanmştır")
            }
        });
    }

    const fetchList = () => {

        fetch("getaddresslist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    setListData(value);
                })
            }
        });
    }

    const Add = () => {
        fetch("addtoaddressbook", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postdata)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                document.getElementById("isim").value = "";
                document.getElementById("soyisim").value = "";
                document.getElementById("tel").value = "";
                document.getElementById("email").value = "";
                document.getElementById("userType").value = "";
                fetchList();

            }
        });

    }




    const filterTable = (event) => {
        const target = event.target;
        const value = removeTurkcLow(target.value).toLowerCase();
        var keywords = value.split(" ");

        $("#myTable tr").filter(function () {
            var rowtext = "";
            var cc=$(this).children().length;
            for (var c = 0; c < cc; c++) 
                if ($(this).children().eq(c + 2).children(":first").val() != null) {
                    rowtext += $(this).children().eq(c + 2).children(":first").val() + " ";
            }
            var t1 = removeTurkcLow(rowtext);

            for (let index = 0; index < keywords.length; ++index) {
                if (t1.indexOf(keywords[index]) == -1) {
                    return $(this).toggle(false);
                }
            }
            return $(this).toggle(true);
        });

    }

   /* const filterTable = (event) => {
        const target = event.target;
        const value = removeTurkcLow(target.value).toLowerCase();
        var cl = ["name", "family", "userTypef", "firmaf", "telefon", "emailf", "idf"]
        $("#myTable tr").filter(function () {
            for (var c = 0; c < cl.length; c++) {
                if ($(this).children().eq(c + 2).children(":first").val() != null) {
                    var t1 = removeTurkcLow($(this).children().eq(c + 2).children(":first").val()).toLowerCase();
                    if (t1.indexOf(value) != -1)
                        return $(this).toggle(true);
                }

            }
            return $(this).toggle(false);
        });
    }*/

    const AddFromUsers = () => {
        var idx = document.getElementById("userlist").value;
        var rec = userlist[idx]
        fetch("addtoaddressbook", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ isim: rec.name, soyisim: rec.family, tel: rec.tel, email: rec.email, userType: rec.userType })
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                document.getElementById("isim").value = "";
                document.getElementById("soyisim").value = "";
                document.getElementById("tel").value = "";
                document.getElementById("email").value = "";
                document.getElementById("userType").value = "";
                fetchList();

            }
        });

    }

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        postdata[target.name] = value;
    }

    const filterColumn = (event) => {
        const target = event.target;
        const value = target.value.toLowerCase();
        var cl = ["name", "family", "userTypef", "firmaf", "telefon", "emailf", "idf"]
        $("#myTable tr").filter(function () {
            for (var c = 0; c < cl.length; c++) {
                if (document.getElementById(cl[c]).value != null && document.getElementById(cl[c]).value.length > 0)
                    if ($(this).children().eq(c + 1).children(":first").val() != null) {
                        if (removeTurkcLow($(this).children().eq(c + 1).children(":first").val()).toLowerCase().indexOf(removeTurkcLow(document.getElementById(cl[c]).value).toLowerCase()) == -1)
                            return $(this).toggle(false);

                    }

            }
            return $(this).toggle(true);
        });
    }


    const renderTable = (tabledata) => {

        var mytable = <div>
            <div className="row rounded-3 m-1 ">
                {/*{role != null && role.indexOf(UserPermConstants.Adres_Defterini_Duzenleme) > -1 &&*/}
                {/*    <div className="col-12 pt-2 pb-2 bg-light rounded-3 border-white border-end border-5" >*/}
                {/*        <h6>Manuel kayıt ekle</h6>*/}
                {/*        <div className="row mb-4">*/}
                {/*            <div className="col-md-2 col-12 " >*/}
                {/*                <input type="text" className="form-control form-control-sm" placeholder="İsim" id="isim" name="isim" onChange={handleInputChange} defaultValue="" />*/}
                {/*            </div>*/}
                {/*            <div className="col-md-2 col-12  " >*/}
                {/*                <input type="text" className="form-control form-control-sm " placeholder="Soyisim" id="soyisim" name="soyisim" onChange={handleInputChange} defaultValue="" />*/}
                {/*            </div>*/}
                {/*            <div className="col-md-2 col-12  " >*/}
                {/*                <input type="text" className="form-control form-control-sm " placeholder="Tel" id="tel" name="tel" onChange={handleInputChange} defaultValue="" />*/}
                {/*            </div>*/}

                {/*            <div className="col-md-2 col-12 " >*/}
                {/*                <input type="text" className="form-control form-control-sm " placeholder="Email" id="email" name="email" onChange={handleInputChange} defaultValue="" />*/}
                {/*            </div>*/}
                {/*            <div className="col-md-2 col-12 " >*/}
                {/*                <input type="text" className="form-control form-control-sm " placeholder="kullanıcı Tipi" id="userType" name="userType" onChange={handleInputChange} defaultValue="" />*/}
                {/*            </div>*/}

                {/*            <div className="col-md-2 col-12 ">  <button onClick={Add} className="btn btn-sm btn-link text-decoration-none m-0 p-0">Ekle</button></div>*/}
                {/*        </div>*/}
                {/*    </div>}*/}
            </div>
            <div style={{ "overflow-x": "auto" }}>
                <table className='table table-vdstriped  table-sm' >
                    <thead className="text-center">
                        <tr >
                            <th width="20px"></th>
                           {/* <th width="20px"></th>*/}

                            <th ><input type="text" className="form-control form-control-sm " id="name" name="name" placeholder="İsim" onChange={filterColumn} /></th>
                            <th ><input type="text" className="form-control form-control-sm " id="family" name="family" placeholder="Soyisim" onChange={filterColumn} />    </th>
                            <th ><input type="text" className="form-control form-control-sm " id="userTypef" name="userType" placeholder="Kullanıcı Tipi " onChange={filterColumn} />  </th>
                            <th ><input type="text" className="form-control form-control-sm " id="firmaf" name="firma" placeholder="Firma " onChange={filterColumn} />  </th>

                            <th ><input type="text" className="form-control form-control-sm " id="telefon" name="tel" placeholder="Tel" onChange={filterColumn} />     </th>
                            <th ><input type="text" className="form-control form-control-sm " id="emailf" name="email" placeholder="Email" onChange={filterColumn} />     </th>
                            <th ><input type="text" className="form-control form-control-sm " id="idf" name="id" placeholder="Kullanıcı Kodu " onChange={filterColumn} />  </th>
                            <th ></th>
                        </tr>
                    </thead>
                    <tbody id="myTable">
                        {userlist.map((apprec, idx) =>
                            <tr key={apprec.id} className="border-bottom" height="50px">

                                <td width="20px">
                                    {idx}
                                </td>
                                {/*<td>*/}
                                {/*    <img className="rounded-circle" style={{ width: "32px", height: "32px" }} src={"/getuserpic?user=" + apprec.userName} />*/}

                                {/*</td>*/}

                                <td  >
                                    <input type="text" className="form-control form-control-sm border-0" style={{ "background-color": "transparent" }} name="name" defaultValue={apprec.name} onChange={(e) => updateRecord(apprec, e)} />
                                </td>
                                <td  >
                                    <input type="text" className="form-control form-control-sm border-0" style={{ "background-color": "transparent" }} name="family" defaultValue={apprec.family} onChange={(e) => updateRecord(apprec, e)} />
                                </td>
                                <td  >
                                    <input type="text" className="form-control form-control-sm border-0" style={{ "background-color": "transparent" }} name="userType" defaultValue={apprec.userType} onChange={(e) => updateRecord(apprec, e)} />
                                </td>

                                <td  >
                                    <input type="text" className="form-control form-control-sm border-0" style={{ "background-color": "transparent" }} name="firma" defaultValue={apprec.firma} onChange={(e) => updateRecord(apprec, e)} />
                                </td>
                                <td  >
                                    <input type="text" className="form-control form-control-sm border-0" style={{ "background-color": "transparent" }} name="tel" defaultValue={apprec.tel} onChange={(e) => updateRecord(apprec, e)} />
                                </td>
                                <td  >
                                    <input type="text" className="form-control form-control-sm border-0" style={{ "background-color": "transparent" }} name="email" defaultValue={apprec.email} onChange={(e) => updateRecord(apprec, e)} />
                                </td>
                                <td  >
                                    <input type="text" className="form-control form-control-sm border-0" style={{ "background-color": "transparent" }} name="id" defaultValue={apprec.id} onChange={(e) => updateRecord(apprec, e)} />
                                </td>
                                <td ></td>
                            </tr>
                        )}



                        {tabledata.map((apprec, idx) =>
                            < tr key={apprec.id} height="40px" className="border-bottom">
                                <td width="20px">
                                    {idx + userlist.length}
                                </td>
                                <td>
                                </td>
                                <td>
                                    <input type="text" className="form-control form-control-sm border-0" style={{ "background-color": "transparent" }} name="isim" defaultValue={apprec.isim} onChange={(e) => updateRecord(apprec, e)} />
                                </td>
                                <td >
                                    <input type="text" className="form-control form-control-sm border-0" style={{ "background-color": "transparent" }} name="soyisim" defaultValue={apprec.soyisim} onChange={(e) => updateRecord(apprec, e)} />
                                </td>
                                <td >
                                    <input type="text" className="form-control form-control-sm border-0" style={{ "background-color": "transparent" }} name="tel" defaultValue={apprec.tel} onChange={(e) => updateRecord(apprec, e)} />
                                </td>
                                <td  >
                                    <input type="text" className="form-control form-control-sm border-0" style={{ "background-color": "transparent" }} name="email" defaultValue={apprec.email} onChange={(e) => updateRecord(apprec, e)} />
                                </td>
                                <td  >
                                    <input type="text" className="form-control form-control-sm border-0" style={{ "background-color": "transparent" }} name="userType" defaultValue={apprec.userType} onChange={(e) => updateRecord(apprec, e)} />
                                </td>

                                <td  >
                                </td>
                                <td  >
                                </td>
                                <td style={{ "overflow": "hidden", "white-space": "nowrap" }}>
                                    <button onClick={() => submitUpdateRecord(apprec)} className="btn btn-link btn-sm text-decoration-none m-0 p-0">Güncelle</button>&nbsp;
                                    <button onClick={() => removeRecord(apprec.id)} className="btn btn-link btn-sm text-decoration-none m-0 p-0">Sil</button>
                                </td>
                            </tr>
                        )}
                    </tbody >
                </table>
            </div>
        </div>

        return (mytable);
    }


    useEffect(() => {
        fetchList();
        getUserList();
    }, [])


    var listTable = "";
    if (listData != null) {
        listTable = renderTable(listData);
    }
    else
        listTable = renderTable([]);

    return (
        <div >
            <div className="row">
                <div className="col-7">
                    <h4 style={{ "text-shadow": "0px 0px 1px grey", color: "#0098db" }}>Adres Defteri</h4>
                </div>
                <div className="col-5 justify-content-end align-items-end text-end text-secondary"><b>{reccount}</b> kayıt</div>
            </div>
            <div className="row">
                <div className="col-12 text-center d-flex justify-content-center mb-2">

                    <input style={{ maxWidth: "400px" }} className="form-control form-control-sm" id="genelarama" type="text" placeholder="Arama" onKeyUp={filterTable} />

                </div>
            </div>

            {listTable}

        </div >


    );


}